import React, {Component, Fragment} from 'react';

import { observer } from 'mobx-react';

import {Paragraph} from './text';

import { DateRangePicker, SingleDatePicker} from 'react-dates';
import {MapIcon, ExitIcon} from '../common/icon';

import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

const getInputClassName = (disabled) => {
	let className = "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
	if(disabled){
		className = `${className} bg-transparent opacity-50`
	}else{
		className = `${className} bg-white`
	}
	return className;
}

@observer
class Label extends Component{
	render(){
		return (
			<label className="block text-sm font-medium leading-5 text-gray-700" {...this.props}>
                {this.props.text}
              </label>
		)
	}
}

@observer
class TextInput extends Component{
	constructor(props){
		super(props);
		this.proxyOnChange = this.proxyOnChange.bind(this);
	}

	proxyOnChange(e){
		this.props.onChange(e.target.value);
	}


	render(){
		let className = getInputClassName(this.props.disabled);
		if(this.props.className){
			className = `${className} ${this.props.className}`
		}
		return (
			<div className="flex flex-1 flex-col mt-1">
				<div className="flex flex-1 rounded-md shadow-sm">
		        	<input 
		        		{...this.props} 
		        		className={className} 
		        		onChange={this.proxyOnChange} 
		        	/>
		        </div>
		        {this.props.error && 
	        		<div className="appearance-none mt-2 text-red-500 text-sm font-bold">{this.props.error}</div>
	        	}
		    </div>
		)
	}
}

@observer
class TextArea extends Component{
	constructor(props){
		super(props);
		this.proxyOnChange = this.proxyOnChange.bind(this);
	}

	proxyOnChange(e){
		this.props.onChange(e.target.value);
	}

	render(){
		let className = getInputClassName(this.props.disabled);
		if(this.props.className){
			className = `${className} ${this.props.className}`
		}
		return (
			<div className="flex flex-col flex-1 mt-1">
				<div className="flex flex-1 mt-1 rounded-md shadow-sm">
		        	<textarea rows="3" 
		        		{...this.props}
		        		className={className} 
		        		onChange={this.proxyOnChange}></textarea>
		        </div>
		        {this.props.error && 
	        		<div className="appearance-none mt-2 text-red-500 text-sm font-bold">{this.props.error}</div>
	        	}
		    </div>
			
		)
	}
}

@observer
class CheckBox extends Component{
	constructor(props){
		super(props);
		this.proxyOnChange = this.proxyOnChange.bind(this);
	}

	proxyOnChange(e){
		this.props.onChange(e.target.checked);
	}

	render(){
		let className = "form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
		if(this.props.disabled){
			className = `${className} opacity-50`
		}
		return (
			<div className="relative flex flex-1 flex-row items-center mt-1">
              <div className="flex">
                <input 
                	type="checkbox" 
                	checked={this.props.checked} 
                	onChange={this.proxyOnChange}
					 className={className}
					 {...this.props} /> 
              </div>
              {this.props.label && 
              	<div className="pl-7 text-sm leading-5">
	                <Label text={this.props.label} />
	              </div>
	          }
	          {!this.props.label && <div></div>}
            </div>

		)
	}
}

@observer
class RadioButton extends Component{
	render(){
		let className = "form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out";
		if(this.props.disabled){
			className = `${className} opacity-50`
		}

		return (
			<div className="flex flex-1 mt-1">
				<input 
					id={this.props.id} 
					name={this.props.name} 
					type="radio"
					className={className}
					{...this.props}
					 />
			</div>
		)
	}
}

@observer 
class Button extends Component{
	constructor(props){
		super(props);
		this.proxyClick = this.proxyClick.bind(this);
	}

	proxyClick(e){
		e.preventDefault();
		this.props.onClick();
	}

	render(){
		let isFetching = this.props.fetching;

		let disabled = false;
		if('disabled' in this.props){
			disabled = this.props.disabled;
		}

		if(isFetching){
			disabled = true;
		}
		
		let fgColor = 'white';
		let bgColor = 'blue';
		if(this.props.fgColor && this.props.bgColor){
			fgColor = this.props.fgColor;
			bgColor = this.props.bgColor;
		}

		let btnClassName = `w-full flex flex-row items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-${fgColor} bg-${bgColor}-600 hover:bg-${bgColor}-500 focus:outline-none focus:border-${bgColor}-700 focus:shadow-outline-${bgColor} active:bg-${bgColor}-700 transition duration-150 ease-in-out`;
		if(disabled){
			btnClassName = `${btnClassName} opacity-50`
		}

		return (
			<span className="block w-full rounded-md mt-1">
				<button
					type="button"
					className={btnClassName}
					disabled={disabled}
					onClick={this.proxyClick}
					>
	              {isFetching ? 'Processing...' : this.props.children}
	            </button>
	        </span>
		)
	}
}

@observer
class CancelButton extends Component{
	render(){
		return (
			<Button fgColor="white" bgColor="red" {...this.props}>{this.props.children}</Button>
		)
	}
}

@observer
class Select extends Component{
	constructor(props){
		super(props);
		this.proxyOnChange = this.proxyOnChange.bind(this);
	}

	proxyOnChange(e){
		let value = e.target.value;
		if(value == -1){
			value = null
		}
		this.props.onChange(value);
	}

	render(){
		let className = "form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
		if(this.props.disabled){
			className = `${className} bg-transparent opacity-50`
		}
		return (
			<div className="mt-1">
				 <select 
				 	{...this.props}
				 	placeholder={this.props.placeholder}
				 	value={this.props.value || ''} 
				 	onChange={this.proxyOnChange} 
				 	className={className}
				 	>
				 	{this.props.placeholder != null &&
				 		<option key={-1} value={-1}>{this.props.placeholder}</option>
				 	}
	              	{this.props.options.map((option) => 
	              		<option key={option.value} value={option.value}>{option.label}</option>
	              	)}
	            </select>
	            {this.props.error && 
	        		<div className="appearance-none mt-2 text-red-500 text-sm font-bold">{this.props.error}</div>
	        	}
	        </div>
		)
	}
}

class RadioButtonWithLabel extends Component{
	render(){
		return (
			<div className="mt-4 flex items-center">
              <RadioButton name="form-input push_notifications" />
              <Label className="ml-3">
                <span className="block text-sm leading-5 font-medium text-gray-700">{this.props.label}</span>
              </Label>
            </div>
		)
	}
}

@observer
class DatePicker extends Component{
	constructor(props){
		super(props);
		this.showAllDatesRangeFunc = this.showAllDatesRangeFunc.bind(this);
		this.state = {
			focused: false
		}
	}

	showAllDatesRangeFunc(){
		return false;
	}

	render(){
		let datePickerClassName = "w-full rounded-md"
		let isOutsideRange = 'isOutsideRange' in this.props ? this.props.isOutsideRange : this.showAllDatesRangeFunc;
		return (
			<div className="flex flex-1 mt-1">
				<SingleDatePicker
				  small
				  noborder
				  hideKeyboardShortcutsPanel
				  format="DD/MM/YYYY"
				  displayFormat="DD/MM/YYYY"
				  date={this.props.date}
				  onDateChange={this.props.onDateChange}
				  focused={this.state.focused}
				  onFocusChange={({ focused }) => this.setState({ focused })}
				  id="date_picker"
				  className={datePickerClassName}
				  isOutsideRange={isOutsideRange}
				  {...this.props}
				/>
				{this.props.error && 
	        		<div className="appearance-none mt-2 text-red-500 text-sm font-bold">{this.props.error}</div>
	        	}
	        </div>
		)
	}
}

@observer
class DateTimePicker extends Component{
	render(){
		let className = getInputClassName(this.props.disabled);

		return (
			<div className="flex flex-col flex-1 mt-1">
				<Datetime 
					value={this.props.value}
					dateFormat="DD/MM/YYYY"
					timeFormat="HH:mm"
					onChange={this.props.onChange}
					className={className}
					inputProps={{
						placeholder: this.props.placeholder,
						disabled: this.props.disabled
					}}
				 />
				 {this.props.error && 
	        		<div className="appearance-none mt-2 text-red-500 text-sm font-bold">{this.props.error}</div>
	        	}
			</div>
		)
	}
}

@observer
class LocationSearchInput extends Component {
  constructor(props){
  	super(props);
  	this.onSelectedAddress = this.onSelectedAddress.bind(this);
  }

  onSelectedAddress(address){
    geocodeByAddress(address)
      .then((results) => {
      	if(results.length > 0){
      		this.props.onChangeAddressComponents(address, results[0].address_components);
      		return getLatLng(results[0]);
      	}
      })
      .then((latLng) => {
        this.props.onChange(address);
      	this.props.onReceivedCoordinates(latLng);
      })
      .catch(error => console.error('Error', error));
  }

  render() {
  	let disabled = 'disabled' in this.props ? this.props.disabled : false;
    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.props.onChange}
        onSelect={this.onSelectedAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="flex flex-col w-full">
            <input
              {...getInputProps({
                placeholder: this.props.placeholder,
                className: getInputClassName(disabled),
                disabled: disabled
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                const className = "py-2 px-4 bg-white text-base"
                return (
                  <div
                  	className="flex flex-col w-full"
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <div className="flex flex-row items-center">
                    	<div className="flex mr-2"><MapIcon color="blue-400" /></div>
                    	<span className="text-base">{suggestion.description}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

@observer
class SearchEntitiesList extends Component{
	constructor(props){
  		super(props);
  	}

	render(){
		let hasSelectedValue = this.props.hasSelectedValue;
		let disabled = 'disabled' in this.props ? this.props.disabled : false;
		return (
			<div className="flex flex-col relative">
				<TextInput 
					value={hasSelectedValue ? this.props.selectedValue : this.props.searchValue}
					disabled={disabled}
					readOnly={hasSelectedValue}
					placeholder={this.props.searchValuePlaceholder}
					onChange={this.props.onChangeSearchValue}
				/>

				{this.props.searchOptions.length > 0 && 
					<div className="absolute flex h-64 overflow-y-scroll flex-col w-full mt-12">
						{this.props.searchOptions.map((searchOption) => 
							<div className="flex flex-col w-full py-2 px-4 bg-white text-base" onClick={() => this.props.onSelectOption(searchOption.value)}>
								<div className="flex flex-row items-center">
			                    	{this.props.resultIcon != null && <div className="flex mr-2">{this.props.resultIcon}</div>}
			                    	<span className="text-base">{searchOption.label}</span>
			                    </div>
							</div>
						)}
					</div>
				}

				{hasSelectedValue && this.props.searchOptions.length == 0 &&
					<div className="absolute right-0 top-1/4 mr-2 cursor-pointer" onClick={() => this.props.onClearSelectedValue()}>
						<ExitIcon color="red-700" />
					</div>
				}
			</div>
		)
	}
}

export default LocationSearchInput;

export {
	Label,
	TextInput,
	CheckBox,
	RadioButton,
	Button,
	CancelButton,
	Select,
	TextArea,
	DatePicker,
	DateTimePicker,
	LocationSearchInput, 
	SearchEntitiesList
}