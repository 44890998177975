import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import JourneyApi from '../../services/journey';
import AccountApi from '../../services/account';
import DriverApi from '../../services/driver';

import moment from 'moment';

import { uuid } from 'uuidv4';

class ManageJourney{
	journeyApi;
	accountApi;
	driverApi;

	@observable addMode;
	@observable viewMode;
	@observable editMode;

	id;
	@observable name;
	@observable repeatsEveryDays;
	@observable archived;

	@observable journeyAccounts;
	@observable selectedDriver;

	@observable drivers;

	@observable fetching;
	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.journeyApi = new JourneyApi(this.uiStore);
		this.accountApi = new AccountApi(this.uiStore);
		this.driverApi = new DriverApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.id = null;
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.repeatsEveryDays = new FieldState(0).validators((val) => isPositiveNumberValidator(val, 'repeats every day'));
		this.archived = new FieldState(false).validators((val) => isNotNullValidator(val, 'archived'));
		this.form = new FormState({
			name: this.name,
			repeatsEveryDays: this.repeatsEveryDays,
			archived: this.archived
		});
		this.journeyAccounts = {
			accounts: [],
			page: 1,
			total: 0,
			hasNext: false,
			hasPrev: false,
			offsetStart: null,
			offsetEnd: null
		};
		this.selectedDriver = null;
		this.drivers = [];
	}


	@computed get validForm(){
		return this.name.value != null && this.name.value.length > 0;
	}

	@action save = async () => {
		const res = await this.form.validate();
	    if(res.hasError) return;

	    let payload = {
			name: this.name.value,
			repeats_every_days: parseInt(this.repeatsEveryDays.value, 10),
			archived: this.archived.value,
			driver_id: this.selectedDriver
		};

	    this.fetching = true;
	    if(this.editMode){
	    	this.journeyApi.updateJourney(this.id, payload)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Journey updated.');
	    			this.uiStore.goToJourneys();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }else{
	    	this.journeyApi.newJourney(payload)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Journey added.');
	    			this.uiStore.goToJourneys();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	formSync(journeyId){
		this.addMode = false;
		this.viewMode = true;

		this.id = journeyId;
		this.fetching = true;
		this.journeyApi.getById(journeyId)
			.then((response) => {
				let journey = response;

				this.id = journey.id;
				this.name.onChange(journey.name);
				this.repeatsEveryDays.onChange(journey.repeats_every_days);
				this.archived.onChange(journey.archived);
				this.selectedDriver = journey.driver_id;

				this.fetchJourneyAccounts();
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			});
	}
	
	@action onCancel(){
		this.uiStore.goToJourneys();
	}

	@action fetchJourneyAccounts(){
		this.accountApi.getAccountsForJourney(this.id, this.journeyAccounts.page)
			.then((response) => {
				this.journeyAccounts.accounts = response.accounts;
				this.journeyAccounts.hasNext = response.has_next;
				this.journeyAccounts.hasPrev = response.has_prev;
				this.journeyAccounts.page = response.page;
				this.journeyAccounts.offsetStart = response.offset_start;
				this.journeyAccounts.offsetEnd = response.offset_end;
				this.journeyAccounts.total = response.total;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onPreviousAccountsPage(){
		this.journeyAccounts.page = Math.max(this.journeyAccounts.page-1, 0);
		this.fetchJourneyAccounts();
	}

	@action onNextAccountsPage(){
		this.journeyAccounts.page += 1;
		this.fetchJourneyAccounts();
	}

	@action onEditAccount(id){
		this.uiStore.goToEditAccount(id);
	}

	fetchDrivers = async () => {
		try{
			this.drivers = await this.driverApi.fetchAllDrivers();
		}catch(e){
			console.log(e);
		}
	}

	@computed get driverOptions(){
		return this.drivers.map((driver) => {
			return {
				value: driver.id,
				label: driver.name
			}
		})
	}
}

export default ManageJourney;