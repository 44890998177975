import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed, extendObservable } from 'mobx';

import InvoiceApi from '../../services/invoice';

import moment from 'moment';

class InvoiceList{
	@observable invoices;
	@observable page;
	@observable total;
	@observable hasNext;
	@observable hasPrev;
	@observable offsetStart;
	@observable offsetEnd;

	@observable searchText;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.invoiceApi = new InvoiceApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.invoices = [];
		this.page = 1;
		this.total = 0;
		this.hasNext = false;
		this.hasPrev = false;
		this.offsetStart = null;
		this.offsetEnd = null;
		this.searchText = null;
	}

	fetchInvoices(){
		this.invoiceApi.getInvoices(this.page, null, this.searchText)
			.then((response) => {
				this.invoices = response.invoices.map((o) => {
					o.logged_on = moment(o.logged_on)
					return o;
				});

				this.page = response.page;
				this.total = response.total;
				this.hasPrev = response.has_prev;
				this.hasNext = response.has_next;
				this.offsetStart = response.offset_start;
				this.offsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchInvoices();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchInvoices();
	}

	onEdit(id){
		this.uiStore.goToEditInvoice(id);
	}

	onNewInvoice(){
		this.uiStore.goToNewInvoice();
	}

	changeSearchText(val){
		this.searchText = val;
		this.page = 1;
		this.fetchInvoices();
	}
}

export default InvoiceList;