import BaseApi from '../services/base';

class InvoiceApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getInvoiceById(id){
		return this.fetch(`v1/invoices/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	getInvoicePDFById(id){
		return this.fetch(`v1/invoices/${id}/pdf`, {
			method: 'GET',
			auth: true,
			contentType: 'application/pdf'
		})
	}

	getInvoices(page, accountId=null, searchText=null){
		let invoiceUrl = `v1/invoices?page=${page}`
		if(accountId != null){
			invoiceUrl = `${invoiceUrl}&account_id=${accountId}`
		}
		if(searchText != null){
			invoiceUrl = `${invoiceUrl}&search_text=${searchText}`	
		}
		return this.fetch(invoiceUrl, {
			method: 'GET',
			auth: true
		})
	}

	emailInvoice(id){
		return this.fetch(`v1/invoices/${id}/email`, {
			method: 'POST',
			auth: true
		})
	}
}

export default InvoiceApi;