import { FormState, FieldState } from 'formstate';
import { validator, isRequiredValidator } from '../../services/validation';
import { observable, action, computed, extendObservable } from 'mobx';

import ServicingApi from '../../services/servicing';

import moment from 'moment';

class ServicingList{
	@observable servicingItems;
	@observable page;
	@observable total;
	@observable hasNext;
	@observable hasPrev;
	@observable offsetStart;
	@observable offsetEnd;

	@observable showCancellationModal;
	@observable cancellationReason;
	@observable cancelId;
	@observable fulfilled;

	@observable searchText;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.servicingApi = new ServicingApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.servicingItems = [];
		this.page = 1;
		this.total = 0;
		this.hasNext = false;
		this.hasPrev = false;
		this.offsetStart = null;
		this.offsetEnd = null;
		this.showCancellationModal = false;
		this.cancellationReason = new FieldState(null).validators((val) => isRequiredValidator(val, 'cancellation reason'));
		this.cancelId = null;
		this.fulfilled = false;
		this.searchText = null;
	}

	@action toggleFulfilled(){
		this.fulfilled = !this.fulfilled;
		this.page = 1;
		this.runServicingList();
	}

	@computed get cancelServiceTitle(){
		let defaultTitle = 'Cancel Servicing';
		if(this.cancelId == null) return defaultTitle;
		let serviceItem = this.servicingItems.find((x) => x.id == this.cancelId);
		if(serviceItem == null) return defaultTitle;
		return `Cancel ${serviceItem.service.name} for ${serviceItem.account.account_reference} (${serviceItem.account.name}) on ${serviceItem.expected_service_date.format('ddd MMMM DD, YYYY')}`
	}

	@computed get canCancel(){
		return this.cancellationReason.value != null && this.cancellationReason.value.length > 0;
	}

	toServiceItem(item){
		item.last_service_date = item.last_service_date != null ? moment(item.last_service_date) : null;
		item.expected_service_date = moment(item.expected_service_date);
		item.dismissed_on = item.dismissed_on != null ? moment(item.dismissed_on) : null;
		item.completed_on = item.completed_on != null ? moment(item.completed_on) : null;
		return item;
	}

	runServicingList(){
		this.fetching = true;
		this.servicingApi.getAll(this.page, this.fulfilled)
			.then((response) => {
				this.servicingItems = response.servicing_items.map(this.toServiceItem);
				this.offsetStart = response.offset_start;
				this.offsetEnd = response.offset_end;
				this.hasNext = response.has_next;
				this.hasPrev = response.has_prev;
				this.total = response.total;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	onNextPage(){
		this.page += 1;
		this.runServicingList();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.runServicingList();
	}

	goToOrder(id){
		this.uiStore.goToEditOrder(id);
	}

	changeSearchText(val){
		this.searchText = val;
		this.page = 1;
	}

	showCancelModal(id){
		this.cancelId = id;
		this.showCancellationModal = true;
	}
	
	closeModal(){
		this.showCancellationModal = false;
		this.cancellationReason.value = '';
		this.cancelId = null;
	}

	@action cancelServiceItem(){
		this.fetching = true;
		this.servicingApi.deleteServicingById(this.cancelId, {
			'cancellation_reason': this.cancellationReason.value
		})
			.then((response) => {
				this.servicingItems = this.servicingItems.filter((s) => s.id != this.cancelId);
				this.closeModal();
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
		
	}
}

export default ServicingList;