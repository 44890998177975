import BaseApi from '../services/base';

class CreditNoteApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getCreditNoteById(id){
		return this.fetch(`v1/credit-notes/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	getCreditNotes(page, accountId=null, searchText=null){
		let creditNoteUrl = `v1/credit-notes?page=${page}`
		if(accountId != null){
			creditNoteUrl = `${creditNoteUrl}&account_id=${accountId}`
		}
		if(searchText != null){
			creditNoteUrl = `${creditNoteUrl}&search_text=${searchText}`	
		}
		return this.fetch(creditNoteUrl, {
			method: 'GET',
			auth: true
		})
	}

	getCreditNotePDFById(id){
		return this.fetch(`v1/credit-notes/${id}/pdf`, {
			method: 'GET',
			auth: true,
			contentType: 'application/pdf'
		})
	}
}

export default CreditNoteApi;