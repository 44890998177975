import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn,Item,Table, Pagination, Status, FormDivider} from '../elements/ui';
import {TextInput, Select, CheckBox, RadioButton, Button, LocationSearchInput, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, CheckIcon} from '../common/icon';

import GoogleMarkerMap from '../common/map';

import Authorisation from '../authorisation';

@inject('manageCustomer')
@observer
class ManageCustomer extends Component{
	constructor(props){
		super(props);
		this.renderAccountListItem = this.renderAccountListItem.bind(this);
		this.onNewAccount = this.onNewAccount.bind(this);
		this.onNewContact = this.onNewContact.bind(this);

		this.renderContactListItem = this.renderContactListItem.bind(this);

		this.renderPriceListItem = this.renderPriceListItem.bind(this);
		this.renderPriceListHeaders = this.renderPriceListHeaders.bind(this);

		this.onNewSpecialPrice = this.onNewSpecialPrice.bind(this);

		this.renderAssetPurchasePriceListItem = this.renderAssetPurchasePriceListItem.bind(this);
		this.renderAssetPurchasePriceListHeaders = this.renderAssetPurchasePriceListHeaders.bind(this);
		this.onNewAssetPurchaseSpecialPrice = this.onNewAssetPurchaseSpecialPrice.bind(this);

		this.renderAssetRentalPriceListItem = this.renderAssetRentalPriceListItem.bind(this);
		this.renderAssetRentalPriceListHeaders = this.renderAssetRentalPriceListHeaders.bind(this);
		this.onNewAssetRentalSpecialPrice = this.onNewAssetRentalSpecialPrice.bind(this);

		this.renderServicePriceListItem = this.renderServicePriceListItem.bind(this);
		this.renderServicePriceListHeaders = this.renderServicePriceListHeaders.bind(this);
		this.onNewServiceSpecialPrice = this.onNewServiceSpecialPrice.bind(this);
	}

	componentDidMount(){
		let manageCustomer = this.props.manageCustomer;
		if(this.props.viewMode){
			manageCustomer.formSync(this.props.customerId);
		}
		manageCustomer.fetchCountries();
		manageCustomer.fetchAssets();
		manageCustomer.fetchServices();
		manageCustomer.fetchVATRates();
		manageCustomer.fetchCurrencies();
		manageCustomer.fetchProducts();
	}

	componentWillUnmount(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.initStore()
	}

	renderAccountListItem(item){
		let manageCustomer = this.props.manageCustomer;
		return (
			<ListItem onEdit={() => manageCustomer.onEditAccount(item.id)}>
				<ItemColumn text={item.name} subtext={item.account_reference} />
				<Status text={item.account_status?.name} />
			</ListItem>
		)
	}

	renderContactListItem(item){
		let manageCustomer = this.props.manageCustomer;
		return (
			<ListItem gridCols={6}>
				<div className="col-span-2">
					<TextInput value={item.name.value} error={item.name.error} onChange={(val) => item.name.onChange(val)} disabled={manageCustomer.viewMode} placeholder='Enter a contact name'  />
				</div>
				<div className="col-span-2">
					<TextInput value={item.jobTitle.value} error={item.jobTitle.error} onChange={(val) => item.jobTitle.onChange(val)} disabled={manageCustomer.viewMode} placeholder='Enter their job title'  />
				</div>
				<div className="col-span-2">
					<TextInput value={item.email.value} error={item.email.error} onChange={(val) => item.email.onChange(val)} disabled={manageCustomer.viewMode} placeholder='Enter their email'  /> 
				</div>
				<div className="col-span-2">
					<TextInput value={item.phoneNumber.value} error={item.phoneNumber.error} onChange={(val) => item.phoneNumber.onChange(val)} disabled={manageCustomer.viewMode} placeholder='Enter their phone number'  /> 
				</div>
				<div className="col-span-2">
					<TextInput value={item.mobilePhoneNumber.value} error={item.mobilePhoneNumber.error} onChange={(val) => item.mobilePhoneNumber.onChange(val)} disabled={manageCustomer.viewMode} placeholder='Enter a mobile number (optional)'  /> 
				</div>
				<div className="col-span-2">
					<TextInput value={item.faxNumber.value} error={item.faxNumber.error} onChange={(val) => item.faxNumber.onChange(val)} disabled={manageCustomer.viewMode} placeholder='Enter their fax number'  /> 
				</div>
				<div className="flex flex-row items-center col-span-2">
					{item.primaryContact.value &&
						<div className="flex flex-row items-center">
							<CheckIcon color='text-success-600' />
							<span>Primary</span>
						</div>
					}
				</div>
				<div className="col-start-5 col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageCustomer.fetching} bgColor="transparent" fgColor='white' disabled={manageCustomer.viewMode} className="flex mt-1" onClick={() => manageCustomer.deleteContactListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        	{!item.primaryContact.value && <Button fetching={manageCustomer.fetching} bgColor="transparent" fgColor='white' disabled={manageCustomer.viewMode} className="flex mt-1" onClick={() => manageCustomer.markContactListItemAsPrimary(item.uuid)}><CheckIcon color='text-success-600' /></Button>}
		        </div>
			</ListItem>
		)
	}

	onNewAccount(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.onNewAccount();
	}

	onNewContact(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.addContactListEntry();
	}

	onNewSpecialPrice(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.addPriceListEntry();
	}

	onNewAssetPurchaseSpecialPrice(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.addAssetPurchasePriceListEntry();
	}

	onNewAssetRentalSpecialPrice(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.addAssetRentalPriceListEntry();
	}

	onNewServiceSpecialPrice(){
		let manageCustomer = this.props.manageCustomer;
		manageCustomer.addServicePriceListEntry();
	}

	renderPriceListHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">Product</div>
				<div className="sm:col-span-1">Currency</div>
				<div className="sm:col-span-1">Price (Excl. VAT)</div>
				<div className="sm:col-span-1">VAT Rate</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAssetPurchasePriceListHeaders(){
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAssetRentalPriceListHeaders(){
		return (
			<ListItem gridCols={12}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-1">Rental Days</div>
				<div className="sm:col-span-2">Rent</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderServicePriceListHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">Service</div>
				<div className="sm:col-span-1">Currency</div>
				<div className="sm:col-span-1">Price (Excl. VAT)</div>
				<div className="sm:col-span-1">VAT Rate</div>
				<div className="sm:col-span-1">Recurring</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderPriceListItem(item){
		let manageCustomer = this.props.manageCustomer;
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">
	              <Select 
	              	onChange={(val) => item.selectedProduct.onChange(val)}
           			placeholder="Choose a product"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.productOptions} 
	           		value={item.selectedProduct.value}
	           		error={item.selectedProduct.error}
	           		 />
		        </div>
				<div className="sm:col-span-1">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-1">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter product price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageCustomer.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-1">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageCustomer.fetching} bgColor="transparent" fgColor='white' disabled={manageCustomer.viewMode} className="flex mt-1" onClick={() => manageCustomer.deletePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetPurchasePriceListItem(item){
		let manageCustomer = this.props.manageCustomer;
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedAsset.onChange(val)}
           			placeholder="Choose asset"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter asset price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageCustomer.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageCustomer.fetching} bgColor="transparent" fgColor='white' disabled={manageCustomer.viewMode} className="flex mt-1" onClick={() => manageCustomer.deleteAssetPurchasePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetRentalPriceListItem(item){
		let manageCustomer = this.props.manageCustomer;
		return (
			<ListItem gridCols={12}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedAsset.onChange(val)}
           			placeholder="Choose asset"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter asset price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageCustomer.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
	              <TextInput 
	              	type="number"
	              	placeholder="Number of days"
	              	onChange={(val) => item.days.onChange(val)}
	              	disabled={manageCustomer.viewMode}
	            	value={item.days.value} 
	            	error={item.days.error}/>
		        </div>
		       <div className="sm:col-span-2 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageCustomer.fetching} bgColor="transparent" fgColor='white' disabled={manageCustomer.viewMode} className="flex mt-1" onClick={() => manageCustomer.deleteAssetRentalPriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderServicePriceListItem(item){
		let manageCustomer = this.props.manageCustomer;
		return (
			<ListItem gridCols={12}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedService.onChange(val)}
           			placeholder="Choose service"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.serviceOptions} 
	           		value={item.selectedService.value}
	           		error={item.selectedService.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter product price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageCustomer.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageCustomer.viewMode}
	           		options={manageCustomer.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-2">
	              	<div className="flex h-full flex-row items-center">
		              	<CheckBox 
				        	disabled={manageCustomer.viewMode}
				        	label={item.recurring.value ? null : 'Recurring charge?'}
				        	checked={item.recurring.value} 
				        	onChange={(e) => item.recurring.onChange(e.target.checked)} />
		              	{item.recurring.value && 
			              	<div className="ml-2">
				              	<TextInput 
					              	type="number"
					              	placeholder="Charge every X days"
					              	onChange={(val) => item.days.onChange(val)}
					              	disabled={manageCustomer.viewMode}
					            	value={item.days.value} 
					            	error={item.days.error}/>
					        </div>
					    }
					</div>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageCustomer.fetching} bgColor="transparent" fgColor='white' disabled={manageCustomer.viewMode} className="flex mt-1" onClick={() => manageCustomer.deleteServicePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	render(){
		let manageCustomer = this.props.manageCustomer;
		return (
			<Main>
				<div>
				  <div>
				    <div>
				      <div>
				      	<div className="flex flex-row flex-1 items-center justify-between">
					        <div className="flex flex-col">
						        <Heading>
						          {manageCustomer.name.value.length > 0 ? manageCustomer.name.value : 'Customer Information'} {manageCustomer.customerReference.value.length > 0 && ` - ${manageCustomer.customerReference.value}`}
						        </Heading>
						        <Paragraph>
						          Customer and Address Information
						        </Paragraph>
						    </div>
						    <div className="flex">
						    	{manageCustomer.viewMode && 
						    		<Button onClick={() => manageCustomer.toggleEditMode()}>
							          Edit Customer
							        </Button>
							    }
						        {!manageCustomer.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton fetching={manageCustomer.fetching} onClick={() => manageCustomer.onCancel()}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button fetching={manageCustomer.fetching} onClick={() => manageCustomer.saveCustomer()}>
								          {this.props.editMode ? 'Update' : 'Save' } Customer
								        </Button>
								      </span>
								  </div>
								}
						   	</div>
						</div>
				        
				      </div>
				      <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
				        <div className="sm:col-span-3">
				          <Label>
				            Customer Name
				          </Label>
			              <TextInput 
			              	disabled={manageCustomer.viewMode}
			            	onChange={(val) => manageCustomer.onChangeCustomerName(val)}
			            	value={manageCustomer.name.value} 
			            	error={manageCustomer.name.error} />
				        </div>

				        <div className="sm:col-span-3">
				          <Label>
				            Customer Reference
				          </Label>
				            <TextInput 
				            	disabled={manageCustomer.viewMode}
				            	onChange={(val) => manageCustomer.onChangeCustomerReference(val)}
				            	value={manageCustomer.customerReference.value} 
				            	error={manageCustomer.customerReference.error}
				            	/>
				        </div>

				        <div className="sm:col-span-6">
			        		<LocationSearchInput 
			        			disabled={manageCustomer.viewMode}
								onChange={(val) => manageCustomer.searchAddress.onChange(val)} 
								onChangeAddressComponents={(streetAddress, val) => manageCustomer.onReceivedCustomerAddressComponents(streetAddress, val)}
								onReceivedCoordinates={(coords) => manageCustomer.receivedCustomerAddressCoords(coords)}
								value={manageCustomer.searchAddress.value} 
								label='Customer Address' 
								placeholder='Search for an address' 
							/>

							{manageCustomer.hasCustomerAddressCoords && 
								<div className="w-full h-64 mt-8">
									<GoogleMarkerMap 
										zoomLevel={15}
										coords={manageCustomer.customerAddressCoords} 
										markers={manageCustomer.customerAddressLocationMarkers} 
										renderMarkerData={(markerData) => {
					        				return (
					        					<div>
										            <span>{manageCustomer.streetAddress.value}</span>
										        </div>
										      	)
							        		}}
						        	/>
								</div>
							}
						</div>

				        <div className="sm:col-span-6">
				          <Label>
				            Street Address
				          </Label>
				            <TextInput 
				            	disabled={manageCustomer.viewMode}
				            	onChange={(val) => manageCustomer.onChangeStreetAddress(val)}
				            	value={manageCustomer.streetAddress.value} 
				            	error={manageCustomer.streetAddress.error}
				            />
				        </div>

				       
				        <div className="sm:col-span-3">
				          <Label>
				            City
				          </Label>
				            <TextInput 
				            	disabled={manageCustomer.viewMode}
				            	onChange={(val) => manageCustomer.onChangeCity(val)}
				            	value={manageCustomer.city.value} 
				            	error={manageCustomer.city.error}
				            />
				        </div>

				        <div className="sm:col-span-3">
				          <Label>
				            Country
				          </Label>
				           	<Select 
				           		placeholder="Choose a country"
				           		disabled={manageCustomer.viewMode}
				           		onChange={(val) => manageCustomer.onChangeCountry(val)}
				           		options={manageCustomer.countryOptions} 
				           		value={manageCustomer.selectedCountry.value} 
				           		error={manageCustomer.selectedCountry.error} 
				           		/>
				        </div>


				        <div className="sm:col-span-3">
				          <Label>
				            County
				          </Label>
				            <TextInput
				            	disabled={manageCustomer.viewMode}
				            	onChange={(val) => manageCustomer.onChangeCounty(val)}
				            	value={manageCustomer.county.value} 
				            	error={manageCustomer.county.error}
				             />
				        </div>

				        <div className="sm:col-span-3">
				          <Label>
				          	Postcode
				          </Label>
				            <TextInput
				            	disabled={manageCustomer.viewMode}
				            	onChange={(val) => manageCustomer.onChangePostcode(val)}
				            	value={manageCustomer.postcode.value} 
				            	error={manageCustomer.postcode.error}
				            />
				        </div>
				        
				        <div className="sm:col-span-2">
					        <CheckBox 
					        	disabled={manageCustomer.viewMode}
					        	label="This customer is no longer active" 
					        	checked={manageCustomer.archived} 
					        	onChange={(e) => manageCustomer.onChangeArchived(e.target.checked)} />
					    </div>

				      </div>
				    </div>
				  </div>
				  {manageCustomer.addMode &&
					  	<FormDivider>
						  <div>
					      	<SubHeading>
					          Customer Contact Information
					        </SubHeading>
					        <Paragraph>
					          Primary point of contact for this customer
					        </Paragraph>
					      </div>
					      <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
				        	<div className="sm:col-span-3">
					          <Label>
					            Contact Name
					          </Label>
					            <TextInput 
					            	disabled={manageCustomer.viewMode}
					            	onChange={(val) => manageCustomer.onChangeContactName(val)}
					            	value={manageCustomer.contactName.value} 
					            	error={manageCustomer.contactName.error} />
					        </div>

					        <div className="sm:col-span-3">
					          <Label>
					            Job Title
					          </Label>
					            <TextInput 
					            	disabled={manageCustomer.viewMode}
					            	onChange={(val) => manageCustomer.onChangeContactJobTitle(val)}
					            	value={manageCustomer.contactJobTitle.value} 
					            	error={manageCustomer.contactJobTitle.error} />
					        </div>

					        <div className="sm:col-span-6">
					          <Label>
					            Contact Email
					          </Label>
					            <TextInput 
					            	disabled={manageCustomer.viewMode}
					            	onChange={(val) => manageCustomer.onChangeContactEmail(val)}
					            	value={manageCustomer.contactEmail.value} 
					            	error={manageCustomer.contactEmail.error} />
					        </div>


					        <div className="sm:col-span-2">
					          <Label>
					            Phone Number
					          </Label>
					            <TextInput
					            	disabled={manageCustomer.viewMode}
					            	onChange={(val) => manageCustomer.onChangeContactPhoneNumber(val)}
					            	value={manageCustomer.contactPhoneNumber.value} 
					            	error={manageCustomer.contactPhoneNumber.error} />
					        </div>

					        <div className="sm:col-span-2">
					          <Label>
					            Mobile Phone Number
					          </Label>
					            <TextInput
					            	disabled={manageCustomer.viewMode}
					            	onChange={(val) => manageCustomer.contactMobilePhoneNumber.onChange(val)}
					            	value={manageCustomer.contactMobilePhoneNumber.value} 
					            	error={manageCustomer.contactMobilePhoneNumber.error} />
					        </div>

					        <div className="sm:col-span-2">
					          <Label>
					            Fax Number
					          </Label>
					            <TextInput
					            	disabled={manageCustomer.viewMode}
					            	onChange={(val) => manageCustomer.onChangeContactFaxNumber(val)}
					            	value={manageCustomer.contactFaxNumber.value} 
					            	error={manageCustomer.contactFaxNumber.error} />
					        </div>
					      </div>
				      	</FormDivider>
				      }

			      	{!manageCustomer.addMode &&
				      	<FormDivider>
					      <div className="mt-6">
					      	<Card title="Manage Customer Accounts" actionBtn={
								<Button onClick={this.onNewAccount}>New Account</Button>
							}>
						      	<List 
						      		type="accounts"
						      		renderListItem={this.renderAccountListItem}
						      		items={manageCustomer.customerAccounts.accounts}
						      		page={manageCustomer.customerAccounts.page}
									total={manageCustomer.customerAccounts.total}
									hasNext={manageCustomer.customerAccounts.hasNext}
									hasPrev={manageCustomer.customerAccounts.hasPrev}
									offsetStart={manageCustomer.customerAccounts.offsetStart}
									offsetEnd={manageCustomer.customerAccounts.offsetEnd}
									onPreviousPage={() => manageCustomer.onPreviousCustomersPage()}
									onNextPage={() => manageCustomer.onNextCustomersPage()}
								/>
						    </Card>
					      </div>
					   	</FormDivider>
					}

					{!manageCustomer.addMode &&
				      	<FormDivider>
					      <div className="mt-6">
					      	<Card title="Manage Customer Contacts" actionBtn={
								<Button disabled={manageCustomer.viewMode} onClick={this.onNewContact}>New Contact</Button>
							}>
						      	<List 
						      		type="contacts"
						      		renderListItem={this.renderContactListItem}
						      		items={manageCustomer.contacts}
								/>
						    </Card>
					      </div>
					   	</FormDivider>
					}


			      	<FormDivider>
						<Card title="Product Prices" subtitle="Set special prices for products for this customer" actionBtn={
							<Button disabled={manageCustomer.viewMode} onClick={this.onNewSpecialPrice}>Add Product Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderPriceListHeaders}
					      		renderListItem={this.renderPriceListItem}
					      		items={manageCustomer.priceList}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Purchase Asset Prices" subtitle="Set special prices for purchasing assets for this customer" actionBtn={
							<Button disabled={manageCustomer.viewMode} onClick={this.onNewAssetPurchaseSpecialPrice}>Add Asset Purchase Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderAssetPurchasePriceListHeaders}
					      		renderListItem={this.renderAssetPurchasePriceListItem}
					      		items={manageCustomer.assetPurchasePriceList}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Rental Asset Prices" subtitle="Set special prices for renting assets for this customer" actionBtn={
							<Button disabled={manageCustomer.viewMode} onClick={this.onNewAssetRentalSpecialPrice}>Add Asset Rental Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderAssetRentalPriceListHeaders}
					      		renderListItem={this.renderAssetRentalPriceListItem}
					      		items={manageCustomer.assetRentalPriceList}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Service Prices" subtitle="Set special prices for services for this customer" actionBtn={
							<Button disabled={manageCustomer.viewMode} onClick={this.onNewServiceSpecialPrice}>Add Service Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderServicePriceListHeaders}
					      		renderListItem={this.renderServicePriceListItem}
					      		items={manageCustomer.servicePriceList}
							/>
				    	</Card>
				    </FormDivider>
				</div>
			</Main>
		)
	}
}


export default Authorisation(ManageCustomer, ['administrator', 'employee', 'accountant']);