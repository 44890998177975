import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import AuthContainer from './auth-container';

import { SpacerTop } from '../elements/ui';
import { Link } from '../elements/text';
import { Label, TextInput, Button } from '../elements/input';

@inject('forgotPassword')
@observer
class ForgotPassword extends Component{
	constructor(props){
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onBackToLogin = this.onBackToLogin.bind(this);
		this.onResetPassword = this.onResetPassword.bind(this);
	}

	componentDidMount(){

	}

	componentWillUnmount(){
		let forgotPassword = this.props.forgotPassword;
		forgotPassword.initStore();
	}

	onChangeEmail(val){
		let forgotPassword = this.props.forgotPassword;
		forgotPassword.onChangeEmail(val);
	}

	onBackToLogin(){
		let forgotPassword = this.props.forgotPassword;
		forgotPassword.onBackToLogin();
	}

	onResetPassword(){
		let forgotPassword = this.props.forgotPassword;
		forgotPassword.onResetPassword();
	}

	render(){
		let forgotPassword = this.props.forgotPassword;
		return (
			<AuthContainer
				title="Forgot Your Password?"
				>
				<div>
					<div>
						<Label text="Email Address" />
						<TextInput 
							value={forgotPassword.email.value} 
							error={forgotPassword.email.error} 
							onChange={this.onChangeEmail} />
					</div>
					<SpacerTop>
						<Button onClick={this.onResetPassword}>Send Reset Password Email</Button>
					</SpacerTop>

					 <div className="mt-6 flex justify-center">
		              <div className="text-sm leading-5">
		                <Link onClick={this.onBackToLogin}>
		                  Back to Login
		                </Link>
		              </div>
		            </div>
				</div>

			</AuthContainer>
		)
	}
}

export default ForgotPassword;