import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {TextInput, TextArea, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';

import Authorisation from '../authorisation';

@inject('manageUser')
@observer
class ManageUser extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let manageUser = this.props.manageUser;
		manageUser.fetchRoles();
		if(this.props.viewMode){
			manageUser.formSync(this.props.userId);
		}
	}

	componentWillUnmount(){
		let manageUser = this.props.manageUser;
		manageUser.initStore()
	}
	
	render(){
		let manageUser = this.props.manageUser;
		return (
			<Main>
				<div>
					<div className="flex flex-row flex-1 items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {manageUser.name.value.length > 0 ? manageUser.name.value : 'User Information'}
					        </Heading>
					        <Paragraph>
					          User Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageUser.viewMode && 
					    		<Button fetching={manageUser.fetching} onClick={() => manageUser.toggleEditMode()}>
						          Edit User
						        </Button>
						    }
					        {!manageUser.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton fetching={manageUser.fetching} onClick={() => manageUser.onCancel()} fetching={manageUser.fetching}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button disabled={!manageUser.validForm} fetching={manageUser.fetching} onClick={() => manageUser.save()} fetching={manageUser.fetching}>
								          {manageUser.editMode ? 'Update' : 'Save' } User
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
					<div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						<div className="sm:col-span-3">
				          <Label>
				            Name
				          </Label>
			              <TextInput 
			              	disabled={manageUser.viewMode}
			            	onChange={(val) => manageUser.name.onChange(val)}
			            	value={manageUser.name.value} 
			            	error={manageUser.name.error} />
				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            Email
				          </Label>
			              <TextInput 
			              	disabled={manageUser.viewMode}
			            	onChange={(val) => manageUser.email.onChange(val)}
			            	value={manageUser.email.value} 
			            	error={manageUser.email.error} />
				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            User Role
				          </Label>
				          <Select
			           		placeholder="Select a role"
			           		disabled={manageUser.viewMode} 
			           		onChange={(val) => manageUser.selectedRole.onChange(val)}
			           		options={manageUser.roleOptions} 
			           		value={manageUser.selectedRole.value}
			           		error={manageUser.selectedRole.error}
			           		/>
				        </div>
				        {!manageUser.addMode && 
				        	<>
					        	<div className="sm:col-span-3">
						         	<Label>Invite Status</Label>
						         	<div className="flex flex-row items-center mt-2">
						         		<Status 
							         		text={manageUser.inviteAccepted ? 'Invite Accepted' : 'Invite Pending'} 
							         		color={manageUser.inviteAccepted ? 'green' : 'red'} />

							         	{!manageUser.inviteAccepted && 
							         		<div className="flex ml-2">
								         		<Button fgColor='white' bgColor='green' fetching={manageUser.fetching} onClick={() => manageUser.resendInvite()}>
										          Resend Invite
										        </Button>
										    </div>
										}
							        </div>
					        	</div>
					        	<div className="sm:col-span-3">
						         	<Button fgColor='white' bgColor='red' fetching={manageUser.fetching} onClick={() => manageUser.deleteUser()}>
										Delete User
									</Button>
				        		</div>
					        </>
				        }
					</div>
				</div>
			</Main>
		)
	}
}

export default Authorisation(ManageUser, ['administrator']);