import React, {Component, Fragment, useState} from 'react';

import Logo from '../common/logo';
import {HomeIcon, CogIcon, UsersIcon, ClipboardListIcon, ChevronLeftIcon, ChevronRightIcon, ViewBoardIcon, CalendarIcon, ChartIcon, CollectionIcon, TagIcon, GlobeIcon, PieChartIcon} from '../common/icon';

import {inject, observer} from 'mobx-react';

const NavItem = ({icon, title, onClick, mobile=false, subitems=null, expanded=false}) => {
	let navClassName = "mt-1 group flex items-center px-2 py-2 text-sm cursor-pointer leading-5 font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition ease-in-out duration-150";
	
	const [showSubItems, setSubItems] = useState(expanded);
	let expandableIcon = (
		<svg className="ml-auto h-5 w-5 transform group-hover:text-gray-400 group-focus:text-gray-400 transition-colors ease-in-out duration-150" viewBox="0 0 20 20">
        	<path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
    )


	return (
		<>
			<div className={navClassName} onClick={onClick}>
		      	{icon}
		      	<div className="flex flex-row w-full">
			      {title}
			      {subitems && 
			      	<div className="flex flex-col justify-end w-full" onClick={() => setSubItems(!showSubItems)} >
			      		{expandableIcon}
			      	</div>
			      }
				</div>
		    </div>
		    {showSubItems && 
      			<div className="flex ml-4">
      				{subitems}
      			</div>
      		}
		</>
 	)
}


@inject('ui')
@observer
class SideBar extends Component{
	constructor(props){
		super(props);		
		this.onHome = this.onHome.bind(this);
		this.onCustomers = this.onCustomers.bind(this);
		this.onAccounts = this.onAccounts.bind(this);
		this.onProducts = this.onProducts.bind(this);
		this.onServicing = this.onServicing.bind(this);
		this.onReports = this.onReports.bind(this);
		this.onUsers = this.onUsers.bind(this);
		this.onAssets = this.onAssets.bind(this);
		this.onOrders = this.onOrders.bind(this);
		this.onPODs = this.onPODs.bind(this);
		this.onServices = this.onServices.bind(this);
		this.onAgreements = this.onAgreements.bind(this);
		this.onJourneys = this.onJourneys.bind(this);
		this.onJourneysPlanner = this.onJourneysPlanner.bind(this);
		this.onInvoices = this.onInvoices.bind(this);
		this.onCreditNotes = this.onCreditNotes.bind(this);
	}

	onHome(){
		let uiStore = this.props.ui;
		uiStore.goToHome();
	}

	onCustomers(){
		let uiStore = this.props.ui;
		uiStore.goToCustomers();
	}

	onAccounts(){
		let uiStore = this.props.ui;
		uiStore.goToAccounts();
	}

	onProducts(){
		let uiStore = this.props.ui;
		uiStore.goToProducts();
	}

	onServicing(){
		let uiStore = this.props.ui;
		uiStore.goToServicing();
	}

	onReports(){
		let uiStore = this.props.ui;
		uiStore.goToReports();
	}

	onUsers(){
		let uiStore = this.props.ui;
		uiStore.goToUsers();
	}

	onAssets(){
		let uiStore = this.props.ui;
		uiStore.goToAssets();
	}

	onOrders(){
		let uiStore = this.props.ui;
		uiStore.goToOrders();
	}

	onPODs(){
		let uiStore = this.props.ui;
		uiStore.goToPODs();
	}

	onServices(){
		let uiStore = this.props.ui;
		uiStore.goToServices();
	}

	onAgreements(){
		let uiStore = this.props.ui;
		uiStore.goToAgreements();
	}

	onJourneys(){
		let uiStore = this.props.ui;
		uiStore.goToJourneys();
	}

	onJourneysPlanner(){
		let uiStore = this.props.ui;
		uiStore.goToJourneyPlanner();
	}

	onInvoices(){
		let uiStore = this.props.ui;
		uiStore.goToInvoices();
	}

	onCreditNotes(){
		let uiStore = this.props.ui;
		uiStore.goToCreditNotes();
	}

	render(){
		let uiStore = this.props.ui;
		let isOfficeStaff = uiStore.isAdministrator || uiStore.isEmployee || uiStore.isAccountant;

		console.log(uiStore.showNavbar);
		return (
			<Fragment>
		  		<div className="md:hidden">
				    <div>
				      {uiStore.showMobileNavbar && 
				      	<div className="flex-1 flex flex-col h-screen max-w-xs w-full pt-5 pb-4 bg-gray-800 transform ease-in-out duration-300 ">
					        <div className="absolute top-0 right-0 -mr-14 p-1">
					          {uiStore.showMobileNavbar && 
					          	<button data-todo-x-show="sidebarOpen" onClick={() => uiStore.toggleMobileNavbar()} className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
						            <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
						              <path stripelinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
						            </svg>
						          </button>
					          }
					        </div>
					        <div className="flex-shrink-0 flex items-center px-4">
					          <Logo color='white' />
					        </div>
					        <div className="flex-1 overflow-y-auto">
					          <nav className="px-2">
					            <NavItem icon={<HomeIcon />} title="Home" mobile={true} onClick={this.onHome} />
					            {isOfficeStaff && 
					            	<NavItem icon={<CogIcon />} expandable title="Configure" mobile={true} onClick={this.onHome} expanded={uiStore.isConfigureView}
						            	subitems={
						            		<div className="flex flex-col ml-2">
						            			<NavItem title="Customers" mobile={true} onClick={this.onCustomers} />
									            <NavItem title="Accounts" mobile={true} onClick={this.onAccounts} />
									            <NavItem title="Products" mobile={true} onClick={this.onProducts} />
									            <NavItem title="Orders"  mobile={true} onClick={this.onOrders} />
									            <NavItem title="PODs"  mobile={true} onClick={this.onPODs} />
									            <NavItem title="Invoices"  mobile={true} onClick={this.onInvoices} />
									            <NavItem title="Credit Notes"  mobile={true} onClick={this.onCreditNotes} />
									            <NavItem title="Services"  mobile={true} onClick={this.onServices} />
									            <NavItem title="Agreements"  mobile={true} onClick={this.onAgreements} />
									            <NavItem title="Assets"  mobile={true} onClick={this.onAssets} />
						            			<NavItem title="Journeys" mobile={true} onClick={this.onJourneys} />
						            			{uiStore.isAdministrator && <NavItem title="Users" mobile={true} onClick={this.onUsers} />}
									        </div>
						            	}
						             />
						        }
					            
					            {isOfficeStaff && <NavItem icon={<ViewBoardIcon />} title="Journey Planner" mobile={true} onClick={this.onJourneysPlanner} />}
					            {isOfficeStaff && <NavItem icon={<ClipboardListIcon />} title="Sanitisations" mobile={true} onClick={this.onServicing} />}
					            {false && isOfficeStaff && <NavItem icon={<PieChartIcon />} title="Reports" mobile={true} onClick={this.onReports} />}
					          </nav>
					        </div>
				      	</div>
				      }
				      
				      
				    </div>
				  </div>

				  <div className="hidden md:flex md:flex-shrink-0">
				    {uiStore.showNavbar && 
				    	<div className="relative flex flex-col w-64">
					      <div className="flex flex-col justify-center items-start px-4 bg-gray-900">
					      	<Logo color='white' />
					      </div>
					      <div className="h-0 flex-1 flex flex-col overflow-y-auto">
					        <nav className="flex-1 px-2 py-4 bg-gray-800">
					          <NavItem icon={<HomeIcon />} title="Home" onClick={this.onHome} />
					          {isOfficeStaff && 
					          	<NavItem 
						          	icon={<CogIcon />}
						          	title="Configure" 
						          	expanded={uiStore.isConfigureView}
					          		subitems={
			   							<div className="flex flex-col w-full">
			   								 <NavItem title="Customers" onClick={this.onCustomers} />
						          			 <NavItem title="Accounts" onClick={this.onAccounts} />
									         <NavItem title="Products" onClick={this.onProducts} />
									         <NavItem title="Orders" onClick={this.onOrders} />
									         <NavItem title="PODs" onClick={this.onPODs} />
									         <NavItem title="Invoices" onClick={this.onInvoices} />
									         <NavItem title="Credit Notes" onClick={this.onCreditNotes} />
									         <NavItem title="Services" onClick={this.onServices} />
									         <NavItem title="Agreements" onClick={this.onAgreements} />
									         <NavItem title="Assets" onClick={this.onAssets} />
									         <NavItem title="Journeys" onClick={this.onJourneys} />
									         {uiStore.isAdministrator && <NavItem title="Users" onClick={this.onUsers} />}
									    </div>
					          		}
						           />
						    }
					         
					          {isOfficeStaff && <NavItem icon={<ViewBoardIcon />} title="Journey Planner" onClick={this.onJourneysPlanner} />}
					          {isOfficeStaff && <NavItem icon={<ClipboardListIcon />} title="Sanitisations" onClick={this.onServicing} />}
					          {false && isOfficeStaff && <NavItem icon={<PieChartIcon />} title="Reports" onClick={this.onReports} />}
					          <div className="hidden lg:inline-flex cursor-pointer absolute bottom-0 left-0 ml-4 mb-8 flex flex-row" onClick={() => uiStore.toggleNavbar()}>
					          	<ChevronLeftIcon color="white" />
					          </div>
					        </nav>
					      </div>
				    	</div>
				    }
				    {!uiStore.showNavbar && 
				    	<div className="relative flex flex-col w-12">
					      <div className="h-0 flex-1 flex flex-col overflow-y-auto">
					        <nav className="flex-1 px-2 py-4 bg-gray-800">
					          <div className="hidden lg:inline-flex cursor-pointer absolute bottom-0 left-0 ml-2 mb-8 flex flex-row" onClick={() => uiStore.toggleNavbar()}>
					          	<ChevronRightIcon color="white" />
					          </div>
					        </nav>
					      </div>
				    	</div>
				    }
				</div>
			</Fragment>
		)
	}
}

const DropdownItem = ({text, onClick}) => (
	<div className="flex w-full px-4 py-2">
		<div onClick={onClick} className="cursor-pointer block flex flex-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150" role="menuitem">{text}</div>
	</div>
)

@inject('ui')
@observer
class DropdownNav extends Component{
	constructor(props){
		super(props);
		this.onSettings = this.onSettings.bind(this);
		this.onLogout = this.onLogout.bind(this);
	}

	componentWillUnmount(){
		this.props.ui.hideDropdown();
	}

	onSettings(){

	}

	onLogout(){
		this.props.ui.logout();
	}

	render(){
		let uiStore = this.props.ui;
		let showDropdown = uiStore.showDropdown;
		if(!showDropdown) return null;
		return (
			<div className="origin-top-right z-40 absolute right-0 mt-2 w-48 rounded-md shadow-lg">
              <div className="flex z-50 w-full py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <DropdownItem text="Log Out" onClick={this.onLogout} />
              </div>
            </div>
		)
	}
}

export{
	SideBar,
	DropdownNav
};