import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable } from 'mobx';
import BaseApi from '../../services/base';

class ResetPasswordApi extends BaseApi{
	constructor(props){
		super(props);
	}

	resetPassword(token, password){
		return this.fetch('api/users/forgot', {
			method: 'PUT',
			body: JSON.stringify({
				token: token,
				password: password
			})
		})

	}
}

class ResetPassword{
	token;
	password;
	confirmPassword;
	@observable submitError;
	form;

	uiStore;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.resetPasswordApi = new ResetPasswordApi(uiStore);
		this.initStore();
	}

	initStore(){
		this.token = '';
		this.password = new FieldState('').validators((val) => !validator.isRequired(val) && 'Please enter a password');
		this.confirmPassword = new FieldState('').validators((val) => !validator.isEqualTo(val, this.password.value) && 'Your passwords do not match');
		this.submitError = '';
		this.form = new FormState({
		    password: this.password,
		    confirmPassword: this.confirmPassword
		});
	}

	setToken(token){
		this.token = token;
	}

	checkForRedirect(){
		this.uiStore.checkForRedirect();
	}

	onResetPassword = async () => {
	    const res = await this.form.validate();
	    if(res.hasError) return;

	    this.resetPasswordApi.resetPassword(this.token, this.password.value)
	    	.then((response) => {
	    		this.uiStore.alertSuccess('Password successfully changed. Please log in');
	    		this.uiStore.goToLogin();
	    	})
	    	.catch((error) => {
	    		this.submitError = error.message;
	    	})	
	};

}

export default ResetPassword;