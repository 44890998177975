import React, {Component} from 'react';

import {inject, observer} from 'mobx-react';

import {EmailIcon, CheckIcon, AngleRightIcon, ExclamationIcon, SearchIcon} from '../common/icon';

const SpacerTop = ({children}) => (
	<div className="mt-6">
		{children}
	</div>
)


const SearchForm = ({placeholder, onChange = null}) => (
	<div className="w-full mt-1 flex flex-1 md:ml-0">
        <label for="search_field" className="sr-only">Search</label>
        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <SearchIcon />
          </div>
          <input 
          	className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm" 
          	placeholder={placeholder} 
          	type="search"
          	onChange={onChange}
          	 />
        </div>
      </div>
)

@inject('ui')
@observer
class UserAvatar extends Component{
	constructor(props){
		super(props);
		this.toggleDropdown = this.toggleDropdown.bind(this);
	}

	toggleDropdown(){
		this.props.ui.toggleDropdown();
	}

	getCurrentUserInitials(){
		let uiStore = this.props.ui;
		let currentUser = uiStore.currentUser;
		if(currentUser == null) return '';
		let splitName = currentUser.name.split(' ');
		let initials = [];
		for(let entry of splitName){
			initials.push(entry[0])
		}
		return initials.join('');
	}

	render(){
		return (
			<div className="p-2 bg-blue-600 rounded-full" onClick={this.toggleDropdown}>
				<button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline" id="user-menu" aria-label="User menu" aria-haspopup="true" data-todo-x-bind-aria-expanded="open">
			        {false && <img className="rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />}
			        <div className="w-full h-full  text-white">{this.getCurrentUserInitials()}</div>
			      </button>
			</div>
		)
	}
}


const Container = ({children}) => (
	<main className="flex flex-1 relative z-10 overflow-y-auto py-6 focus:outline-none">
	{children}
	</main>
)

const InnerContainer = ({children}) => (
	<div className="flex flex-1 flex-col h-full w-full px-4 sm:px-6 md:px-8">
	{children}
	</div>
)

const PageTitle = ({title}) => (
	<div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
	    <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
	  </div>
)

class ItemColumn extends Component{
	render(){
		return (
			<div>
	            <div className="text-sm leading-5 font-medium text-blue-600 truncate">{this.props.text}</div>
	            {this.props.subtext && <div className="mt-2 flex justify-start items-center text-sm leading-5 text-gray-500">
		              {this.props.icon}
		              <span className="truncate">{this.props.subtext}</span>
		            </div>
		        }
	          </div>
		)
	}
}

@observer
class Item extends Component{
	render(){
		return (
			<div>
	            <div className="text-sm leading-5 font-medium text-blue-600 truncate">{this.props.text}</div>
	          </div>
		)
	}
}


@observer
class ListItem extends Component{
	render(){
		let gridCols = 'gridCols' in this.props ? this.props.gridCols : 2;
		return (
			<div className="block border-b border-gray-100 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out" onClick={this.props.onEdit}>
		        <div className="flex items-center px-4 py-4 sm:px-6">
		          <div className="min-w-0 flex-1 flex items-center">
		            {this.props.imgSrc && this.props.imgAlt &&
		            	<div className="flex-shrink-0">
			              <img className="h-12 w-12 rounded-full" src={this.props.imgSrc} alt={this.props.imgAlt} />
			            </div>
			        }
		            <div className={`min-w-0 flex-1 px-4 md:grid md:grid-cols-${gridCols} md:gap-4`}>
		              {this.props.children}
		            </div>
		          </div>
		          {this.props.onEdit && 
		          	<div onClick={this.props.onEdit}>
		            	<AngleRightIcon  />
		          	</div>
			      } 
		        </div>
		    </div>
		)
	}
}

const NoListItemsMessage = ({type}) => (
	<div className="flex-1 flex w-full items-center justify-center py-4">
		<ExclamationIcon color="text-red-600" />
		<span className="ml-2 text-sm leading-5 font-medium text-gray-600 truncate">No {type == null ? 'entries' : type} found</span>
	</div>
)

@observer
class List extends Component{
	render(){
		return (
			<div className="flex flex-1 flex-col">
				{'renderListHeaders' in this.props && this.props.items.length > 0 && 
					<div className="bg-white shadow overflow-hidden">{this.props.renderListHeaders()}</div>
				}
				<div className="bg-white shadow overflow-hidden">
				  <ul>
				      {this.props.items.map((item) => 
				      	<li>
				      		{this.props.renderListItem(item)}
				      	</li>
				      )}
				      {this.props.items.length == 0 && 
				      	<NoListItemsMessage type={this.props.type} />
				      }
				  </ul>
				</div>
				{this.props.items.length > 0 && 'total' in this.props &&
					<Pagination
						{...this.props}
					 />
				}
			</div>
		)
	}
}

@observer
class Pagination extends Component{
	render(){
		return (
			<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
			  <div className="hidden sm:block">
			    <p className="text-sm leading-5 text-gray-700">
			      Showing
			      <span className="font-medium"> {this.props.offsetStart} </span>
			      to
			      <span className="font-medium"> {this.props.offsetEnd} </span>
			      of
			      <span className="font-medium"> {this.props.total} </span>
			      results
			    </p>
			  </div>
			  <div className="flex-1 flex justify-between sm:justify-end">
			    {this.props.hasPrev && 
			    	<div onClick={this.props.onPreviousPage} className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
				      Previous
				    </div>
				}
			    {this.props.hasNext && 
			    	<div onClick={this.props.onNextPage} className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
				      Next
				    </div>
				}
			  </div>
		  </div>
		)
	}
}

const Card = ({title, actionBtn, children, subtitle=null, search=null}) => (
	<div className="flex flex-1 flex-col w-full z-10">
		<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
		  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
		    <div className="flex flex-1 ml-4 mt-2 flex-col">
		      <h3 className="text-lg leading-6 font-medium text-gray-900">
		        {title}
		      </h3>
		      {subtitle != null && 
		      	<h4 className="text-sm text-gray-400">
		      		{subtitle}
		      	</h4>
		  	  }
		      {search}
		    </div>
		    <div className="ml-4 mt-2 flex-shrink-0">
		      <span className="inline-flex rounded-md shadow-sm">
		        {actionBtn}
		      </span>
		    </div>
		  </div>
		</div>
		{children}
  </div>
)

const Table = () => (
	<div className="flex flex-col">
	  <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
	    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
	      <table className="min-w-full">
	        <thead>
	          <tr>
	            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
	              Name
	            </th>
	            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
	              Title
	            </th>
	            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
	              Status
	            </th>
	            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
	              Role
	            </th>
	            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
	          </tr>
	        </thead>
	        <tbody className="bg-white">
	          <tr>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <div className="flex items-center">
	                <div className="flex-shrink-0 h-10 w-10">
	                  <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                </div>
	                <div className="ml-4">
	                  <div className="text-sm leading-5 font-medium text-gray-900">Bernard Lane</div>
	                  <div className="text-sm leading-5 text-gray-500">bernardlane@example.com</div>
	                </div>
	              </div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <div className="text-sm leading-5 text-gray-900">Director</div>
	              <div className="text-sm leading-5 text-gray-500">Human Resources</div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
	                Active
	              </span>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	              Owner
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
	              <a href="/" className="text-blue-600 hover:text-blue-900 focus:outline-none focus:underline">Edit</a>
	            </td>
	          </tr>
	          <tr>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <div className="flex items-center">
	                <div className="flex-shrink-0 h-10 w-10">
	                  <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1532910404247-7ee9488d7292?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                </div>
	                <div className="ml-4">
	                  <div className="text-sm leading-5 font-medium text-gray-900">Bernard Lane</div>
	                  <div className="text-sm leading-5 text-gray-500">bernardlane@example.com</div>
	                </div>
	              </div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <div className="text-sm leading-5 text-gray-900">Director</div>
	              <div className="text-sm leading-5 text-gray-500">Human Resources</div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
	                Active
	              </span>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	              Owner
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
	              <a href="/" className="text-blue-600 hover:text-blue-900 focus:outline-none focus:underline">Edit</a>
	            </td>
	          </tr>
	          <tr>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <div className="flex items-center">
	                <div className="flex-shrink-0 h-10 w-10">
	                  <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1505503693641-1926193e8d57?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                </div>
	                <div className="ml-4">
	                  <div className="text-sm leading-5 font-medium text-gray-900">Bernard Lane</div>
	                  <div className="text-sm leading-5 text-gray-500">bernardlane@example.com</div>
	                </div>
	              </div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <div className="text-sm leading-5 text-gray-900">Director</div>
	              <div className="text-sm leading-5 text-gray-500">Human Resources</div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
	              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
	                Inactive
	              </span>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	              Owner
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
	              <a href="/" className="text-blue-600 hover:text-blue-900 focus:outline-none focus:underline">Edit</a>
	            </td>
	          </tr>
	          <tr>
	            <td className="px-6 py-4 whitespace-no-wrap">
	              <div className="flex items-center">
	                <div className="flex-shrink-0 h-10 w-10">
	                  <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                </div>
	                <div className="ml-4">
	                  <div className="text-sm leading-5 font-medium text-gray-900">Bernard Lane</div>
	                  <div className="text-sm leading-5 text-gray-500">bernardlane@example.com</div>
	                </div>
	              </div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap">
	              <div className="text-sm leading-5 text-gray-900">Director</div>
	              <div className="text-sm leading-5 text-gray-500">Human Resources</div>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap">
	              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
	                Inactive
	              </span>
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
	              Owner
	            </td>
	            <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
	              <a href="/" className="text-blue-600 hover:text-blue-900 focus:outline-none focus:underline">Edit</a>
	            </td>
	          </tr>
	        </tbody>
	      </table>
	    </div>

	  </div>

	</div>
)


const STATUS_COLOR_MAP = {
	'pending': 'blue',
	'in_progress': 'orange',
	'complete': 'green',
	'void': 'red',
	'scheduled': 'orange',
	'invoiced': 'purple',
	'paid': 'black',
	'refunded': 'red'
}


const Status = ({text, color=null}) => {

	if(color == null){
		color = STATUS_COLOR_MAP[text]
	}
	if(color == null){
		color = 'blue';
	}
	return (
		<div class="flex items-center justify-between">
			<div class="ml-2 flex-shrink-0 flex">
				<span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}>
			        {text}
			      </span>
			</div>
		</div>
	)
}

const ArchivedStatus = ({archived}) => {
	let color = archived ? 'red': 'green';
	return (
		<Status text={archived ? 'Archived' : 'Active'} color={color} />
	)
}

const FormDivider = ({children}) => (
	<div className="mt-8 border-t border-gray-200 pt-5">
	{children}
	</div>
)

const Stat = ({title, children, onClick=null}) => {
	return (
		<div className="flex flex-col bg-white rounded-md px-4 py-4 cursor-pointer" onClick={onClick}>
			<div className="flex flex-col h-48 overflow-y-scroll flex-column">
				{children}
			</div>
			<div className="flex border-t border-gray-200 justify-end ">
				<span className="text-sm text-gray-600 py-2">{title}</span>
			</div>
		</div>
	)
}

const StatMetric = ({color, value, percentageChange=null, percentageChangeColor=null}) => {
	return (
		<div className="flex flex-1 flex-col justify-center items-center">
			<span className={`text-2xl md:text-3xl lg:text-4xl text-${color}-500`}>{value}</span>
			{percentageChange != null && 
				<span className={`text-xs font-bold mt-1 text-${percentageChangeColor}-700`}>({percentageChange})</span>
			}
		</div>
	)
}


export {
	SpacerTop,
	SearchForm,
	UserAvatar,
	Container,
	InnerContainer,
	PageTitle,
	Card,
	List,
	ListItem,
	Item,
	ItemColumn,
	Pagination,
	Table,
	Status,
	ArchivedStatus,
	FormDivider,
	Stat,
	StatMetric
}