import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, DateTimePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, DownloadIcon} from '../common/icon';
import AccountSearchInput from '../common/account-search';


@observer
class PODInformation extends Component{
	constructor(props){
		super(props);
		this.renderProductItemHeaders = this.renderProductItemHeaders.bind(this);
		this.renderProductItem = this.renderProductItem.bind(this);
		this.renderAssetPurchaseHeaders = this.renderAssetPurchaseHeaders.bind(this);
		this.renderAssetPurchaseItem = this.renderAssetPurchaseItem.bind(this);
		this.renderAssetReturnHeaders = this.renderAssetReturnHeaders.bind(this);
		this.renderAssetReturnItem = this.renderAssetReturnItem.bind(this);
		this.renderServiceItemHeaders = this.renderServiceItemHeaders.bind(this);
		this.renderServiceItem = this.renderServiceItem.bind(this);
	}

	renderProductItemHeaders(){
		return (
			<ListItem gridCols={7}>
				<div className="sm:col-span-2">Product</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
				<div className="sm:col-span-1">Location</div>
				<div className="sm:col-span-1">Returns</div>
			</ListItem>
		)
	}

	renderProductItem(item){
		return (
			<ListItem gridCols={7}>
				<div className="sm:col-span-2">
					<Paragraph>{item.product?.name}</Paragraph>
				</div>
		       	<div className="sm:col-span-1">
		        	<Paragraph>{item.price}</Paragraph>
		       	</div>
		        <div className="sm:col-span-1">
		        	<Paragraph>{item.quantity}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.foc}</Paragraph>
		       </div>
		        <div className="sm:col-span-1">
	             <Paragraph>{item.account_location?.name}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.returns}</Paragraph>
		       </div>
			</ListItem>
		);
	}

	renderAssetPurchaseHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-1">Serial No.</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
			</ListItem>
		)
	}

	renderAssetPurchaseItem(item){
		let managePOD = this.props.managePOD;
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">
	              <Paragraph>{item.asset.name}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.serial_number}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.price}</Paragraph>
		       </div>
		        <div className="sm:col-span-1">
		        	<Paragraph>{item.quantity}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.foc}</Paragraph>
		       </div>
			</ListItem>
		);
	}

	renderAssetReturnHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Serial No.</div>
				<div className="sm:col-span-2">Quantity</div>
				<div className="sm:col-span-2"></div>
			</ListItem>
		)
	}

	renderAssetReturnItem(item){
		let managePOD = this.props.managePOD;
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">
	              <Paragraph>{item.asset.name}</Paragraph>
		       </div>
		       <div className="sm:col-span-2">
		        	<Paragraph>{item.serial_number}</Paragraph>
		       </div>
		        <div className="sm:col-span-2">
		        	<Paragraph>{item.quantity}</Paragraph>
		       </div>
			</ListItem>
		);
	}

	renderServiceItemHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">Service</div>
				<div className="sm:col-span-1">Asset</div>
				<div className="sm:col-span-1">Serial Number</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Qty</div>
			</ListItem>
		)
	}

	renderServiceItem(item){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">
	              <Paragraph>{item.service.name}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
	              <Paragraph>{item.asset?.name}</Paragraph>
		        </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.serial_number}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.price}</Paragraph>
		       </div>
		       <div className="sm:col-span-1">
		        	<Paragraph>{item.quantity}</Paragraph>
		       </div>
			</ListItem>
		);
	}


	render(){
		let proofOfDelivery = this.props.proofOfDelivery;
		return (
			<FormDivider>
		   		<div>
			      	<SubHeading>
			          POD Information
			        </SubHeading>
			        <Paragraph>
			          What was delivered
			        </Paragraph>
			    </div>
			    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
			    	<div className="sm:col-span-3">
			   			<Label>POD Reference</Label>
			   			<Paragraph>{proofOfDelivery.pod_reference}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-3">
		        		<Label>
			            	Driver
			          	</Label>
		           		<Paragraph>{proofOfDelivery.driver?.name}</Paragraph>
			        </div>

			        <div className="sm:col-span-2">
			   			<Label>Note</Label>
			   			<Paragraph>{proofOfDelivery.note}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Delivered On</Label>
			   			<Paragraph>{proofOfDelivery.delivered_on.format('DD/MM/YYYY HH:mm')}</Paragraph>
			   		</div>

			   		
		   			<div className="sm:col-span-2">
			   			<Label>Created On</Label>
			   			<Paragraph>{proofOfDelivery.created_on.format('DD/MM/YYYY HH:mm')}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-3">
			   			<Label>Cash Received</Label>
			   			<Paragraph>{proofOfDelivery.cash_received}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-3">
			   			<Label>PO Number</Label>
			   			<Paragraph>{proofOfDelivery.po_number}</Paragraph>
			   		</div>

		   			<div className="sm:col-span-3">
			   			<Label>Customer Print Name</Label>
			   			<Paragraph>{proofOfDelivery.customer_print_name}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-3">
			   			<Label>Customer Signature</Label>

			   			<div className="flex flex-row items-center mt-2">
				   			  {proofOfDelivery.customer_signature_photo_url != null &&
				   			  	<div className="flex flex-row items-center">
				   			  		<div className="flex"><DownloadIcon color="blue-600" /></div>
				   			  		<div className="flex ml-2"><Paragraph><span className="text-blue-600 hover:underline"><a target="_blank" download href={proofOfDelivery.customer_signature_photo_url}>Download customer signature</a></span></Paragraph></div>
				   			  	</div>
				   			  }
				   			  {proofOfDelivery.customer_signature_photo_url == null &&
				   			  	<Paragraph>No signature here</Paragraph>
				   			  }
					    </div>
			   		</div>
			    </div>

			     <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-8">
					<div className="sm:col-span-8">
						<Card title="Products" subtitle="Add products to this delivery">
					      	<List 
					      		type="products"
					      		renderListHeaders={this.renderProductItemHeaders}
					      		renderListItem={this.renderProductItem}
					      		items={proofOfDelivery.product_items}
							/>
				    	</Card>
				    </div>
				    <div className="sm:col-span-8">
						<Card title="Assets purchased" subtitle="Add assets in this delivery">
					      	<List 
					      		type="assets"
					      		renderListHeaders={this.renderAssetPurchaseHeaders}
					      		renderListItem={this.renderAssetPurchaseItem}
					      		items={proofOfDelivery.purchase_asset_items}
							/>
				    	</Card>
				    </div>
				    <div className="sm:col-span-8">
						<Card title="Assets collected" subtitle="Add assets returned in this delivery">
					      	<List 
					      		type="assets"
					      		renderListHeaders={this.renderAssetReturnHeaders}
					      		renderListItem={this.renderAssetReturnItem}
					      		items={proofOfDelivery.return_asset_items}
							/>
				    	</Card>
				    </div>
					<div className="sm:col-span-8">
						<Card title="Services" subtitle="Add service to delivery">
					      	<List 
					      		type="services"
					      		renderListHeaders={this.renderServiceItemHeaders}
					      		renderListItem={this.renderServiceItem}
					      		items={proofOfDelivery.service_items}
							/>
				    	</Card>
				    </div>
			    </div>
		   	</FormDivider>
		)
	}
}

export default PODInformation;