import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon} from '../common/icon';
import AccountSearchInput from '../common/account-search';

import Authorisation from '../authorisation';


@inject('manageOrder')
@observer
class ManageOrder extends Component{
	constructor(props){
		super(props);
		this.renderProductOrderItemHeaders = this.renderProductOrderItemHeaders.bind(this);
		this.renderProductOrderItem = this.renderProductOrderItem.bind(this);
		this.renderAssetOrderItemHeaders = this.renderAssetOrderItemHeaders.bind(this);
		this.renderAssetReturnOrderItemHeaders = this.renderAssetReturnOrderItemHeaders.bind(this);
		this.renderAssetOrderItem = this.renderAssetOrderItem.bind(this);
		this.renderAssetReturnOrderItem = this.renderAssetReturnOrderItem.bind(this);
		this.renderServiceOrderItemHeaders = this.renderServiceOrderItemHeaders.bind(this);
		this.renderServiceOrderItem = this.renderServiceOrderItem.bind(this);
		this.renderOrderSummaryItemHeaders = this.renderOrderSummaryItemHeaders.bind(this);
		this.renderOrderSummaryItem = this.renderOrderSummaryItem.bind(this);
	}

	componentDidMount(){
		let manageOrder = this.props.manageOrder;
		manageOrder.fetchProducts();
		manageOrder.fetchAssets();
		manageOrder.fetchServices();
		manageOrder.fetchCurrencies();
		manageOrder.fetchVATRates();
		if(this.props.orderId){
			manageOrder.formSync(this.props.orderId);
		}
	}

	componentWillUnmount(){
		let manageOrder = this.props.manageOrder;
		manageOrder.initStore();
	}

	renderProductOrderItemHeaders(){
		return (
			<ListItem gridCols={9}>
				<div className="sm:col-span-2">Product</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
				<div className="sm:col-span-1">Location</div>
				<div className="sm:col-span-1">Returns</div>
				<div className="sm:col-span-1">Subtotal</div>
			</ListItem>
		)
	}

	renderProductOrderItem(item){
		let manageOrder = this.props.manageOrder;
		return (
			<ListItem gridCols={9}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose product"
           			onChange={(val) => manageOrder.changeProductItemProduct(item.uuid, val)}
           			disabled={manageOrder.isDisabled}
	           		options={manageOrder.productOptions} 
	           		value={item.selectedProduct.value}
	           		error={item.selectedProduct.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		       </div>
		        <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={manageOrder.isDisabled}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter FOC"
		              	onChange={(val) => item.foc.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.foc.value} 
		            	error={item.foc.error}/>
		       </div>
		       <div className="sm:col-span-1">
	              <Select 
           			placeholder="Choose location"
           			onChange={(val) => manageOrder.changeProductItemLocation(item.uuid, val)}
           			disabled={manageOrder.isDisabled}
	           		options={manageOrder.locationOptions} 
	           		value={item.selectedLocation.value}
	           		error={item.selectedLocation.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
		        	{item.depositable && 
		        		<TextInput 
			              	type="number"
			              	placeholder="Enter Returns"
			              	disabled={manageOrder.isDisabled}
			              	onChange={(val) => item.returns.onChange(val)}
			            	value={item.returns.value} 
			            	error={item.returns.error}/>
			        }
			        {!item.depositable && <div></div>}
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       		<Label>{item.subtotal}</Label>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button 
		        		fetching={manageOrder.fetching} 
		        		onClick={() => manageOrder.deleteProductPriceItem(item.uuid)}
		        		bgColor="transparent" 
		        		fgColor='white' 
		        		disabled={manageOrder.isDisabled} 
		        		className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetOrderItemHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-1">Serial No.</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAssetReturnOrderItemHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Serial No.</div>
				<div className="sm:col-span-2">Quantity</div>
				<div className="sm:col-span-2"></div>
			</ListItem>
		)
	}

	renderAssetReturnOrderItem(item){
		let manageOrder = this.props.manageOrder;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose asset"
           			onChange={(val) => manageOrder.changeReturnAssetItemAsset(item.uuid, val)}
           			disabled={manageOrder.isDisabled}
	           		options={manageOrder.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		       </div>
		       <div className="sm:col-span-2">
		        	<TextInput 
		              	type="text"
		              	placeholder="Enter serial number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		       </div>
		        <div className="sm:col-span-2">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={manageOrder.isDisabled}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-2 flex flex-1 justify-end items-center">
		        	<Button 
		        		onClick={() => manageOrder.deleteAssetReturnItem(item.uuid)}
		        		fetching={manageOrder.fetching} bgColor="transparent" fgColor='white' disabled={manageOrder.isDisabled} className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetOrderItem(item){
		let manageOrder = this.props.manageOrder;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose asset"
           			onChange={(val) => manageOrder.changeAssetItemAsset(item.uuid, val)}
           			disabled={manageOrder.isDisabled}
	           		options={manageOrder.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="text"
		              	placeholder="Enter serial number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		       </div>
		        <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={manageOrder.isDisabled}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter FOC"
		              	disabled={manageOrder.isDisabled}
		              	onChange={(val) => item.foc.onChange(val)}
		            	value={item.foc.value} 
		            	error={item.foc.error}/>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       		<Label>{item.subtotal}</Label>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button 
		        		onClick={() => manageOrder.deleteAssetPriceItem(item.uuid)}
		        		fetching={manageOrder.fetching} bgColor="transparent" fgColor='white' disabled={manageOrder.isDisabled} className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}
	
	renderServiceOrderItemHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Service</div>
				<div className="sm:col-span-1">Asset</div>
				<div className="sm:col-span-1">Serial Number</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Qty</div>
				<div className="sm:col-span-1">Subtotal</div>
			</ListItem>
		)
	}

	renderServiceOrderItem(item){
		let manageOrder = this.props.manageOrder;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose service"
           			onChange={(val) => manageOrder.changeServiceItemService(item.uuid, val)}
           			disabled={manageOrder.isDisabled}
	           		options={manageOrder.serviceOptions} 
	           		value={item.selectedService.value}
	           		error={item.selectedService.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
	              <Select 
           			placeholder="Choose asset"
           			onChange={(val) => manageOrder.changeServiceItemAsset(item.uuid, val)}
           			disabled={manageOrder.isDisabled}
	           		options={manageOrder.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		        </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	placeholder="Enter serial number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={manageOrder.isDisabled}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={manageOrder.isDisabled}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       		<Label>{item.subtotal}</Label>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button
		        		onClick={() => manageOrder.deleteServicePriceItem(item.uuid)} 
		        		fetching={manageOrder.fetching} bgColor="transparent" fgColor='white' disabled={manageOrder.isDisabled} className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderOrderSummaryItemHeaders(){
		return (
			<ListItem gridCols={11}>
				<div className="sm:col-span-2">Item</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
				<div className="sm:col-span-1">Returns</div>
				<div className="sm:col-span-1">VAT</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1">Deposit</div>
				<div className="sm:col-span-1">Refund</div>
				<div className="sm:col-span-1">Total</div>
			</ListItem>
		)
	}

	renderOrderSummaryItem(item){
		return (
			<ListItem gridCols={11}>
				<div className="sm:col-span-2">{item.name}</div>
				<div className="sm:col-span-1">{item.price}</div>
				<div className="sm:col-span-1">{item.quantity}</div>
				<div className="sm:col-span-1">{item.foc}</div>
				<div className="sm:col-span-1">{item.returns}</div>
				<div className="sm:col-span-1">{item.vat_rate}</div>
				<div className="sm:col-span-1">{item.subtotal}</div>
				<div className="sm:col-span-1">{item.deposit}</div>
				<div className="sm:col-span-1">{item.refund}</div>
				<div className="sm:col-span-1">{item.total}</div>
			</ListItem>
		)
	}

	render(){
		let manageOrder = this.props.manageOrder;

		let orderTitle = 'Add Order';
		if(manageOrder.editMode){
			orderTitle = 'Edit Order'
		}else if(manageOrder.viewMode){
			orderTitle = 'View Order'
		}
		let orderAccount = manageOrder.orderAccount;
		return (
			<Main>
				<>
					<div className="flex flex-row items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {orderTitle}
					        </Heading>
					        <Paragraph>
					          Order Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageOrder.viewMode && 
					    		<Button fetching={manageOrder.fetching} onClick={() => manageOrder.toggleEditMode()}>
						          Edit Order
						        </Button>
						    }
					        {!manageOrder.viewMode && 
								  <div className="flex justify-end">
								      {manageOrder.editMode && 
								      		<>
												<span className="inline-flex rounded-md shadow-sm">
													<CancelButton fetching={manageOrder.fetching} onClick={() => manageOrder.toggleViewMode()}>
														Cancel changes
													</CancelButton>
												</span>
												{manageOrder.canBeVoided && 
													<span className="ml-3 inline-flex rounded-md shadow-sm">
													    <Button fetching={manageOrder.fetching} fgColor='white' bgColor='pink' onClick={() => manageOrder.voidOrder()}>
													      Void Order
													    </Button>
												  </span>
												}
											</>
									  }
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button fetching={manageOrder.fetching} onClick={() => manageOrder.save()}>
								          {manageOrder.editMode ? 'Update' : 'Save' } Order
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
				   	<AccountSearchInput
				   		accounts={manageOrder.accounts}
				   		editMode={manageOrder.editMode || manageOrder.viewMode}
				   		selectedAccountId={manageOrder.selectedAccountId}
				   		disabled={manageOrder.viewMode}
				   		onChange={(val) => manageOrder.onChangeAccountSearchText(val)}
				   		onSelect={(id) => manageOrder.selectAccount(id)}
				   		currentAccount={manageOrder.orderAccount}
				   		onGoToAccount={(id) => manageOrder.appStore.goToEditAccount(id)}
						onGoToCustomer={(id) => manageOrder.appStore.goToEditCustomer(id)}
				   	/>
				   	{orderAccount != null &&
				   		<>
						   	<FormDivider>
						   		<div>
							      	<SubHeading>
							          Order Items
							        </SubHeading>
							        <Paragraph>
							          What's contained in this order
							        </Paragraph>
							    </div>
							    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-8">
									<div className="sm:col-span-8">
										<Card title="Products" subtitle="Add products to this order" actionBtn={
											<Button disabled={manageOrder.isDisabled} onClick={() => manageOrder.addProductItemEntry()}>Add Product to Order</Button>
										}>
									      	<List 
									      		type="products"
									      		renderListHeaders={this.renderProductOrderItemHeaders}
									      		renderListItem={this.renderProductOrderItem}
									      		items={manageOrder.productOrderItems}
											/>
								    	</Card>
								    </div>
								    <div className="sm:col-span-8">
										<Card title="Assets purchased" subtitle="Add assets purchased to deliver as part of this order" actionBtn={
											<Button disabled={manageOrder.isDisabled} onClick={() => manageOrder.addAssetItemEntry()}>Add Asset to Order</Button>
										}>
									      	<List 
									      		type="assets"
									      		renderListHeaders={this.renderAssetOrderItemHeaders}
									      		renderListItem={this.renderAssetOrderItem}
									      		items={manageOrder.assetOrderItems}
											/>
								    	</Card>
								    </div>
								     <div className="sm:col-span-8">
										<Card title="Assets to collect" subtitle="Add assets to collect as part of this order" actionBtn={
											<Button disabled={manageOrder.isDisabled} onClick={() => manageOrder.addAssetReturnItemEntry()}>Add Asset to Collect</Button>
										}>
									      	<List 
									      		type="assets"
									      		renderListHeaders={this.renderAssetReturnOrderItemHeaders}
									      		renderListItem={this.renderAssetReturnOrderItem}
									      		items={manageOrder.assetReturnOrderItems}
											/>
								    	</Card>
								    </div>
									<div className="sm:col-span-8">
										<Card title="Services" subtitle="Add service to order" actionBtn={
											<Button disabled={manageOrder.isDisabled} onClick={() => manageOrder.addServiceItemEntry()}>Add Service to Order</Button>
										}>
									      	<List 
									      		type="services"
									      		renderListHeaders={this.renderServiceOrderItemHeaders}
									      		renderListItem={this.renderServiceOrderItem}
									      		items={manageOrder.serviceOrderItems}
											/>
								    	</Card>
								    </div>
							    </div>
						   	</FormDivider>
						   	<FormDivider>
				   				<div>
							      	<SubHeading>
							          Order Information
							        </SubHeading>
							        <Paragraph>
							          Information about this specific order
							        </Paragraph>
							    </div>
							    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						   			{!manageOrder.addMode && 
						   				<>
								   			<div className="sm:col-span-3">
									   			<Label>Fulfillment Status</Label>
									   			<div className="flex flex-row items-center">
										   			<Paragraph>
											        	<Status text={manageOrder.fulfillmentStatus.value} />
											        </Paragraph>
											    </div>
									   		</div>
									   		<div className="sm:col-span-3">
									   			<Label>Payment Status</Label>
									   			<div className="flex flex-row items-center">
										   			<Paragraph>
											        	<Status text={manageOrder.paymentStatus.value} />
											        </Paragraph>
											    </div>
									   		</div>
									   	</>
									}
					   				<div className="sm:col-span-3">
								    	<Label>Requested Delivery Date</Label>
								    	<div className="flex">
									    	<DatePicker 
									    		disabled={manageOrder.viewMode}
									    		placeholder="Choose delivery date"
									    		date={manageOrder.requestedDeliveryDate.value}
									    		onDateChange={(date) => manageOrder.requestedDeliveryDate.onChange(date)}
									    	/>
									    </div>
									</div>
									<div className="sm:col-span-3">
								    	<Label>Purchase Order Number</Label>
								    	<div className="flex">
									    	<TextInput
									    		placeholder="Customer PO "
									    		disabled={manageOrder.viewMode}
									    		className="flex flex-1 w-full"
									    		onChange={(val) => manageOrder.poNumber.onChange(val)}
									    		value={manageOrder.poNumber.value}
									    	 />
									    </div>
									</div>
									<div className="sm:col-span-6">
								    	<Label>Notes</Label>
								    	<div className="flex">
									    	<TextArea
									    		disabled={manageOrder.viewMode}
									    		placeholder="Special instructions for this order"
									    		className="flex flex-1 w-full"
									    		onChange={(val) => manageOrder.specialInstructions.onChange(val)}
									    		value={manageOrder.specialInstructions.value}
									    	 />
									    </div>
									</div>
					   				<div className="sm:col-span-6">
						   				<Card title="Order Summary" subtitle="Summary details of this order">
						   					<List 
									      		type="summary"
									      		renderListHeaders={this.renderOrderSummaryItemHeaders}
									      		renderListItem={this.renderOrderSummaryItem}
									      		items={manageOrder.orderItemSummary}
											/>
										</Card>
									</div>
								</div>
						   	</FormDivider>

						</>
				   	}
				</>
			</Main>
		)
	}
}

export default Authorisation(ManageOrder, ['administrator', 'employee', 'accountant']);