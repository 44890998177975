import BaseApi from '../services/base';

class AccountApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, searchText=null){
		let url = `v1/accounts?page=${page}`;
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllAccounts = async (searchText=null) => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page, searchText);
			let data = response.accounts;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getAccountById(id){
		return this.fetch(`v1/accounts/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	newAccount(account){
		return this.fetch(`v1/accounts`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(account)
		})
	}

	updateAccount(id, account){
		return this.fetch(`v1/accounts/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(account)
		})
	}

	getAllStatuses(){
		return this.fetch(`v1/accounts/statuses`, {
			method: 'GET',
			auth: true
		})
	}

	getAllAccountTypes(){
		return this.fetch(`v1/accounts/types`, {
			method: 'GET',
			auth: true
		})
	}

	getAccountsForCustomer(customerId, page){
		return this.fetch(`v1/accounts?customer_id=${customerId}&page=${page}`, {
			method: 'GET',
			auth: true
		})
	}

	getAccountsForJourney(journeyId, page){
		return this.fetch(`v1/accounts?journey_id=${journeyId}&page=${page}`, {
			method: 'GET',
			auth: true
		})
	}
}

export default AccountApi;