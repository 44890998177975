import BaseApi from '../services/base';

class OrderApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, searchText=null, pending=null, exclude_servicing_item_orders=false){
		let url = `v1/orders?page=${page}`;
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		if(pending){
			url = `${url}&pending=${pending}`
		}
		url = `${url}&exclude_servicing_item_orders=${exclude_servicing_item_orders}`;
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllOrders = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page);
			let data = response.orders;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getById(id){
		return this.fetch(`v1/orders/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	patchOrder(id, payload){
		return this.fetch(`v1/orders/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	updateOrder(id, order){
		return this.fetch(`v1/orders/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(order)
		})
	}

	newOrder(order){
		return this.fetch(`v1/orders`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(order)
		})
	}
}

export default OrderApi;