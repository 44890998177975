import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('productList')
@observer
class Products extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let productList = this.props.productList;
		productList.fetchProducts();
	}

	componentWillUnmount(){
		let productList = this.props.productList;
		productList.initStore();
	}

	onPreviousPage(){
		let productList = this.props.productList;
		productList.onPreviousPage();
	}

	onNextPage(){
		let productList = this.props.productList;
		productList.onNextPage();
	}

	renderListItem(item){
		let productList = this.props.productList;
		return (
			<ListItem onEdit={() => productList.onEdit(item.id)}>
				<ItemColumn text={item.name} subtext={item.product_reference} />
				<Status color={item.obsolete ? 'red' : 'green'} text={item.obsolete ? "Obsolete" : "Active"} />
			</ListItem>
		)
	}

	render(){
		let productList = this.props.productList;
		return (
			<Main>
				<Card title="Products" search={
					  	<SearchForm 
					  		placeholder="Search by Product Reference"
					  		onChange={(e) => productList.changeSearchText(e.target.value)}
					  		 />
					  }
				actionBtn={
					<Button onClick={() => productList.onNewProduct()}>New Product</Button>
				}>
					<List 
						type="products"
						items={productList.products}
						renderListItem={this.renderListItem}
						page={productList.page}
						total={productList.total}
						hasNext={productList.hasNext}
						hasPrev={productList.hasPrev}
						offsetStart={productList.offsetStart}
						offsetEnd={productList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Products, ['administrator', 'employee', 'accountant']);