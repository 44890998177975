import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, Table} from '../elements/ui';

import Authorisation from '../authorisation';

@observer
class Reports extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){

	}

	componentWillUnmount(){

	}

	render(){
		return (
			<Main>
			</Main>
		)
	}
}

export default Authorisation(Reports,  ['administrator', 'employee', 'accountant']);