import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../../main';
import {Card, List, ListItem, ItemColumn, Item, Table, SearchForm, Pagination, Status} from '../../elements/ui';
import {TextInput, TextArea, Select, CheckBox, Label, RadioButton, Button, CancelButton, SearchEntitiesList} from '../../elements/input';
import {DeleteIcon, ArrowUpIcon, ArrowDownIcon, ClipboardListIcon, InfoIcon, ClockIcon, ExitIcon, SaveIcon, PlusIcon} from '../../common/icon';
import {SubHeading, Heading, Paragraph} from '../../elements/text';
import {DISPLAY_DATE_FORMAT} from '../../../services/util';

import moment from 'moment';
import Modal from '../../common/modal';
import GoogleMarkerMap from '../../common/map';

import Authorisation from '../../authorisation';

@observer
class DeliveryDatePicker extends Component{
	render(){
		let deliveryDate = this.props.deliveryDate;

	let datePickerDivClassName = "flex-shrink-0 w-24 h-16 flex items-center cursor-pointer text-center py-8 px-4 rounded-lg "
		let labelClassName = "inline-flex text-xs cursor-pointer font-bold";
		let isActive = this.props.isActive;
		if(isActive){
			datePickerDivClassName = `${datePickerDivClassName} bg-gray-800 border-b border-white`
			labelClassName = `${labelClassName} text-white`
		}else{
			datePickerDivClassName = `${datePickerDivClassName}`
			labelClassName = `${labelClassName} text-gray-800`
		}
		return (
			<div isActive={isActive} className={datePickerDivClassName} onClick={() => this.props.onChange(deliveryDate)}>
				<Label 
					text={deliveryDate.format('ddd Do MMM')}
					className={labelClassName}
					 />
			</div>
		)
	}
}



@observer
class DriverOption extends Component{
	render(){
		let driverClassName = "flex cursor-pointer py-4 rounded-lg";
		let labelClassName = "text-sm font-bold pl-4";
		let driver = this.props.driver;
		let isActive = this.props.isActive;
		if(isActive){
			driverClassName = `${driverClassName} bg-blue-600`;
			labelClassName = `${labelClassName} text-white`
		}else{
			labelClassName = `${labelClassName} text-blue-600`
		}
		return (
			<div className={driverClassName} onClick={() => this.props.onChange(driver.id)}>
				<Label 
					text={driver.name} 
					className={labelClassName}
				/>
			</div>
		)
	}
}

@observer
class PanelOption extends Component{
	render(){
		let panelClassName = "flex cursor-pointer py-4 rounded-lg mb-2";
		let labelClassName = "text-sm font-bold pl-4";
		let isActive = this.props.isActive;
		if(isActive){
			panelClassName = `${panelClassName} bg-blue-600`;
			labelClassName = `${labelClassName} text-white`
		}else{
			labelClassName = `${labelClassName} text-blue-600`
		}
		return (
			<div className={panelClassName} onClick={() => this.props.onToggle()}>
				<Label 
					text={this.props.text} 
					className={labelClassName}
				/>
			</div>
		)
	}
}

@observer
class JourneyCall extends Component{
	constructor(props){
		super(props);
		this.toggleShowInfo = this.toggleShowInfo.bind(this);
		this.onChangeNote = this.onChangeNote.bind(this);
		this.state = {
			showInfo: false
		}
	}

	toggleShowInfo(){
		this.setState({
			showInfo: !this.state.showInfo
		})
	}

	onChangeNote(val){
		let journeyCall = this.props.journeyCall;
		journeyCall.note = val;
		this.props.markDirty()
	}

	render(){
		let journeyCall = this.props.journeyCall;
		let sequenceNumber = this.props.sequenceNumber;
		let account = journeyCall.account;
		let itemTitle = `${sequenceNumber}. ${account.name} (${account.account_reference})`;
		let accountDeliveryAddress = account.delivery_address;
		let deliveryAddress = this.props.buildAddress(accountDeliveryAddress);

		let openingHoursForDay = this.props.openingHoursForDay;
		let gridClassName = "grid grid-cols-9 gap-4 w-full bg-white py-4 px-4 rounded-lg mb-4 shadow-md overflow-x-scroll";
		if(journeyCall.completed_on != null){
			gridClassName = `${gridClassName} bg-success`
		}
		if(journeyCall.dismissed_on != null){
			gridClassName = `${gridClassName} bg-error`
		}

		let hasBeenCompleted = journeyCall.completed_on != null || journeyCall.dismissed_on != null;
		return (
			<div className={gridClassName}>
				<div className="col-span-5">
					<ItemColumn text={itemTitle} subtext={deliveryAddress} />
				</div>
				<div className="col-span-2">
					<ItemColumn text="Last Delivered" subtext={journeyCall.last_delivery_date != null ? journeyCall.last_delivery_date?.fromNow() : 'None'} />
				</div>
				<div className="col-span-2">
					<div className="flex flex-row items-center">
						{openingHoursForDay != null && !openingHoursForDay.active && 
							<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.toggleShowInfo()}><ClockIcon color='error' /></Button>
				        }
			        	 {journeyCall.sequence_number > 1 && 
			        	 	<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onMoveUp(journeyCall.uuid)}>
			        			<ArrowUpIcon color='blue-400' />
			        		</Button>
			        	}
		        		{journeyCall.sequence_number < this.props.maxSequenceNumber && 
		        			<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onMoveDown(journeyCall.uuid)}>
			        			<ArrowDownIcon color='blue-400' />
			        		</Button>
			        	}
			        	<Button 
			        		bgColor="transparent" 
			        		fgColor='white' className="flex" onClick={() => this.toggleShowInfo()}>
		        			<InfoIcon color='blue-400' />
		        		</Button>
			        	{!hasBeenCompleted && 
				        	<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onDelete(journeyCall.uuid)}>
			        			<DeleteIcon color='text-error' />
		        			</Button>
		        		}
			        </div>
			    </div>
			    {this.state.showInfo && 
			    	<div className="col-span-9 flex w-full flex-col mt-2 py-2 ">
			    		<div className="grid grid-cols-3 gap-4">
			    			<div className="flex flex-col">
			    				<Label className="text-sm font-bold text-gray-800" text="Last Delivery Date" />
			    				<Paragraph>{journeyCall.last_delivery_date != null ? journeyCall.last_delivery_date?.format(DISPLAY_DATE_FORMAT) : null}</Paragraph>
			    			</div>
			    			<div className="flex flex-col">
			    				<Label className="text-sm font-bold text-gray-800" text="Contact" />
			    				<Paragraph>{account.primary_contact?.name}</Paragraph>
			    			</div>
			    			<div className="flex flex-col">
			    				<Label className="text-sm font-bold text-gray-800" text="PO Required" />
			    				<Paragraph>{account.po_required ? 'Yes' : 'No'}</Paragraph>
			    			</div>
			    			<div className="flex flex-col">
			    				<Label className="text-sm font-bold text-gray-800" text="Account Status" />
			    				<div className="flex mt-1"><Status text={account.account_status?.name} /></div>
			    			</div>
			    			{openingHoursForDay != null && 
			    				<div className="flex flex-col">
				    				<Label className="text-sm font-bold text-gray-800" text="Opening Hours" />
				    				{openingHoursForDay.active && 
				    					<Paragraph>{openingHoursForDay.start_time} to {openingHoursForDay.end_time}</Paragraph>
				    				}
				    				{!openingHoursForDay.active && 
				    					<Paragraph>Closed</Paragraph>
				    				}
				    			</div>
				    		}
			    			<div className="flex flex-col col-span-3">
			    				<Label className="text-sm font-bold text-gray-800" text="Note for Driver" />
			    				<TextArea 
					              	type="note"
					              	placeholder="Enter a note"
					              	onChange={this.onChangeNote}
					            	value={journeyCall.note}
					            	/>
			    			</div>
			    		</div>
			    	</div>
			    }
			</div>
		)
	}
}

@observer
class JourneyOrder extends Component{
	constructor(props){
		super(props);
		this.toggleShowInfo = this.toggleShowInfo.bind(this);
		this.onChangeNote = this.onChangeNote.bind(this);
		this.state = {
			showInfo: false
		}
	}

	toggleShowInfo(){
		this.setState({
			showInfo: !this.state.showInfo
		})
	}

	onChangeNote(val){
		let journeyOrder = this.props.journeyOrder;
		journeyOrder.note = val;
		this.props.markDirty()
	}

	render(){
		let journeyOrder = this.props.journeyOrder;
		let account = journeyOrder.account;
		let accountDeliveryAddress = account.delivery_address;
		let deliveryAddress = this.props.buildAddress(accountDeliveryAddress);
		let sequenceNumber = this.props.sequenceNumber;
		let openingHoursForDay = this.props.openingHoursForDay;
		let gridClassName = "grid grid-cols-9 gap-4 w-full bg-white py-4 px-4 rounded-lg mb-4 shadow-md overflow-x-scroll";
		if(journeyOrder.completed_on != null){
			gridClassName = `${gridClassName} bg-success`
		}
		if(journeyOrder.dismissed_on != null){
			gridClassName = `${gridClassName} bg-error`
		}
		let hasBeenCompleted = journeyOrder.completed_on != null || journeyOrder.dismissed_on != null;
		return (
			<div className={gridClassName}>
				<div className="col-span-2">
					<ItemColumn text={`${sequenceNumber}. Order #${journeyOrder.id}`} subtext={account.account_reference} />
				</div>
				<div className="col-span-3">
					<ItemColumn text={account.name} subtext={deliveryAddress} />
				</div>
				<div className="col-span-2">
					<ItemColumn text={journeyOrder.created_on.format('lll')} subtext={journeyOrder.created_on.fromNow()} />
				</div>
				<div className="col-span-2">
					<div className="flex flex-row items-center">
						{openingHoursForDay != null && !openingHoursForDay.active && 
							<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.toggleShowInfo()}><ClockIcon color='error' /></Button>
				        }
				        {journeyOrder.sequence_number > 1 && 
				        	<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onMoveUp(journeyOrder.uuid)}>
			        			<ArrowUpIcon color='blue-400' />
			        		</Button>
			        	}
		        		{journeyOrder.sequence_number < this.props.maxSequenceNumber && 
		        			<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onMoveDown(journeyOrder.uuid)}>
			        			<ArrowDownIcon color='blue-400' />
			        		</Button>
			        	}
				        <Button 
			        		bgColor="transparent" 
			        		fgColor='white' className="flex" onClick={() => this.toggleShowInfo()}>
		        			<InfoIcon color='blue-400' />
		        		</Button>
			        	{!hasBeenCompleted && 
			        		<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onDelete(journeyOrder.uuid)}>
			        			<DeleteIcon color='text-error' />
			        		</Button>
			        	}
			        </div>
			    </div>
			    {this.state.showInfo && 
			    	<div className="col-span-9 flex w-full flex-col mt-2 py-2 ">
			    		<div className="grid grid-cols-3 gap-4">
			    			<div className="flex flex-col col-span-3">
			    				<Label className="text-sm font-bold text-gray-800" text="Note for Driver" />
			    				<TextArea 
					              	type="note"
					              	placeholder="Enter a note"
					              	onChange={this.onChangeNote}
					            	value={journeyOrder.note}
					            	/>
			    			</div>
			    		</div>
			    	</div>
			    }
			</div>
		)
	}
}

@observer
class JourneyServicingItem extends Component{
	constructor(props){
		super(props);
		this.toggleShowInfo = this.toggleShowInfo.bind(this);
		this.onChangeNote = this.onChangeNote.bind(this);
		this.state = {
			showInfo: false
		}
	}

	toggleShowInfo(){
		this.setState({
			showInfo: !this.state.showInfo
		})
	}

	onChangeNote(val){
		let journeyServicingItem = this.props.journeyServicingItem;
		journeyServicingItem.note = val;
		this.props.markDirty()
	}

	render(){
		let journeyServicingItem = this.props.journeyServicingItem;
		let gridClassName = "grid grid-cols-9 gap-4 w-full bg-white py-4 px-4 rounded-lg mb-4 shadow-md overflow-x-scroll";
		if(journeyServicingItem.completed_on != null){
			gridClassName = `${gridClassName} bg-success`
		}
		if(journeyServicingItem.dismissed_on != null){
			gridClassName = `${gridClassName} bg-error`
		}
		let serviceDaysAgo = journeyServicingItem.expected_service_date.clone().fromNow();
		let hasBeenCompleted = journeyServicingItem.completed_on != null || journeyServicingItem.dismissed_on != null;
		let displayDateFormat = 'ddd MMMM DD, YYYY';
		let sequenceNumber = this.props.sequenceNumber;
		let openingHoursForDay = this.props.openingHoursForDay;
		return (
			<div className={gridClassName}>
				<div className="col-span-4"><ItemColumn text={`${sequenceNumber}. ${journeyServicingItem.account?.account_reference}`} subtext={this.props.buildAddress(journeyServicingItem.account.delivery_address)} /></div>
				<div className="col-span-2"><ItemColumn text={journeyServicingItem.asset?.name} subtext={`Serial: ${journeyServicingItem.serial_number}`} /></div>
				<div className="col-span-1"><ItemColumn text={journeyServicingItem.service?.name} subtext={`Order #${journeyServicingItem.order.id}`} /></div>
				<div className="col-span-2">
					<div className="flex flex-row items-center">
			        	{openingHoursForDay != null && !openingHoursForDay.active && 
							<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.toggleShowInfo()}><ClockIcon color='error' /></Button>
				        }
				        {journeyServicingItem.sequence_number > 1 && 
				        	<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onMoveUp(journeyServicingItem.uuid)}>
			        			<ArrowUpIcon color='blue-400' />
			        		</Button>
			        	}
		        		{journeyServicingItem.sequence_number < this.props.maxSequenceNumber && 
		        			<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onMoveDown(journeyServicingItem.uuid)}>
			        			<ArrowDownIcon color='blue-400' />
			        		</Button>
			        	}
				        <Button 
			        		bgColor="transparent" 
			        		fgColor='white' className="flex" onClick={() => this.toggleShowInfo()}>
		        			<InfoIcon color='blue-400' />
		        		</Button>
			        	{!hasBeenCompleted && 
			        		<Button 
				        		bgColor="transparent" 
				        		fgColor='white' className="flex" onClick={() => this.props.onDelete(journeyServicingItem.uuid)}>
			        			<DeleteIcon color='text-error' />
			        		</Button>
			        	}
			        </div>
			    </div>
			    {this.state.showInfo && 
			    	<div className="col-span-9 flex w-full flex-col mt-2 py-2 ">
			    		<div className="grid grid-cols-3 gap-4">
			    			<div className="flex flex-col">
			    				<Label className="text-sm font-bold text-gray-800" text="Last Service Date" />
			    				<Paragraph>{journeyServicingItem.last_service_date != null ? journeyServicingItem.last_service_date?.format(DISPLAY_DATE_FORMAT) : 'None'}</Paragraph>
			    			</div>
			    			<div className="flex flex-col">
			    				<Label className="text-sm font-bold text-gray-800" text="Expected Service Date" />
			    				<Paragraph>{journeyServicingItem.expected_service_date != null ? journeyServicingItem.expected_service_date?.format(DISPLAY_DATE_FORMAT) : 'None'}</Paragraph>
			    			</div>
			    			<div className="flex flex-col col-span-3">
			    				<Label className="text-sm font-bold text-gray-800" text="Note for Driver" />
			    				<TextArea 
					              	type="note"
					              	placeholder="Enter a note"
					              	onChange={this.onChangeNote}
					            	value={journeyServicingItem.note}
					            	/>
			    			</div>
			    		</div>
			    	</div>
			    }
			</div>
		)
	}
}

@inject('journeyPlanner')
@observer
class JourneysPlanner extends Component{
	constructor(props){
		super(props);
		this.renderOrderListHeaders = this.renderOrderListHeaders.bind(this);
		this.renderOrderListItem = this.renderOrderListItem.bind(this);
		this.renderServicingListHeaders = this.renderServicingListHeaders.bind(this);
		this.renderServicingListItem = this.renderServicingListItem.bind(this);
	}

	componentDidMount(){
		let journeyPlanner = this.props.journeyPlanner;
		journeyPlanner.onLoad();
	}

	componentWillUnmount(){
		let journeyPlanner = this.props.journeyPlanner;
		journeyPlanner.initStore();
	}

	renderOrderListHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-1">Order</div>
				<div className="sm:col-span-4">Account</div>
				<div className="sm:col-span-2">Created On</div>
				<div className="sm:col-span-1">Actions</div>
			</ListItem>
		)
	}

	renderOrderListItem(item){
		let journeyPlannerStore = this.props.journeyPlanner;
		return (
			<ListItem gridCols={8}>
				<div className="col-span-1"><ItemColumn text={`#${item.id}`} subtext={item.account.account_reference} /></div>
				<div className="col-span-4"><ItemColumn text={item.account.name} subtext={journeyPlannerStore.buildAddress(item.account.delivery_address)} /></div>
				<div className="col-span-2"><ItemColumn text={item.created_on.format('lll')} subtext={item.created_on.fromNow()} /></div>
				<div className="col-span-1"><Button 
	        		bgColor="transparent" 
	        		fgColor='white' className="flex" onClick={() => journeyPlannerStore.onAddOrderToCallSheet(item)}><PlusIcon color='success' /></Button></div>
			</ListItem>
		)
	}

	renderServicingListHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">Account</div>
				<div className="sm:col-span-1">Asset</div>
				<div className="sm:col-span-1">Service</div>
				<div className="sm:col-span-1">Last Serviced</div>
				<div className="sm:col-span-1">Next Service</div>
				<div className="sm:col-span-1">Actions</div>
			</ListItem>
		)
	}

	renderServicingListItem(item){
		let journeyPlannerStore = this.props.journeyPlanner;
		let serviceDaysAgo = item.expected_service_date.clone().fromNow();
		let displayDateFormat = 'ddd MMMM DD, YYYY';
		return (
			<ListItem gridCols={6}>
				<ItemColumn text={item.account?.account_reference} subtext={item.account?.name} />
				<ItemColumn text={item.asset?.name} subtext={`Serial: ${item.serial_number}`} />
				<ItemColumn text={item.service?.name} subtext={
					<Status text={`Order #${item.order?.id}`} color={item.is_complete ? 'success' : 'error'} />
				}/>
				<ItemColumn text={item.last_service_date != null ? item.last_service_date.format(displayDateFormat) : ''} subtext={
					<Status text={item.last_service_date != null ? `${item.last_service_date?.fromNow()}` : ''} />
				} />
				<ItemColumn text={item.expected_service_date.format(displayDateFormat)} subtext={
					<>
						{!item.is_complete && 
							<Status text={item.expected_service_date.isSameOrAfter(moment(), 'day') ? `Due ${serviceDaysAgo}` : `Late ${serviceDaysAgo}`} color={item.expected_service_date.isSameOrAfter(moment(), 'day') ? 'success' : 'error'} />
						}
					</>
				} />
				<div className="sm:col-span-1">
					<Button 
		        		bgColor="transparent" 
		        		fgColor='white' className="flex" onClick={() => journeyPlannerStore.onAddServicingItemToCallSheet(item)}><PlusIcon color='success' /></Button>
				</div>
			</ListItem>
		)
	}

	render(){
		let journeyPlannerStore = this.props.journeyPlanner;

		let middleColSpan = journeyPlannerStore.showRightPanel ? "col-span-3" : "col-span-5";
		return (
			<Main>
				<Modal 
					show={journeyPlannerStore.showConfirmDiscardChangesModal}
					icon={
						<DeleteIcon color="text-white" />
					}
					color='error'
					title="You have unsaved changes"
					actionPanel={
						<>
							<Button fgColor="white" bgColor="gray" onClick={() => journeyPlannerStore.hideDiscardChangesModal()}>Go back</Button>
							<Button fgColor="white" bgColor="error" onClick={() => journeyPlannerStore.discardChanges()}>Discard changes</Button>
						</>
					}
					description="Please discard or save your changes before changing driver or day."
				/>
				<div className="flex flex-1 flex-col">
					<div className="flex disable-scrollbars overflow-x-scroll flex-row w-full items-center">
						{journeyPlannerStore.deliveryDates.map((deliveryDate) => 
							<DeliveryDatePicker 
								key={deliveryDate} 
								deliveryDate={deliveryDate}
								onChange={(date) => journeyPlannerStore.changeDeliveryDate(date)}
								isActive={journeyPlannerStore.isActiveDeliveryDate(deliveryDate)}
								 />
						)}
					</div>
					<div className="flex flex-1 grid grid-cols-6 gap-4 mt-8">
						<div className="col-span-1 flex flex-1">
							<div className="flex flex-1 flex-col justify-between">
								<div className="flex flex-col">
									<div className="flex">
										<SubHeading>Drivers</SubHeading>
									</div>
									<div className="flex flex-col mt-4">
										{journeyPlannerStore.drivers.map((driver) => 
											<DriverOption 
												driver={driver} 
												key={driver.id}
												onChange={(driverId) => journeyPlannerStore.changeSelectedDriver(driverId)}
												isActive={journeyPlannerStore.isActiveDriver(driver.id)}
												 />
										)}
									</div>
								</div>
								<div className="flex flex-col mt-4">
									<SubHeading>Journeys</SubHeading>

									<div className="flex flex-col mt-2">
										<Select 
							              	onChange={(val) => journeyPlannerStore.selectedJourney = val}
						           			placeholder="Choose a journey to load"
							           		options={journeyPlannerStore.journeyOptions}
							           		value={journeyPlannerStore.selectedJourney}
							           	/>

							           	{journeyPlannerStore.selectedJourney != null && 
							           		<div className="flex mt-1">
								           		<Button 
								           			fetching={false} 
								           			bgColor="blue" 
								           			fgColor='white' 
								           			onClick={() => journeyPlannerStore.loadSelectedJourneyTasks()}>Load Journey List</Button>
								           	</div>
							           	}

							        </div>
								</div>
								<div className="flex flex-col mt-4">
									<SubHeading>Accounts</SubHeading>

									<div className="flex flex-col mt-2">
										<SearchEntitiesList
											hasSelectedValue={journeyPlannerStore.selectedAccount != null}
											searchValue={journeyPlannerStore.searchAccountValue}
											onChangeSearchValue={(val) => journeyPlannerStore.onChangeSearchAccountValue(val)}
											searchValuePlaceholder="Search Accounts"
											searchOptions={journeyPlannerStore.searchAccountOptions}
											onSelectOption={(id) => journeyPlannerStore.onSelectAccount(id)}
											onClearSelectedValue={() => journeyPlannerStore.onClearSelectedValue()}
											selectedValue={journeyPlannerStore.selectedAccount?.label}
										/>

										{journeyPlannerStore.selectedAccount != null && 
							           		<div className="flex mt-1">
								           		<Button 
								           			fetching={false} 
								           			bgColor="blue" 
								           			fgColor='white' 
								           			onClick={() => journeyPlannerStore.loadSelectedAccount()}>Add Account</Button>
								           	</div>
							           	}

							        </div>
								</div>
								<div className="flex flex-col mt-4">
									<div className="flex flex-col">
										<SubHeading>Options</SubHeading>
										<div className="flex flex-col mt-4">
											<PanelOption 
												text="Show Orders"
												onToggle={() => journeyPlannerStore.onToggleOrdersPanel()}
												isActive={journeyPlannerStore.showOrdersPanel}
											/>
											<PanelOption 
												text="Show Servicing"
												onToggle={() => journeyPlannerStore.onToggleServicingPanel()}
												isActive={journeyPlannerStore.showServicingPanel}
											/>
											<PanelOption 
												text="Show Map"
												onToggle={() => journeyPlannerStore.onToggleMapPanel()}
												isActive={journeyPlannerStore.showMapsPanel}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={`${middleColSpan} flex flex-1 flex-col`}>
							{journeyPlannerStore.showMapsPanel && 
								<div className="flex w-full justify-between flex-col mb-8">
									<div className="flex w-full flex-col">
										<SubHeading>Map View</SubHeading>

										<div className="w-full mt-8" style={{ height: '500px'}} >
											<GoogleMarkerMap 
												zoomLevel={9}
												coords={journeyPlannerStore.journeyMarkers.length > 0 ? {
													latitude: journeyPlannerStore.journeyMarkers[0].latitude,
													longitude: journeyPlannerStore.journeyMarkers[0].longitude
												}: null} 
												path={journeyPlannerStore.journeyPathMarkers}
												markers={journeyPlannerStore.journeyMarkers} 
												renderMarkerData={(markerData) => {
							        				return (
							        					<div className="flex flex-col">
												            <div className="flex"><span className="font-bold">{markerData.sequence_number}. {markerData.type} at {markerData.account.name} {markerData.account.account_reference}</span></div>
												            <div className="flex"> {markerData.account.delivery_address.street_address}</div>
												        </div>
												      	)
									        		}}
								        		/>
										</div>
									</div>
								</div>
							}
							{journeyPlannerStore.showOrdersPanel && 
								<div className="col-span-9 flex w-full">
									<div className="flex w-full justify-between flex-col">
										<div className="flex w-full flex-col">
											<SubHeading>Pending Orders</SubHeading>
											<div className="flex mt-4 mb-8 w-full">
												<List 
													type="orders"
													items={journeyPlannerStore.filteredOrders}
													renderListItem={this.renderOrderListItem}
													renderListHeaders={this.renderOrderListHeaders}
													page={journeyPlannerStore.ordersPage}
													total={journeyPlannerStore.ordersTotal}
													hasNext={journeyPlannerStore.hasNextOrders}
													hasPrev={journeyPlannerStore.hasPrevOrders}
													offsetStart={journeyPlannerStore.ordersOffsetStart}
													offsetEnd={journeyPlannerStore.ordersOffsetEnd}
													onPreviousPage={() => journeyPlannerStore.onPreviousOrdersPage()}
													onNextPage={() => journeyPlannerStore.onNextOrdersPage()}
												/>
											</div>
										</div>
									</div>
								</div>
							}
							{journeyPlannerStore.showServicingPanel && 
								<div className="col-span-9 flex w-full	">
									<div className="flex w-full justify-between flex-col">
										<div className="flex w-full flex-col">
											<SubHeading>Servicing</SubHeading>
											<div className="flex mt-4 mb-8 w-full">
												<List 
													type="servicings"
													items={journeyPlannerStore.filteredServicingItems}
													renderListItem={this.renderServicingListItem}
													renderListHeaders={this.renderServicingListHeaders}
													page={journeyPlannerStore.servicingPage}
													total={journeyPlannerStore.servicingTotal}
													hasNext={journeyPlannerStore.hasNextServicing}
													hasPrev={journeyPlannerStore.hasPrevServicing}
													offsetStart={journeyPlannerStore.servicingOffsetStart}
													offsetEnd={journeyPlannerStore.servicingOffsetEnd}
													onPreviousPage={() => journeyPlannerStore.onPreviousServicingPage()}
													onNextPage={() => journeyPlannerStore.onNextServicingPage()}
												/>
											</div>
										</div>
									</div>
								</div>
							}
							<div className="flex flex-1 flex-col">
								<div className="flex flex-row items-center justify-between">
									<SubHeading>Tasks for {journeyPlannerStore.employeeOnDateTitle}</SubHeading>
									{journeyPlannerStore.dirty &&
										<div className="flex flex-row items-center">
						           			<div className="flex">
							           			<Button 
							           			fetching={journeyPlannerStore.savingTasks} 
							           			bgColor="error" 
							           			fgColor='white' 
							           			onClick={() => journeyPlannerStore.discardChanges()}> <ExitIcon className="w-4 h-4 text-white" />&nbsp; Discard Changes</Button>
							           		</div>
							           		<div className="flex ml-4">
								           		<Button 
								           			fetching={journeyPlannerStore.savingTasks} 
								           			bgColor="blue" 
								           			fgColor='white' 
								           			onClick={() => journeyPlannerStore.saveTasks()}> <SaveIcon className="w-4 h-4 text-white" />&nbsp; Save Tasks</Button>
								           	</div>
							           	</div>
							        }
						        </div>

						        <div className="flex w-full flex-col mt-4">
						        	{journeyPlannerStore.currentDriverTasks.length == 0 && 
										<div className="flex w-full items-center">
											<Label text="No tasks found" />
										</div>
									}
									{journeyPlannerStore.currentDriverTasks.map((driverTask, idx) => {
										let type = driverTask.type;
										if(type === 'journey_call'){
											return (
												<JourneyCall 
													sequenceNumber={driverTask.sequence_number} 
													maxSequenceNumber={journeyPlannerStore.currentDriverTasks.length}
													journeyCall={driverTask} 
													openingHoursForDay={journeyPlannerStore.getOpeningHoursForDay(driverTask)}
													key={driverTask.uuid}
													buildAddress={journeyPlannerStore.buildAddress}
													markDirty={() => journeyPlannerStore.markDirty()}
													onMoveUp={(uuid) => journeyPlannerStore.onMoveUpDriverTask(uuid)}
													onMoveDown={(uuid) => journeyPlannerStore.onMoveDownDriverTask(uuid)}
													onDelete={(uuid) => journeyPlannerStore.onDeleteDriverTask(uuid)}
												/>
											)
										}else if(type === 'order'){
											return (
												<JourneyOrder 
													sequenceNumber={driverTask.sequence_number} 
													maxSequenceNumber={journeyPlannerStore.currentDriverTasks.length}
													journeyOrder={driverTask} 
													key={driverTask.id}
													openingHoursForDay={journeyPlannerStore.getOpeningHoursForDay(driverTask)}
													buildAddress={journeyPlannerStore.buildAddress}
													markDirty={() => journeyPlannerStore.markDirty()}
													onMoveUp={(uuid) => journeyPlannerStore.onMoveUpDriverTask(uuid)}
													onMoveDown={(uuid) => journeyPlannerStore.onMoveDownDriverTask(uuid)}
													onDelete={(uuid) => journeyPlannerStore.onDeleteDriverTask(uuid)}
												/>
											)
										}else if(type === 'servicing_item'){
											return (
												<JourneyServicingItem
													sequenceNumber={driverTask.sequence_number} 
													maxSequenceNumber={journeyPlannerStore.currentDriverTasks.length}
													journeyServicingItem={driverTask} 
													key={driverTask.id}
													openingHoursForDay={journeyPlannerStore.getOpeningHoursForDay(driverTask)}
													buildAddress={journeyPlannerStore.buildAddress}
													markDirty={() => journeyPlannerStore.markDirty()}
													onMoveUp={(uuid) => journeyPlannerStore.onMoveUpDriverTask(uuid)}
													onMoveDown={(uuid) => journeyPlannerStore.onMoveDownDriverTask(uuid)}
													onDelete={(uuid) => journeyPlannerStore.onDeleteDriverTask(uuid)}
												/>
											)
										}else{
											return null;
										}
									})}
						        </div>
							</div>
						</div>
					</div>
				</div>
			</Main>
		)
	}
}

export default Authorisation(JourneysPlanner,  ['administrator', 'employee', 'accountant']);