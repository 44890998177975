import React from 'react';
import {inject, observer} from 'mobx-react';
import {intersect} from '../services/util';
import Home from './home/index';

var createReactClass = require('create-react-class');

export const Authorisation = (WrappedComponent, allowedRoles, allowedPermissions = null) => {
	  let withAuthorisation = createReactClass({
		render(){
			 let isAuthorised = false;
		     const roles = this.props.ui.userRoles;
		     
		     let intersectedRoles = intersect(roles, allowedRoles);
		     if(intersectedRoles.length == 0){
		     	this.props.ui.alertError('You are unauthorised to access this page');
		       	return <Home />
		     }

		     return <WrappedComponent {...this.props} />;
		}
	  });
	  return inject('ui')(observer(withAuthorisation));
	}

export default Authorisation;
