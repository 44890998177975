import { FormState, FieldState } from 'formstate';
import { observable } from 'mobx';
import UserApi from '../../services/user';

import { validator, isEmailValidator } from '../../services/validation';

class ForgotPassword{
	email;
	@observable submitError;
	form;

	uiStore;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.userApi = new UserApi(uiStore);
		this.initStore();
	}

	initStore(){
		this.email = new FieldState('').validators(isEmailValidator);
		this.submitError = '';
		this.form = new FormState({
		    email: this.email
		});
	}

	checkForRedirect(){
		this.uiStore.checkForRedirect();
	}

	onBackToLogin(){
		this.uiStore.goToLogin();
	}

	onChangeEmail(val){
		this.email.onChange(val);
	}

	onResetPassword = async () => {
	    const res = await this.form.validate();
	    if(res.hasError) return;

	    this.userApi.forgotPassword(this.email.value)
	    	.then((response) => {
	    		this.uiStore.alertInfo('Please check your email for instructions on how to reset your password.')
	    		this.uiStore.goToLogin();
	    	})
	    	.catch((error) => {
	    		this.submitError = error.message;
	    	})	
	};

}

export default ForgotPassword;