import { observable, computed } from 'mobx';
import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import InviteApi from '../../services/invite';


class AcceptInvite{
	token;
	@observable password;
	@observable confirmPassword;
	@observable agreedToTerms;
	@observable fetching;
	form;

	inviteApi;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.inviteApi = new InviteApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.password = new FieldState('').validators((val) => !validator.isRequired(val) && 'Please enter a password');
		this.confirmPassword = new FieldState('').validators((val) => !validator.isEqualTo(val, this.password.value) && 'Your passwords do not match');
		this.form = new FormState({
		    password: this.password,
		    confirmPassword: this.confirmPassword
		});
		this.agreedToTerms = false;
		this.fetching = false;
	}

	@computed get acceptInviteButtonDisabled(){
		return !this.agreedToTerms;
	}

	onChangeTerms(val){
		this.agreedToTerms = val;
	}

	setToken(token){
		this.token = token;
	}

	noToken(){
		this.uiStore.alertError('An invalid token was provided');
		this.uiStore.goToLogin();
	}

	onSubmit = async () => {
	    const res = await this.form.validate();
	    if(res.hasError) return;

	    this.fetching = true;
	    this.inviteApi.acceptInvite(this.token, this.password.value)
	    	.then((response) => {
	    		this.uiStore.alertSuccess('Invite accepted. You can now log in.');
	    		this.uiStore.goToLogin();
	    	})
	    	.catch((error) => {
	    		this.uiStore.alertError(error.message);
	    	})
	    	.finally(() => {
	    		this.fetching = false;
	    	})


	};

}

export default AcceptInvite;