import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPostcodeValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import PODApi from '../../services/pod';
import AccountApi from '../../services/account';
import DriverApi from '../../services/driver';

import ProductApi from '../../services/product';
import AssetApi from '../../services/asset';
import ServiceApi from '../../services/service';
import CommonApi from '../../services/common';
import PricingApi from '../../services/pricing';
import TaskApi from '../../services/task';

import {SERVER_DATE_FORMAT, SERVER_DATETIME_FORMAT} from '../../services/util';

import uuidv4 from 'uuid';
import moment from 'moment';

import { uuid } from 'uuidv4';

class ManagePOD{

	id;

	podApi;
	accountApi;
	driverApi;
	accountApi;
	productApi;
	assetApi;
	commonApi;
	pricingApi;

	@observable currentPOD;
	
	@observable accountSearchText;
	@observable accounts;
	@observable selectedAccountId;
	@observable podAccount;
	@observable podData;
	@observable drivers;
	@observable fetching;

	@observable assets;
	@observable services;
	@observable products;

	@observable podForm;
	@observable downloadingPDF;


	@observable mode;

	taskApi;

	constructor(appStore){
		this.appStore = appStore;
		this.podApi = new PODApi(appStore);
		this.accountApi = new AccountApi(appStore);
		this.driverApi = new DriverApi(appStore);
		this.productApi = new ProductApi(appStore);
		this.assetApi = new AssetApi(appStore);
		this.serviceApi = new ServiceApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.pricingApi = new PricingApi(appStore);
		this.taskApi = new TaskApi(appStore);
		this.initStore();
	}

	initStore(){
		this.id = null;
		this.currentPOD = null;
		this.podData = {
			podReference: new FieldState(null),
			selectedDriver: new FieldState(null).validators((val) => isNotNullValidator(val, 'driver')),
			note: new FieldState(null),
			deliveredOn: new FieldState(null).validators((val) => isNotNullValidator(val, 'delivered on')),
			createdOn: new FieldState(null),
			cashReceived: new FieldState(null),
			customerPrintName: new FieldState(null),
			poNumber: new FieldState(null),
			productItems: [],
			assetPurchaseItems: [],
			assetReturnItems: [],
			serviceItems: []
		}
		this.mode = 'add';
		this.accounts = [];
		this.accountSearchText = new FieldState(null);
		this.podAccount = null;
		this.selectedAccountId = null;
		this.drivers = [];
		this.products = [];
		this.assets = [];
		this.services = [];
		this.fetching = false;
		this.podForm = new FormState({
			selectedDriver: this.podData.selectedDriver,
			deliveredOn: this.podData.deliveredOn
		});
		this.downloadingPDF = false;
	}

	formSync = async (id) => {
		try{
			this.fetching = true;
			let response = await this.podApi.getById(id);
			this.currentPOD = response;
			this.setPOD(this.currentPOD);
			await this.selectAccount(this.currentPOD.account.id);
			this.fetching = false;
			this.mode = 'view';
		}catch(e){
			console.log(e)
		}
	}

	fetchDrivers = async () => {
		try{
			this.drivers = await this.driverApi.fetchAllDrivers();
		}catch(e){
			console.log(e);
		}
	}

	setPOD(pod){
		this.id = pod.id;
		this.mode = 'view';
		this.podData.podReference.value = pod.pod_reference;
		this.podData.selectedDriver.value = pod.driver.id;
		this.podData.note.value = pod.note;
		this.podData.deliveredOn.value = moment(pod.delivered_on).tz(this.appStore.timezone);
		this.podData.createdOn.value =  moment(pod.created_on).tz(this.appStore.timezone);
		this.podData.cashReceived.value = pod.cash_received;
		this.podData.customerPrintName.value = pod.customer_print_name;
		this.podData.poNumber.value = pod.po_number;

		this.podData.productItems = pod.product_items.map((productItem) => {
			return this.generateProductItemEntry(productItem.id, productItem.product.id, productItem.quantity, productItem.foc, productItem.price, productItem.product.depositable,  productItem.returns, productItem.account_location?.id);
		})
		this.podData.assetPurchaseItems = pod.purchase_asset_items.map((assetPurchaseItem) => {
			return this.generateAssetPurchaseItemEntry(assetPurchaseItem.id, assetPurchaseItem.asset.id, assetPurchaseItem.serial_number, assetPurchaseItem.quantity, assetPurchaseItem.foc, assetPurchaseItem.price);
		})
		this.podData.assetReturnItems = pod.return_asset_items.map((assetReturnItem) => {
			return this.generateAssetReturnItemEntry(assetReturnItem.id, assetReturnItem.asset.id, assetReturnItem.serial_number, assetReturnItem.quantity);
		})
		this.podData.serviceItems = pod.service_items.map((serviceItem) => {
			return this.generateServiceItemEntry(serviceItem.id, serviceItem.service.id, serviceItem.asset.id, serviceItem.serial_number, serviceItem.quantity, serviceItem.price);
		});
	}

	selectAccount = async (id) => {
		this.selectedAccountId = id;
		this.fetching = true;
		try{
			let response = await this.accountApi.getAccountById(id);
			this.podAccount = response.account;
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	searchAccounts = async (searchText) => {
		if(searchText == ''){
			this.accounts = [];
			return;
		}
		this.fetching = true;
		try{
			this.accounts = await this.accountApi.fetchAllAccounts(searchText);
		}catch(error){
			console.log(error);
		}finally{
			this.fetching = false
		}		
	}

	onChangeAccountSearchText(val){
		this.accountSearchText.onChange(val);
		this.selectedAccountId = null;
		this.searchAccounts(this.accountSearchText.value);
	}

	@computed get addMode(){
		return this.mode === 'add';
	}

	@computed get editMode(){
		return this.mode === 'edit';
	}

	@computed get viewMode(){
		return this.mode === 'view';
	}

	@computed get driverOptions(){
		return this.drivers.map((driver) => {
			return {
				value: driver.id,
				label: driver.name
			}
		})
	}

	@computed get isDisabled(){
		return this.viewMode;
	}

	@computed get isDisabledAsOrderChange(){
		return this.viewMode || this.editMode;
	}

	@action addProductItemEntry(){
		this.podData.productItems.push(this.generateProductItemEntry());
	}

	deleteProductItem(uuid){
		this.podData.productItems = this.podData.productItems.filter((a) => a.uuid != uuid);
	}

	@action addAssetItemEntry(){
		this.podData.assetPurchaseItems.push(this.generateAssetPurchaseItemEntry());
	}

	@action deleteAssetPurchaseItem(uuid){
		this.podData.assetPurchaseItems = this.podData.assetPurchaseItems.filter((a) => a.uuid != uuid);
	}

	@action deleteAssetReturnItem(uuid){
		this.podData.assetReturnItems = this.podData.assetReturnItems.filter((a) => a.uuid != uuid);
	}

	@action addAssetReturnedItemEntry(){
		this.podData.assetReturnItems.push(this.generateAssetReturnItemEntry());
	}

	@action addServiceItemEntry(){
		this.podData.serviceItems.push(this.generateServiceItemEntry());
	}

	@action deleteServiceItem(uuid){
		this.podData.serviceItems = this.podData.serviceItems.filter((a) => a.uuid != uuid);
	}


	@action toggleEditMode(){
		this.mode = 'edit';
	}

	@action toggleViewMode(){
		this.setPOD(this.currentPOD);
		this.mode = 'view';
	}

	generateProductItemEntry(id=null, productId=null, qty=null, foc=null, price=null, depositable=false, returns=null, locationId=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedProduct: new FieldState(productId).validators((val) => isNotNullValidator(val, 'product')),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val)),
			price: new FieldState(price).validators((val) => isPositiveNumberValidator(val)),
			foc: new FieldState(foc).validators((val) => isPositiveNumberValidator(val)),
			depositable: depositable,
			returns: new FieldState(returns).validators((val) => depositable && isPositiveNumberValidator(val)),
			selectedLocation: new FieldState(locationId),
			get subtotal(){
				return that.calculateSubtotal(this.price, this.qty);
			}
		})
	}

	generateAssetPurchaseItemEntry(id=null, assetId=null, serialNumber=null, qty=null, foc=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			serialNumber: new FieldState(serialNumber),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val)),
			price: new FieldState(price).validators((val) => isPositiveNumberValidator(val)),
			foc: new FieldState(foc).validators((val) => isPositiveNumberValidator(val)),
			get subtotal(){
				return that.calculateSubtotal(this.price, this.qty);
			}
		})
	}

	generateAssetReturnItemEntry(id=null, assetId=null, serialNumber=null, qty=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			serialNumber: new FieldState(serialNumber),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val))
		})
	}

	generateServiceItemEntry(id=null, serviceId=null, assetId=null, serialNumber=null, qty=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedService: new FieldState(serviceId).validators((val) => isNotNullValidator(val, 'service')),
			selectedAsset: new FieldState(assetId),
			serialNumber: new FieldState(serialNumber),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val)),
			price: new FieldState(price).validators((val) => isPositiveNumberValidator(val)),
			get subtotal(){
				return that.calculateSubtotal(this.price, this.qty);
			}
		})
	}

	calculateSubtotal(price, qty){
		if(this.podAccount == null || price.value == null || qty.value == null) return null;
		let currency = this.podAccount.currency;
		if(currency == null) return null;
		let vatRate = this.podAccount.vat_rate;
		if(vatRate == null) return null;
		if(price.error != undefined) return null;

		if(qty == null) return null;
		if(qty.error != undefined) return null;
		let beforeVATPrice = parseFloat(price.value) * parseInt(qty.value);
		let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
		let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
		return `${currency.symbol}${totalPrice}`
	}

	fetchProducts = async () => {
		try{
			this.products  = await this.productApi.getAllProducts();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchAssets = async () => {
		try{
			this.assets  = await this.assetApi.fetchAllAssets();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchServices = async () => {
		try{
			this.services  = await this.serviceApi.fetchAllServices();
		}catch(error){ 	
			console.log(error);
		}
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get productOptions(){
		return this.products.map((product) => {
			return {
				label: product.name,
				value: product.id
			}
		})
	}

	@computed get assetOptions(){
		return this.assets.map((asset) => {
			return {
				value: asset.id,
				label: asset.name
			}
		})
	}

	@computed get locationOptions(){
		if(this.podAccount == null) return []

		return this.podAccount.locations.map((l) => {
			let locationName = l.name;
			if(l.location_code != null){
				locationName = `${locationName} (${l.location_code})`
			}
			return {
				value: l.id,
				label: locationName
			}
		})
	}

	@computed get serviceOptions(){
		return this.services.map((service) => {
			return {
				value: service.id,
				label: service.name
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				label: `${currency.name} (${currency.symbol})`,
				value: currency.id
			}
		})
	}


	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: vatRate.id,
				label: vatRate.name
			}
		})
	}

	findProductItemIdx(uuid){
		return this.podData.productItems.findIndex((p) => p.uuid == uuid);
	}

	findAssetPurchaseItemIdx(uuid){
		return this.podData.assetPurchaseItems.findIndex((a) => a.uuid == uuid);
	}

	findAssetRentalItemIdx(uuid){
		return this.podData.assetReturnItems.findIndex((a) => a.uuid == uuid);	
	}

	findServiceItemIdx(uuid){
		return this.podData.serviceItems.findIndex((s) => s.uuid == uuid);
	}

	changeProductItemProduct(uuid, val){
		let productId = val;
		let productItemIdx = this.findProductItemIdx(uuid);
		let product = this.products.find((p) => p.id == productId);
		if(productItemIdx != -1 && product != null){
			this.podData.productItems[productItemIdx].selectedProduct.onChange(productId);
			this.podData.productItems[productItemIdx].depositable = product.depositable;
			this.getAccountProductPrice(productItemIdx, productId);
		}
	}

	changeProductItemLocation(uuid, val){
		let locationId = val;
		let productItemIdx = this.findProductItemIdx(uuid);
		if(productItemIdx != -1){
			this.podData.productItems[productItemIdx].selectedLocation.onChange(locationId);
		}
	}

	changePurchaseAssetItem(uuid, val){
		let assetId = val;
		let assetItemIdx = this.findAssetPurchaseItemIdx(uuid);
		if(assetItemIdx != -1){
			this.podData.assetPurchaseItems[assetItemIdx].selectedAsset.onChange(assetId);
			this.getAccountAssetPrice(assetItemIdx, assetId);
		}
	}

	changeReturnAssetItem(uuid, val){
		let assetId = val;
		let assetItemIdx = this.findAssetRentalItemIdx(uuid);
		if(assetItemIdx != -1){
			this.podData.assetReturnItems[assetItemIdx].selectedAsset.onChange(assetId);
		}
	}

	getAccountAssetPrice(assetItemIdx, assetId){
		let currencyId = this.podAccount.currency?.id;
		this.fetching = true;
		this.pricingApi.getAccountAssetPrice(this.selectedAccountId, assetId, currencyId)
				.then((response) => {
					let specialPrice = response.special_price;
					this.podData.assetPurchaseItems[assetItemIdx].price.onChange(specialPrice.price);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
	}

	getAccountProductPrice(productItemIdx, productId){
		let currencyId = this.podAccount.currency.id;
		this.fetching = true;
		this.pricingApi.getAccountProductPrice(this.selectedAccountId, productId, currencyId)
				.then((response) => {
					let specialPrice = response.special_price;
					this.podData.productItems[productItemIdx].price.onChange(specialPrice.price);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
	}

	changeServiceItemService(uuid, val){
		let serviceId = val;
		let serviceItemIdx = this.findServiceItemIdx(uuid);
		if(serviceItemIdx != -1){
			this.podData.serviceItems[serviceItemIdx].selectedService.onChange(serviceId);
			this.getAccountServicePrice(serviceItemIdx, serviceId);
		}
	}

	changeServiceItemAsset(uuid, val){
		let serviceId = val;
		let serviceItemIdx = this.findServiceItemIdx(uuid);
		if(serviceItemIdx != -1){
			this.podData.serviceItems[serviceItemIdx].selectedAsset.onChange(serviceId);
		}
	}

	getAccountServicePrice(serviceItemIdx, serviceId){
		let currencyId = this.podAccount.currency.id;
		this.fetching = true;
		this.pricingApi.getAccountServicePrice(this.selectedAccountId, serviceId, currencyId)
				.then((response) => {
					let specialPrice = response.special_price;
					this.podData.serviceItems[serviceItemIdx].price.onChange(specialPrice.price);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
	}




	save = async () => {
		
		const productItemsForm = new FormState(this.podData.productItems.map((item) => {
			return new FormState({
				price: item.price,
				selectedProduct: item.selectedProduct,
				qty: item.qty,
				foc: item.foc,
				returns: item.returns
			});
		}));
		
		const purchaseAssetItemsForm = new FormState(this.podData.assetPurchaseItems.map((item) => {
			return new FormState({
				price: item.price,
				selectedAsset: item.selectedAsset,
				qty: item.qty,
				foc: item.foc
			});
		}));

		const assetReturnItemsForm = new FormState(this.podData.assetReturnItems.map((item) => {
			return new FormState({
				selectedAsset: item.selectedAsset,
				serialNumber: item.serialNumber,
				qty: item.qty,
			});
		}));

		const serviceItemsForm = new FormState(this.podData.serviceItems.map((item) => {
			return new FormState({
				price: item.price,
				selectedService: item.selectedService,
				qty: item.qty
			});
		}));
		
		let res = await this.podForm.validate();
		let productItemsRes = await productItemsForm.validate();
		let purchaseAssetItemsRes = await purchaseAssetItemsForm.validate();
		let assetItemsReturnRes = await assetReturnItemsForm.validate();
		let serviceItemsRes = await serviceItemsForm.validate();

		if(res.hasError || productItemsRes.hasError || purchaseAssetItemsRes.hasError || serviceItemsRes.hasError || assetItemsReturnRes.hasError) return;

		try{
			this.fetching = true;
			let payload = {
				pod_reference: this.podData.podReference.value,
				driver_id: parseInt(this.podData.selectedDriver.value, 10),
				notes: this.podData.note.value,
				delivered_on: moment.utc(this.podData.deliveredOn.value).format(SERVER_DATETIME_FORMAT),
				created_on: !this.addMode ? moment.utc(this.podData.createdOn.value).format(SERVER_DATETIME_FORMAT) : null, 
				cash_received: this.podData.cashReceived.value,
				customer_print_name: this.podData.customerPrintName.value,
				po_number: this.podData.poNumber.value,
				products: this.podData.productItems.map((productItem) => {
					return {
						id: productItem.id,
						product_id: parseInt(productItem.selectedProduct.value, 10),
						price: parseFloat(productItem.price.value).toFixed(4),
						quantity: parseInt(productItem.qty.value, 10),
						foc: parseInt(productItem.foc.value, 10),
						returns: productItem.returns.value != null ? parseInt(productItem.returns.value, 10): null,
						location_id: productItem.selectedLocation.value != null ? parseInt(productItem.selectedLocation.value, 10) : null
					}
				}),
				purchase_assets: this.podData.assetPurchaseItems.map((assetPurchaseItem) => {
					return {
						id: assetPurchaseItem.id,
						asset_id: parseInt(assetPurchaseItem.selectedAsset.value, 10),
						price: parseFloat(assetPurchaseItem.price.value).toFixed(4),
						quantity: parseInt(assetPurchaseItem.qty.value, 10),
						foc: parseInt(assetPurchaseItem.foc.value, 10),
						serial_number: assetPurchaseItem.serialNumber.value
					}
				}),
				returned_assets: this.podData.assetReturnItems.map((assetReturnItem) => {
					return {
						id: assetReturnItem.id,
						asset_id: parseInt(assetReturnItem.selectedAsset.value, 10),
						quantity: parseInt(assetReturnItem.qty.value, 10),
						serial_number: assetReturnItem.serialNumber.value
					}
				}),
				services: this.podData.serviceItems.map((serviceItem) => {
					return {
						id: serviceItem.id,
						service_id: parseInt(serviceItem.selectedService.value, 10),
						asset_id: parseInt(serviceItem.selectedAsset.value, 10),
						serial_number: serviceItem.serialNumber.value,
						price: parseFloat(serviceItem.price.value).toFixed(4),
						quantity: parseInt(serviceItem.qty.value, 10)
					}
				})
			}
			
			if(this.addMode){
				let response = await this.podApi.newPOD(this.selectedAccountId, payload);
				this.appStore.alertSuccess('POD created');
			}else{
				let response = await this.podApi.updatePOD(this.id, payload);
				this.appStore.alertSuccess('POD updated');
			}
			
			this.appStore.goToPODs();
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
	}

	@action downloadPDF(){
		if(this.id == null || this.downloadingPDF) return;
		this.downloadingPDF = true;
		this.podApi.downloadPDF(this.id)
			.then((response) => {
				this.taskApi.handleAsyncTask(response, this.appStore, {
					'file_name': `POD_${this.id}.pdf`
				})
				.finally(() => {
					this.downloadingPDF = false;
				});
			})
			.catch((error) => {
				console.log(error);
			})
	}


}

export default ManagePOD;