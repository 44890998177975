import BaseApi from '../services/base';

class DriverApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllDrivers = async (page) => {
		let url = `v1/drivers?page=${page}`;
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllDrivers= async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAllDrivers(page);
			let data = response.users;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}

	getDriverDeliveries(driverId, deliveryDate){
    	return this.fetch(`v1/drivers/${driverId}/deliveries/${deliveryDate}`, {
			auth: true,
			method: 'GET'
		})
    }
	

    saveDriverDeliveries(driverId, deliveryDate, payload){
    	return this.fetch(`v1/drivers/${driverId}/deliveries/${deliveryDate}`, {
			auth: true,
			method: 'PUT',
			body: JSON.stringify(payload)
		})
    }
}

export default DriverApi;