
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* 
EXAMPLE 
validate('test', isBetween, { options }, 'Error Msg')
*/
export const validate = (val, validateFn, options, onError) => {
	let result = validateFn(val, options);
	let errorMsg = onError === null ? 'Error' : onError;
	return result ? '' : errorMsg;
}

export const validator = {
	isBetween: (val, options) => {
		return val.length > options['min'] && val.length < options['max'];
	},
	isRequired: (val) => {
		return val != null && val.length > 0;
	},
	isEmail: (val) => {
		return EMAIL_REGEX.test(val);
	},
	isPostcode: (val) => {
		return val.length >= 5;
	},
	isEqualTo: (val, compareTo) => {
		return val === compareTo;
	},
	isNumber: (val) => {
		return !isNaN(val);
	},
	isPositiveNumber: (val) => {
		return val != null && val !== '' && validator.isNumber(val) && parseFloat(val, 2) >= 0;
	},
	isGreaterThanZero: (val) => {
		return validator.isPositiveNumber(val) && parseFloat(val, 2) > 0;
	},
	hasValue: (val) => {
		return val != null && val.length > 0;
	},
	isPhoneNumber: (val) => {
		if(val.length != 11) return false;
		for(let i = 0;i < val.length;i++){
			if(!validator.isNumber(val[i])){
				return false;
			}
		}
		return true;
	},

};

export const isEmailValidator = (val) => !validator.isEmail(val) && 'Please enter a valid email';
export const isRequiredValidator = (val, field) => !validator.isRequired(val) && `Please provide a ${field}`;
export const isPostcodeValidator = (val) => val != null && !validator.isPostcode(val) && 'Please enter a valid postcode';
export const isPositiveNumberValidator = (val) => !validator.isPositiveNumber(val) && 'Please enter a positive number';
export const isNumberValidator = (val) => !validator.isNumber(val) && 'Please enter a valid number';
export const isNotNullValidator = (val, field) => (val == null || val == undefined) && `Please provide a ${field}`;

