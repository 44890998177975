import BaseApi from '../services/base';

class UserApi extends BaseApi{
	constructor(props){
		super(props);
	}

	cancelSubscription(){
		return this.fetch('v1/users/subscriptions/cancel', {
			auth: true,
			method: 'POST',
		})
	}

	getAll(page, searchText=null){
		let url = `v1/users?page=${page}`;
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	getById(userId){
		return this.fetch(`v1/users/${userId}`, {
			auth: true,
			method: 'GET',
		})
	}

	
	inviteUser(payload){
		return this.fetch(`v1/users/invite`, {
			auth: true,
			method: 'POST',
			body: JSON.stringify(payload)
		})
	}

	updateUser(userId, user){
		return this.fetch(`v1/users/${userId}`, {
			auth: true,
			method: 'PUT',
			body: JSON.stringify(user)
		})
	}
	
	deleteUserById(userId){
		return this.fetch(`v1/users/${userId}`, {
			auth: true,
			method: 'DELETE'
		})
	}

    getUser(){
        return this.fetch('v1/users/current',{
            method: 'GET',
            auth:true
        })
    }

    forgotPassword(email){
    	return this.fetch(`v1/users/forgot-password`, {
			auth: true,
			method: 'POST',
			body: JSON.stringify({
				email: email
			})
		})

    }
}

export default UserApi;