import BaseApi from '../services/base';

class JourneyApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, searchText=null){
		let url = `v1/journeys?page=${page}`;
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllJourneys = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page);
			let data = response.journeys;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getById(id){
		return this.fetch(`v1/journeys/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateJourney(id, journey){
		return this.fetch(`v1/journeys/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(journey)
		})
	}

	newJourney(journey){
		return this.fetch(`v1/journeys`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(journey)
		})
	}

	getJourneyList(id){
		return this.fetch(`v1/journeys/${id}/list`, {
			method: 'GET',
			auth: true
		})
	}
}

export default JourneyApi;