import React, {Component} from 'react';

import {inject, observer} from 'mobx-react';

import { SpacerTop } from '../elements/ui';
import { Heading, Link } from '../elements/text';
import { Label, TextInput, CheckBox, Button } from '../elements/input';

import AuthContainer from '../home/auth-container';


const TERMS_URL = 'https://www.drinkflow.com/terms'

@inject('acceptInvite')
@observer
class AcceptInvite extends Component{
	componentDidMount(){
		let acceptInviteStore = this.props.acceptInvite;
		acceptInviteStore.setToken(this.props.token);
	}

	componentWillUnmount(){
		let acceptInviteStore = this.props.acceptInvite;
		acceptInviteStore.initStore();
	}

	render(){
		let acceptInviteStore = this.props.acceptInvite;
		return (
			<AuthContainer
				title="Accept Invite to Drinkflow"
				description="Set up your password for your Drinkflow user account"
				>
				<div>
		            <div>
		              <Label htmlFor="password" text="Password" />
		              <TextInput
		              	id="password" 
		              	type="password" 
		              	value={acceptInviteStore.password.value}
		              	error={acceptInviteStore.password.error}
		              	onChange={(val) => acceptInviteStore.password.onChange(val)} />
		            </div>

		            <SpacerTop>
		              <Label htmlFor="password" text="Confirm Password" />
		              <TextInput id="password" type="password" 
		              	value={acceptInviteStore.confirmPassword.value}
		              	error={acceptInviteStore.confirmPassword.error}
		              	onChange={(val) => acceptInviteStore.confirmPassword.onChange(val)} />
		            </SpacerTop>

		            <SpacerTop>
			            <CheckBox 
				        	label={
				        		<span>I agree to the <a href={TERMS_URL}>terms and conditions</a> of using this application</span>
				        	}
				        	checked={acceptInviteStore.agreedToTerms} 
				        	onChange={(e) => acceptInviteStore.onChangeTerms(e.target.checked)} />
				    </SpacerTop>

		            <SpacerTop>
		                <Button 
		                	disabled={acceptInviteStore.acceptInviteButtonDisabled} 
		                	onClick={() => acceptInviteStore.onSubmit()} 
		                	fetching={acceptInviteStore.fetching}>Accept Invite</Button>
		            </SpacerTop>
	        	</div>
		    </AuthContainer>
		);
	}
}

export default AcceptInvite;