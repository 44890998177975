import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';

import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';

import AccountInformation from './account-information';

@observer
class AccountSearchInput extends Component{
	constructor(props){
		super(props);
	}

	render(){
		let accounts = this.props.accounts;
		let selectedAccountId = this.props.selectedAccountId;
		let currentAccount = this.props.currentAccount;
		let editMode = 'editMode' in this.props ? this.props.editMode : false;
		let noOfAccounts = this.props.accounts.length;
		return (
			<div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
              	{!editMode && 
              		<div className="sm:col-span-6">
              		  <Label>
			            Search by Account Name or Reference
			          </Label>	
		              <TextInput 
		              	disabled={this.props.disabled}
		              	value={this.props.value}
		              	onChange={(val) => this.props.onChange(val)}
		              	 />
			            {accounts.length > 0 &&	selectedAccountId == null &&
				              <div className="flex flex-col w-full bg-white rounded-md border border-gray-300 mt-4 px-3 py-2 ">
				              	{accounts.map((account) => {
				              		let itemClassName = "flex flex-1 flex-row justify-between items-center py-2 mt-2"
				              		if(noOfAccounts > 1){
				              			itemClassName = `${itemClassName} border-b border-gray-300`
				              		}
				              		return (
				              			<div className={itemClassName}>
					              			<div className="flex">
					              				<Label>{account.account_reference} - {account.name}</Label>
					              			</div>
					              			<div className="flex w-1/5">
					              				<Button 
					              					bgColor="blue" 
					              					fgColor='white' 
					              					disabled={this.props.isDisabled} 
					              					onClick={() => this.props.onSelect(account.id)}>Choose {account.account_reference}</Button>
					              			</div>
					              		</div>
					              	)
				              	})}
				              </div>
				        }
				    </div>
				}
		        {currentAccount != null && 
		        	<div className="sm:col-span-6">
		        		<AccountInformation 
		        			currentAccount={currentAccount} 
		        			onGoToAccount={this.props.onGoToAccount} 
		        			onGoToCustomer={this.props.onGoToCustomer}
		        		/>
		        	</div>
		        }
		   	</div>
		)
	}
}



export default AccountSearchInput;