import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {TextInput, TextArea, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {DeleteIcon} from '../common/icon';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';

import Authorisation from '../authorisation';

@inject('manageJourney')
@observer
class ManageJourney extends Component{
	constructor(props){
		super(props);
		this.renderAccountListItem = this.renderAccountListItem.bind(this);
	}

	componentDidMount(){
		let manageJourney = this.props.manageJourney;
		if(this.props.viewMode){
			manageJourney.formSync(this.props.journeyId);
		}
		manageJourney.fetchDrivers();
	}

	componentWillUnmount(){
		let manageJourney = this.props.manageJourney;
		manageJourney.initStore()
	}

	renderAccountListItem(item){
		let manageJourney = this.props.manageJourney;
		return (
			<ListItem onEdit={() => manageJourney.onEditAccount(item.id)}>
				<ItemColumn text={item.name} subtext={item.account_reference} />
				<Status text={item.account_status?.name} />
			</ListItem>
		)
	}


	render(){
		let manageJourney = this.props.manageJourney;
		return (
			<Main>
				<div>
					<div className="flex flex-row flex-1 items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {manageJourney.name.value.length > 0 ? manageJourney.name.value : 'Journey Information'}
					        </Heading>
					        <Paragraph>
					          Journey Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageJourney.viewMode && 
					    		<Button fetching={manageJourney.fetching} onClick={() => manageJourney.toggleEditMode()}>
						          Edit Journey
						        </Button>
						    }
					        {!manageJourney.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton fetching={manageJourney.fetching} onClick={() => manageJourney.onCancel()} fetching={manageJourney.fetching}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button disabled={!manageJourney.validForm} fetching={manageJourney.fetching} onClick={() => manageJourney.save()} fetching={manageJourney.fetching}>
								          {manageJourney.editMode ? 'Update' : 'Save' } Journey
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
					<div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						<div className="sm:col-span-3">
				          <Label>
				            Journey Name
				          </Label>
			              <TextInput 
			              	disabled={manageJourney.viewMode}
			            	onChange={(val) => manageJourney.name.onChange(val)}
			            	value={manageJourney.name.value} 
			            	error={manageJourney.name.error} />
				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            Repeats Every Days
				          </Label>
			              <TextInput 
			              	type="number"
			              	disabled={manageJourney.viewMode}
			            	onChange={(val) => manageJourney.repeatsEveryDays.onChange(val)}
			            	value={manageJourney.repeatsEveryDays.value} 
			            	error={manageJourney.repeatsEveryDays.error} />
				        </div>
				       	<div className="sm:col-span-3">
				          <Label>
				            Driver
				          </Label>
			               <Select 
			              	onChange={(val) => manageJourney.selectedDriver = val}
		           			placeholder="Choose a driver for this journey"
		           			disabled={manageJourney.viewMode}
			           		options={manageJourney.driverOptions} 
			           		value={manageJourney.selectedDriver}
			           		 />
				        </div>
				        <div className="sm:col-span-6">
				          <CheckBox 
				        	disabled={manageJourney.viewMode}
				        	label="Archived" 
				        	checked={manageJourney.archived.value} 
				        	onChange={(e) => manageJourney.archived.onChange(e.target.checked)} />
				        </div>
					</div>
					{!manageJourney.addMode &&
				      	<FormDivider>
					      <div className="mt-6">
					      	<Card title="Accounts linked to this Journey">
						      	<List 
						      		type="accounts"
						      		renderListItem={this.renderAccountListItem}
						      		items={manageJourney.journeyAccounts.accounts}
						      		page={manageJourney.journeyAccounts.page}
									total={manageJourney.journeyAccounts.total}
									hasNext={manageJourney.journeyAccounts.hasNext}
									hasPrev={manageJourney.journeyAccounts.hasPrev}
									offsetStart={manageJourney.journeyAccounts.offsetStart}
									offsetEnd={manageJourney.journeyAccounts.offsetEnd}
									onPreviousPage={() => manageJourney.onPreviousAccountsPage()}
									onNextPage={() => manageJourney.onNextAccountsPage()}
								/>
						    </Card>
					      </div>
					   	</FormDivider>
					}
				</div>
			</Main>
		)
	}
}

export default Authorisation(ManageJourney,  ['administrator', 'employee', 'accountant']);