import 'react-dates/initialize';

import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import DevTools from 'mobx-react-devtools';

import Alert from './components/common/alert';

import { renderView } from './render';

import 'react-dates/lib/css/_datepicker.css';

import './styles/tailwind.css';



@inject('ui')
@observer
class App extends Component {
    constructor(props){
        super(props);
        this.renderAlerts = this.renderAlerts.bind(this);
    }

	componentDidMount(){
        let uiStore = this.props.ui;
        uiStore.startUp();
	}

    renderAlerts(){
        let uiStore = this.props.ui;
        if(uiStore.alerts.length > 0){
            return <Alert key={alert.id} alert={uiStore.alerts[0]} />
        }else{
            return null;
        }
    }

	render() {
        let uiStore = this.props.ui;
        if(!uiStore.appLoaded) return null;
        let alertsView = this.renderAlerts(uiStore);
        let renderedView = renderView(uiStore);
        return (
            <Fragment>
                {alertsView}
                {renderedView}
            </Fragment>
        )
	}
}


export default App;
