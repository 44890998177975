import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, Item, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('orderList')
@observer
class Orders extends Component{
	constructor(props){
		super(props);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let orderList = this.props.orderList;
		orderList.fetchOrders();
	}

	componentWillUnmount(){
		let orderList = this.props.orderList;
		orderList.initStore();
	}

	onPreviousPage(){
		let orderList = this.props.orderList;
		orderList.onPreviousPage();
	}

	onNextPage(){
		let orderList = this.props.orderList;
		orderList.onNextPage();
	}

	renderListHeaders(){
		return (
			<ListItem gridCols={9}>
				<div className="sm:col-span-1">Order</div>
				<div className="sm:col-span-2">Account</div>
				<div className="sm:col-span-2">Customer</div>
				<div className="sm:col-span-2">Created On</div>
				<div className="sm:col-span-1">Fulfillment</div>
				<div className="sm:col-span-1">Payment</div>
			</ListItem>
		)
	}

	renderListItem(item){
		let orderList = this.props.orderList;
		return (
			<ListItem gridCols={9} onEdit={() => orderList.onEdit(item.id)}>
				<Item text={`#${item.id}`} />
				<div className="sm:col-span-2"><ItemColumn text={item.account.name} subtext={item.account.account_reference} /></div>
				<div className="sm:col-span-2"><ItemColumn text={item.account.customer.name} subtext={item.account.customer.customer_reference} /></div>
				<div className="sm:col-span-2"><ItemColumn text={item.created_on.format('lll')} subtext={item.created_on.fromNow()} /></div>
				<Status text={item.fulfillment_status} />
				<Status text={item.payment_status} />
			</ListItem>
		)
	}

	render(){
		let orderList = this.props.orderList;
		return (
			<Main>
				<Card title="Orders" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Order Name"
					  		onChange={(e) => orderList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => orderList.onNewOrder()}>New Order</Button>
					}>
					<List 
						type="order"
						items={orderList.orders}
						renderListItem={this.renderListItem}
						renderListHeaders={this.renderListHeaders}
						page={orderList.page}
						total={orderList.total}
						hasNext={orderList.hasNext}
						hasPrev={orderList.hasPrev}
						offsetStart={orderList.offsetStart}
						offsetEnd={orderList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Orders, ['administrator', 'employee', 'accountant']);