import BaseApi from '../services/base';


class RoleApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(){
		return this.fetch('v1/roles', {
			method: 'GET',
			auth: true
		})
	}
}

export default RoleApi;