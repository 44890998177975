import { FormState, FieldState } from 'formstate';
import { observable } from 'mobx';
import AuthApi from '../../services/auth';
import { validator, isEmailValidator, isRequiredValidator } from '../../services/validation';


class Login{
	@observable email;
	@observable password;

	@observable isFetching;
	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.authApi = new AuthApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.email = new FieldState('').validators(isEmailValidator);
		this.password = new FieldState('').validators((val) => isRequiredValidator(val, 'password'));
		this.form = new FormState({
		    email: this.email,
		    password: this.password,
		});
	}

	onChangeEmail(val){
		this.email.onChange(val);
	}

	onChangePassword(val){
		this.password.onChange(val);
	}

	goToForgotPassword(){
		this.uiStore.goToForgottenPassword();
	}

	checkForRedirect(){
		this.uiStore.checkForRedirect();
	}

	onLogin = async () => {
	    const res = await this.form.validate();
	    if(res.hasError) return;

	    this.isFetching = true;
	    this.authApi.login(this.email.value, this.password.value)
	    	.then((response) => {
	    		localStorage.setItem(this.uiStore.ACCESS_TOKEN_KEY, response.access_token);
	    		localStorage.setItem(this.uiStore.REFRESH_TOKEN_KEY, response.refresh_token);
	    		this.uiStore.setCurrentToken(response.access_token);
	    		this.uiStore.setCurrentRefreshToken(response.refresh_token);

	    		this.uiStore.getCurrentUser()
	    			.then(() => {
	    				let currentUser = this.uiStore.currentUser;
		                this.uiStore.goToHome();
	    			})
	    			.catch((error) => {
	    				this.uiStore.alertError('Looks like there was an issue getting your user account');
	    				console.log(error);
	    			})
	    			.finally(() => {
	    				this.isFetching = false;
	    			})
	    	})
	    	.catch((error) => {
	    		console.log(error);
	    		this.isFetching = false;
	    	})	

	};

}

export default Login;