import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn,Item,Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, DownloadIcon, LinkIcon} from '../common/icon';

import AccountInformation from '../common/account-information';
import PODInformation from '../pods/pod-information';

import InvoiceInformation from './invoice-information';

import Authorisation from '../authorisation';

@inject('manageInvoice')
@observer
class ManageInvoice extends Component{
	constructor(props){
		super(props);
		this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);
		
	}

	componentDidMount(){
		let store = this.props.manageInvoice;
		if('invoiceId' in this.props){
			store.formSync(this.props.invoiceId);
		}
	}

	componentWillUnmount(){
		let store = this.props.manageInvoice;	
		store.initStore();
	}

	copyLinkToClipboard(){
		let store = this.props.manageInvoice;
		if(store.currentInvoice?.invoice_details != null){
			window.navigator.clipboard.writeText(store.currentInvoice.invoice_details.online_invoice_url);
			store.uiStore.alertSuccess('Copied to clipboard');
		}
	}

	render(){
		let store = this.props.manageInvoice;
		let currentInvoice = store.currentInvoice;
		let currentAccount = currentInvoice?.account;
		return (
			<Main>
				<>
					<div className="flex flex-row items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          View Invoice {currentInvoice?.invoice_reference} 
					        </Heading>
					        <Paragraph>
					          <Status text={currentInvoice?.invoice_details.status} />
					        </Paragraph>
					    </div>
					    <div className="flex flex-row items-center">
					    	{currentInvoice != null && 
								<>
									<div className='flex'>
										<div className="flex mr-4 cursor-pointer" onClick={() => store.downloadInvoicePDF()}>
											<DownloadIcon color="blue-600" />
									        <Paragraph>{store.downloadingPDF ? 'Downloading...' : 'Download PDF'}</Paragraph>
									    </div>  
								    </div> 
									<div className='flex'>
										<div className="flex mr-4 cursor-pointer" onClick={this.copyLinkToClipboard}>
											<LinkIcon color="blue-600" />
									        <Paragraph>Copy Invoice Link</Paragraph>
									    </div>  
								    </div> 
									<div className='flex ml-4'>
										<Button fetching={false} onClick={() => window.open(currentInvoice.invoice_details?.online_invoice_url, '_blank')}>
								          View Online Invoice
								        </Button>  
								    </div>  		
								    <div className='flex ml-4'>
									    <Button fetching={store.emailing} onClick={() => store.emailInvoice()}>
								          Email Invoice
								        </Button>
								    </div>
							    </>
						    }
					    	
						</div>
					</div>

					{currentAccount != null &&
						<AccountInformation
					   		currentAccount={currentAccount}
					   		onGoToAccount={(id) => store.appStore.goToEditAccount(id)}
							onGoToCustomer={(id) => store.appStore.goToEditCustomer(id)}
					   	/>
					}
					{currentInvoice != null && 
						<InvoiceInformation 
							invoice={currentInvoice.invoice_details} />
					}
					{currentInvoice?.proof_of_delivery != null && 
						<PODInformation
					   		proofOfDelivery={currentInvoice.proof_of_delivery}
					   	/>
					}
					
				</>
			</Main>
		)
	}
}


export default Authorisation(ManageInvoice, ['administrator', 'employee', 'accountant']);