import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('customerList')
@observer
class Customers extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let customerList = this.props.customerList;
		customerList.fetchCustomers();
	}

	componentWillUnmount(){
		let customerList = this.props.customerList;
		customerList.initStore();
	}

	onPreviousPage(){
		let customerList = this.props.customerList;
		customerList.onPreviousPage();
	}

	onNextPage(){
		let customerList = this.props.customerList;
		customerList.onNextPage();
	}

	renderListItem(item){
		let customerList = this.props.customerList;
		return (
			<ListItem onEdit={() => customerList.onEdit(item.id)}>
				<ItemColumn text={item.name} subtext={item.customer_reference} />
				<Status color={item.archived ? 'red' : 'green'} text={item.archived ? "Archived" : "Active"} />
			</ListItem>
		)
	}

	render(){
		let customerList = this.props.customerList;
		return (
			<Main>
				<Card title="Customers" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Customer Reference"
					  		onChange={(e) => customerList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => customerList.onNewCustomer()}>New Customer</Button>
					}>
					<List 
						type="customers"
						items={customerList.customers}
						renderListItem={this.renderListItem}
						page={customerList.page}
						total={customerList.total}
						hasNext={customerList.hasNext}
						hasPrev={customerList.hasPrev}
						offsetStart={customerList.offsetStart}
						offsetEnd={customerList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Customers, ['administrator', 'employee', 'accountant']);