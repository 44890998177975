import React, {Component} from 'react';


class Modal extends Component{
	render(){
		if(!('show' in this.props) || this.props.show == false) return null;
		return (
			<div className="fixed inset-0 overflow-y-auto z-50">
			  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			    <div className="fixed inset-0 transition-opacity">
			      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
			    </div>

			    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
			    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
			      <div>
			        <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-${this.props.color}`}>
			          {this.props.icon}
			        </div>
			        <div className="mt-3 text-center sm:mt-5">
			          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
			            {this.props.title}
			          </h3>
			          <div className="mt-2">
			            <p className="text-sm leading-5 text-gray-500">
			              {this.props.description}
			            </p>
			          </div>
			        </div>
			      </div>
			      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
			        {this.props.actionPanel}
			      </div>
			    </div>
			  </div>
			</div>
		)
	}
}

export default Modal;