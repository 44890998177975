import { FormState, FieldState } from 'formstate';
import { observable, computed, extendObservable } from 'mobx';

import StatApi from '../services/stats';

import moment from 'moment';

class Stats{
	@observable stats;

	statApi;
	uiStore;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.statApi = new StatApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.stats = null;
	}

	fetchTodayStats(){
		this.statApi.getTodayStats()
			.then((response) => {
				let stats = response.stats;
				stats.deliveries_due_by_today = stats.deliveries_due_by_today.map((deliveryDueByToday) => {
					let requestedDeliveryDate = moment(deliveryDueByToday.requested_delivery_date);
					deliveryDueByToday.requested_delivery_date_formatted = `${requestedDeliveryDate.format('DD/MM/YYYY')}`;
					deliveryDueByToday.fromNow = requestedDeliveryDate.fromNow();
					return deliveryDueByToday;
				})
				this.stats = stats;
			})
			.catch((error) => {
				console.log(error);
			})
	}
}

export default Stats;