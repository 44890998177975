import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPostcodeValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import AccountApi from '../../services/account';
import OrderApi from '../../services/order';
import ProductApi from '../../services/product';
import AssetApi from '../../services/asset';
import ServiceApi from '../../services/service';
import CommonApi from '../../services/common';
import PricingApi from '../../services/pricing';

import {SERVER_DATE_FORMAT} from '../../services/util';

import uuidv4 from 'uuid';
import moment from 'moment';

import { uuid } from 'uuidv4';

class ManageOrder{

	id;
	@observable addMode;
	@observable editMode;
	@observable viewMode;

	@observable orderForm;

	@observable fetching;

	@observable accountSearchText;
	@observable accounts;
	@observable selectedAccountId;
	@observable orderAccount;

	@observable productOrderItems;
	@observable assetOrderItems;
	@observable assetReturnOrderItems;
	@observable serviceOrderItems;

	@observable assets;
	@observable services;
	@observable products;

	@observable requestedDeliveryDate;
	@observable specialInstructions;
	@observable poNumber;
	@observable fulfillmentStatus;
	@observable paymentStatus;

	@observable currentOrder;

	commonApi;
	orderApi;
	accountApi;
	productApi;
	assetApi;
	serviceApi;

	constructor(appStore){
		this.appStore = appStore;
		this.orderApi = new OrderApi(appStore);
		this.accountApi = new AccountApi(appStore);
		this.productApi = new ProductApi(appStore);
		this.assetApi = new AssetApi(appStore);
		this.serviceApi = new ServiceApi(appStore);
		this.commonApi = new CommonApi(appStore);
		this.pricingApi = new PricingApi(appStore);
		this.initStore();
	}

	initStore(){
		this.id = null;
		this.addMode = true;
		this.editMode = false;
		this.viewMode = false;
		this.fetching = false;
		this.accounts = [];
		this.accountSearchText = new FieldState(null);
		this.orderAccount = null;
		this.selectedAccountId = null;
		this.productOrderItems = [];
		this.assetOrderItems = [];
		this.assetReturnOrderItems = [];
		this.serviceOrderItems = [];
		this.products = [];
		this.assets = [];
		this.services = [];
		this.requestedDeliveryDate = new FieldState(null);
		this.specialInstructions = new FieldState(null);
		this.fulfillmentStatus = new FieldState(null);
		this.paymentStatus = new FieldState(null);
		this.poNumber = new FieldState(null);
		this.orderForm = new FormState({
			poNumber: this.poNumber,
			requestedDeliveryDate: this.requestedDeliveryDate,
			specialInstructions: this.specialInstructions,
		});
		this.currentOrder = null;
	}


	onChangeAccountSearchText(val){
		this.accountSearchText.onChange(val);
		this.selectedAccountId = null;
		this.searchAccounts(this.accountSearchText.value);
	}

	searchAccounts = async (searchText) => {
		if(searchText == ''){
			this.accounts = [];
			return;
		}
		this.fetching = true;
		try{
			this.accounts = await this.accountApi.fetchAllAccounts(searchText);
		}catch(error){
			console.log(error);
		}finally{
			this.fetching = false
		}		
	}

	selectAccount = async (id) => {
		this.selectedAccountId = id;
		this.fetching = true;
		try{
			let response = await this.accountApi.getAccountById(id);
			this.orderAccount = response.account;
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}


	fetchProducts = async () => {
		try{
			this.products  = await this.productApi.getAllProducts();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchAssets = async () => {
		try{
			this.assets  = await this.assetApi.fetchAllAssets();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchServices = async () => {
		try{
			this.services  = await this.serviceApi.fetchAllServices();
		}catch(error){ 	
			console.log(error);
		}
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get productOptions(){
		return this.products.map((product) => {
			return {
				label: product.name,
				value: product.id
			}
		})
	}

	@computed get locationOptions(){
		if(this.orderAccount == null) return [];
		return this.orderAccount.locations.map((l) => {
			let locationName = l.name;
			if(l.location_code != null){
				locationName = `${locationName} (${l.location_code})`
			}
			return {
				value: l.id,
				label: locationName
			}
		})
	}

	@computed get assetOptions(){
		return this.assets.map((asset) => {
			return {
				value: asset.id,
				label: asset.name
			}
		})
	}

	@computed get serviceOptions(){
		return this.services.map((service) => {
			return {
				value: service.id,
				label: service.name
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				label: `${currency.name} (${currency.symbol})`,
				value: currency.id
			}
		})
	}


	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: vatRate.id,
				label: vatRate.name
			}
		})
	}

	calculateSubtotal(price, qty){
		if(this.orderAccount == null || price.value == null || qty.value == null) return null;
		let currency = this.orderAccount.currency;
		if(currency == null) return null;
		let vatRate = this.orderAccount.vat_rate;
		if(vatRate == null) return null;
		if(price.error != undefined) return null;

		if(qty == null) return null;
		if(qty.error != undefined) return null;
		let beforeVATPrice = parseFloat(price.value) * parseInt(qty.value);
		let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
		let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
		return `${currency.symbol}${totalPrice}`
	}


	generateProductOrderItemEntry(id=null, productId=null, qty=null, foc=null, price=null, depositable=false, deposit=null, refund=null, returns=null, selectedLocation=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedProduct: new FieldState(productId).validators((val) => isNotNullValidator(val, 'product')),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val)),
			price: new FieldState(price).validators((val) => isPositiveNumberValidator(val)),
			foc: new FieldState(foc).validators((val) => isPositiveNumberValidator(val)),
			depositable: depositable,
			deposit: deposit,
			refund: refund,
			returns: new FieldState(returns).validators((val) => depositable && isPositiveNumberValidator(val)),
			selectedLocation: new FieldState(selectedLocation),
			get subtotal(){
				return that.calculateSubtotal(this.price, this.qty);
			}
		})
	}

	generateAssetOrderItemEntry(id=null, assetId=null, serialNumber=null, qty=null, foc=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			serialNumber: new FieldState(serialNumber),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val)),
			price: new FieldState(price).validators((val) => isPositiveNumberValidator(val)),
			foc: new FieldState(foc).validators((val) => isPositiveNumberValidator(val)),
			get subtotal(){
				return that.calculateSubtotal(this.price, this.qty);
			}
		})
	}

	generateAssetReturnOrderItemEntry(id=null, assetId=null, serialNumber=null, qty=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			serialNumber: new FieldState(serialNumber).validators((val) => isNotNullValidator(val, 'serial number')),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val))
		})
	}

	generateServiceOrderItemEntry(id=null, serviceId=null, assetId=null, serialNumber=null, qty=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedService: new FieldState(serviceId).validators((val) => isNotNullValidator(val, 'service')),
			selectedAsset: new FieldState(assetId),
			serialNumber: new FieldState(serialNumber),
			qty: new FieldState(qty).validators((val) => isPositiveNumberValidator(val)),
			price: new FieldState(price).validators((val) => isPositiveNumberValidator(val)),
			get subtotal(){
				return that.calculateSubtotal(this.price, this.qty);
			}
		})
	}

	addProductItemEntry(){
		this.productOrderItems.push(this.generateProductOrderItemEntry());
	}

	addServiceItemEntry(){
		this.serviceOrderItems.push(this.generateServiceOrderItemEntry());
	}

	addAssetItemEntry(){
		this.assetOrderItems.push(this.generateAssetOrderItemEntry());
	}

	addAssetReturnItemEntry(){
		this.assetReturnOrderItems.push(this.generateAssetReturnOrderItemEntry());
	}


	findProductItemIdx(uuid){
		return this.productOrderItems.findIndex((p) => p.uuid == uuid);
	}

	findAssetItemIdx(uuid){
		return this.assetOrderItems.findIndex((a) => a.uuid == uuid);
	}

	findAssetReturnItemIdx(uuid){
		return this.assetReturnOrderItems.findIndex((a) => a.uuid == uuid);
	}

	findServiceItemIdx(uuid){
		return this.serviceOrderItems.findIndex((s) => s.uuid == uuid);
	}

	getAccountProductPrice(productItemIdx, productId){
		let currencyId = this.orderAccount.currency.id;
		this.fetching = true;
		this.pricingApi.getAccountProductPrice(this.selectedAccountId, productId, currencyId)
				.then((response) => {
					let specialPrice = response.special_price;
					this.productOrderItems[productItemIdx].price.onChange(specialPrice.price);
					this.productOrderItems[productItemIdx].deposit = specialPrice.deposit;
					this.productOrderItems[productItemIdx].refund = specialPrice.refund;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
	}


	getAccountServicePrice(serviceItemIdx, serviceId){
		let currencyId = this.orderAccount.currency.id;
		this.fetching = true;
		this.pricingApi.getAccountServicePrice(this.selectedAccountId, serviceId, currencyId)
				.then((response) => {
					let specialPrice = response.special_price;
					this.serviceOrderItems[serviceItemIdx].price.onChange(specialPrice.price);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
	}

	getAccountAssetPrice(assetItemIdx, assetId){
		let currencyId = this.orderAccount.currency?.id;
		this.fetching = true;
		this.pricingApi.getAccountAssetPrice(this.selectedAccountId, assetId, currencyId)
				.then((response) => {
					let specialPrice = response.special_price;
					this.assetOrderItems[assetItemIdx].price.onChange(specialPrice.price);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
	}


	changeProductItemProduct(uuid, val){
		let productId = val;
		let productItemIdx = this.findProductItemIdx(uuid);
		let product = this.products.find((p) => p.id == productId);
		if(productItemIdx != -1 && product != null){
			this.productOrderItems[productItemIdx].selectedProduct.onChange(productId);
			this.productOrderItems[productItemIdx].depositable = product.depositable;
			this.getAccountProductPrice(productItemIdx, productId);
		}
	}

	changeProductItemLocation(uuid, val){
		let productId = val;
		let productItemIdx = this.findProductItemIdx(uuid);
		if(productItemIdx != -1){
			this.productOrderItems[productItemIdx].selectedLocation.onChange(val);
		}
	}

	changeAssetItemAsset(uuid, val){
		let assetId = val;
		let assetItemIdx = this.findAssetItemIdx(uuid);
		if(assetItemIdx != -1){
			this.assetOrderItems[assetItemIdx].selectedAsset.onChange(assetId);
			this.getAccountAssetPrice(assetItemIdx, assetId);
		}
	}

	changeReturnAssetItemAsset(uuid, val){
		let assetId = val;
		let assetItemIdx = this.findAssetReturnItemIdx(uuid);
		if(assetItemIdx != -1){
			this.assetReturnOrderItems[assetItemIdx].selectedAsset.onChange(assetId);
		}
	}

	changeServiceItemService(uuid, val){
		let serviceId = val;
		let serviceItemIdx = this.findServiceItemIdx(uuid);
		if(serviceItemIdx != -1){
			this.serviceOrderItems[serviceItemIdx].selectedService.onChange(serviceId);
			this.getAccountServicePrice(serviceItemIdx, serviceId);
		}
	}

	changeServiceItemAsset(uuid, val){
		let serviceId = val;
		let serviceItemIdx = this.findServiceItemIdx(uuid);
		if(serviceItemIdx != -1){
			this.serviceOrderItems[serviceItemIdx].selectedAsset.onChange(serviceId);
		}
	}


	@action deleteProductPriceItem(uuid){
		this.productOrderItems = this.productOrderItems.filter((p) => p.uuid != uuid);
	}

	@action deleteAssetPriceItem(uuid){
		this.assetOrderItems = this.assetOrderItems.filter((a) => a.uuid != uuid);
	}

	@action deleteAssetReturnItem(uuid){
		this.assetReturnOrderItems = this.assetReturnOrderItems.filter((a) => a.uuid != uuid);	
	}

	@action deleteServicePriceItem(uuid){
		this.serviceOrderItems = this.serviceOrderItems.filter((s) => s.uuid != uuid);
	}

	@computed get isDisabled(){
		return !(!this.viewMode && !this.fetching);
	}

	@action subtotalWithVAT(price, qty){
		if(this.orderAccount == null) return null;
		let subtotal = (price * qty);
		subtotal = (subtotal) + (subtotal * (this.orderAccount.vat_rate.vat_rate/100));
		return subtotal;
	}

	setOrder(order){
		this.id = order.id;
		this.productOrderItems = order.product_items.map((productItem) => {
			let product = productItem.product;
			return this.generateProductOrderItemEntry(productItem.id, productItem.product.id, productItem.quantity, productItem.foc, productItem.price, product.depositable, productItem.deposit, productItem.refund, productItem.returns, productItem.account_location?.id);
		})
		
		this.assetOrderItems = order.asset_items.map((assetItem) => {
			return this.generateAssetOrderItemEntry(assetItem.id, assetItem.asset.id, assetItem.serial_number, assetItem.quantity, assetItem.foc, assetItem.price);
		});

		this.assetReturnOrderItems = order.asset_return_items.map((assetReturnItem) => {
			return this.generateAssetReturnOrderItemEntry(assetReturnItem.id, assetReturnItem.asset.id, assetReturnItem.serial_number, assetReturnItem.quantity);
		})
		
		this.serviceOrderItems = order.service_items.map((serviceItem) => {
			return this.generateServiceOrderItemEntry(serviceItem.id, serviceItem.service.id, serviceItem.asset?.id, serviceItem.serial_number, serviceItem.quantity, serviceItem.price);
		})

		this.requestedDeliveryDate.value = moment(order.requested_delivery_date);
		this.poNumber.value = order.po_number;
		this.specialInstructions.value = order.notes;
		this.fulfillmentStatus.value = order.fulfillment_status;
		this.paymentStatus.value = order.payment_status;
		this.viewMode = true;
		this.editMode = false;
		this.addMode = false;
	}

	formSync = async (id) => {
		try{
			let response = await this.orderApi.getById(id);
			let order = response.order;
			this.currentOrder = order;
			await this.selectAccount(order.account.id);
			this.setOrder(this.currentOrder);
		}catch(e){
			console.log(e)
		}
	}


	@computed get orderItemSummary(){
		if(this.orderAccount == null) return []

		let foundCurrency = this.currencies.find((c) => c.id == this.orderAccount.currency.id);
		if(foundCurrency == null) return null;

		let allProducts = this.productOrderItems.map((item) => {
			if(item.selectedProduct.value == null || item.price.value == null || item.qty.value == null || item.price.value == null || item.foc.value == null) return null;
			let productId = item.selectedProduct.value;
			let foundProduct = this.products.find((p) => p.id == productId)
			if(foundProduct == null) return null;
			let price = parseFloat(item.price.value).toFixed(4);
			let qty = parseInt(item.qty.value, 10);
			let foc = parseInt(item.foc.value, 10);
			if(foundProduct.depositable && item.returns.value == null) return null;
			let deposit = foundProduct.depositable ? parseFloat(item.deposit, 10) * qty : null;
			let refund = foundProduct.depositable ? parseFloat(item.refund, 10) * qty : null;
			let returns = foundProduct.depositable ? parseInt(item.returns.value, 10) : null;

			let subtotal = this.subtotalWithVAT(price, qty);
			let total = subtotal;
			let depositCharge = null;
			let refundCharge = null;
			if(foundProduct.depositable){
				depositCharge = (deposit*qty);
				refundCharge = (refund*returns);
				total += depositCharge - refundCharge;
			}

			return {
				name: foundProduct.name,
				quantity: qty,
				currency: foundCurrency,
				price: `${foundCurrency.symbol}${price}`,
				refund: refund,
				foc: foc,
				deposit: depositCharge != null ? `${foundCurrency.symbol}${depositCharge.toFixed(4)}` : null,
				refund: refundCharge != null ? `${foundCurrency.symbol}${refundCharge.toFixed(4)}` : null, 
				returns: returns,
				vat_rate: this.orderAccount.vat_rate.name,
				subtotal: `${foundCurrency.symbol}${subtotal.toFixed(4)}`,
				total_raw: total.toFixed(4),
				total: `${foundCurrency.symbol}${total.toFixed(4)}`
			}
		}).filter((p) => p != null);

		let allAssets = this.assetOrderItems.map((item) => {
			if(item.selectedAsset.value == null || item.price.value == null || item.qty.value == null || item.foc.value == null) return null;
			let assetId = item.selectedAsset.value;
			let foundAsset = this.assets.find((a) => a.id == assetId)
			if(foundAsset == null) return null;

			let price = parseFloat(item.price.value).toFixed(4);
			let qty = parseInt(item.qty.value, 10);
			let foc = parseInt(item.foc.value, 10);

			let subtotal = this.subtotalWithVAT(price, qty);

			let total = subtotal;
			
			return {
				name: foundAsset.name,
				quantity: qty,
				currency: foundCurrency,
				price: `${foundCurrency.symbol}${price}`,
				refund: null,
				foc: foc,
				deposit: null,
				refund: null, 
				returns: null,
				vat_rate: this.orderAccount.vat_rate.name,
				subtotal: `${foundCurrency.symbol}${subtotal.toFixed(4)}`,
				total_raw: total.toFixed(4),
				total: `${foundCurrency.symbol}${total.toFixed(4)}`
			}
		}).filter((a) => a != null);

		let allServices = this.serviceOrderItems.map((item) => {
			if(item.selectedService.value == null || item.price.value == null || item.qty.value == null) return null;
			let serviceId = item.selectedService.value;
			let foundService = this.services.find((s) => s.id == serviceId)
			if(foundService == null) return null;
			let price = parseFloat(item.price.value).toFixed(4);
			let qty = parseInt(item.qty.value, 10);

			let subtotal = this.subtotalWithVAT(price, qty);
			let total = subtotal;
			
			return {
				name: foundService.name,
				quantity: qty,
				currency: foundCurrency,
				price: `${foundCurrency.symbol}${price}`,
				refund: null,
				foc: null,
				deposit: null,
				refund: null, 
				returns: null,
				vat_rate: this.orderAccount.vat_rate.name,
				subtotal: `${foundCurrency.symbol}${subtotal.toFixed(4)}`,
				total_raw: total.toFixed(4),
				total: `${foundCurrency.symbol}${total.toFixed(4)}`
			}
		}).filter((s) => s != null);

		let allItems = [...allProducts, ...allAssets, ...allServices];
		let orderTotal = 0;
		for(let item of allItems){
			if(isNaN(item.total_raw)) continue;
			let itemTotal = parseFloat(item.total_raw);
			orderTotal += itemTotal;
		}
		
		allItems.push({
			name: 'Order Total',
			quantity: null,
			currency: null,
			price: null,
			refund: null,
			foc: null,
			deposit: null,
			refund: null, 
			returns: null,
			vat_rate: null,
			subtotal: null,
			total: `${foundCurrency.symbol}${orderTotal.toFixed(4)}`
		})
		return allItems;
	}

	voidOrder = async () => {
		if(this.orderAccount == null) return
		if(!this.editMode || this.id == null) return;

		let confirmVoided = window.confirm('Are you sure you want to void this order?')
		if(confirmVoided){

			let payload = {
				fulfillment_status: 'void'
			}
			try{
				this.fetching = true;
				let response = await this.orderApi.patchOrder(this.id, payload);
				
				let updatedOrder = response.order;
				this.fulfillmentStatus.onChange(updatedOrder.fulfillment_status);
				this.appStore.alertSuccess('This order has been voided');
			}catch(e){
				console.log(e);
			}finally{
				this.fetching = false;
			}
		}

		
	}


	save = async () => {
		if(this.orderAccount == null) return
		
		const productItemsForm = new FormState(this.productOrderItems.map((item) => {
			return new FormState({
				price: item.price,
				selectedProduct: item.selectedProduct,
				qty: item.qty,
				foc: item.foc,
				returns: item.returns
			});
		}));
		
		const assetItemsForm = new FormState(this.assetOrderItems.map((item) => {
			return new FormState({
				price: item.price,
				selectedAsset: item.selectedAsset,
				qty: item.qty,
				foc: item.foc
			});
		}));

		const assetReturnItemsForm = new FormState(this.assetReturnOrderItems.map((item) => {
			return new FormState({
				selectedAsset: item.selectedAsset,
				serialNumber: item.serialNumber,
				qty: item.qty,
			});
		}));

		const serviceItemsForm = new FormState(this.serviceOrderItems.map((item) => {
			return new FormState({
				price: item.price,
				selectedService: item.selectedService,
				qty: item.qty
			});
		}));
		
		let res = await this.orderForm.validate();
		let productItemsRes = await productItemsForm.validate();
		let assetItemsRes = await assetItemsForm.validate();
		let assetItemsReturnRes = await assetReturnItemsForm.validate();
		let serviceItemsRes = await serviceItemsForm.validate();

		if(res.hasError || productItemsRes.hasError || assetItemsRes.hasError || serviceItemsRes.hasError || assetItemsReturnRes.hasError) return;

		try{
			this.fetching = true;
			let payload = {
				account_id: this.orderAccount.id,
				requested_delivery_date: this.requestedDeliveryDate.value.format(SERVER_DATE_FORMAT),
				po_number: this.poNumber.value,
				notes: this.specialInstructions.value,
				product_items: this.productOrderItems.map((productItem) => {
					return {
						id: productItem.id,
						product_id: parseInt(productItem.selectedProduct.value, 10),
						price: parseFloat(productItem.price.value).toFixed(4),
						quantity: parseInt(productItem.qty.value, 10),
						foc: parseInt(productItem.foc.value, 10),
						returns: productItem.returns.value != null ? parseInt(productItem.returns.value, 10) : null,
						location_id: productItem.selectedLocation.value
					}
				}),
				asset_items: this.assetOrderItems.map((assetItem) => {
					return {
						id: assetItem.id,
						asset_id: parseInt(assetItem.selectedAsset.value, 10),
						price: parseFloat(assetItem.price.value).toFixed(4),
						quantity: parseInt(assetItem.qty.value, 10),
						foc: parseInt(assetItem.foc.value, 10),
						serial_number: assetItem.serialNumber.value
					}
				}),
				asset_return_items: this.assetReturnOrderItems.map((assetItem) => {
					return {
						id: assetItem.id,
						asset_id: parseInt(assetItem.selectedAsset.value, 10),
						quantity: parseInt(assetItem.qty.value, 10),
						serial_number: assetItem.serialNumber.value
					}
				}),
				service_items: this.serviceOrderItems.map((serviceItem) => {
					return {
						id: serviceItem.id,
						service_id: parseInt(serviceItem.selectedService.value, 10),
						asset_id: parseInt(serviceItem.selectedAsset.value, 10),
						serial_number: serviceItem.serialNumber.value,
						price: parseFloat(serviceItem.price.value).toFixed(4),
						quantity: parseInt(serviceItem.qty.value, 10)
					}
				})
			}
			if(this.addMode){
				let response = await this.orderApi.newOrder(payload);
			}else{
				let response = await this.orderApi.updateOrder(this.id, payload);
			}
			
			this.appStore.alertSuccess('Order saved');
			this.appStore.goToOrders();
		}catch(e){
			console.log(e);
		}finally{
			this.fetching = false;
		}
	}

	@action toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	@action toggleViewMode(){
		// reset order changes
		this.setOrder(this.currentOrder);
		this.editMode = false;
		this.viewMode = true;
		this.addMode = false;

	}

	@computed get canBeVoided(){
		if(!this.editMode) return false;
		return this.fulfillmentStatus.value != 'complete' && this.fulfillmentStatus.value != 'void';
	}

}

export default ManageOrder;