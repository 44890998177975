import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('agreementList')
@observer
class Agreements extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
		this.renderListHeaders = this.renderListHeaders.bind(this);
	}

	componentDidMount(){
		let agreementList = this.props.agreementList;
		agreementList.fetchAgreements();
	}

	componentWillUnmount(){
		let agreementList = this.props.agreementList;
		agreementList.initStore();
	}

	onPreviousPage(){
		let agreementList = this.props.agreementList;
		agreementList.onPreviousPage();
	}

	onNextPage(){
		let agreementList = this.props.agreementList;
		agreementList.onNextPage();
	}

	renderListHeaders(){
		return (
			<ListItem gridCols={5}>
				<div className="sm:col-span-1">Agreement</div>
				<div className="sm:col-span-1">Status</div>
				<div className="sm:col-span-1">Account</div>
				<div className="sm:col-span-1">Customer</div>
				<div className="sm:col-span-1">Created On</div>
			</ListItem>
		)
	}

	renderListItem(item){
		let agreementList = this.props.agreementList;
		return (
			<ListItem gridCols={5} onEdit={() => agreementList.onEdit(item.id)}>
				<div className="sm:col-span-1"><ItemColumn text={`#${item.id}`} subtext={item.agreement_reference} /></div>
				<div className="sm:col-span-1"><Status text={item.closed ? 'Closed' : 'Open'} color={item.closed ? 'red': 'green'} /></div>
				<div className="sm:col-span-1"><ItemColumn text={item.account?.name} subtext={item.account?.account_reference} /></div>
				<div className="sm:col-span-1"><ItemColumn text={item.account?.customer?.name} subtext={item.account?.customer?.customer_reference} /></div>
				<div className="sm:col-span-1"><ItemColumn text={item.created_on.format('lll')} /></div>
			</ListItem>
		)
	}

	render(){
		let agreementList = this.props.agreementList;
		return (
			<Main>
				<Card title="Agreements" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Agreement Reference"
					  		onChange={(e) => agreementList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => agreementList.onNewAgreement()}>New Agreement</Button>
					}>
					<List 
						type="agreements"
						items={agreementList.agreements}
						renderListItem={this.renderListItem}
						renderListHeaders={this.renderListHeaders}
						page={agreementList.page}
						total={agreementList.total}
						hasNext={agreementList.hasNext}
						hasPrev={agreementList.hasPrev}
						offsetStart={agreementList.offsetStart}
						offsetEnd={agreementList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Agreements, ['administrator', 'employee', 'accountant']);