import React, { Component } from 'react';
import {inject, observer} from 'mobx-react';

import { SpacerTop } from '../elements/ui';
import { Heading, Link } from '../elements/text';
import { Label, TextInput, CheckBox, Button } from '../elements/input';

import AuthContainer from './auth-container';

@inject('login')
@observer
class Login extends Component{
	constructor(props){
		super(props);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.goToForgotPassword = this.goToForgotPassword.bind(this);
		this.onLogin = this.onLogin.bind(this);
	}

	componentDidMount(){
		let loginStore = this.props.login;
		loginStore.checkForRedirect();
	}

	componentWillUnmount(){
		let loginStore = this.props.login;
		loginStore.initStore();
	}

	goToForgotPassword(){
		let loginStore = this.props.login;
		loginStore.goToForgotPassword();
	}

	onChangeEmail(val){
		let loginStore = this.props.login;
		loginStore.onChangeEmail(val);
	}

	onChangePassword(val){
		let loginStore = this.props.login;
		loginStore.onChangePassword(val);
	}

	onLogin(){
		let loginStore = this.props.login;
		loginStore.onLogin();
	}

	render(){
		let loginStore = this.props.login;

		let forgotPasswordView = (
			 <div className="mt-48 flex justify-end">
              <div className="text-sm leading-5">
                <Link onClick={this.goToForgotPassword}>
                  Forgot your password?
                </Link>
              </div>
            </div>
		)

		return (
			<AuthContainer
				title="Sign in to your account">
				<div>
		            <div>
		              <Label htmlFor="email" text="Email Address" />
		              <TextInput id="email" type="email" 
		              	value={loginStore.email.value}
		              	error={loginStore.email.error}
		              	onChange={this.onChangeEmail} />
		            </div>

		            <SpacerTop>
		              <Label htmlFor="password" text="Password" />
		              <TextInput id="password" type="password" 
		              	value={loginStore.password.value}
		              	error={loginStore.password.error}
		              	onChange={this.onChangePassword} />
		            </SpacerTop>

		            <SpacerTop>
		                <Button onClick={this.onLogin} fetching={loginStore.isFetching}>Sign In</Button>
		            </SpacerTop>
	        	</div>
		    </AuthContainer>
		);
	}
}

export default Login;