import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Item, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button, TextArea, Select} from '../elements/input';
import {Paragraph} from '../elements/text';

import {DeleteIcon} from '../common/icon';
import Modal from '../common/modal';
import moment from 'moment';

import Authorisation from '../authorisation';

@inject('servicingList')
@observer
class Servicing extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let servicingList = this.props.servicingList;
		servicingList.runServicingList();
	}

	componentWillUnmount(){
		let servicingList = this.props.servicingList;
		servicingList.initStore();
	}

	onPreviousPage(){
		let servicingList = this.props.servicingList;
		servicingList.onPreviousPage();
	}

	onNextPage(){
		let servicingList = this.props.servicingList;
		servicingList.onNextPage();
	}

	renderListHeaders(){
		return (
			<ListItem gridCols={7}>
				<div className="sm:col-span-1">Account</div>
				<div className="sm:col-span-1">Asset</div>
				<div className="sm:col-span-2">Service</div>
				<div className="sm:col-span-1">Last Serviced</div>
				<div className="sm:col-span-1">Next Due</div>
				<div className="sm:col-span-1">Actions</div>
			</ListItem>
		)
	}

	renderListItem(item){
		let servicingList = this.props.servicingList;

		let serviceDaysAgo = item.expected_service_date.clone().fromNow();
		let displayDateFormat = 'ddd MMMM DD, YYYY';
		return (
			<ListItem gridCols={7}>
				<ItemColumn text={item.account?.account_reference} subtext={item.account?.name} />
				<ItemColumn text={item.asset?.name} subtext={`Serial: ${item.serial_number}`} />
				<div className="col-span-2">
					<ItemColumn text={item.service?.name} subtext={
						<Status text={`${item.fulfillment_status} in order #${item.order?.id}`} color={item.is_complete ? 'green' : 'red'} />
					}/>
				</div>
				<ItemColumn text={item.last_service_date != null ? item.last_service_date.format(displayDateFormat) : ''} subtext={
					<Status text={item.last_service_date != null ? `${item.last_service_date?.fromNow()}` : ''} />
				} />
				<ItemColumn text={item.expected_service_date.format(displayDateFormat)} subtext={
					<>
						{!item.is_complete && 
							<Status text={item.expected_service_date.isSameOrAfter(moment(), 'day') ? `Due ${serviceDaysAgo}` : `Late ${serviceDaysAgo}`} color={item.expected_service_date.isSameOrAfter(moment(), 'day') ? 'green' : 'red'} />
						}
						{item.is_complete && 
							<Status text={item.dismissed_on != null ? 'Dismissed' : 'Completed'} color={item.dismissed_on != null ? 'red' : 'green'} />
						}
					</>
				} />
				<div className="flex flex-col">
					<Button onClick={() => servicingList.goToOrder(item.order?.id)} fgColor="white" bgColor="blue">View Order</Button>
					{!item.is_complete && <Button onClick={() => servicingList.showCancelModal(item.id)} fgColor="white" bgColor="red">Cancel</Button>}
				</div>
				{item.is_complete && item.dismissed_on != null && 
					<div className="col-span-6">
						<Status text={`Dismissed by ${item.dismissed_by?.name} at ${item.dismissed_on?.format('LLL')} with reason ${item.dismissed_note}`} color='red' />
					</div>
				}
				{item.is_complete && item.completed_on != null && 
					<div className="col-span-6">
						<Status text={`Completed by ${item.completed_by?.name} at ${item.completed_on?.format('LLL')}`} color='green' />
					</div>
				}
			</ListItem>
		)
	}

	render(){
		let servicingList = this.props.servicingList;
		return (
			<Main>
				<Modal 
					show={servicingList.showCancellationModal}
					icon={
						<DeleteIcon color="text-white" />
					}
					color='red-500'
					title={servicingList.cancelServiceTitle}
					actionPanel={
						<>
							<div className="col-span-2">
								<TextArea 
					              	placeholder="Enter cancellation reason"
					              	onChange={(val) => servicingList.cancellationReason.onChange(val)}
					              	disabled={false}
					            	value={servicingList.cancellationReason.value} 
					            	error={servicingList.cancellationReason.error}/>
							</div>
							<Button fgColor="white" bgColor="gray" onClick={() => servicingList.closeModal()}>Go back</Button>
							<Button disabled={!servicingList.canCancel} fgColor="white" bgColor="red" onClick={() => servicingList.cancelServiceItem()}>Cancel Service</Button>
						</>
					}
					description="Please enter the reason why you are cancelling this service."
				/>
				<Card title="Sanitisations" actionBtn={
					<Button 
						fgColor="white" 
						bgColor={!servicingList.fulfilled ? 'green' : 'red'}
						onClick={() => servicingList.toggleFulfilled()}>{!servicingList.fulfilled ? 'Show Fulfilled' : 'Show Unfulfilled'}</Button>
				}>
					<List 
						type="servicing items"
						items={servicingList.servicingItems}
						renderListHeaders={this.renderListHeaders}
						renderListItem={this.renderListItem}
						page={servicingList.page}
						total={servicingList.total}
						hasNext={servicingList.hasNext}
						hasPrev={servicingList.hasPrev}
						offsetStart={servicingList.offsetStart}
						offsetEnd={servicingList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Servicing, ['administrator', 'employee', 'accountant']);