import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, Item, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('invoiceList')
@observer
class Invoices extends Component{
	constructor(props){
		super(props);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let invoiceList = this.props.invoiceList;
		invoiceList.fetchInvoices();
	}

	componentWillUnmount(){
		let invoiceList = this.props.invoiceList;
		invoiceList.initStore();
	}

	onPreviousPage(){
		let invoiceList = this.props.invoiceList;
		invoiceList.onPreviousPage();
	}

	onNextPage(){
		let invoiceList = this.props.invoiceList;
		invoiceList.onNextPage();
	}

	renderListHeaders(){
		return (
			<ListItem gridCols={4}>
				<div className="sm:col-span-1">Invoice Reference</div>
				<div className="sm:col-span-1">Account</div>
				<div className="sm:col-span-1">POD</div>
				<div className="sm:col-span-1">Created On</div>
			</ListItem>
		)
	}

	renderListItem(item){
		let invoiceList = this.props.invoiceList;
		return (
			<ListItem gridCols={4} onEdit={() => invoiceList.onEdit(item.id)}>
				<Item text={item.invoice_reference} />
				<ItemColumn text={item.account?.name} subtext={item.account?.account_reference} />
				<ItemColumn text={item.proof_of_delivery?.pod_reference} subtext={item.proof_of_delivery?.driver?.name} />
				<Item text={item.logged_on.format('DD/MM/YYYY')} />
			</ListItem>
		)
	}

	render(){
		let invoiceList = this.props.invoiceList;
		return (
			<Main>
				<Card title="Invoices" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Invoice Reference"
					  		onChange={(e) => invoiceList.changeSearchText(e.target.value)}
					  		 />
					  }
					>

					<List 
						type="invoices"
						items={invoiceList.invoices}
						renderListItem={this.renderListItem}
						renderListHeaders={this.renderListHeaders}
						page={invoiceList.page}
						total={invoiceList.total}
						hasNext={invoiceList.hasNext}
						hasPrev={invoiceList.hasPrev}
						offsetStart={invoiceList.offsetStart}
						offsetEnd={invoiceList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Invoices, ['administrator', 'employee', 'accountant']);