import React, {Component} from 'react';

import logoDarkSrc from '../../assets/images/logo_dark.svg';
import logoWhiteSrc from '../../assets/images/logo_white.svg';

class Logo extends Component{
	render(){
		let renderLogoSrc = null;
		switch(this.props.color){
			case 'white':
				renderLogoSrc = logoWhiteSrc;
				break;
			default:
				renderLogoSrc = logoDarkSrc;
		}
		return (
			<div className="flex logo">
				<img src={renderLogoSrc} alt="Drinkflow" {...this.props} />
			</div>
		)
	}
}

export default Logo;