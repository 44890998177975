import React, {Component} from 'react';

import {inject, observer} from 'mobx-react';

import {ExitIcon} from './icon';

@inject('ui')
@observer
class Alert extends Component{
	constructor(props){
		super(props);
		this.dismissAlert = this.dismissAlert.bind(this);
	}

	dismissAlert(){
		let alertData = this.props.alert;
		this.props.ui.removeAlert(alertData.id);
	}

	render(){
		let alertColorClassName = null;
		let alertData = this.props.alert;
		if(alertData.alertType == 'error'){
			alertColorClassName = 'red-500'
		}else if(alertData.alertType == 'info'){
			alertColorClassName = 'blue-500'
		}else if(alertData.alertType == 'success'){
			alertColorClassName = 'green-500'
		}else{
			alertColorClassName = 'orange-500'
		}
		return (
			<div className={`bg-${alertColorClassName}`}>
			  <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
			    <div className="flex items-center justify-between flex-wrap">
			      <div className="w-0 flex-1 flex items-center">
			        <p className="ml-3 font-medium text-white truncate">
			          <span className="hidden md:inline">
			            {alertData.message}
			          </span>
			        </p>
			      </div>
			      <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
			        <div onClick={this.dismissAlert} className={`-mr-1 flex p-2 rounded-md cursor-pointer hover:bg-${alertColorClassName}-500 focus:outline-none focus:bg-${alertColorClassName}-500 sm:-mr-2 transition ease-in-out duration-150`}>
			         	<ExitIcon color='white' />
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		)
	}
}

export default Alert;