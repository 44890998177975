import BaseApi from '../services/base';

class AgreementApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, accountId=null, searchText=null){
		let url = `v1/agreements?page=${page}`;
		if(accountId != null){
			url = `${url}&account_id=${accountId}`
		}
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllAgreements = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page);
			let data = response.agreements;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getById(id){
		return this.fetch(`v1/agreements/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateAgreement(id, agreement){
		return this.fetch(`v1/agreements/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(agreement)
		})
	}

	newAgreement(agreement){
		return this.fetch(`v1/agreements`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(agreement)
		})
	}

	patchAgreement(id, payload){
		return this.fetch(`v1/agreements/${id}`, {
			method: 'PATCH',
			auth: true,
			body: JSON.stringify(payload)
		})
	}

	getAgreementTypes(){
		return this.fetch(`v1/agreements/types`, {
			method: 'GET',
			auth: true
		})
	}
}

export default AgreementApi;