import React from 'react';

const Heading = ({children}) => (
	<h2 className="mt-6 font-bold text-3xl leading-9 font-extrabold text-gray-900">
		{children}
	</h2>
)

const SubHeading = ({children, className=null}) => (
	<h3 className={`font-bold text-lg leading-6 font-medium text-gray-900 ${className}`}>{children}</h3>
)

const Paragraph = ({children}) => (
	<p className="mt-1 text-sm leading-5 text-gray-500">{children}</p>
)

const Link = ({onClick, children}) => (
	<div onClick={onClick} className="font-medium cursor-pointer text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
      {children}
    </div>
)

const Label = ({children, htmlFor=null}) => (
	<label htmlFor={htmlFor} className="block text-sm font-medium leading-5 text-gray-700">{children}</label>
)

export {
	Heading,
	SubHeading,
	Paragraph,
	Link,
	Label
}