import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, SearchForm} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('accountList')
@observer
class Accounts extends Component{
	constructor(props){
		super(props);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
	}

	componentDidMount(){
		let accountList = this.props.accountList;
		accountList.fetchAccounts();
	}

	componentWillUnmount(){
		let accountList = this.props.accountList;
		accountList.initStore();
	}

	onPreviousPage(){
		let accountList = this.props.accountList;
		accountList.onPreviousPage();
	}

	onNextPage(){
		let accountList = this.props.accountList;
		accountList.onNextPage();
	}

	renderListItem(item){
		let accountList = this.props.accountList;
		return (
			<ListItem onEdit={() => accountList.onEdit(item.id)}>
				<ItemColumn text={item.name} subtext={item.account_reference} />
				<Status text={item.account_status.name} />
			</ListItem>
		)
	}
	render(){
		let accountList = this.props.accountList;
		return (
			<Main>
				<Card title="Accounts" 
					  search={
					  	<SearchForm 
					  		placeholder="Search by Account Reference"
					  		onChange={(e) => accountList.changeSearchText(e.target.value)}
					  		 />
					  }
				actionBtn={
					<Button onClick={() => accountList.onNewAccount()}>New Account</Button>
				}>
					<List 
						type="accounts"
						items={accountList.accounts}
						renderListItem={this.renderListItem}
						page={accountList.page}
						total={accountList.total}
						hasNext={accountList.hasNext}
						hasPrev={accountList.hasPrev}
						offsetStart={accountList.offsetStart}
						offsetEnd={accountList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Accounts, ['administrator', 'employee', 'accountant']);