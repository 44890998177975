import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import UserApi from '../../services/user';
import InviteApi from '../../services/invite';
import RoleApi from '../../services/role';

import moment from 'moment';

import { uuid } from 'uuidv4';

class ManageUser{
	userApi;
	roleApi;
	inviteApi;

	@observable addMode;
	@observable viewMode;
	@observable editMode;

	id;
	@observable name;
	@observable email;
	@observable inviteAccepted;

	@observable fetching;
	@observable roles;

	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.userApi = new UserApi(this.uiStore);
		this.roleApi = new RoleApi(this.uiStore);
		this.inviteApi = new InviteApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.id = null;
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.email = new FieldState('').validators((val) => isRequiredValidator(val, 'email'));
		this.selectedRole = new FieldState(null).validators((val) => isNotNullValidator(val, 'role'));
		this.form = new FormState({
			name: this.name,
			email: this.email
		});
		this.roles = [];
		this.inviteAccepted = false;
	}

	fetchRoles(){
		this.roleApi.getAll()
			.then((response) => {
				this.roles = response.roles;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	formSync(userId){
		this.addMode = false;
		this.viewMode = true;

		this.id = userId;
		this.fetching = true;
		this.userApi.getById(userId)
			.then((response) => {
				let user = response.user;
				this.id = user.id;
				this.name.onChange(user.name);
				this.email.onChange(user.email);
				if(user.roles.length > 0){
					this.selectedRole.onChange(user.roles[0].id);
				}
				if(user.invites.length > 0){
					this.inviteAccepted = user.invites[0].invite_accepted;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			});
	}
	
	@action onCancel(){
		this.uiStore.goToUsers();
	}

	@computed get validForm(){
		return true;
	}

	@computed get roleOptions(){
		return this.roles.map((r) => {
			return {
				value: r.role_id,
				label: r.role_name
			}
		})
	}

	@action onNewUser(){
		this.uiStore.goToNewUser();
	}

	@action save = async () => {
		const res = await this.form.validate();
	    if(res.hasError) return;

	    this.fetching = true;
	    if(this.editMode){
	    	this.userApi.updateUser(this.id, {
				name: this.name.value,
				email: this.email.value,
				role_ids: [this.selectedRole.value]
			})
	    		.then((response) => {
	    			this.uiStore.alertSuccess('User updated.');
	    			this.uiStore.goToUsers();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }else{
	    	this.inviteApi.newInvite({
				name: this.name.value,
				email: this.email.value,
				role_id: this.selectedRole.value
			})
	    		.then((response) => {
	    			this.uiStore.alertSuccess('User invited.');
	    			this.uiStore.goToUsers();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }
	}

	@action resendInvite(){
		this.fetching = true;
		this.inviteApi.resendInvite(this.id)
			.then((response) => {
				this.uiStore.alertSuccess('Invite re-sent.');
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	@action deleteUser(){
		let confirmed = window.confirm('Are you sure you want to delete this user?')
		if(confirmed){
			this.fetching = true;
			this.userApi.deleteUserById(this.id)
				.then((response) => {
					this.uiStore.alertSuccess('User deleted.');
					this.uiStore.goToUsers();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}
}

export default ManageUser;