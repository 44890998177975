import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn,Item,Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, DownloadIcon} from '../common/icon';

import AccountInformation from '../common/account-information';
import PODInformation from '../pods/pod-information';

import CreditNoteInformation from './credit-note-information';

import Authorisation from '../authorisation';

@inject('manageCreditNote')
@observer
class ManageCreditNote extends Component{
	constructor(props){
		super(props);
		
	}

	componentDidMount(){
		let store = this.props.manageCreditNote;
		if('creditNoteId' in this.props){
			store.formSync(this.props.creditNoteId);
		}
	}

	componentWillUnmount(){
		let store = this.props.manageCreditNote;	
		store.initStore();
	}

	render(){
		let store = this.props.manageCreditNote;
		let currentCreditNote = store.currentCreditNote;
		let currentAccount = currentCreditNote?.account;
		return (
			<Main>
				<>
					<div className="flex flex-row items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          View Credit Note {currentCreditNote?.credit_note_reference} 
					        </Heading>
					        <Paragraph>
					          <Status text={currentCreditNote?.credit_note_details.status} />
					        </Paragraph>
					    </div>
					    <div className="flex flex-row items-center">
					    	{currentCreditNote != null && 
								<>
									<div className='flex'>
										<div className="flex mr-4 cursor-pointer" onClick={() => store.downloadCreditNotePDF()}>
											<DownloadIcon color="blue-600" />
									        <Paragraph>{store.downloadingPDF ? 'Downloading...' : 'Download PDF'}</Paragraph>
									    </div>  
								    </div> 
							    </>
						    }
					    	
						</div>
					</div>

					{currentAccount != null &&
						<AccountInformation
					   		currentAccount={currentAccount}
					   		onGoToAccount={(id) => store.appStore.goToEditAccount(id)}
							onGoToCustomer={(id) => store.appStore.goToEditCustomer(id)}
					   	/>
					}
					{currentCreditNote != null && 
						<CreditNoteInformation 
							creditNote={currentCreditNote.credit_note_details} />
					}
					{currentCreditNote?.proof_of_delivery != null && 
						<PODInformation
					   		proofOfDelivery={currentCreditNote.proof_of_delivery}
					   	/>
					}
					
				</>
			</Main>
		)
	}
}


export default Authorisation(ManageCreditNote, ['administrator', 'employee', 'accountant']);