import BaseApi from '../services/base';

const MAX_TIMEOUT_MS = 3000000;
const TASK_STATUS_CHECK_INTERVAL = 1000;

class TaskApi extends BaseApi{
	constructor(props){
		super(props);
		this.currentMaxRequestTimers = [];
		this.currentTaskIntervals = [];
	}

	getTaskStatus(url){
		let token = localStorage.getItem('@user:token');
		return fetch(url, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Authorization': `Bearer ${token}`,
				'Accept': 'application/json',
        		'Content-Type': 'application/json'
			}
		})
	}

	handleAsyncTask(response, uiStore, options){
		let location = response.location;
		uiStore.clearExistingAsyncTasksTimeOutsAndIntervals();
		this.interval = null;
		
		this.maxRequestTimer = setTimeout(() => {
			clearInterval(this.interval);
			uiStore.alertError('Looks like this request has timed out');
		}, MAX_TIMEOUT_MS);
		
		uiStore.addMaxRequestTimeout(this.maxRequestTimer);

		return new Promise((resolve, reject) => {
			this.interval = setInterval(() => {
			return this.getTaskStatus(location)
				.then((response) => {
					let contentType = response.headers.get('Content-Type');
					if(contentType == 'application/json'){
						return response.json().then((json) => {
							if(json.state == 'SUCCESS'){
								if('filename' in json){
							       clearTimeout(this.maxRequestTimer);
								   clearInterval(this.interval);

							       var link = window.document.createElement('a');
								   // convert bite array into blob
								   link.href = json.filename;
								   // set a human file name
								   link.download = options.file_name;
								   // triggers automatic download
								   link.click();
								   uiStore.alertSuccess('File downloaded');
								  
								   return resolve();
								}else{
									// Maybe some json logic here?
									clearTimeout(this.maxRequestTimer);
									clearInterval(this.interval);
									return resolve(json);
								}
							}else if(json.state == 'FAILURE'){
								uiStore.alertError('We were unable to run this report for some reason');
								clearTimeout(this.maxRequestTimer);
								clearInterval(this.interval);
								return resolve();
							}
						}) 
					}
				})
				.catch((error) => {
					clearTimeout(this.maxRequestTimer);
					clearInterval(this.interval);
					uiStore.alertError('Looks like an error occurred');
					return resolve();
				});
			}, TASK_STATUS_CHECK_INTERVAL);
			
			uiStore.addAsyncTaskInterval(this.interval);
		});
	}

}

export default TaskApi;