import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';

import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';


@observer
class AccountInformation extends Component{
	render(){
		let currentAccount = this.props.currentAccount;
		return (
			<div className="sm:col-span-6">
        		<FormDivider>
		   			<div>
				      	<SubHeading>
				          Account Information
				        </SubHeading>
				        <Paragraph>
				          Information about the chosen account
				        </Paragraph>
				    </div>
				    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
				   		<div className="sm:col-span-3">
				   			<Label>Account Name</Label>
				   			<div className="flex flex-row items-center cursor-pointer" onClick={() => this.props.onGoToAccount(currentAccount.id)}>
					   			<Paragraph>
						        	<span className="text-blue-600 underline">{currentAccount.name}</span>
						        </Paragraph>
						        <Status text={currentAccount.account_status.name} />
						        <ArchivedStatus archived={currentAccount.archived} />
						    </div>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Account Reference</Label>
				   			<Paragraph>
					        	{currentAccount.account_reference}
					        </Paragraph>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Customer Name</Label>
				   			<div className="flex flex-row items-center cursor-pointer" onClick={() => this.props.onGoToCustomer(currentAccount.customer.id)}>
					   			<Paragraph>
						        	<span className="text-blue-600 underline">{currentAccount.customer.name}</span>
						        </Paragraph>
						        <ArchivedStatus archived={currentAccount.customer.archived} />
						    </div>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Customer Reference</Label>
				   			<div className="flex flex-row items-center">
					   			<Paragraph>
						        	{currentAccount.customer.customer_reference} 
						        </Paragraph>
						    </div>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Account Delivery Address</Label>
				   			<Paragraph>
					        	{currentAccount.delivery_address.street_address}, <br />
					        	{currentAccount.delivery_address.city}, <br />
					        	{currentAccount.delivery_address.county}, <br />
					        	{currentAccount.delivery_address.country.name}, <br />
					        	{currentAccount.delivery_address.postcode}
					        </Paragraph>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Account Invoice Address</Label>
				   			<Paragraph>
					        	{currentAccount.invoice_address.street_address}, <br />
					        	{currentAccount.invoice_address.city}, <br />
					        	{currentAccount.invoice_address.county}, <br />
					        	{currentAccount.invoice_address.country.name}, <br />
					        	{currentAccount.invoice_address.postcode}
					        </Paragraph>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Account Currency</Label>
				   			<div className="flex flex-row items-center">
					   			<Paragraph>
						        	{currentAccount.currency.name} ({currentAccount.currency.symbol})
						        </Paragraph>
						    </div>
				   		</div>
				   		<div className="sm:col-span-3">
				   			<Label>Account VAT Rate</Label>
				   			<div className="flex flex-row items-center">
					   			<Paragraph>
						        	{currentAccount.vat_rate?.name} 
						        </Paragraph>
						    </div>
				   		</div>
				   	</div>
	   			</FormDivider>
        	</div>
		)

	}
}

export default AccountInformation;