import BaseApi from '../services/base';

class PricingApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAccountProductPrice(accountId, productId, currencyId=null){
		let url = `v1/pricing/${accountId}/products/${productId}`;
		if(currencyId != null){
			url = `${url}?currency_id=${currencyId}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	getAccountAssetPrice(accountId, assetId, currencyId=null, rental=false){
		let url = `v1/pricing/${accountId}/assets/${assetId}?rental=${rental}`;
		if(currencyId != null){
			url = `${url}&currency_id=${currencyId}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	getAccountServicePrice(accountId, serviceId, currencyId=null, recurring=false){
		let url = `v1/pricing/${accountId}/services/${serviceId}?recurring=${recurring}`;
		if(currencyId != null){
			url = `${url}&currency_id=${currencyId}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}
}

export default PricingApi;