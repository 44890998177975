import BaseApi from '../services/base';

class CommonApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAllCurrencies(){
		return this.fetch(`v1/currencies`, {
			method: 'GET',
			auth: true
		})
	}	

	getAllPaymentMethods(){
		return this.fetch(`v1/payments/methods`, {
			method: 'GET',
			auth: true
		})
	}

	getAllCountries(){
		return this.fetch('v1/countries', {
			method: 'GET',
			auth: true
		})
	}


	getAllVATRates(){
		return this.fetch(`v1/vat/rates`, {
			method: 'GET',
			auth: true
		})
	}


}

export default CommonApi;