import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, DateTimePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, DownloadIcon} from '../common/icon';
import AccountSearchInput from '../common/account-search';

import Authorisation from '../authorisation';

@inject('managePOD')
@observer
class ManagePOD extends Component{
	constructor(props){
		super(props);
		this.renderProductItemHeaders = this.renderProductItemHeaders.bind(this);
		this.renderProductItem = this.renderProductItem.bind(this);
		this.renderAssetPurchaseHeaders = this.renderAssetPurchaseHeaders.bind(this);
		this.renderAssetPurchaseItem = this.renderAssetPurchaseItem.bind(this);
		this.renderAssetReturnHeaders = this.renderAssetReturnHeaders.bind(this);
		this.renderAssetReturnItem = this.renderAssetReturnItem.bind(this);
		this.renderServiceItemHeaders = this.renderServiceItemHeaders.bind(this);
		this.renderServiceItem = this.renderServiceItem.bind(this);
	}

	componentDidMount(){
		let managePOD = this.props.managePOD;
		if('podId' in this.props){
			managePOD.formSync(this.props.podId);
		}

		managePOD.fetchDrivers();
		managePOD.fetchProducts();
		managePOD.fetchAssets();
		managePOD.fetchServices();
		managePOD.fetchCurrencies();
		managePOD.fetchVATRates();
	}

	componentWillUnmount(){
		let managePOD = this.props.managePOD;
		managePOD.initStore();
	}

	renderProductItemHeaders(){
		return (
			<ListItem gridCols={9}>
				<div className="sm:col-span-2">Product</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
				<div className="sm:col-span-1">Location</div>
				<div className="sm:col-span-1">Returns</div>
				<div className="sm:col-span-1">Subtotal</div>
			</ListItem>
		)
	}

	renderProductItem(item){
		let managePOD = this.props.managePOD;
		return (
			<ListItem gridCols={9}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose product"
           			onChange={(val) => managePOD.changeProductItemProduct(item.uuid, val)}
           			disabled={managePOD.isDisabledAsOrderChange}
	           		options={managePOD.productOptions} 
	           		value={item.selectedProduct.value}
	           		error={item.selectedProduct.error}
	           		 />
		        </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		       </div>
		        <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter FOC"
		              	onChange={(val) => item.foc.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.foc.value} 
		            	error={item.foc.error}/>
		       </div>
		        <div className="sm:col-span-1">
	              <Select 
           			placeholder="Choose location"
           			onChange={(val) => managePOD.changeProductItemLocation(item.uuid, val)}
           			disabled={managePOD.isDisabledAsOrderChange}
	           		options={managePOD.locationOptions} 
	           		value={item.selectedLocation.value}
	           		error={item.selectedLocation.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
		        	{item.depositable && 
		        		<TextInput 
			              	type="number"
			              	placeholder="Enter Returns"
			              	disabled={managePOD.isDisabledAsOrderChange}
			              	onChange={(val) => item.returns.onChange(val)}
			            	value={item.returns.value} 
			            	error={item.returns.error}/>
			        }
			        {!item.depositable && <div></div>}
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       		<Label>{item.subtotal}</Label>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button 
		        		fetching={managePOD.fetching} 
		        		onClick={() => managePOD.deleteProductItem(item.uuid)}
		        		bgColor="transparent" 
		        		fgColor='white' 
		        		disabled={managePOD.isDisabledAsOrderChange}
		        		className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetPurchaseHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-1">Serial No.</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Quantity</div>
				<div className="sm:col-span-1">FOC</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAssetPurchaseItem(item){
		let managePOD = this.props.managePOD;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose asset"
           			onChange={(val) => managePOD.changePurchaseAssetItem(item.uuid, val)}
           			disabled={managePOD.isDisabledAsOrderChange}
	           		options={managePOD.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="text"
		              	placeholder="Enter serial number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		       </div>
		        <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter FOC"
		              	disabled={managePOD.isDisabledAsOrderChange}
		              	onChange={(val) => item.foc.onChange(val)}
		            	value={item.foc.value} 
		            	error={item.foc.error}/>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       		<Label>{item.subtotal}</Label>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button 
		        		onClick={() => managePOD.deleteAssetPurchaseItem(item.uuid)}
		        		fetching={managePOD.fetching} bgColor="transparent" fgColor='white' disabled={managePOD.isDisabledAsOrderChange}className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetReturnHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Serial No.</div>
				<div className="sm:col-span-2">Quantity</div>
				<div className="sm:col-span-2"></div>
			</ListItem>
		)
	}

	renderAssetReturnItem(item){
		let managePOD = this.props.managePOD;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose asset"
           			onChange={(val) => managePOD.changeReturnAssetItem(item.uuid, val)}
           			disabled={managePOD.isDisabledAsOrderChange}
	           		options={managePOD.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		       </div>
		       <div className="sm:col-span-2">
		        	<TextInput 
		              	type="text"
		              	placeholder="Enter serial number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		       </div>
		        <div className="sm:col-span-2">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button 
		        		onClick={() => managePOD.deleteAssetReturnItem(item.uuid)}
		        		fetching={managePOD.fetching} bgColor="transparent" fgColor='white' disabled={managePOD.isDisabledAsOrderChange}className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderServiceItemHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Service</div>
				<div className="sm:col-span-1">Asset</div>
				<div className="sm:col-span-1">Serial Number</div>
				<div className="sm:col-span-1">Price</div>
				<div className="sm:col-span-1">Qty</div>
				<div className="sm:col-span-1">Subtotal</div>
			</ListItem>
		)
	}

	renderServiceItem(item){
		let managePOD = this.props.managePOD;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
           			placeholder="Choose service"
           			onChange={(val) => managePOD.changeServiceItemService(item.uuid, val)}
           			disabled={managePOD.isDisabledAsOrderChange}
	           		options={managePOD.serviceOptions} 
	           		value={item.selectedService.value}
	           		error={item.selectedService.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
	              <Select 
           			placeholder="Choose asset"
           			onChange={(val) => managePOD.changeServiceItemAsset(item.uuid, val)}
           			disabled={managePOD.isDisabledAsOrderChange}
	           		options={managePOD.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		        </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	placeholder="Enter serial number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		       </div>
		       <div className="sm:col-span-1">
		        	<TextInput 
		              	type="number"
		              	onChange={(val) => item.qty.onChange(val)}
		              	placeholder="Enter quantity"
		              	disabled={managePOD.isDisabledAsOrderChange}
		            	value={item.qty.value} 
		            	error={item.qty.error}/>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       		<Label>{item.subtotal}</Label>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button
		        		onClick={() => managePOD.deleteServiceItem(item.uuid)} 
		        		fetching={managePOD.fetching} bgColor="transparent" fgColor='white' disabled={managePOD.isDisabledAsOrderChange} className="flex mt-1"><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}


	render(){
		let managePOD = this.props.managePOD;
		let currentPOD = managePOD.currentPOD;
		let podTitle = null;
		if(managePOD.addMode){
			podTitle = 'New POD'
		}else if(managePOD.viewMode){
			podTitle = 'View POD';
		}else if(managePOD.editMode){
			podTitle = 'Edit POD';
		}
		return (
			<Main>
				<>
					<div className="flex flex-row items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {podTitle}
					        </Heading>
					        <Paragraph>
					          POD Details
					        </Paragraph>
					    </div>
					     <div className="flex flex-row items-center">
					    	{managePOD.viewMode && 
					    		<div className="flex mr-4 cursor-pointer" onClick={() => managePOD.downloadPDF()}>
						    		{!managePOD.downloadingPDF && <DownloadIcon color="blue-600" />}
							        <Paragraph>{managePOD.downloadingPDF ? 'Downloading....' : 'Download PDF'}</Paragraph>
							    </div>
						    }
					    	{managePOD.viewMode && 
					    		<div className="flex">
						    		<Button fetching={managePOD.fetching} onClick={() => managePOD.toggleEditMode()}>
							          Edit POD
							        </Button>
							    </div>
						    }
					        {!managePOD.viewMode && 
								  <div className="flex justify-end">
								      {managePOD.editMode && 
								      	<span className="inline-flex rounded-md shadow-sm">
									        <CancelButton fetching={managePOD.fetching} onClick={() => managePOD.toggleViewMode()}>
									          Cancel changes
									        </CancelButton>
									      </span>
									  }
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button fetching={managePOD.fetching} onClick={() => managePOD.save()}>
								          {managePOD.editMode ? 'Update' : 'Save' } POD
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
					<AccountSearchInput
				   		accounts={managePOD.accounts}
				   		editMode={managePOD.editMode || managePOD.viewMode}
				   		selectedAccountId={managePOD.selectedAccountId}
				   		disabled={managePOD.viewMode}
				   		onChange={(val) => managePOD.onChangeAccountSearchText(val)}
				   		onSelect={(id) => managePOD.selectAccount(id)}
				   		currentAccount={managePOD.podAccount}
				   		onGoToAccount={(id) => managePOD.appStore.goToEditAccount(id)}
						onGoToCustomer={(id) => managePOD.appStore.goToEditCustomer(id)}
				   	/>
				   	{managePOD.podAccount != null &&
				   		<>
						   	<FormDivider>
						   		<div>
							      	<SubHeading>
							          POD Information
							        </SubHeading>
							        <Paragraph>
							          What was delivered
							        </Paragraph>
							    </div>
							    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
							    	<div className="sm:col-span-3">
							   			<Label>POD Reference</Label>
							   			<div className="flex flex-row items-center">
								   			  <TextInput 
								   			  		placeholder="Leave blank for a POD reference to be generated for you"
									              	disabled={managePOD.viewMode}
									            	onChange={(val) => managePOD.podData.podReference.onChange(val)}
									            	value={managePOD.podData.podReference.value} 
									            	error={managePOD.podData.podReference.error} 
								            	/>
									    </div>
							   		</div>

							   		<div className="sm:col-span-3">
						        		<Label>
							            	Driver
							          	</Label>
						           		<Select 
						           			placeholder="Select a driver"
						           			disabled={managePOD.isDisabled}
							           		onChange={(val) => managePOD.podData.selectedDriver.value = val}
							           		options={managePOD.driverOptions} 
							           		value={managePOD.podData.selectedDriver.value}
							           		error={managePOD.podData.selectedDriver.error}
							           		 />
							        </div>

							        <div className="sm:col-span-2">
							   			<Label>Note</Label>
							   			<div className="flex flex-row items-center">
								   			  <TextInput 
								   			  		placeholder="A note to attach to the POD"
									              	disabled={managePOD.viewMode}
									            	onChange={(val) => managePOD.podData.note.onChange(val)}
									            	value={managePOD.podData.note.value} 
									            	error={managePOD.podData.note.error} 
								            	/>
									    </div>
							   		</div>

							   		<div className="sm:col-span-2">
							   			<Label>Delivered On</Label>
							   			<div className="flex flex-row items-center">
							   				<DateTimePicker 
							   					placeholder="Please select a delivery date and time"
							   					disabled={managePOD.viewMode}
							   					onChange={(val) => managePOD.podData.deliveredOn.onChange(val)}
							   					value={managePOD.podData.deliveredOn.value} 
							   					error={managePOD.podData.deliveredOn.error}
							   				/>
									    </div>
							   		</div>

							   		{!managePOD.addMode && 
							   			<div className="sm:col-span-2">
								   			<Label>Created On</Label>
								   			<div className="flex flex-row items-center">
									   			  <DateTimePicker 
									   					disabled={true}
									   					onChange={(val) => managePOD.podData.createdOn.onChange(val)}
									   					value={managePOD.podData.createdOn.value}
									   					error={managePOD.podData.createdOn.error}
									   				/>
										    </div>
								   		</div>
								   	}

							   		<div className="sm:col-span-3">
							   			<Label>Cash Received</Label>
							   			<div className="flex flex-row items-center">
								   			  <TextInput 
								   			  		placeholder="If any cash received from the driver"
									              	disabled={managePOD.viewMode}
									            	onChange={(val) => managePOD.podData.cashReceived.onChange(val)}
									            	value={managePOD.podData.cashReceived.value} 
									            	error={managePOD.podData.cashReceived.error} 
								            	/>
									    </div>
							   		</div>

							   		<div className="sm:col-span-3">
							   			<Label>PO Number</Label>
							   			<div className="flex flex-row items-center">
								   			  <TextInput 
								   			  		placeholder="Please enter if required a PO number"
									              	disabled={managePOD.viewMode}
									            	onChange={(val) => managePOD.podData.poNumber.onChange(val)}
									            	value={managePOD.podData.poNumber.value} 
									            	error={managePOD.podData.poNumber.error} 
								            	/>
									    </div>
							   		</div>

							   		{!managePOD.addMode && 
							   			<>
								   			<div className="sm:col-span-3">
									   			<Label>Customer Print Name</Label>
									   			<div className="flex flex-row items-center">
										   			  <TextInput 
											              	disabled={true}
											            	onChange={(val) => managePOD.podData.customerPrintName.onChange(val)}
											            	value={managePOD.podData.customerPrintName.value} 
											            	error={managePOD.podData.customerPrintName.error} 
										            	/>
											    </div>
									   		</div>

									   		<div className="sm:col-span-3">
									   			<Label>Customer Signature</Label>
									   			<div className="flex flex-row items-center mt-2">
										   			  {currentPOD != null && currentPOD.customer_signature_photo_url != null &&
										   			  	<div className="flex flex-row items-center">
										   			  		<div className="flex"><DownloadIcon color="blue-600" /></div>
										   			  		<div className="flex ml-2"><Paragraph><span className="text-blue-600 hover:underline"><a target="_blank" download href={currentPOD.customer_signature_photo_url}>Download customer signature</a></span></Paragraph></div>
										   			  	</div>
										   			  }
										   			  {currentPOD != null && currentPOD.customer_signature_photo_url == null &&
										   			  	<Paragraph>No signature here</Paragraph>
										   			  }
											    </div>
									   		</div>
									   	</>
								   	}
							    </div>

							     <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-8">
									<div className="sm:col-span-8">
										<Card title="Products" subtitle="Add products to this delivery" actionBtn={
											<Button disabled={managePOD.isDisabledAsOrderChange} onClick={() => managePOD.addProductItemEntry()}>Add Product</Button>
										}>
									      	<List 
									      		type="products"
									      		renderListHeaders={this.renderProductItemHeaders}
									      		renderListItem={this.renderProductItem}
									      		items={managePOD.podData.productItems}
											/>
								    	</Card>
								    </div>
								    <div className="sm:col-span-8">
										<Card title="Assets purchased" subtitle="Add assets in this delivery" actionBtn={
											<Button disabled={managePOD.isDisabledAsOrderChange} onClick={() => managePOD.addAssetItemEntry()}>Add Asset </Button>
										}>
									      	<List 
									      		type="assets"
									      		renderListHeaders={this.renderAssetPurchaseHeaders}
									      		renderListItem={this.renderAssetPurchaseItem}
									      		items={managePOD.podData.assetPurchaseItems}
											/>
								    	</Card>
								    </div>
								    <div className="sm:col-span-8">
										<Card title="Assets collected" subtitle="Add assets returned in this delivery" actionBtn={
											<Button disabled={managePOD.isDisabledAsOrderChange} onClick={() => managePOD.addAssetReturnedItemEntry()}>Add Asset Return</Button>
										}>
									      	<List 
									      		type="assets"
									      		renderListHeaders={this.renderAssetReturnHeaders}
									      		renderListItem={this.renderAssetReturnItem}
									      		items={managePOD.podData.assetReturnItems}
											/>
								    	</Card>
								    </div>
									<div className="sm:col-span-8">
										<Card title="Services" subtitle="Add service to delivery" actionBtn={
											<Button disabled={managePOD.isDisabledAsOrderChange} onClick={() => managePOD.addServiceItemEntry()}>Add Service</Button>
										}>
									      	<List 
									      		type="services"
									      		renderListHeaders={this.renderServiceItemHeaders}
									      		renderListItem={this.renderServiceItem}
									      		items={managePOD.podData.serviceItems}
											/>
								    	</Card>
								    </div>
							    </div>
						   	</FormDivider>
						   

						</>
				   	}
				</>
			</Main>
		)
	}
}

export default Authorisation(ManagePOD,['administrator', 'employee', 'accountant']);