export const intersect = (arrA, arrB) => arrA.filter((x) => arrB.includes(x));

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD HH:MM:ss';


export const buildAddressFromGoogleAddress = (addressComponents) => {
	let streetNumberComponent = addressComponents.find((x) => x.types.includes('street_number'));
	let streetRouteComponent = addressComponents.find((x) => x.types.includes('route'));
	let cityComponent = addressComponents.find((x) => x.types.includes('postal_town'));
	let countyComponent = addressComponents.find((x) => x.types.includes('administrative_area_level_2'));
	let countryComponent = addressComponents.find((x) => x.types.includes('country'));
	let postalCodeComponent = addressComponents.find((x) => x.types.includes('postal_code'));

	return {
		street_address: `${streetNumberComponent?.long_name} ${streetRouteComponent?.long_name}`,
		city: cityComponent?.long_name,
		county: countyComponent?.long_name,
		country: countryComponent?.short_name,
		postal_code: postalCodeComponent?.long_name
	}
};