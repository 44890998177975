import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import UIStore from './stores/ui';

import RegisterStore from './stores/users/register';
import LoginStore from './stores/users/login';

import ForgotPassword from './stores/users/forgot-password';
import ResetPassword from './stores/users/reset-password';

import CustomerList from './stores/customers/list';
import ManageCustomer from './stores/customers/manage-customer';

import AccountList from './stores/accounts/list';
import ManageAccount from './stores/accounts/manage-account';

import ProductList from './stores/products/list';
import ManageProduct from './stores/products/manage-product';

import AssetList from './stores/assets/list';
import ManageAsset from './stores/assets/manage-asset';

import ServiceList from './stores/services/list';
import ManageService from './stores/services/manage-service';

import OrderList from './stores/orders/list';
import ManageOrder from './stores/orders/manage-order';

import PODList from './stores/pods/list';
import ManagePOD from './stores/pods/manage-pod';

import AgreementList from './stores/agreements/list';
import ManageAgreement from './stores/agreements/manage-agreement';

import UserList from './stores/users/list';
import ManageUser from './stores/users/manage-user';

import ServicingList from './stores/servicing/list'

import JourneyList from './stores/journeys/list';
import ManageJourney from './stores/journeys/manage-journey';
import JourneyPlanner from './stores/journeys/planner';

import AcceptInvite from './stores/invites/accept-invite';

import InvoiceList from './stores/invoices/list';
import ManageInvoice from './stores/invoices/manage-invoice';

import CreditNoteList from './stores/credit-notes/list';
import ManageCreditNote from './stores/credit-notes/manage-credit-note';

import Stats from './stores/stats';

import { startRouter } from './router';

import App from './app';

let ui = new UIStore();

let register = new RegisterStore(ui);
let login = new LoginStore(ui);

let forgotPassword = new ForgotPassword(ui);
let resetPassword = new ResetPassword(ui);

let customerList = new CustomerList(ui);
let manageCustomer = new ManageCustomer(ui);

let accountList = new AccountList(ui);
let manageAccount = new ManageAccount(ui);

let productList = new ProductList(ui);
let manageProduct = new ManageProduct(ui);

let assetList = new AssetList(ui);
let manageAsset = new ManageAsset(ui);

let serviceList = new ServiceList(ui);
let manageService = new ManageService(ui);

let orderList = new OrderList(ui);
let manageOrder = new ManageOrder(ui);

let podList = new PODList(ui);
let managePOD = new ManagePOD(ui);

let agreementList = new AgreementList(ui);
let manageAgreement = new ManageAgreement(ui);

let userList = new UserList(ui);
let manageUser = new ManageUser(ui);

let servicingList = new ServicingList(ui);

let acceptInvite = new AcceptInvite(ui);

let journeyList = new JourneyList(ui);
let manageJourney = new ManageJourney(ui);
let journeyPlanner = new JourneyPlanner(ui);

let invoiceList = new InvoiceList(ui);
let manageInvoice = new ManageInvoice(ui);

let creditNoteList = new CreditNoteList(ui);
let manageCreditNote = new ManageCreditNote(ui);

let stats = new Stats(ui);

let stores = { 
		ui, register, login, customerList, manageCustomer, accountList, manageAccount, 
		productList, manageProduct, assetList, manageAsset, serviceList, manageService,
		orderList, manageOrder, agreementList, manageAgreement, userList, manageUser,
		acceptInvite, servicingList, journeyList, manageJourney, journeyPlanner, 
		podList, managePOD, forgotPassword, stats, invoiceList, manageInvoice,
		creditNoteList, manageCreditNote
	};
				
startRouter(ui);

class Root extends Component{
	render() {
		return (
	      	<Provider {...stores}>
    			<App />
    		</Provider>
	    )
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
