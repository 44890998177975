import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed, extendObservable } from 'mobx';

import JourneyApi from '../../services/journey';
import DriverApi from '../../services/driver';
import OrderApi from '../../services/order';
import AccountApi from '../../services/account';
import ServicingApi from '../../services/servicing';

import { uuid } from 'uuidv4';
import {DISPLAY_DATE_FORMAT, SERVER_DATE_FORMAT} from '../../services/util';

import moment from 'moment';


const sortBySequenceNumber = (o1, o2) => {
	if(o1.sequence_number > o2.sequence_number) return 1
	return -1
}

class JourneyPlanner{

	journeyApi;
	driverApi;
	orderApi;
	servicingApi;
	accountApi;

	@observable dates;
	@observable drivers;

	@observable currentDate;
	@observable selectedDriver;
	@observable selectedJourney;

	@observable journeys;
	@observable loadJourneyList;

	@observable currentDailyTasks;

	@observable showOrdersPanel;
	@observable showServicingPanel;
	@observable showMapsPanel;

	@observable ordersPage;
	@observable orders;
	@observable ordersTotal;
	@observable hasPrevOrders;
	@observable hasNextOrders;
	@observable ordersOffsetStart;
	@observable ordersOffsetEnd;

	@observable servicingPage;
	@observable servicingItems;
	@observable servicingTotal;
	@observable hasPrevServicing;
	@observable hasNextServicing;
	@observable servicingOffsetStart;
	@observable servicingOffsetEnd;

	@observable currentJourniesLoaded;

	@observable showConfirmDiscardChangesModal;

	@observable savingTasks;

	@observable searchAccountValue;
	@observable searchAccountOptions;
	@observable selectedAccount;

	@observable dirty;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.driverApi = new DriverApi(this.uiStore);
		this.journeyApi = new JourneyApi(this.uiStore);
		this.orderApi = new OrderApi(this.uiStore);
		this.servicingApi = new ServicingApi(this.uiStore);
		this.accountApi = new AccountApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.dates = [];
		this.drivers = [];
		this.currentDate = moment();
		this.selectedDriver = null;
		this.selectedJourney = null;
		this.journeys = [];
		this.loadJourneyList = false;
		this.currentDailyTasks = [];
		this.showOrdersPanel = false;
		this.showServicingPanel = false;
		this.currentJourniesLoaded = [];
		this.dirty = false;
		this.orders = [];
		this.ordersPage = 1;
		this.ordersTotal = 0;
		this.hasNextOrders = false;
		this.hasPrevOrders = false;
		this.ordersOffsetStart = null;
		this.ordersOffsetEnd = null;

		this.servicingItems = [];
		this.servicingPage = 1;
		this.servicingTotal = 0;
		this.hasNextServicing = false;
		this.hasPrevServicing = false;
		this.servicingOffsetStart = null;
		this.servicingOffsetEnd = null;
		this.savingTasks = false;
		this.showConfirmDiscardChangesModal = false;
		this.showMapsPanel = false;
		this.searchAccountValue = null;
		this.searchAccountOptions = [];
		this.selectedAccount = null;
	}

	fetchDrivers = async () => {
		try{
			this.drivers = await this.driverApi.fetchAllDrivers();
			if(this.drivers.length > 0){
				this.changeSelectedDriver(this.drivers[0].id);
			}
		}catch(e){
			console.log(e);
		}
	}

	@computed get deliveryDates(){
		let fromDate = moment();
		let deliveryDates = [];
		for(let i = -5; i < 30; i++){
			let currentDate = fromDate.clone().add(i, 'days');
			deliveryDates.push(currentDate);
		}
		return deliveryDates;
	}

	onLoad(){
		this.fetchDrivers();
		this.fetchJourneys();
	}

	isActiveDeliveryDate(deliveryDate){
		let currentDateFormatted = this.currentDate.format(DISPLAY_DATE_FORMAT);
		return deliveryDate.format(DISPLAY_DATE_FORMAT) === currentDateFormatted;
	}

	changeDeliveryDate(deliveryDate){
		if(this.dirty){
			this.displayConfirmDiscardChangesModal();
		}else{
			this.currentDate = deliveryDate;
			this.onReset();
			this.retrieveDriverDeliveries();
		}
	}

	isActiveDriver(driverId){
		return this.selectedDriver === driverId;
	}

	onReset(){
		this.currentDailyTasks = [];
		this.selectedJourney = null;
	}

	changeSelectedDriver(id){
		if(this.dirty){
			this.displayConfirmDiscardChangesModal();
		}else{
			this.selectedDriver = id;
			this.onReset();
			this.retrieveDriverDeliveries();
		}
	}

	fetchJourneys = async () => {
		try{
			this.journeys = await this.journeyApi.fetchAllJourneys();
		}catch(e){
			console.log(e);
		}
	}

	@computed get currentDailyJourneyCalls(){
		return this.currentDailyTasks.filter((x) => x.type == 'journey_call');
	}

	@computed get currentDailyJourneyOrders(){
		return this.currentDailyTasks.filter((x) => x.type == 'order');
	}

	@computed get currentDailyJourneyServicing(){
		return this.currentDailyTasks.filter((x) => x.type == 'servicing_item');
	}

	@computed get existingJourneyCallAccountIds(){
		return this.currentDailyJourneyCalls.map((jc) => jc.account.id);
	}

	loadSelectedJourneyTasks(){
		if(this.selectedJourney == null) return;

		this.loadJourneyList = false;

		let selectedJourneyId = parseInt(this.selectedJourney, 10);

		this.journeyApi.getJourneyList(selectedJourneyId)
			.then((response) => {
				let currentMaxSequenceNumber = this.currentDriverTasks.length;
				let journeyCalls = response.journey_calls.map((j, idx) => this.toJourneyCall(j, currentMaxSequenceNumber + idx + 1));
				let filteredJourneyCalls = journeyCalls.filter((j) => !this.existingJourneyCallAccountIds.includes(j.account.id))
				if(filteredJourneyCalls.length > 0){
					this.currentDailyTasks = this.currentDailyTasks.concat(filteredJourneyCalls);
					this.markDirty();
				}

				this.currentJourniesLoaded.push(selectedJourneyId);
				this.selectedJourney = null;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.loadJourneyList = false;
			})
	}

	@computed get journeyOptions(){
		return this.journeys.filter((journey) => !this.currentJourniesLoaded.includes(journey.id)).map((journey) => {
			return {
				label: journey.name,
				value: journey.id
			}
		})
	}

	onDeleteDriverTask(uuid){
		this.currentDailyTasks = this.currentDailyTasks.filter((j) => j.uuid != uuid);
		this.markDirty();
		this.recomputeSequenceNumbers();
	}

	recomputeSequenceNumbers(){
		this.currentDailyTasks = this.currentDailyTasks.map((task, idx) => {
			task.sequence_number = idx + 1;
			return task
		})
	}

	onMoveUpDriverTask(uuid){
		let currentTaskIdx = this.currentDailyTasks.findIndex((j) => j.uuid == uuid);
		if(currentTaskIdx != -1){
			let currentSequenceNumber = this.currentDailyTasks[currentTaskIdx].sequence_number;
			if(currentSequenceNumber > 1){
				let previousSequenceNumber = currentSequenceNumber - 1;
				let previousSequenceNumberIdx = this.currentDailyTasks.findIndex((x) => x.sequence_number == previousSequenceNumber);
				if(previousSequenceNumberIdx != -1){
					this.currentDailyTasks[previousSequenceNumberIdx].sequence_number = currentSequenceNumber;
					this.currentDailyTasks[currentTaskIdx].sequence_number = previousSequenceNumber;
					this.markDirty();
				}
			}				
		}
	}

	onMoveDownDriverTask(uuid){
		let currentTaskIdx = this.currentDailyTasks.findIndex((j) => j.uuid == uuid);
		if(currentTaskIdx != -1){
			let currentSequenceNumber = this.currentDailyTasks[currentTaskIdx].sequence_number;
			let nextSequenceNumber = currentSequenceNumber + 1;
			let nextSequenceNumberIdx = this.currentDailyTasks.findIndex((x) => x.sequence_number == nextSequenceNumber);
			if(nextSequenceNumberIdx != -1){
				this.currentDailyTasks[nextSequenceNumberIdx].sequence_number = currentSequenceNumber;
				this.currentDailyTasks[currentTaskIdx].sequence_number = nextSequenceNumber;
				this.markDirty();
			}
		}
	}


	getOpeningHoursForDay(entity){
		if(this.currentDate == null) return null;
		let openingHours = entity.account.opening_hours;
		if(openingHours.length == 0) return null;
		let dayOfWeek = this.currentDate.isoWeekday();

		let openingHoursOnDay = openingHours.filter((openingHourEntry) => openingHourEntry.day.day_id === dayOfWeek);
		if(openingHoursOnDay.length > 0){
			let startTime = moment(openingHoursOnDay[0].start_time, "HH:mm").format('hh:mm a');
			let endTime = moment(openingHoursOnDay[0].end_time, "HH:mm").format('hh:mm a');
			return {
				active: openingHoursOnDay[0].active,
				start_time: startTime,
				end_time: endTime
			}
		}
		return openingHoursOnDay.length == 0 ? null : openingHoursOnDay[0];
	}

	@computed get journeyCallsTitle(){
		let defaultTitle = 'Journey Calls'
		if(this.currentJourniesLoaded.length == 0) return defaultTitle;

		let journeyNames = this.journeys.filter((j) => this.currentJourniesLoaded.includes(j.id)).map((j) => j.name);
		return `${defaultTitle} including ${journeyNames.join(',')}`
	}

	@computed get employeeOnDateTitle(){
		if(this.selectedDriver == null || this.currentDate == null) return null;
		let driver = this.drivers.find((d) => d.id === this.selectedDriver);
		
		return `${driver.name} on ${this.currentDate.format('ll')}`
	}

	toOrder(o){
		o.created_on = moment(o.created_on)
		return o;
	}

	loadPendingOrders(){
		this.orderApi.getAll(this.ordersPage, null, true, true)
			.then((response) => {
				let newOrders = response.orders.map(this.toOrder);
				this.orders = newOrders
				this.ordersPage = response.page;
				this.ordersTotal = response.total;
				this.hasPrevOrders = response.has_prev;
				this.hasNextOrders = response.has_next;
				this.ordersOffsetStart = response.offset_start;
				this.ordersOffsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}




	onNextOrdersPage(){
		this.ordersPage += 1;
		this.loadPendingOrders();
	}

	onPreviousOrdersPage(){
		this.ordersPage = Math.max(this.ordersPage - 1, 0);
		this.loadPendingOrders();
	}

	onToggleOrdersPanel(){
		this.showOrdersPanel = !this.showOrdersPanel;
		this.ordersPage = 1;
		this.loadPendingOrders();
	}

	onToggleMapPanel(){
		this.showMapsPanel = !this.showMapsPanel;
	}

	toServiceItem(item){
		item.uuid = uuid();
		item.last_service_date = item.last_service_date != null ? moment(item.last_service_date) : null;
		item.expected_service_date = moment(item.expected_service_date);
		item.dismissed_on = item.dismissed_on != null ? moment(item.dismissed_on) : null;
		item.completed_on = item.completed_on != null ? moment(item.completed_on) : null;
		return item;
	}


	loadPendingServicing(){
		this.servicingApi.getAll(this.servicingPage, null, true)
			.then((response) => {
				let newServicings = response.servicing_items.map(this.toServiceItem);
				this.servicingItems = newServicings
				this.servicingPage = response.page;
				this.servicingTotal = response.total;
				this.hasPrevServicing = response.has_prev;
				this.hasNextServicing = response.has_next;
				this.servicingOffsetStart = response.offset_start;
				this.servicingOffsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get allAddedOrderIds(){
		let addedOrderIds = this.currentDailyJourneyOrders.map((j) => j.id);
		let addedServiceOrderIds = this.currentDailyJourneyServicing.map((j) => j.order.id);
		return addedOrderIds.concat(addedServiceOrderIds);
	}

	@computed get allAddedServicingIds(){
		return this.currentDailyJourneyServicing.map((j) => j.id);
	}

	@computed get filteredServicingItems(){
		return this.servicingItems.filter((s) => !this.allAddedServicingIds.includes(s.id));
	}

	@computed get filteredOrders(){
		return this.orders.filter((s) => !this.allAddedOrderIds.includes(s.id));
	}

	onNextServicingPage(){
		this.servicingPage += 1;
		this.loadPendingServicing();
	}

	onPreviousServicingPage(){
		this.servicingPage = Math.max(this.servicingPage - 1, 0);
		this.loadPendingServicing();
	}


	onToggleServicingPanel(){
		this.showServicingPanel = !this.showServicingPanel;
		this.servicingPage = 1;
		this.loadPendingServicing();
	}

	buildAddress(deliveryAddress){
		return `${deliveryAddress.street_address}, ${deliveryAddress.city}, ${deliveryAddress.county}, ${deliveryAddress.country.name}, ${deliveryAddress.postcode}`
	}

	onAddOrderToCallSheet(order){
		if(order == null) return;
		let orderWithNote = extendObservable(order, {
			uuid: uuid(),
			type: 'order',
			note: null,
			sequence_number: this.currentDailyTasks.length + 1
		});
		this.currentDailyTasks.push(orderWithNote);
		this.markDirty();
	}

	onAddServicingItemToCallSheet(servicingItem){
		if(servicingItem == null) return;
		let servicingItemWithNote = extendObservable(servicingItem, {
			uuid: uuid(),
			type: 'servicing_item',
			note: null,
			sequence_number: this.currentDriverTasks.length + 1
		});
		this.currentDailyTasks.push(servicingItemWithNote);
		this.markDirty();
	}


	mapResponseToDelivery(response){
		let dailyJourneyCalls = response.journey_calls.map((j, idx) => this.toJourneyCall(j, idx + 1));
		let dailyJourneyServicing = response.servicing_items.map((journeyServiceItem, idx) => {
			let serviceItemWithNote = extendObservable(journeyServiceItem.servicing_item, {
				uuid: uuid(),
				note: journeyServiceItem.note,
				completed_on: journeyServiceItem.completed_on,
				dismissed_on: journeyServiceItem.dismissed_on,
				sequence_number: journeyServiceItem.sequence_number,
				type: 'servicing_item'
			});
			return this.toServiceItem(serviceItemWithNote);
		});
		let dailyJourneyOrders = response.orders.map((journeyOrder, idx) => {
			let orderWithNote = extendObservable(journeyOrder.order, {
				uuid: uuid(),
				note: journeyOrder.note,
				completed_on: journeyOrder.completed_on,
				dismissed_on: journeyOrder.dismissed_on,
				sequence_number: journeyOrder.sequence_number,
				type: 'order'
			});
			return this.toOrder(orderWithNote);
		});
		let allNewTasks = [];
		allNewTasks = allNewTasks.concat(dailyJourneyCalls);
		allNewTasks = allNewTasks.concat(dailyJourneyServicing);
		allNewTasks = allNewTasks.concat(dailyJourneyOrders);
		this.currentDailyTasks = allNewTasks;
	}

	retrieveDriverDeliveries(){
		let currentDriverId = this.selectedDriver;
		if(currentDriverId == null || this.currentDate == null) return;
		let deliveryDateFormatted = this.currentDate.format(SERVER_DATE_FORMAT);
		this.driverApi.getDriverDeliveries(currentDriverId, deliveryDateFormatted)
			.then((response) => {
				this.mapResponseToDelivery(response);
			})
			.catch((error) => {
				console.log(error);
			})
	}

	toJourneyCall(j, idx){
		j.uuid = uuid();
		j.last_delivery_date = j.last_delivery_date != null ? moment(j.last_delivery_date): null;
		j.completed_on = j.completed_on != null ? moment(j.completed_on) : null;
		j.dismissed_on = j.dismissed_on != null ? moment(j.dismissed_on) : null;
		j.sequence_number = 'sequence_number' in j ? j.sequence_number: idx;
		j.type = 'journey_call';
		return j;
	}

	toJourneyCallFromAccount(account, idx){
		let journeyCall = this.toJourneyCall({
			account: account
		}, idx);
		journeyCall.last_delivery_date = account.last_delivery_date != null ? moment(account.last_delivery_date): null;
		return journeyCall;

	}

	saveTasks(){
		let currentDriverId = this.selectedDriver;
		if(currentDriverId == null || this.currentDate == null) return;
		let deliveryDateFormatted = this.currentDate.format(SERVER_DATE_FORMAT);
		let journeyCalls = this.currentDailyJourneyCalls;
		let journeyServicingItems = this.currentDailyJourneyServicing;
		let journeyOrders = this.currentDailyJourneyOrders;

		let tasksPayload = {
			journey_calls: journeyCalls.map((journeyCall) => {
				return {
					account_id: journeyCall.account.id,
					note: journeyCall.note,
					sequence_number: journeyCall.sequence_number
				}
			}),
			orders: journeyOrders.map((journeyOrder) => {
				return { 
					order_id: journeyOrder.id,
					note: journeyOrder.note,
					sequence_number: journeyOrder.sequence_number
				}
			}),
			servicing_items: journeyServicingItems.map((servicingItem) => {
				return {
					servicing_item_id: servicingItem.id,
					note: servicingItem.note,
					sequence_number: servicingItem.sequence_number
				}
			})
		}

		this.savingTasks = true;
		this.driverApi.saveDriverDeliveries(currentDriverId, deliveryDateFormatted, tasksPayload)
			.then((response) => {
				this.mapResponseToDelivery(response);
				this.uiStore.alertSuccess('Driver schedule updated.')
				this.showOrdersPanel = false;
				this.showServicingPanel = false;
				this.dirty = false;
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.savingTasks = false;
			})
	}

	markDirty(){
		this.dirty = true;	
	}

	discardChanges(){
		this.hideDiscardChangesModal();
		this.dirty = false;
		this.retrieveDriverDeliveries();
	}

	hideDiscardChangesModal(){
		this.showConfirmDiscardChangesModal = false;
	}

	displayConfirmDiscardChangesModal(){
		this.showConfirmDiscardChangesModal = true;
	}

	@computed get currentDriverTasks(){
		return this.currentDailyTasks.slice().sort(sortBySequenceNumber);
	}

	@computed get journeyMarkers(){
		return this.currentDriverTasks.filter((x) => x.account.delivery_address.latitude != null).map((driverTask, idx) => {
			return {
				id: idx,
				latitude: driverTask.account.delivery_address.latitude,
				longitude: driverTask.account.delivery_address.longitude,
				sequence_number: driverTask.sequence_number,
				account: driverTask.account,
				type: driverTask.type
			}
		})
	}

	@computed get journeyPathMarkers(){
		return this.journeyMarkers.map((journeyMarker) => {
			return {
				lat: journeyMarker.latitude,
				lng: journeyMarker.longitude
			}
		})
	}

	onChangeSearchAccountValue(val){
		this.searchAccountValue = val;

		this.searchAccounts();
	}


	searchAccounts(){
		if(this.searchAccountValue == ''){
			this.searchAccountOptions = [];
		}else{
			this.accountApi.getAll(1, this.searchAccountValue)
				.then((response) => {
					this.searchAccountOptions = response.accounts.map((account) => {
						return {
							label: `${account.name} (${account.account_reference})`,
							value: account.id 
						}
					})
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}

	onSelectAccount(id){
		let selectedAccount = this.searchAccountOptions.find((a) => a.value == id);
		if(selectedAccount != null){
			this.selectedAccount = selectedAccount;
			this.searchAccountOptions = [];
		}
	}

	onClearSelectedValue(){
		this.selectedAccount = null;
		this.searchAccountValue = '';
	}

	loadSelectedAccount(){
		if(this.selectedAccount == null) return;

		this.accountApi.getAccountById(this.selectedAccount.value)
			.then((response) => {
				let accountToAdd = response.account;
				let currentMaxSequenceNumber = this.currentDriverTasks.length;
				let hasAccountAddedAlready = this.existingJourneyCallAccountIds.includes(accountToAdd.id);
				if(!hasAccountAddedAlready){
					this.currentDailyTasks.push(this.toJourneyCallFromAccount(accountToAdd, currentMaxSequenceNumber + 1));

					this.markDirty();
				}
				this.onClearSelectedValue();


			})
			.catch((error) => {
				console.log(error);
			})

		
	}
}

export default JourneyPlanner;