import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {TextInput, TextArea, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {DeleteIcon} from '../common/icon';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import Authorisation from '../authorisation';

@inject('manageAsset')
@observer
class ManageAsset extends Component{
	constructor(props){
		super(props);
		this.onNewPurchasePrice = this.onNewPurchasePrice.bind(this);
		this.renderPurchasePriceListHeaders = this.renderPurchasePriceListHeaders.bind(this);
		this.renderPurchasePriceListItem = this.renderPurchasePriceListItem.bind(this);
		this.onNewRentalPrice = this.onNewRentalPrice.bind(this);
		this.renderRentalPriceListHeaders = this.renderRentalPriceListHeaders.bind(this);
		this.renderRentalPriceListItem = this.renderRentalPriceListItem.bind(this);
	}

	componentDidMount(){
		let manageAsset = this.props.manageAsset;
		manageAsset.fetchCurrencies();
		manageAsset.fetchVATRates();
		if(this.props.viewMode){
			manageAsset.formSync(this.props.assetId);
		}
	}

	componentWillUnmount(){
		let manageAsset = this.props.manageAsset;
		manageAsset.initStore()
	}

	onNewPurchasePrice(){
		let manageAsset = this.props.manageAsset;
		manageAsset.addPurchasePriceListEntry();
	}

	onNewRentalPrice(){
		let manageAsset = this.props.manageAsset;
		manageAsset.addRentalPriceListEntry();
	}


	renderPurchasePriceListHeaders(){
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderRentalPriceListHeaders(){
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-1">Rental Days</div>
				<div className="sm:col-span-2">Rent</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderPurchasePriceListItem(item){
		let manageAsset = this.props.manageAsset;
		return (
			<ListItem gridCols={8}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => manageAsset.changePurchasePriceListItemCurrency(item.uuid, val)}
           			placeholder="Choose price currency"
           			disabled={manageAsset.viewMode}
	           		options={manageAsset.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter asset price"
	              	onChange={(val) => manageAsset.changePurchasePriceListItemPrice(item.uuid, val)}
	              	disabled={manageAsset.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => manageAsset.changePurchasePriceListItemVATRate(item.uuid, val)}
           			placeholder="Choose vat rate"
           			disabled={manageAsset.viewMode}
	           		options={manageAsset.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAsset.fetching} bgColor="transparent" fgColor='white' disabled={manageAsset.viewMode} className="flex mt-1" onClick={() => manageAsset.deletePurchasePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderRentalPriceListItem(item){
		let manageAsset = this.props.manageAsset;
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => manageAsset.changeRentalPriceListItemCurrency(item.uuid, val)}
           			placeholder="Choose price currency"
           			disabled={manageAsset.viewMode}
	           		options={manageAsset.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter asset price"
	              	onChange={(val) => manageAsset.changeRentalPriceListItemPrice(item.uuid, val)}
	              	disabled={manageAsset.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => manageAsset.changeRentalPriceListItemVATRate(item.uuid, val)}
           			placeholder="Choose vat rate"
           			disabled={manageAsset.viewMode}
	           		options={manageAsset.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
	              <TextInput 
	              	type="number"
	              	placeholder="Number of days"
	              	onChange={(val) => manageAsset.changeRentalPriceListItemDays(item.uuid, val)}
	              	disabled={manageAsset.viewMode}
	            	value={item.days.value} 
	            	error={item.days.error}/>
		        </div>
		       <div className="sm:col-span-2 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAsset.fetching} bgColor="transparent" fgColor='white' disabled={manageAsset.viewMode} className="flex mt-1" onClick={() => manageAsset.deleteRentalPriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	render(){
		let manageAsset = this.props.manageAsset;
		return (
			<Main>
				<div>
					<div className="flex flex-row flex-1 items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {manageAsset.name.value.length > 0 ? manageAsset.name.value : 'Asset Information'}
					        </Heading>
					        <Paragraph>
					          Asset Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageAsset.viewMode && 
					    		<Button fetching={manageAsset.fetching} onClick={() => manageAsset.toggleEditMode()}>
						          Edit Asset
						        </Button>
						    }
					        {!manageAsset.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton fetching={manageAsset.fetching} onClick={() => manageAsset.onCancel()} fetching={manageAsset.fetching}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button disabled={!manageAsset.validForm} fetching={manageAsset.fetching} onClick={() => manageAsset.save()} fetching={manageAsset.fetching}>
								          {manageAsset.editMode ? 'Update' : 'Save' } Asset
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
					<div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						<div className="sm:col-span-3">
				          <Label>
				            Asset Name
				          </Label>
			              <TextInput 
			              	disabled={manageAsset.viewMode}
			            	onChange={(val) => manageAsset.name.onChange(val)}
			            	value={manageAsset.name.value} 
			            	error={manageAsset.name.error} />
				        </div>
				        <div className="sm:col-span-3">
				        	<Label>Model No</Label>
				            <TextInput 
				              	disabled={manageAsset.viewMode}
				            	onChange={(val) => manageAsset.modelNumber.onChange(val)}
				            	value={manageAsset.modelNumber.value} 
				            	error={manageAsset.modelNumber.error} />
				        </div>
					</div>
					<FormDivider>
						<Card title="Asset Purchase Prices" subtitle="Set default prices for purchasing this asset" actionBtn={
							<Button disabled={manageAsset.viewMode} onClick={this.onNewPurchasePrice}>Add New Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderPurchasePriceListHeaders}
					      		renderListItem={this.renderPurchasePriceListItem}
					      		items={manageAsset.defaultPurchasePrices}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Asset Rental Prices" subtitle="Set default prices for renting this asset" actionBtn={
							<Button disabled={manageAsset.viewMode} onClick={this.onNewRentalPrice}>Add New Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderRentalPriceListHeaders}
					      		renderListItem={this.renderRentalPriceListItem}
					      		items={manageAsset.defaultRentalPrices}
							/>
				    	</Card>
				    </FormDivider>
				</div>
			</Main>
		)
	}
}

export default Authorisation(ManageAsset, ['administrator', 'employee', 'accountant']);