import React, {Component} from 'react';
import Logo from '../common/logo';
import { Heading } from '../elements/text';
import waterBg1Src from '../../assets/images/water-bg-1.jpg';

class AuthContainer extends Component{
	render(){
		return (
			<div className="min-h-screen bg-white flex">
			  <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			    <div className="mx-auto w-full max-w-sm">
			      <div>
			        <Logo className="h-12 w-auto"/>
			        <Heading>{this.props.title}</Heading>
			        <div className="mt-2"><span className="text-gray-700 text-sm">{this.props.description}</span></div>
			      </div>

			      <div className="mt-8">
			        <div className="mt-6">
			        	{this.props.children}
			        </div>
			      </div>
			    </div>
			  </div>
			  <div className="hidden lg:block relative w-0 flex-1">
			    <img className="absolute inset-0 h-full w-full object-cover" src={waterBg1Src} alt="Water Bubbles" />
			  </div>
			</div>
		)
	}
}

export default AuthContainer;