import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {TextInput, TextArea, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {DeleteIcon} from '../common/icon';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';

import Authorisation from '../authorisation';

@inject('manageProduct')
@observer
class ManageProduct extends Component{
	constructor(props){
		super(props);
		this.renderPriceListItem = this.renderPriceListItem.bind(this);
		this.renderPriceListHeaders = this.renderPriceListHeaders.bind(this);
		this.onNewPrice = this.onNewPrice.bind(this);
	}

	componentDidMount(){
		let manageProduct = this.props.manageProduct;
		manageProduct.fetchProductCategories();
		manageProduct.fetchVATRates();
		manageProduct.fetchCurrencies();
		if(this.props.viewMode){
			manageProduct.formSync(this.props.productId);
		}
		
	}

	componentWillUnmount(){
		let manageProduct = this.props.manageProduct;
		manageProduct.initStore()
	}

	renderPriceListHeaders(){
		let manageProduct = this.props.manageProduct;
		return (
			<ListItem gridCols={manageProduct.gridHeadersCols}>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				{manageProduct.requiresDeposit && 
					<>
						<div className="sm:col-span-2">Deposit</div>
						<div className="sm:col-span-2">Refund</div>
					</>
				}
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderPriceListItem(item){
		let manageProduct = this.props.manageProduct;
		return (
			<ListItem gridCols={manageProduct.gridHeadersCols}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageProduct.viewMode}
	           		options={manageProduct.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter product price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageProduct.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageProduct.viewMode}
	           		options={manageProduct.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       {manageProduct.requiresDeposit && 
			       	<>
				       	<div className="sm:col-span-2">
			              <TextInput 
			              	type="number"
			              	placeholder="Enter product deposit"
			              	onChange={(val) => item.deposit.onChange(val)}
			              	disabled={manageProduct.viewMode}
			            	value={item.deposit.value} 
			            	error={item.deposit.error}/>
				       </div>
				        <div className="sm:col-span-2">
			              <TextInput 
			              	type="number"
			              	placeholder="Enter product refund"
			              	onChange={(val) => item.refund.onChange(val)}
			              	disabled={manageProduct.viewMode}
			            	value={item.refund.value} 
			            	error={item.refund.error}/>
				       </div>
				    </>
			   }
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageProduct.fetching} bgColor="transparent" fgColor='white' disabled={manageProduct.viewMode} className="flex mt-1" onClick={() => manageProduct.deletePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	onNewPrice(){
		let manageProduct = this.props.manageProduct;
		manageProduct.addPriceListEntry();
	}

	render(){
		let manageProduct = this.props.manageProduct;
		return (
			<Main>
				<div>
					<div className="flex flex-row flex-1 items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {manageProduct.name.value.length > 0 ? manageProduct.name.value : 'Product Information'} {manageProduct.productReference.value.length > 0 && ` - ${manageProduct.productReference.value}`}
					        </Heading>
					        <Paragraph>
					          Product Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageProduct.viewMode && 
					    		<Button fetching={manageProduct.fetching} onClick={() => manageProduct.toggleEditMode()}>
						          Edit Product
						        </Button>
						    }
					        {!manageProduct.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton fetching={manageProduct.fetching} onClick={() => manageProduct.onCancel()} fetching={manageProduct.fetching}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button fetching={manageProduct.fetching} onClick={() => manageProduct.save()} fetching={manageProduct.fetching}>
								          {manageProduct.editMode ? 'Update' : 'Save' } Product
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
					<div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						<div className="sm:col-span-3">
				          <Label>
				            Product Name
				          </Label>
			              <TextInput 
			              	disabled={manageProduct.viewMode}
			            	onChange={(val) => manageProduct.name.onChange(val)}
			            	value={manageProduct.name.value} 
			            	error={manageProduct.name.error} />
				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            Product Reference
				          </Label>
			              <TextInput 
			              	disabled={manageProduct.viewMode}
			            	onChange={(val) => manageProduct.productReference.onChange(val)}
			            	value={manageProduct.productReference.value} 
			            	error={manageProduct.productReference.error} />
				        </div>
				        <div className="sm:col-span-6">
				          <Label>
				            Product Description
				          </Label>
			              <TextArea 
			              	disabled={manageProduct.viewMode}
			            	onChange={(val) => manageProduct.productDescription.onChange(val)}
			            	value={manageProduct.productDescription.value} 
			            	error={manageProduct.productDescription.error} />
				        </div>
				        <div className="sm:col-span-6">
			        		<Label>
				            	Product Category
				          	</Label>
			           		<Select 
			           			placeholder="Choose Product Category"
			           			disabled={manageProduct.viewMode}
				           		onChange={(val) => manageProduct.onChangeProductCategory(val)}
				           		options={manageProduct.productCategoryOptions} 
				           		value={manageProduct.selectedProductCategory.value}
				           		error={manageProduct.selectedProductCategory.error}
				           		 />
				        </div>
					    <div className="sm:col-span-6 flex items-center">
					        <CheckBox 
					        	disabled={manageProduct.viewMode}
					        	label="This product is obsolete" 
					        	checked={manageProduct.obsolete.value} 
					        	onChange={(e) => manageProduct.onChangeObsolete(e.target.checked)} />
					    </div>
					    <div className="sm:col-span-6 flex items-center">
					        <CheckBox 
					        	disabled={manageProduct.viewMode}
					        	label="This product requires a deposit" 
					        	checked={manageProduct.depositable.value} 
					        	onChange={(e) => manageProduct.onChangeDepositable(e.target.checked)} />
					    </div>
					</div>
					<FormDivider>
						<Card title="Price List" subtitle="Set default prices for product currencies" actionBtn={
							<Button disabled={manageProduct.viewMode} onClick={this.onNewPrice}>Add New Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderPriceListHeaders}
					      		renderListItem={this.renderPriceListItem}
					      		items={manageProduct.priceList}
							/>
				    	</Card>
				    </FormDivider>
				</div>
			</Main>
		)
	}
}

export default Authorisation(ManageProduct, ['administrator', 'employee', 'accountant']);