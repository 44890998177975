import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, Item, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('creditNoteList')
@observer
class CreditNotes extends Component{
	constructor(props){
		super(props);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let creditNoteList = this.props.creditNoteList;
		creditNoteList.fetchCreditNotes();
	}

	componentWillUnmount(){
		let creditNoteList = this.props.creditNoteList;
		creditNoteList.initStore();
	}

	onPreviousPage(){
		let creditNoteList = this.props.creditNoteList;
		creditNoteList.onPreviousPage();
	}

	onNextPage(){
		let creditNoteList = this.props.creditNoteList;
		creditNoteList.onNextPage();
	}

	renderListHeaders(){
		return (
			<ListItem gridCols={4}>
				<div className="sm:col-span-1">Credit Note Reference</div>
				<div className="sm:col-span-1">Account</div>
				<div className="sm:col-span-1">POD</div>
				<div className="sm:col-span-1">Created On</div>
			</ListItem>
		)
	}

	renderListItem(item){
		let creditNoteList = this.props.creditNoteList;
		return (
			<ListItem gridCols={4} onEdit={() => creditNoteList.onEdit(item.id)}>
				<Item text={item.credit_note_reference} />
				<ItemColumn text={item.account?.name} subtext={item.account?.account_reference} />
				<ItemColumn text={item.proof_of_delivery?.pod_reference} subtext={item.proof_of_delivery?.driver?.name} />
				<Item text={item.logged_on.format('DD/MM/YYYY')} />
			</ListItem>
		)
	}

	render(){
		let creditNoteList = this.props.creditNoteList;
		return (
			<Main>
				<Card title="Credit Notes" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Credit Note Reference"
					  		onChange={(e) => creditNoteList.changeSearchText(e.target.value)}
					  		 />
					  }
					>

					<List 
						type="credit notes"
						items={creditNoteList.creditNotes}
						renderListItem={this.renderListItem}
						renderListHeaders={this.renderListHeaders}
						page={creditNoteList.page}
						total={creditNoteList.total}
						hasNext={creditNoteList.hasNext}
						hasPrev={creditNoteList.hasPrev}
						offsetStart={creditNoteList.offsetStart}
						offsetEnd={creditNoteList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(CreditNotes, ['administrator', 'employee', 'accountant']);