import BaseApi from '../services/base';

class StatsApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getTodayStats(){
		return this.fetch('v1/stats/today', {
			method: 'GET',
			auth: true
		})
	}
}

export default StatsApi;