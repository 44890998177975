import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import ProductApi from '../../services/product';
import CommonApi from '../../services/common';

import { uuid } from 'uuidv4';

class ManageProduct{
	productApi;
	commonApi;

	id;

	@observable editMode;
	@observable viewMode;
	@observable addMode;

	@observable name;
	@observable productReference;
	@observable productDescription;
	@observable selectedProductCategory;
	@observable obsolete;
	@observable depositable;

	@observable priceList;
	@observable currencies;
	@observable vatRates;

	@observable form;

	@observable productCategories;
	
	@observable fetching;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.productApi = new ProductApi(this.uiStore);
		this.commonApi = new CommonApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.productReference = new FieldState('').validators((val) => isRequiredValidator(val, 'product reference'));
		this.productDescription = new FieldState(null);
		this.selectedProductCategory = new FieldState(null);
		this.obsolete = new FieldState(false);
		this.depositable = new FieldState(false);
		this.addMode = true;
		this.viewMode = false
		this.editMode = false;
		this.productCategories = [];
		this.vatRates = [];
		this.fetching = false;
		this.priceList = [];
		this.currencies = [];
		this.form = new FormState({
			name: this.name,
			productReference: this.productReference,
			productDescription: this.productDescription,
			selectedProductCategory: this.selectedProductCategory,
			obsolete: this.obsolete,
			depositable: this.depositable
		})
	}

	onCancel(){
		this.uiStore.goToProducts();
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	formSync(productId){
		this.viewMode = true;
		this.editMode = false;
		this.addMode = false;

		this.id = productId;
		this.productApi.getProductById(productId)
			.then((response) => {
				let product = response.product;
				this.name.value = product.name;
				this.productReference.value = product.product_reference;
				this.productDescription.value = product.product_description;
				this.selectedProductCategory.value = product.product_category_id;
				this.obsolete.value = product.obsolete;
				this.depositable.value = product.depositable;
				this.priceList = product.default_prices.map((priceItem) => {
					return this.generatePriceListEntry(priceItem.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price, priceItem.deposit, priceItem.refund)
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}
	
	fetchProductCategories = async () => {
		try{
			this.productCategories = await this.productApi.getAllProductCategories();
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get productCategoryOptions(){
		return this.productCategories.map((productCategory) => {
			return {
				value: productCategory.id,
				label: productCategory.name
			}
		})
	}

	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: vatRate.id,
				label: vatRate.name
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				value: currency.id,
				label: `${currency.name} (${currency.symbol})`
			}
		})
	}

	@action onChangeProductCategory(val){
		this.selectedProductCategory.value = val;
	}

	@action onChangeObsolete(val){
		this.obsolete.value = val;
	}

	@action onChangeDepositable(val){
		this.depositable.value = val;
	}

	@action generatePriceListEntry(id=null, currencyId=null, vatRateId=null, price=null, deposit=null, refund=null){
		let that = this;

		return observable.object({
			id: id,
			uuid: uuid(),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			deposit: new FieldState(deposit).validators((val) => that.requiresDeposit && isPositiveNumberValidator(val)),
			refund: new FieldState(refund).validators((val) => that.requiresDeposit && isPositiveNumberValidator(val)),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				return `${currency.symbol}${totalPrice}`
			}
		})
	}

	@action addPriceListEntry(){
		this.priceList.push(this.generatePriceListEntry())
	}

	changePriceListItemCurrency(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedCurrency');
	}

	changePriceListItemPrice(uuid, val){
		this.setPriceListItem(uuid, val, 'price');
	}

	setPriceListItem(uuid, val, key){
		let entry = this.priceList.find((p) => p.uuid == uuid);
		if(entry != null){
			entry[key].onChange(val);
		}
	}

	changePriceListItemVATRate(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedVATRate');
	}

	deletePriceListItem(uuid){
		this.priceList = this.priceList.filter((p) => p.uuid != uuid);
	}

	save = async () => {

		let needsDepositValues = this.requiresDeposit;
		const priceListForm = new FormState(this.priceList.map((priceListItem) => {
			return new FormState({
				price: priceListItem.price,
				deposit: priceListItem.deposit,
				refund: priceListItem.refund,
				selectedVATRate: priceListItem.selectedVATRate,
				selectedCurrency: priceListItem.selectedCurrency
			});
		}));
		let priceListValidation = await priceListForm.validate();
		if(priceListValidation.hasError) return;

		let res = await this.form.validate();
		if(res.hasError) return;

		let productPayload = {
			name: this.name.value,
			product_reference: this.productReference.value,
			product_description: this.productDescription.value,
			product_category_id: this.selectedProductCategory.value,
			obsolete: this.obsolete.value,
			depositable: this.depositable.value,
			default_prices: this.priceList.map((priceListEntry) => {
				return {
					id: priceListEntry.id,
					price: parseFloat(priceListEntry.price.value).toFixed(4),
					currency_id: parseInt(priceListEntry.selectedCurrency.value),
					vat_rate_id: parseInt(priceListEntry.selectedVATRate.value),
					deposit: needsDepositValues ? parseFloat(priceListEntry.deposit.value).toFixed(4) : null,
					refund: needsDepositValues ? parseFloat(priceListEntry.refund.value).toFixed(4) : null
				}
			})
		}

		this.fetching = true;
		if(this.addMode){
			this.productApi.newProduct(productPayload)
				.then((response) => {
					this.uiStore.alertSuccess('Product successfully added');
					this.uiStore.goToProducts();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			this.productApi.updateProduct(this.id, productPayload)
				.then((response) => {
					this.uiStore.alertSuccess('Product successfully updated');
					this.uiStore.goToProducts();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}		
	}

	@computed get requiresDeposit(){
		return this.depositable.value === true;
	}

	@computed get gridHeadersCols(){
		return this.requiresDeposit ? 12 : 8;
	}

}

export default ManageProduct;