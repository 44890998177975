import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import {Stat, StatMetric} from '../elements/ui';
import { ResponsiveContainer, LineChart, Line, Tooltip, XAxis  } from 'recharts';
import {ExclamationIcon} from '../common/icon';

import Main from '../main';


let leftStyle = "w-1/2 text-xs px-4 py-2 text-left";
let rightStyle = "w-1/2 text-xs px-4 py-2 text-right";

@inject('stats')
@observer
class Home extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){
		let stats = this.props.stats;
		stats.fetchTodayStats()
	}

	componentWillUnmount(){
		let stats = this.props.stats;
		stats.initStore();
	}

	render(){
		let store = this.props.stats;
		let percentageCustomerChange = store.stats?.active_customers.percentage_change;
		return (
			<Main title="Home">
				<div className="flex flex-col">
					<div className="flex">
						<h1 className="font-bold">Hi {store.uiStore.currentUser?.name},</h1>
					</div>
					<div className="flex">
						<p className="text-sm text-gray-500">Here is what is going on today</p>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
						<Stat title="Open Orders (Unfulfilled)" onClick={() => store.uiStore.goToOrders()}>
							<StatMetric value={store.stats?.open_orders} color='green' />
						</Stat>
						<Stat title="Active Customers" onClick={() => store.uiStore.goToCustomers()}>
							<StatMetric 
									value={store.stats?.active_customers?.count} 
									percentageChange={`${percentageCustomerChange}% vs last month`} 
									color='green'
									percentageChangeColor={percentageCustomerChange > 0 ? 'green' : 'red'}  />
						</Stat>
						<Stat title="Active Accounts" onClick={() => store.uiStore.goToAccounts()}>
							<AccountsByStatus accountStatusCounts={store.stats?.accounts_by_account_status} />
						</Stat>
						<Stat title="Servicing Due" onClick={() => store.uiStore.goToServicing()}>
							<StatMetric value={store.stats?.servicing_due} color='green' />
						</Stat>
						<Stat title="Driver Tasks Remaining (Today)" onClick={() => store.uiStore.goToJourneyPlanner()}>
							<DriverDeliveryRemaining driverTasks={store.stats?.driver_delivery_tasks} />
						</Stat>
						<Stat title="Deliveries Due By Today">
							<LateDeliveries 
								deliveriesDueToday={store.stats?.deliveries_due_by_today} 
								goToOrder={(orderId) => store.uiStore.goToEditOrder(orderId)}
								/>
						</Stat>

					</div>

				</div>
			</Main>
		)
	}
}

@observer
class LateDeliveries extends Component{
	render(){
		let deliveriesDueToday = this.props.deliveriesDueToday;
		if(deliveriesDueToday == null || deliveriesDueToday.length == 0){
			return (
				<div className='flex flex-1 flex-row justify-center items-center'>
					<NoDataMessage message="No deliveries due" />
				</div>
			)
		}
		return (
			<table className="table-fixed">
				<thead>
					<tr>
						<th className={leftStyle}>Order</th>
						<th className={rightStyle}>Due</th>
					</tr>
				</thead>
				<tbody>
					{deliveriesDueToday?.map((deliveryDue) => 
						<tr>
							<td className={leftStyle} onClick={() => this.props.goToOrder(deliveryDue.order_id)}>
								<div className='cursor-pointer hover:text-blue-500'>Order #{deliveryDue.order_id}</div> </td>
							<td className={rightStyle}>
								<div className="flex flex-col items-end">
									<div className="flex">{deliveryDue.requested_delivery_date_formatted}</div>
									<div className="flex"><span className="text-xs">{deliveryDue.fromNow}</span></div>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		)
	}
}

@observer
class AccountsByStatus extends Component{
	render(){
		let accountsByAccountStatus = this.props.accountStatusCounts;
		if(accountsByAccountStatus == null || accountsByAccountStatus.length == 0){
			return (
				<div className='flex flex-1 flex-row justify-center items-center'>
					<NoDataMessage message="No accounts found" />
				</div>
			)
		}
		return (
			<table className="table-fixed">
				<thead>
					<tr>
						<th className={leftStyle}>Account Status</th>
						<th className={rightStyle}>Count</th>
					</tr>
				</thead>
				<tbody>
					{accountsByAccountStatus?.map((accountStatusEntry) => 
						<tr>
							<td className={leftStyle}>{accountStatusEntry.account_status} </td>
							<td className={rightStyle}>{accountStatusEntry.count}</td>
						</tr>
					)}
				</tbody>
			</table>
		)
	}
}

class NoDataMessage extends Component{
	render(){
		return (
			<div className="flex flex-row items-center">
				<div className="flex"><ExclamationIcon color='gray-700' /></div>
				<span className='text-sm text-gray-700'> {this.props.message}</span>
			</div>
		)
	}
}

@observer
class DriverDeliveryRemaining extends Component{
	render(){
		let driverTasks = this.props.driverTasks;
		if(driverTasks == null || driverTasks.length == 0){
			return (
				<div className='flex flex-1 flex-row justify-center items-center'>
					<NoDataMessage message="No driver tasks found" />
				</div>
			)
		}
		return (
			<table className="table-fixed">
				<thead>
					<tr>
						<th className={leftStyle}>Driver</th>
						<th className={rightStyle}>Tasks Left</th>
					</tr>
				</thead>
				<tbody>
					{driverTasks?.map((driverTask) => 
						<tr>
							<td className={leftStyle}>{driverTask.driver.name} </td>
							<td className={rightStyle}>{driverTask.count}</td>
						</tr>
					)}
				</tbody>
			</table>
		)
	}
}

@observer
class ActiveLineChart extends Component{
	render(){
		return (
			<ResponsiveContainer>
				<LineChart data={this.props.data} >
					<Line type="monotone" 
						dataKey={this.props.dataAttribute} 
						stroke={this.props.strokeColor}
						 />
					<Tooltip />
				</LineChart>
			</ResponsiveContainer>
		)
	}
}

export default Home;