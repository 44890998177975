import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, Item, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('podList')
@observer
class PODs extends Component{
	constructor(props){
		super(props);
		this.renderListHeaders = this.renderListHeaders.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let podList = this.props.podList;
		podList.fetchPODs();
	}

	componentWillUnmount(){
		let podList = this.props.podList;
		podList.initStore();
	}

	onPreviousPage(){
		let podList = this.props.podList;
		podList.onPreviousPage();
	}

	onNextPage(){
		let podList = this.props.podList;
		podList.onNextPage();
	}

	renderListHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-2">POD Reference</div>
				<div className="sm:col-span-1">Driver</div>
				<div className="sm:col-span-1">Account</div>
				<div className="sm:col-span-2">Order</div>
			</ListItem>
		)
	}

	renderListItem(item){
		let podList = this.props.podList;
		return (
			<ListItem gridCols={6} onEdit={() => podList.onEdit(item.id)}>
				<div className="sm:col-span-2">
					<Item text={item.pod_reference} />
				</div>
				<div className="sm:col-span-1"><Item text={item.driver?.name} /></div>
				<div className="sm:col-span-1"><ItemColumn text={item.account?.name} subtext={item.account?.account_reference} /></div>
				<div className="sm:col-span-2"><Item text={`#${item.order?.id}`} /></div>
				
			</ListItem>
		)
	}

	render(){
		let podList = this.props.podList;
		return (
			<Main>
				<Card title="PODs" 
					search={
					  	<SearchForm 
					  		placeholder="Search by POD Reference"
					  		onChange={(e) => podList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => podList.onNewPOD()}>New POD</Button>
					}>

					<List 
						type="proof of deliveries"
						items={podList.pods}
						renderListItem={this.renderListItem}
						renderListHeaders={this.renderListHeaders}
						page={podList.page}
						total={podList.total}
						hasNext={podList.hasNext}
						hasPrev={podList.hasPrev}
						offsetStart={podList.offsetStart}
						offsetEnd={podList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(PODs, ['administrator', 'employee', 'accountant']);