import BaseApi from '../services/base';

class ProductApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, searchText=null){
		let url = `v1/products?page=${page}`
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	getAllProducts = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page);
			let data = response.products;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getProductById(productId){
		return this.fetch(`v1/products/${productId}`, {
			method: 'GET',
			auth: true
		})
	}

	getProductCategories(page){
		return this.fetch(`v1/products/categories?page=${page}`, {
			method: 'GET',
			auth: true
		})
	}

	newProduct(product){
		return this.fetch('v1/products', {
			method: 'POST',
			auth: true,
			body: JSON.stringify(product)
		})
	}

	updateProduct(id, product){
		return this.fetch(`v1/products/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(product)
		})
	}

	getAllProductCategories = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getProductCategories(page);
			let data = response.product_categories;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	
}

export default ProductApi;