import { createHistory } from 'history';
import { Router } from 'director/build/director';
import { autorun } from 'mobx';

export function startRouter(store) {
	let routes = {
    	"/login": () => store.goToLogin(),
        "/home": () => store.goToHome(),
        
        "/password/forgot": () => store.goToForgottenPassword(),
        "/password/reset/:token": (token) => store.goToResetPassword(token),
        
        "/customers": () => store.goToCustomers(),
        "/customers/new": () => store.goToNewCustomer(),
        "/customers/:customerId": (customerId) => store.goToEditCustomer(customerId),
        

        "/accounts": () => store.goToAccounts(),
        "/accounts/new": () => store.goToNewAccount(),
        "/accounts/:accountId": (accountId) => store.goToEditAccount(accountId),

        "/products/:productId": (productId) => store.goToEditProduct(productId),
        "/products": () => store.goToProducts(),
        "/products/new": () => store.goToNewProduct(),
        
        "/users/:userId": (userId) => store.goToEditUser(userId),
        "/users": () => store.goToUsers(),
        
        
        "/assets": () => store.goToAssets(),
        "/assets/new": () => store.goToNewAsset(),
        "/assets/:assetId": (assetId) => store.goToEditAsset(assetId),
        
        
        "/services": () => store.goToServices(),
        "/services/new": () => store.goToNewService(),
        "/services/:serviceId": (serviceId) => store.goToEditService(serviceId),

        "/orders": () => store.goToOrders(),
        "/orders/new": () => store.goToNewOrder(),
        "/orders/:orderId": (orderId) => store.goToEditOrder(orderId),

        "/invoices": () => store.goToInvoices(),
        "/invoices/:invoiceId": (invoiceId) => store.goToEditInvoice(invoiceId),

        "/credit-notes": () => store.goToCreditNotes(),
        "/credit-notes/:creditNoteId": (creditNoteId) => store.goToEditCreditNote(creditNoteId),
        
        "/pods": () => store.goToPODs(),
        "/pods/new": () => store.goToNewPOD(),
        "/pods/:podId": (podId) => store.goToEditPOD(podId),
        

        "/agreements": () => store.goToAgreements(),
        "/agreements/new": () => store.goToNewAgreement(),
        "/agreements/:agreementId": (agreementId) => store.goToEditAgreement(agreementId),

        "/invites/accept/:token" : (token) => store.goToAcceptInvite(token),

        "/servicing": () => store.goToServicing(),
        
        "/journeys": () => store.goToJourneys(),
        "/journeys/planner": () => store.goToJourneyPlanner(),
        "/journeys/new": () => store.goToNewJourney(),
        "/journeys/:journeyId": (journeyId) => store.goToEditJourney(journeyId),
        
        "/reports": () => store.goToReports(),

        
    };
    // update state on url change
    const router = new Router(routes);
    router
    	.configure({
	        notfound: () => store.goToHome(),
	        html5history: true
	    })
	    .init();

     autorun(() => {
            const path = store.currentPath;
            if (path !== window.location.pathname){
                window.history.pushState(null, null, path)
            }
        })
}