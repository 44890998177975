import { observable } from 'mobx';

import InvoiceApi from '../../services/invoice';

import moment from 'moment';

class ManageInvoice{

	id;
	@observable currentInvoice;
	@observable fetching;

	@observable emailing;
	@observable downloadingPDF;

	invoiceApi;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.invoiceApi = new InvoiceApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.currentInvoice = null;
		this.fetching = false;
		this.emailing = false;
		this.downloadingPDF = false;
	}

	formSync(invoiceId){
		this.id = invoiceId;
		let timezone = this.uiStore.timezone;
		this.invoiceApi.getInvoiceById(invoiceId)
			.then((response) => {
				let invoice = response.invoice;
				invoice.invoice_details.invoice_date = moment(invoice.invoice_details.invoice_date);
				if(invoice.proof_of_delivery != null){
					invoice.proof_of_delivery.delivered_on = moment(invoice.proof_of_delivery.delivered_on).tz(timezone);
					invoice.proof_of_delivery.created_on = moment(invoice.proof_of_delivery.created_on).tz(timezone);
				}
				this.currentInvoice = invoice;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	emailInvoice(){
		if(this.id == null) return;

		this.emailing = true;
		this.invoiceApi.emailInvoice(this.id)
			.then((response) => {
				if(response.invoice_sent){
					this.uiStore.alertSuccess('Invoice emailed successfully');
				}else{
					this.uiStore.alertSuccess('Error sending email');
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.emailing = false;
			})
	}

	downloadInvoicePDF(){
		if(this.currentInvoice == null) return;
		if(this.downloadingPDF) return;
		this.downloadingPDF = true;

		this.invoiceApi.getInvoicePDFById(this.id)
			.then((response) => {
				const link = document.createElement('a');
		        const url = URL.createObjectURL(response)
		        link.download = `Invoice-${this.currentInvoice.invoice_reference}.pdf`;
		        link.href = url;
		        document.body.appendChild(link);
		        link.click();
		        document.body.removeChild(link);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.downloadingPDF = false;
			})
	}
}

export default ManageInvoice;