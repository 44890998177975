import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import {SideBar, DropdownNav} from './nav/nav';
import {Container,InnerContainer, PageTitle, SearchForm, UserAvatar} from './elements/ui';
import {BellIcon} from './common/icon';

@inject('ui')
@observer
class Main extends Component{
	constructor(props){
		super(props);
	}

	componentDidMount(){

	}

	componentWillUnmount(){

	}

	render(){
		let uiStore = this.props.ui;
		return (
			<div className="h-screen flex overflow-hidden bg-gray-100">
			  <SideBar />
			  <div className="flex flex-1 flex-col w-0 h-screen overflow-hidden">
			    <div className="relative flex-shrink-0 flex h-16 bg-white shadow">
			      <button onClick={() => uiStore.toggleMobileNavbar()} className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden" aria-label="Open sidebar">
			        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
			          <path stripelinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
			        </svg>
			      </button>
			      <div className="flex-1 px-4 flex justify-between">
			        <div className="flex-1 flex">
			        </div>
			        <div className="ml-4 flex items-center md:ml-6">
			          <div className="ml-3 relative">
			            <div>
			              <UserAvatar />
			            </div>
			            <DropdownNav />
			          </div>
			        </div>
			      </div>
			    </div>
			    <Container>
			      <InnerContainer>
			        {this.props.children}
			      </InnerContainer>
			    </Container>
			  </div>
			</div>
		)
	}
}

export default Main;