import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {TextInput, TextArea, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {DeleteIcon} from '../common/icon';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';

import Authorisation from '../authorisation';

@inject('manageService')
@observer
class ManageService extends Component{
	constructor(props){
		super(props);
		this.renderPriceListHeaders = this.renderPriceListHeaders.bind(this);
		this.renderPriceListItem = this.renderPriceListItem.bind(this);
	}

	componentDidMount(){
		let manageService = this.props.manageService;
		manageService.fetchVATRates();
		manageService.fetchCurrencies();
		if(this.props.viewMode){
			manageService.formSync(this.props.serviceId);
		}
	}

	componentWillUnmount(){
		let manageService = this.props.manageService;
		manageService.initStore()
	}

	renderPriceListHeaders(){
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-2">Recurring</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderPriceListItem(item){
		let manageService = this.props.manageService;
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => manageService.changePriceListItemCurrency(item.uuid, val)}
           			placeholder="Choose price currency"
           			disabled={manageService.viewMode}
	           		options={manageService.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter product price"
	              	onChange={(val) => manageService.changePriceListItemPrice(item.uuid, val)}
	              	disabled={manageService.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => manageService.changePriceListItemVATRate(item.uuid, val)}
           			placeholder="Choose vat rate"
           			disabled={manageService.viewMode}
	           		options={manageService.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-2">
	              	<div className="flex h-full flex-row items-center">
		              	<CheckBox 
				        	disabled={manageService.viewMode}
				        	label={item.recurring.value ? null : 'Recurring charge?'}
				        	checked={item.recurring.value} 
				        	onChange={(e) => manageService.changePriceListItemRecurring(item.uuid, e.target.checked)} />
		              	{item.recurring.value && 
			              	<TextInput 
				              	type="number"
				              	placeholder="Charge every X days"
				              	onChange={(val) => manageService.changePriceListItemDays(item.uuid, val)}
				              	disabled={manageService.viewMode}
				            	value={item.days.value} 
				            	error={item.days.error}/>
					    }
					</div>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageService.fetching} bgColor="transparent" fgColor='white' disabled={manageService.viewMode} className="flex mt-1" onClick={() => manageService.deletePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}
	
	render(){
		let manageService = this.props.manageService;
		return (
			<Main>
				<div>
					<div className="flex flex-row flex-1 items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {manageService.name.value.length > 0 ? manageService.name.value : 'Service Information'}
					        </Heading>
					        <Paragraph>
					          Service Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageService.viewMode && 
					    		<Button fetching={manageService.fetching} onClick={() => manageService.toggleEditMode()}>
						          Edit Service
						        </Button>
						    }
					        {!manageService.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton fetching={manageService.fetching} onClick={() => manageService.onCancel()} fetching={manageService.fetching}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button disabled={!manageService.validForm} fetching={manageService.fetching} onClick={() => manageService.save()} fetching={manageService.fetching}>
								          {manageService.editMode ? 'Update' : 'Save' } Service
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
					<div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						<div className="sm:col-span-3">
				          <Label>
				            Service Name
				          </Label>
			              <TextInput 
			              	disabled={manageService.viewMode}
			            	onChange={(val) => manageService.name.onChange(val)}
			            	value={manageService.name.value} 
			            	error={manageService.name.error} />
				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            Service Reference
				          </Label>
			              <TextInput 
			              	disabled={manageService.viewMode}
			            	onChange={(val) => manageService.serviceReference.onChange(val)}
			            	value={manageService.serviceReference.value} 
			            	error={manageService.serviceReference.error} />
				        </div>
					</div>
					<FormDivider>
						<Card title="Price List" subtitle="Set default prices for services" actionBtn={
							<Button disabled={manageService.viewMode} onClick={() => manageService.addPriceListItemEntry()}>Add New Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderPriceListHeaders}
					      		renderListItem={this.renderPriceListItem}
					      		items={manageService.priceList}
							/>
				    	</Card>
				    </FormDivider>
				</div>
			</Main>
		)
	}
}

export default Authorisation(ManageService, ['administrator', 'employee', 'accountant']);