import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPostcodeValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import CommonApi from '../../services/common';
import CustomerApi from '../../services/customer';
import AccountApi from '../../services/account';
import ProductApi from '../../services/product';
import AssetApi from '../../services/asset';
import ServiceApi from '../../services/service';

import uuidv4 from 'uuid';
import moment from 'moment';
import {buildAddressFromGoogleAddress} from '../../services/util';
import { uuid } from 'uuidv4';

class ManageCustomer{
	commonApi;
	customerApi;
	accountApi;
	productApi;
	serviceApi;
	assetApi;

	@observable addMode;
	@observable viewMode;
	@observable editMode;

	id;
	@observable name;
	@observable customerReference;
	@observable searchAddress;
	@observable streetAddress;
	@observable city;
	@observable county;
	@observable postcode;
	@observable latitude;
	@observable longitude;
	@observable archived;
	
	/* Only used when adding primary contact */
	@observable contactName;
	@observable contactEmail;
	@observable contactJobTitle;
	@observable contactPhoneNumber;
	@observable contactFaxNumber;
	@observable contactMobilePhoneNumber;

	@observable countries;
	@observable selectedCountry;

	@observable customerAccounts;
	@observable contacts;

	@observable products;
	@observable vatRates;
	@observable assets;
	@observable services;
	@observable currencies;

	@observable priceList;
	@observable assetPurchasePriceList;
	@observable assetRentalPriceList;
	@observable servicePriceList;

	@observable fetching;

	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.commonApi = new CommonApi(this.uiStore);
		this.customerApi = new CustomerApi(this.uiStore);
		this.accountApi = new AccountApi(this.uiStore);
		this.productApi = new ProductApi(this.uiStore);
		this.assetApi = new AssetApi(this.uiStore);
		this.serviceApi = new ServiceApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.id = null;
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.customerReference = new FieldState('').validators((val) => isRequiredValidator(val, 'customer reference'));
		this.searchAddress = new FieldState('');
		this.streetAddress = new FieldState('').validators((val) => isRequiredValidator(val, 'street address'));
		this.city = new FieldState('').validators((val) => isRequiredValidator(val, 'city'));
		this.county = new FieldState('').validators((val) => isRequiredValidator(val, 'county'));
		this.postcode = new FieldState('').validators((val) => isPostcodeValidator(val));
		this.latitude = new FieldState(null);
		this.longitude = new FieldState(null);
		this.contactName = new FieldState('').validators((val) => isRequiredValidator(val, 'contact name'));
		this.contactEmail = new FieldState('').validators(isEmailValidator);
		this.contactJobTitle = new FieldState('').validators((val) => isRequiredValidator(val, 'job title'));
		this.contactPhoneNumber = new FieldState('').validators((val) => isRequiredValidator(val, 'contact phone number'));
		this.contactFaxNumber = new FieldState('');
		this.contactMobilePhoneNumber = new FieldState('');
		this.selectedCountry = new FieldState(null).validators((val) => isNotNullValidator(val, 'country'));

		this.form = new FormState({
			name: this.name,
			customerReference: this.customerReference,
			streetAddress: this.streetAddress,
			city: this.city,
			county: this.county,
			postcode: this.postcode,
			selectedCountry: this.selectedCountry
		});


		this.archived = false;
		this.countries = [];
		this.fetching = false;
		this.customerAccounts = {
			accounts: [],
			page: 1,
			total: 0,
			hasNext: false,
			hasPrev: false,
			offsetStart: null,
			offsetEnd: null
		};

		this.contacts = [];
		this.priceList = [];
		this.assetPurchasePriceList = [];
		this.assetRentalPriceList = [];
		this.servicePriceList = [];
		this.products = [];
		this.vatRates = [];
		this.assets = [];
		this.services = [];
		this.currencies = [];
	}


	@action saveCustomer = async () => {
		const res = await this.form.validate();
	    if(res.hasError) return;


	    if(this.addMode){
	    	let contactForm = new FormState({
		    	name: this.contactName,
		    	email: this.contactEmail,
		    	jobTitle: this.contactJobTitle,
		    	phoneNumber: this.contactPhoneNumber,
		    	faxNumber: this.contactFaxNumber,
		    	mobilePhoneNumber: this.contactMobilePhoneNumber
		    });
		    const contactFormRes = await contactForm.validate();
		    if(contactFormRes.hasError) return;
		} else{
			let invalidContactFormEntry = false;
			for(let contact of this.contacts){

				const contactFormRes = await new FormState({
					name: contact.name,
					jobTitle: contact.jobTitle,
					email: contact.email,
					phoneNumber: contact.phoneNumber,
					faxNumber: contact.faxNumber
				}).validate();
		    	if(contactFormRes.hasError){
		    		invalidContactFormEntry = true;
		    		break;
		    	}
			}

			if(invalidContactFormEntry){
				return;
			}
			
		}


	    let customerJSON = this.toJSON();

	    this.fetching = true;
	    if(this.editMode){
	    	this.customerApi.updateCustomer(this.id, customerJSON)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Customer updated.');
	    			this.uiStore.goToCustomers();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }else{
	    	this.customerApi.newCustomer(customerJSON)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Customer added.');
	    			this.uiStore.goToCustomers();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	formSync(customerId){
		this.addMode = false;
		this.viewMode = true;

		this.id = customerId;
		this.fetching = true;
		this.customerApi.getById(customerId)
			.then((response) => {
				let customer = response.customer;
				this.id = customer.id;
				this.name.onChange(customer.name);
				this.customerReference.onChange(customer.customer_reference);
				this.streetAddress.onChange(customer.street_address);
				this.city.onChange(customer.city);
				this.county.onChange(customer.county);
				this.postcode.onChange(customer.postcode);
				this.selectedCountry.value = customer.country_id;
				this.latitude.value = customer.latitude;
				this.longitude.value = customer.longitude;
				this.archived = customer.archived;
				this.contacts = customer.contacts.map((contact) => {
					return this.generateCustomerContact(contact.id, contact.name, contact.job_title, contact.email, contact.phone_number, contact.mobile_phone_number, contact.fax_number, contact.primary_contact);
				});
				this.priceList = customer.product_prices.map((priceItem) => {
					return this.generatePriceListEntry(priceItem.id, priceItem.product.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price)
				});

				this.assetRentalPriceList = customer.asset_rental_prices.map((priceItem) => {
					return this.generateAssetRentalPriceListEntry(priceItem.id, priceItem.asset.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price, priceItem.days)
				})
				this.assetPurchasePriceList = customer.asset_purchase_prices.map((priceItem) => {
					return this.generateAssetPurchasePriceListEntry(priceItem.id, priceItem.asset.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price)
				})
				this.servicePriceList = customer.service_prices.map((priceItem) => {
					return this.generateServicePriceListEntry(priceItem.id, priceItem.service.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price, priceItem.recurring, priceItem.recurring_days)
				})
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			});

		this.fetchCustomerAccounts();
	}

	fetchProducts = async () => {
		try{
			this.products  = await this.productApi.getAllProducts();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchAssets = async () => {
		try{
			this.assets  = await this.assetApi.fetchAllAssets();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchServices = async () => {
		try{
			this.services  = await this.serviceApi.fetchAllServices();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	@action fetchCustomerAccounts(){
		this.accountApi.getAccountsForCustomer(this.id, this.customerAccounts.page)
			.then((response) => {
				this.customerAccounts.accounts = response.accounts;
				this.customerAccounts.hasNext = response.has_next;
				this.customerAccounts.hasPrev = response.has_prev;
				this.customerAccounts.page = response.page;
				this.customerAccounts.offsetStart = response.offset_start;
				this.customerAccounts.offsetEnd = response.offset_end;
				this.customerAccounts.total = response.total;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onPreviousAccountsPage(){
		this.customerAccounts.page = Math.max(this.customerAccounts.page-1, 0);
		this.fetchCustomerAccounts();
	}

	@action onNextAccountsPage(){
		this.customerAccounts.page += 1;
		this.fetchCustomerAccounts();
	}

	@action onEditAccount(id){
		this.uiStore.goToEditAccount(id);
	}

	fetchCountries(){
		return this.commonApi.getAllCountries()
			.then((response) => {
				this.countries = response.countries;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@computed get countryOptions(){
		return this.countries.map((x) => {
			return {
				value: x.id,
				label: x.name
			}
		})
	}

	@action onChangeCountry(val){
		this.selectedCountry.value = val;
	}

	@action onChangeCustomerName(val){
		this.name.onChange(val);
	}

	@action onChangeCustomerReference(val){
		this.customerReference.onChange(val);
	}

	@action onChangeStreetAddress(val){
		this.streetAddress.onChange(val);
	}

	@action onChangePostcode(val){
		this.postcode.onChange(val);
	}

	@action onChangeCity(val){
		this.city.onChange(val);
	}

	@action onChangeCounty(val){
		this.county.onChange(val);
	}

	@action onChangeArchived(val){
		this.archived = val;
	}

	@action onChangeContactName(val){
		this.contactName.onChange(val);
	}

	@action onChangeContactEmail(val){
		this.contactEmail.onChange(val);
	}

	@action onChangeContactPhoneNumber(val){
		this.contactPhoneNumber.onChange(val);
	}

	@action onChangeContactFaxNumber(val){
		this.contactFaxNumber.onChange(val);
	}

	@action onChangeContactJobTitle(val){
		this.contactJobTitle.onChange(val);
	}

	@action onCancel(){
		this.uiStore.goToCustomers();
	}

	@action onNewAccount(){
		this.uiStore.goToNewAccount(this.id);
	}

	toJSON(){
		let contacts = [];
		if(this.addMode){
			contacts.push({
				name: this.contactName.value,
				email: this.contactEmail.value,
				job_title: this.contactJobTitle.value,
				phone_number: this.contactPhoneNumber.value,
				fax_number: this.contactFaxNumber.value,
				mobile_phone_number: this.contactMobilePhoneNumber.value,
				primary_contact: true
			});
		}else{
			contacts = this.contacts.map((contact) => {
				return {
					name: contact.name.value,
					email: contact.email.value,
					job_title: contact.jobTitle.value,
					phone_number: contact.phoneNumber.value,
					fax_number: contact.faxNumber.value,
					mobile_phone_number: contact.mobilePhoneNumber.value,
					primary_contact: contact.primaryContact.value
				}
			});
		}

		let customerJSON = {
			name: this.name.value,
			customer_reference: this.customerReference.value,
			street_address: this.streetAddress.value,
			city: this.city.value,
			county: this.county.value,
			country_id: this.selectedCountry.value,
			archived: this.archived,
			postcode: this.postcode.value == '' ? null : this.postcode.value,
			latitude: this.latitude.value,
			longitude: this.longitude.value,
			contacts: contacts,
			product_prices: this.priceList.map((priceListEntry) => {
				return {
					id: priceListEntry.id,
					product_id: priceListEntry.selectedProduct.value,
					price: parseFloat(priceListEntry.price.value).toFixed(4),
					currency_id: parseInt(priceListEntry.selectedCurrency.value),
					vat_rate_id: parseInt(priceListEntry.selectedVATRate.value)
				}
			}),
			asset_rental_prices: this.assetRentalPriceList.map((rentalPrice) => {
				return {
					id: rentalPrice.id,
					asset_id: rentalPrice.selectedAsset.value,
					currency_id: rentalPrice.selectedCurrency.value,
					vat_rate_id: rentalPrice.selectedVATRate.value,
					price: parseFloat(rentalPrice.price.value).toFixed(4),
					days: parseInt(rentalPrice.days.value, 10)
				}
			}),
			asset_purchase_prices: this.assetPurchasePriceList.map((purchasePrice) => {
				return {
					id: purchasePrice.id,
					asset_id: purchasePrice.selectedAsset.value,
					currency_id: purchasePrice.selectedCurrency.value,
					vat_rate_id: purchasePrice.selectedVATRate.value,
					price: parseFloat(purchasePrice.price.value).toFixed(4)
				}
			}),
			service_prices: this.servicePriceList.map((servicePriceListEntry) => {
				return {
					id: servicePriceListEntry.id,
					service_id: servicePriceListEntry.selectedService.value,
					currency_id: servicePriceListEntry.selectedCurrency.value,
					vat_rate_id: servicePriceListEntry.selectedVATRate.value,
					price: parseFloat(servicePriceListEntry.price.value).toFixed(4),
					recurring: servicePriceListEntry.recurring.value,
					recurring_days: parseInt(servicePriceListEntry.days.value, 10)
				}
			})
		}
		return customerJSON;
	}

	@computed get productOptions(){
		return this.products.map((product) => {
			return {
				label: product.name,
				value: product.id
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				label: `${currency.name} (${currency.symbol})`,
				value: currency.id
			}
		})
	}


	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: vatRate.id,
				label: vatRate.name
			}
		})
	}

	@computed get assetOptions(){
		return this.assets.map((asset) => {
			return {
				value: asset.id,
				label: asset.name
			}
		})
	}

	@computed get serviceOptions(){
		return this.services.map((service) => {
			return {
				value: service.id,
				label: service.name
			}
		})
	}

	@action addPriceListEntry(){
		this.priceList.push(this.generatePriceListEntry())
	}

	@action addAssetPurchasePriceListEntry(){
		this.assetPurchasePriceList.push(this.generateAssetPurchasePriceListEntry())
	}

	@action addAssetRentalPriceListEntry(){
		this.assetRentalPriceList.push(this.generateAssetRentalPriceListEntry())
	}

	@action addServicePriceListEntry(){
		this.servicePriceList.push(this.generateServicePriceListEntry())
	}

	@action addContactListEntry(){
		this.contacts.push(this.generateCustomerContact())
	}

	deletePriceListItem(uuid){
		this.priceList = this.priceList.filter((p) => p.uuid != uuid);
	}

	deleteAssetPurchasePriceListItem(uuid){
		this.assetPurchasePriceList = this.assetPurchasePriceList.filter((p) => p.uuid != uuid);
	}

	deleteAssetRentalPriceListItem(uuid){
		this.assetRentalPriceList = this.assetRentalPriceList.filter((p) => p.uuid != uuid);
	}

	deleteServicePriceListItem(uuid){
		this.servicePriceList = this.servicePriceList.filter((p) => p.uuid != uuid);
	}

	@action generatePriceListEntry(id=null, productId=null, currencyId=null, vatRateId=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedProduct: new FieldState(productId).validators((val) => isNotNullValidator(val, 'product')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				return `${currency.symbol}${totalPrice}`
			}
		})
	}

	@action generateCustomerContact(id=null, name=null, jobTitle=null, email=null, phoneNumber=null, mobilePhoneNumber=null, faxNumber=null, primaryContact=false){
		return observable.object({
			id: id,
			uuid: uuid(),
			name: new FieldState(name).validators((val) => isRequiredValidator(val, 'name')),
			jobTitle: new FieldState(jobTitle).validators((val) => isRequiredValidator(val, 'job title')),
			email: new FieldState(email).validators(isEmailValidator),
			phoneNumber: new FieldState(phoneNumber).validators((val) => isRequiredValidator(val, 'phone number')),
			mobilePhoneNumber: new FieldState(mobilePhoneNumber),
			faxNumber: new FieldState(faxNumber),
			primaryContact: new FieldState(primaryContact),
		})
	}

	deleteContactListItem(uuid){
		this.contacts = this.contacts.filter((c) => c.uuid != uuid);
	}

	markContactListItemAsPrimary(uuid){
		let contactListItemIdx = this.contacts.findIndex((c) => c.uuid == uuid);
		if(contactListItemIdx != -1){
			for(let i = 0; i < this.contacts.length; i++){
				this.contacts[i].primaryContact.onChange(false);
			}

			this.contacts[contactListItemIdx].primaryContact.onChange(true);
		}
	}

	@action generateAssetPurchasePriceListEntry(id=null, assetId=null, currencyId=null, vatRateId=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedAsset.value == null || this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				return `${currency.symbol}${totalPrice}`
			}
		})
	}

	@action generateAssetRentalPriceListEntry(id=null, assetId=null, currencyId=null, vatRateId=null, price=null, days=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedAsset.value == null || this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null || this.days.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				if(this.days.error != undefined) return null;
				let days = parseInt(this.days.value, 10);
				return `${currency.symbol}${totalPrice} every ${days} ${days > 1 ? 'days' : 'day'}`
			}
		})
	}

	@action generateServicePriceListEntry(id=null, serviceId=null, currencyId=null, vatRateId=null, price=null, recurring=false, days=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedService: new FieldState(serviceId).validators((val) => isNotNullValidator(val, 'service')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			recurring: new FieldState(recurring).validators((val) => isNotNullValidator(val, 'recurring')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators((val) => val != null && isPositiveNumberValidator(val)),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				let isRecurring = this.recurring.value;
				if(isRecurring && this.days.error != undefined) return null;
				let priceMsg = `${currency.symbol}${totalPrice}`
				let days = this.days.value;
				if(isRecurring && days != null){
					priceMsg = `${priceMsg} every ${days} ${days > 1 ? 'days' : 'day'}`
				}
				return priceMsg
			}
		})
	}

	@action receivedCustomerAddressCoords(coords){
		this.latitude.value = coords?.lat;
		this.longitude.value = coords?.lng;
	}

	@action onReceivedCustomerAddressComponents(streetAddress, addressComponents){
		let address = buildAddressFromGoogleAddress(addressComponents);
		this.streetAddress.value = streetAddress;
		this.city.value = address.city != null ? address.city : '';

		let countryCode = address.country;
		if(countryCode != null){
			let matchingCountry = this.countries.find((c) => c.iso_alpha_3_code == countryCode);
			if(matchingCountry != null){
				this.selectedCountry.value = matchingCountry.id;
			}else{
				this.selectedCountry.value = null;
			}
		}else{
			this.selectedCountry.value = null;
		}
		
		this.county.value = address.county != null ? address.county: '';
		this.postcode.value = address.postal_code != null ? address.postal_code : '';
	}

	@computed get customerAddressCoords(){
		if(!this.hasCustomerAddressCoords) return null;
		return {
			latitude: this.latitude.value,
			longitude: this.longitude.value,
		}
	}

	@computed get hasCustomerAddressCoords(){
		return this.latitude.value != null && this.longitude.value != null;
	}

	@computed get customerAddressLocationMarkers(){
		if(this.hasCustomerAddressCoords){
			return [{
				id:0,
				latitude: this.latitude.value,
				longitude: this.longitude.value
			}]
		}else{
			return [];
		}
	}
}

export default ManageCustomer;