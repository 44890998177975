import React, {Component} from 'react';
import {observer} from 'mobx-react';
const { compose, withProps, withStateHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker, 
  Polyline
} = require("react-google-maps");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

@observer
class HoverableMarker extends Component {
    constructor(props){
      super(props);
    }
    state = {
        showInfoBox: false
    };
    handleMouseOver = e => {
        this.setState({
            showInfoBox: true
        });
    };
    handleMouseExit = e => {
        this.setState({
            showInfoBox: false
        });
    };


    render() {
        const { showInfoBox } = this.state;
        let markerData = this.props.markerData;
        let renderedMouseover = this.props.renderMarkerData(markerData);
        let draggable = 'onDragEnd' in this.props && this.props.onDragEnd != null ? true : false;

        let highlighted = markerData.highlighted;
        let showHoverBox = showInfoBox || highlighted;
        let icon = null;
        if('iconUrl' in markerData){
          icon = {
            url: markerData.iconUrl,
            scaledSize: {width: 32, height: 32}
          }
        }
       
        return (
            <Marker icon={icon} onDragEnd={this.props.onDragEnd} draggable={draggable} position={{ lat: markerData.latitude, lng:markerData.longitude }} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseExit}>
                {showHoverBox && (
                    <InfoBox
      				        options={{ closeBoxURL: ``, enableEventPropagation: true }}
      				      >
      				        <div style={{ backgroundColor: '#576574',  borderRadius: '5px', width: '150px', opacity: 0.95, padding: `12px` }}>
      				          <div style={{ fontSize: `12px`, color: 'white' }}>
      				          	{renderedMouseover}
      				          </div>
      				        </div>
      			      	</InfoBox>
                )}
            </Marker>
        );
    }
}

const GoogleMarkerMap = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`}} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(props => {
    let zoomLevel = 'zoomLevel' in props ? props.zoomLevel : 5;

    let centreCoords = { lat: 54.5973, lng: -5.9301 };
    if(props.coords != null){
      centreCoords = { lat: props.coords.latitude, lng: props.coords.longitude}
    }

    if(props.centreLatitude != null && props.centreLongitude != null){
      centreCoords = {
        lat: props.centreLatitude,
        lng: props.centreLongitude 
      }
    }


    return (
        <GoogleMap
      	center={centreCoords}
        defaultZoom={zoomLevel}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {props.markers.map((marker, idx) => (
            <HoverableMarker 
              key={idx} 
              markerData={marker} 
              onDragEnd={props.onDragEnd}
              renderMarkerData={props.renderMarkerData} />
          ))
      	}
        </MarkerClusterer>

        {'path' in props && 
          <Polyline
                path={props.path}
                geodesic={true}
                options={{
                    strokeColor: "#60a5fa",
                    strokeOpacity: 0.75,
                    strokeWeight: 2
                }}
            />
        }
      </GoogleMap>
    )
  }
);

export default GoogleMarkerMap;