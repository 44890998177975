import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed } from 'mobx';
import BaseApi from '../../services/base';

class RegisterApi extends BaseApi{
	constructor(props){
		super(props);
	}

	register(tenant, name, email, password){
		return this.fetch('api/users', {
			method: 'POST',
			body: JSON.stringify({
				tenant: tenant,
				name: name,
				email: email,
				password: password
			})
		})

	}
}

class Register{
	name
	tenant;
	email;
	password;
	confirmPassword;
	@observable submitError;
	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.registerApi = new RegisterApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.name = new FieldState('').validators((val) => !validator.isRequired(val) && 'A name is required');
		this.tenant = new FieldState('').validators((val) => !validator.isRequired(val) && 'A tenant name is required');
		this.email = new FieldState('').validators((val) => !validator.isEmail(val) && 'Please enter a valid email');
		this.password = new FieldState('').validators((val) => !validator.isRequired(val) && 'Please enter a password');
		this.confirmPassword = new FieldState('').validators((val) => !validator.isEqualTo(val, this.password.value) && 'Your passwords do not match');
		this.submitError = '';
		this.form = new FormState({
			tenant: this.tenant,
			name: this.name,
		    email: this.email,
		    password: this.password,
		    confirmPassword: this.confirmPassword
		});
	}


	onRegister = async () => {
	    const res = await this.form.validate();
	    if(res.hasError) return;

	    this.registerApi.register(this.tenant.value, this.name.value, this.email.value, this.password.value)
	    	.then((response) => {
	    		this.uiStore.alertSuccess('Congratulations! You have signed up to Drinkflow.');
	    		let registeringUser = {
	    			name: this.name.value,
	    			email: this.email.value
	    		}
	    		this.uiStore.goToLogin();
	    	})
	    	.catch((error) => {
	    		this.submitError = error.message;
	    	})


	};
}

export default Register;