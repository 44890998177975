import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('serviceList')
@observer
class Service extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let serviceList = this.props.serviceList;
		serviceList.fetchServices();
	}

	componentWillUnmount(){
		let serviceList = this.props.serviceList;
		serviceList.initStore();
	}

	onPreviousPage(){
		let serviceList = this.props.serviceList;
		serviceList.onPreviousPage();
	}

	onNextPage(){
		let serviceList = this.props.serviceList;
		serviceList.onNextPage();
	}

	renderListItem(item){
		let serviceList = this.props.serviceList;
		return (
			<ListItem onEdit={() => serviceList.onEdit(item.id)}>
				<ItemColumn text={item.name} />
			</ListItem>
		)
	}

	render(){
		let serviceList = this.props.serviceList;
		return (
			<Main>
				<Card title="Services" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Service Name"
					  		onChange={(e) => serviceList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => serviceList.onNewService()}>New Service</Button>
					}>
					<List 
						type="service"
						items={serviceList.services}
						renderListItem={this.renderListItem}
						page={serviceList.page}
						total={serviceList.total}
						hasNext={serviceList.hasNext}
						hasPrev={serviceList.hasPrev}
						offsetStart={serviceList.offsetStart}
						offsetEnd={serviceList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Service, ['administrator', 'employee', 'accountant']);