import { observable } from 'mobx';

import CreditNoteApi from '../../services/credit-note';

import moment from 'moment';

class ManageCreditNote{

	id;
	@observable currentCreditNote;
	@observable fetching;

	@observable emailing;

	@observable downloadingPDF;

	creditNoteApi;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.creditNoteApi = new CreditNoteApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.currentCreditNote = null;
		this.fetching = false;
		this.emailing = false;
		this.downloadingPDF = false;
	}

	formSync(creditNoteId){
		this.id = creditNoteId;
		let timezone = this.uiStore.timezone;
		this.creditNoteApi.getCreditNoteById(creditNoteId)
			.then((response) => {
				let creditNote = response.credit_note;
				creditNote.credit_note_details.credit_note_date = moment(creditNote.credit_note_details.credit_note_date);
				if(creditNote.proof_of_delivery != null){
					creditNote.proof_of_delivery.delivered_on = moment(creditNote.proof_of_delivery.delivered_on).tz(timezone);
					creditNote.proof_of_delivery.created_on = moment(creditNote.proof_of_delivery.created_on).tz(timezone);
				}
				this.currentCreditNote = creditNote;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	downloadCreditNotePDF(){
		if(this.currentCreditNote == null) return;
		if(this.downloadingPDF) return;
		this.downloadingPDF = true;

		this.creditNoteApi.getCreditNotePDFById(this.id)
			.then((response) => {
				const link = document.createElement('a');
		        const url = URL.createObjectURL(response)
		        link.download = `CreditNote-${this.currentCreditNote.credit_note_reference}.pdf`;
		        link.href = url;
		        document.body.appendChild(link);
		        link.click();
		        document.body.removeChild(link);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.downloadingPDF = false;
			})
	}
}

export default ManageCreditNote;