import { observable, when,  action, computed, autorun } from 'mobx';
import React from 'react';
import moment from 'moment-timezone';
import 'moment-timezone';

import uuidv4 from 'uuid/v4';
import UserApi from '../services/user';

class UIStore {
    ACCESS_TOKEN_KEY = '@user:token';
    REFRESH_TOKEN_KEY = '@user:refresh_token'
    USER_PROFILE_KEY = '@user:profile'

    SHOW_NAVBAR_LOCAL_STORAGE_KEY = '@user:show_navbar';

	@observable currentView;
	@observable currentUser;
	@observable currentToken;
    @observable currentRefreshToken;
	@observable pendingNetworkRequests;
    @observable alerts; 

    @observable showNavbar;

    @observable showMobileNavbar;

    @observable showDropdown;

    @observable appLoaded;

    // async time
    currentMaxRequestTimeOuts = [];
    currentAsyncTaskIntervals = [];

    userApi;

	constructor(props){
		when(
			() => !this.isAuthenticated,
			() => this.goToLogin()
		);
        this.userApi = new UserApi(this);
        this.initStore();
	}

    initStore(){
        this.currentView = {
            name: '/'
        }
       
        this.showNavbar = true;
        this.currentUser = null;
        this.currentToken = null;
        this.currentRefreshToken = null;
        this.pendingNetworkRequests = 0;
        this.alerts = [];
        this.appLoaded = false;
        this.showDropdown = false;
        this.showMobileNavbar = false;
    }

    toggleMobileNavbar(){
        this.showMobileNavbar = !this.showMobileNavbar;
    }

    @action startUp(){
        let token = localStorage.getItem(this.ACCESS_TOKEN_KEY);
        if(token !== null){
            this.setCurrentToken(token);
        }

        let refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
        if(refreshToken !== null){
            this.setCurrentRefreshToken(token);
        }

        let navBarIsVisible = localStorage.getItem(this.SHOW_NAVBAR_LOCAL_STORAGE_KEY);
        if(navBarIsVisible != null){
            this.showNavbar = navBarIsVisible === 'true' ? true : false;
        }

        if(token){
            this.getCurrentUser()
                .then(() => {
                    this.appLoaded = true;
                })
        }else{
            this.appLoaded = true;
        }
    }

    @action goToHome(){
        this.goToView('home');
    }

	@action goToLogin(){
		this.goToView('login');
	}

	@action goToForgottenPassword(){
        this.logout();
		this.goToView('forgotten-password');
	}


    @action goToAddInvite(){
        this.goToView('add-invite');
    }

    @action goToAcceptInvite(token){
        this.logout();
        this.goToView('accept-invite', {
            token: token
        });
    }

    @action goToResetPassword(token){
        this.logout();
        this.goToView('reset-password', {
            token: token
        })
    }
   
    @action goToUsers(){
        this.goToView('users');
    }

    @action goToNewUser(){
        this.goToView('new-user')
    }
    
    @action goToEditUser(userId){
        this.goToView('edit-user', {
            userId: userId
        })
    }

    @action goToCustomers(){
        this.goToView('customers');
    }

    @action goToAccounts(){
        this.goToView('accounts');
    }

    @action goToAssets(){
        this.goToView('assets');
    }

    @action goToNewAsset(){
        this.goToView('new-asset');
    }

    @action goToEditAsset(assetId){
        this.goToView('edit-asset', {
            assetId: assetId
        })
    }

    @action goToDeliveries(){
        this.goToView('deliveries');
    }

    @action goToReports(){
        this.goToView('reports');
    }

    @action goToProducts(){
        this.goToView('products');
    }

    @action goToNewProduct(){
        this.goToView('new-product');
    }

     @action goToEditProduct(productId){
        this.goToView('edit-product', {
            productId: productId
        })
    }

    @action goToProductCategories(){
        this.goToView('product-categories');
    }

    @action goToAddProductCategory(){
        this.goToView('add-product-category');
    }

     @action goToEditProductCategory(productCategoryId){
        this.goToView('edit-product-category', {
            productCategoryId: productCategoryId
        })
    }

    @action goToProductClasses(){
        this.goToView('product-classes');
    }

    @action goToAddProductClass(){
        this.goToView('add-product-class');
    }

     @action goToEditProductClass(productClassId){
        this.goToView('edit-product-class', {
            productClassId: productClassId
        })
    }

    @action goToNewCustomer(){
        this.goToView('new-customer');
    }

    @action goToEditCustomer(customerId){
        this.goToView('edit-customer', {
            customerId: customerId
        })
    }

    @action goToNewAccount(customerId){
        this.goToView('new-account', {
            customerId: customerId
        });
    }

    @action goToEditAccount(accountId){
        this.goToView('edit-account', {
            accountId: accountId
        })
    }

    @action goToOrders(){
        this.goToView('orders');
    }

    @action goToPODs(){
        this.goToView('pods');
    }

    @action goToNewPOD(){
        this.goToView('new-pod');
    }

     @action goToEditPOD(podId){
        this.goToView('edit-pod', {
            podId: podId
        });
    }

    @action goToInvoices(){
        this.goToView('invoices');
    }

    @action goToCreditNotes(){
        this.goToView('credit-notes');
    }

    @action goToNewOrder(){
        this.goToView('new-order');
    }

    @action goToEditOrder(orderId){
        this.goToView('edit-order', {
            orderId: orderId
        });
    }

    @action goToEditInvoice(invoiceId){
        this.goToView('edit-invoice', {
            invoiceId: invoiceId
        });
    }

    @action goToEditCreditNote(creditNoteId){
        this.goToView('edit-credit-note', {
            creditNoteId: creditNoteId
        });
    }

    @action goToServices(){
        this.goToView('services');
    }

    @action goToNewService(){
        this.goToView('new-service');
    }

     @action goToEditService(serviceId){
        this.goToView('edit-service', {
            serviceId: serviceId
        })
    }

    @action goToAgreements(){
        this.goToView('agreements');
    }

    @action goToNewAgreement(accountId=null){
        this.goToView('new-agreement', {
            accountId: accountId
        });
    }

     @action goToEditAgreement(agreementId){
        this.goToView('edit-agreement', {
            agreementId: agreementId
        })
    }

    @action goToServicing(){
        this.goToView('servicing');
    }

    @action checkForRedirect(){
        if(this.isAuthenticated){
            this.goToHome();
        }
    }

    @action goToAcceptInvite(token){
        this.goToView('accept-invite', {
            token: token
        })
    }

    @action goToJourneys(){
        this.goToView('journeys');
    }

    @action goToJourneyPlanner(){
        this.goToView('journey-planner');
    }

    @action goToNewJourney(){
        this.goToView('new-journey');
    }

    @action goToEditJourney(journeyId){
        this.goToView('edit-journey', {
            journeyId: journeyId
        })
    }

    @computed get isConfigureView(){
        let configureViews = ['customers', 'invoices', 'credit-notes', 'accounts', 'products', 'orders', 'pods', 'services', 'agreements', 'assets', 'users', 'journeys'];
        return configureViews.includes(this.currentView.name);
    }

    confirmEmail(token){
        this.userApi.confirmEmail(token)
            .then((response) => {
                this.alertSuccess('Great! Your email has now been confirmed.')
            })
            .catch((error) => {
                this.alertError('We were unable to confirm your email.');
            })
            .finally(() => {
                this.goToLogin()
            });
    }


    @action goToView(name, data=null){
        let viewObject = {
            name: name
        }
        if(data != null){
            viewObject = Object.assign({}, viewObject, data);
        }
        this.currentView = viewObject;
    }

	@computed get timezone(){
		return this.currentUser == null || this.currentUser.timezone == null ? 'Europe/London' : this.currentUser.timezone;
	}

    @computed get userRoles(){
        return this.currentUser == null ? [] : this.currentUser.roles.map((x) => x.role_name.toLowerCase().split(' ').join(''));
    }

    @computed get isAdministrator(){
        return this.userRoles.includes('administrator');
    }

    @computed get isDriver(){
        return this.userRoles.includes('driver');
    }

    @computed get isAccountant(){
        return this.userRoles.includes('accountant');
    }

    @computed get isEmployee(){
        return this.userRoles.includes('employee');
    }

    @computed get currentUserName(){
        return this.currentUser == null ? '': this.currentUser.name;
    }

    @computed get currentUserId(){
        return this.currentUser == null ? '': this.currentUser.id;
    }

	@action getCurrentTime(){
		return moment().tz(this.timezone);
	}

	@computed get isAuthenticated(){
		return this.currentUser !== null;
	}

	@action setCurrentUser(user){
		this.currentUser = user;
	}

    @action setCurrentView(name){
    	this.currentView = {
    		name: name
    	}
    }

    @action setCurrentToken(token){
    	this.currentToken = token;
    }

    @action setCurrentRefreshToken(token){
        this.currentRefreshToken = token;
    }

    @action logout(){
    	this.currentUser = null;
    	this.currentToken = '';
        this.currentRefreshToken = '';
        
    	localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    	localStorage.removeItem(this.USER_PROFILE_KEY);

        this.clearExistingAsyncTasksTimeOutsAndIntervals();

    	this.goToLogin();
    }

    refreshAccessToken(){
        return this.userApi.refreshAccessToken();
    }

    @computed get isFetching(){
    	return this.pendingNetworkRequests > 0;
    }

    @computed get isLoading(){
        return this.pendingNetworkRequests > 0;
    }

    @action startFetch(){
    	this.pendingNetworkRequests = this.pendingNetworkRequests + 1;
    }
    
    @action completeFetch(){
    	this.pendingNetworkRequests = this.pendingNetworkRequests - 1;
        if(this.pendingNetworkRequests < 0){
            this.pendingNetworkRequests = 0;
        }
    }

    @action cancelFetching(){
        this.pendingNetworkRequests = 0;
    }

    resendConfirmationEmail(){
        this.userApi.resendConfirmationEmail()
            .then((response) => {
                this.alertSuccess('Confirmation email re-sent');
            })
            .catch((error) => {
                console.log(error);
            })
    }

    @action getCurrentUser(){
        return this.userApi.getUser()
            .then((response) => {
                let user = response.data;
                this.setCurrentUser(user);
            })
            .catch((error) => {
                this.logout();
            })
    }

    @computed get currentPath() {
        switch(this.currentView.name) {
            case "login": return "/login"
            case "home": return "/home"
            case "forgotten-password": return "/password/forgot"
            case "reset-password": return "/password/reset"
            case "users": return "/users"
            case "edit-user":
                return `/users/${this.currentView.userId}`
            case "customers": return "/customers"
            case "new-customer":
                return "/customers/new"
            case "edit-customer":
                return `/customers/${this.currentView.customerId}`
            case "products": return "/products"
            case "assets": return "/assets"
            case "new-asset":
                return `/assets/new`
            case "edit-asset":
                return `/assets/${this.currentView.assetId}`
            case "accounts": return "/accounts"
            case "new-account":
                return `/accounts/new`
            case "edit-account":
                return `/accounts/${this.currentView.accountId}`
            case "new-product":
                return `/products/new`
            case "edit-product":
                return `/products/${this.currentView.productId}`
            case "orders":
                return "/orders"
            case "new-order":
                return "/orders/new"
            case "edit-order":
                return `/orders/${this.currentView.orderId}`
            case "edit-invoice":
                return `/invoices/${this.currentView.invoiceId}`
            case "credit-notes":
                return "/credit-notes"
            case "edit-credit-note":
                return `/credit-notes/${this.currentView.creditNoteId}`
            case "pods":
                return "/pods"
            case "new-pod":
                return "/pods/new"
            case "edit-pod":
                return `/pods/${this.currentView.podId}`
            case "services":
                return "/services"
            case "new-service":
                return "/services/new"
            case "edit-service":
                return `/services/${this.currentView.serviceId}`
            case "agreements":
                return "/agreements"
            case "new-agreement":
                return "/agreements/new"
            case "edit-agreement":
                return `/agreements/${this.currentView.agreementId}`
            case "deliveries": return "/deliveries"
            case "reports": return "/reports"
            case "accept-invite":
                return `/invites/accept/${this.currentView.token}`
            case "servicing":
                return "/servicing"
            case "journeys":
                return "/journeys"
            case "new-journey":
                return "/journeys/new"
            case "edit-journey":
                return `/journeys/${this.currentView.journeyId}`
            case "journey-planner":
                return "/journeys/planner"
            case "invoices":
                return "/invoices"
            default:
                return "/"
        }
    }

    @action addAlert(message, alertType){
        let alertId = uuidv4();
        let alert = {
            id: alertId,
            message: message,
            alertType: alertType
        };
     
        this.alerts.push(alert);
        setTimeout(() => {
            this.removeAlert(alertId);
        }, 7500);
    }

    @action alertError(message){
        this.addAlert(message, 'error');
    }

    @action alertWarning(message){
        this.addAlert(message, 'warning');
    }

    @action alertSuccess(message){
        this.addAlert(message, 'success');
    }

    @action alertInfo(message){
        this.addAlert(message, 'info');
    }

    resetErrors(){
        this.alerts = this.alerts.filter((x) => x.alertType !== 'error');
    }


    @action removeAlert(id){
        this.alerts = this.alerts.filter(alert => alert.id != id);
    }

    @computed get hasAlerts(){
        return this.alerts.length > 0;
    }

    @action toggleDropdown(){
        this.showDropdown = !this.showDropdown;
    }

    @action hideDropdown(){
        this.showDropdown = false;
    }

    @action toggleNavbar(){
        this.showNavbar = !this.showNavbar;
        localStorage.setItem(this.SHOW_NAVBAR_LOCAL_STORAGE_KEY, this.showNavbar ? 'true' : 'false');
    }

    clearExistingAsyncTasksTimeOutsAndIntervals(){
        for(let maxRequestTimeout of this.currentMaxRequestTimeOuts){
            clearTimeout(maxRequestTimeout);
        }
        this.currentMaxRequestTimeOuts = [];

        for(let asyncTaskInterval of this.currentAsyncTaskIntervals){
            clearInterval(asyncTaskInterval);
        }
        this.currentAsyncTaskIntervals = [];
    }

    addMaxRequestTimeout(maxRequestTimeout){
        this.currentMaxRequestTimeOuts.push(maxRequestTimeout);
    }

    addAsyncTaskInterval(asyncTaskInterval){
        this.currentAsyncTaskIntervals.push(asyncTaskInterval);
    }
}

export default UIStore;