import BaseApi from '../services/base';

class CustomerApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, searchText=null){
		let url = `v1/customers?page=${page}`;
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllCustomers = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page);
			let data = response.customers;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getById(id){
		return this.fetch(`v1/customers/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateCustomer(id, customer){
		return this.fetch(`v1/customers/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(customer)
		})
	}

	newCustomer(customer){
		return this.fetch(`v1/customers`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(customer)
		})
	}
}

export default CustomerApi;