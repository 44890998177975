import React from 'react';
import Home from './components/home/index';
import Login from './components/home/login';
import ForgotPassword from './components/home/forgot-password';

import Customers from './components/customers/index';
import ManageCustomer from './components/customers/manage';

import Accounts from './components/accounts/index';
import ManageAccount from './components/accounts/manage';

import Products from './components/products/index';
import ManageProduct from './components/products/manage';

import Deliveries from './components/deliveries/index';

import Asset from './components/assets/index';
import ManageAsset from './components/assets/manage';

import Service from './components/services/index';
import ManageService from './components/services/manage';

import Reports from './components/reports/index';

import Users from './components/users/index';
import ManageUser from './components/users/manage';

import Orders from './components/orders/index';
import ManageOrder from './components/orders/manage';

import Invoices from './components/invoices/index';
import ManageInvoice from './components/invoices/manage';

import CreditNotes from './components/credit-notes/index';
import ManageCreditNote from './components/credit-notes/manage';

import PODs from './components/pods/index';
import ManagePOD from './components/pods/manage';

import Agreements from './components/agreements/index';
import ManageAgreement from './components/agreements/manage';

import Servicing from './components/servicing/index';

import Journeys from './components/journeys/index';
import ManageJourney from './components/journeys/manage';

import JourneysPlanner from './components/journeys/planner/index';

import AcceptInvite from './components/invites/accept-invite';


export const renderView = (store) =>  {
    const view = store.currentView;
    switch (view.name) {
       	case 'home':
          return <Home view={view} />
        case 'forgotten-password':
          return <ForgotPassword view={view} />
        case 'customers':
          return <Customers view={view} />
        case 'new-customer':
          return <ManageCustomer view={view} />
        case 'edit-customer':
          return <ManageCustomer view={view} customerId={view.customerId} viewMode={true} />
        case 'accounts':
          return <Accounts view={view} />
        case 'new-account':
          return <ManageAccount view={view} customerId={view.customerId} addMode={true} />
        case 'edit-account':
          return <ManageAccount view={view} accountId={view.accountId} viewMode={true} />
        case 'products':
          return <Products view={view} />
        case 'new-product':
          return <ManageProduct view={view} addMode={true} />
        case 'edit-product':
          return <ManageProduct view={view} productId={view.productId} viewMode={true} />
        case 'assets':
          return <Asset view={view} />
        case 'new-asset':
          return <ManageAsset view={view} addMode={true} />
        case 'edit-asset':
          return <ManageAsset view={view} assetId={view.assetId} viewMode={true} />
        case 'deliveries':
          return <Deliveries view={view} />
        case 'reports':
          return <Reports view={view} />
        case 'users':
          return <Users view={view} />
        case 'new-user':
          return <ManageUser view={view} viewMode={false} />
        case 'edit-user':
          return <ManageUser view={view} userId={view.userId} viewMode={true} />
        case 'orders':
          return <Orders view={view} />
        case 'new-order':
          return <ManageOrder view={view} />
        case 'invoices':
          return <Invoices view={view} />
        case 'credit-notes':
          return <CreditNotes view={view} />
        case 'edit-credit-note':
          return <ManageCreditNote view={view} creditNoteId={view.creditNoteId} />
        case 'edit-order':
          return <ManageOrder view={view} orderId={view.orderId} />
        case 'edit-invoice':
          return <ManageInvoice view={view} invoiceId={view.invoiceId} />
        case 'pods':
          return <PODs view={view} />
        case 'new-pod':
          return <ManagePOD view={view} />
        case 'edit-pod':
          return <ManagePOD view={view} podId={view.podId} />
        case 'services':
          return <Service view={view} />
        case 'new-service':
          return <ManageService view={view} addMode={true} />
        case 'edit-service':
          return <ManageService view={view} serviceId={view.serviceId} viewMode={true} />
        case 'agreements':
          return <Agreements view={view} />
        case 'new-agreement':
          return <ManageAgreement view={view} addMode={true} accountId={view.accountId} />
        case 'edit-agreement':
          return <ManageAgreement view={view} agreementId={view.agreementId} viewMode={true} />
        case 'accept-invite':
          return <AcceptInvite view={view} token={view.token} />

        case 'servicing':
          return <Servicing view={view} />
        case 'journeys':
          return <Journeys view={view} />
        case 'new-journey':
          return <ManageJourney view={view} addMode={true}  />
        case 'edit-journey':
          return <ManageJourney view={view} journeyId={view.journeyId} viewMode={true} />
        case 'journey-planner':
          return <JourneysPlanner view={view} />
        case 'login':
    	default:
    		return <Login view={view} />
    }
}
