import { apiFetch } from '../services/fetch';

class BaseApi{
	constructor(uiStore){
		this.uiStore = uiStore;
		this.fetch = this.fetchProxy;
	}

	fetchProxy(url, options){
		return apiFetch(url, options, this.uiStore);
	}
}

export default BaseApi;