import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, Item, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {TextInput, Select, CheckBox, RadioButton, Button, LocationSearchInput, SearchEntitiesList,  CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, CloneIcon, CheckIcon} from '../common/icon';
import GoogleMarkerMap from '../common/map';
import Authorisation from '../authorisation';

@observer
class OpeningHours extends Component{
	render(){
		let viewMode = this.props.viewMode;
		return (
			<>
				{this.props.openingHours.map((openingHour, idx) =>
					<>
						<DayRow 
							key={openingHour.dayId}
							idx={idx}
							dayName={openingHour.dayName}
							dayId={openingHour.dayId} 
							viewMode={viewMode} 
							options={this.props.timeInputOptions} 
							active={openingHour.active}
							startTime={openingHour.startTime}
							endTime={openingHour.endTime}
							onClone={this.props.onClone}
						/>
					</>
				)}
			</>
		)
	}
}

@observer
class DayRow extends Component{
	render(){
		let viewMode = this.props.viewMode;
		let dayId = this.props.dayId;
		let idx = this.props.idx;
		return (
			<>
				<div className={`sm:col-span-1 flex flex-row items-center`}>
		            <CheckBox 
			        	disabled={viewMode}
			        	label={this.props.dayName}
			        	checked={this.props.active.value} 
			        	onChange={(e) => this.props.active.onChange(e.target.checked)} 
			        />
			        
		        </div>
		        <div className="sm:col-span-2">
		            <Select 
		              	onChange={(val) => this.props.startTime.onChange(val)}
	           			placeholder="Choose opening time"
	           			disabled={viewMode}
		           		options={this.props.options}
		           		value={this.props.startTime.value}
		           		error={this.props.startTime.error}
		           	/>
		        </div>
		        <div className="sm:col-span-2">
		             <Select 
		              	onChange={(val) => this.props.endTime.onChange(val)}
	           			placeholder="Choose closing time"
	           			disabled={viewMode}
		           		options={this.props.options} 
		           		value={this.props.endTime.value}
		           		error={this.props.endTime.error}
		           		 />
		        </div>
		        <div className="sm:col-span-1 flex flex-row items-center">
		        	{idx == 0 && 
		           		<div className={"flex cursor-pointer"} onClick={() => this.props.onClone()}>
		           			<CloneIcon />
		           		</div>
		           	}
		        </div>
		    </>
		)
	}
}

@inject('manageAccount')
@observer
class ManageAccount extends Component{
	constructor(props){
		super(props);
		this.renderPriceListItem = this.renderPriceListItem.bind(this);
		this.renderPriceListHeaders = this.renderPriceListHeaders.bind(this);
		this.onNewSpecialPrice = this.onNewSpecialPrice.bind(this);

		this.renderAssetPurchasePriceListItem = this.renderAssetPurchasePriceListItem.bind(this);
		this.renderAssetPurchasePriceListHeaders = this.renderAssetPurchasePriceListHeaders.bind(this);
		this.onNewAssetPurchaseSpecialPrice = this.onNewAssetPurchaseSpecialPrice.bind(this);

		this.renderAssetRentalPriceListItem = this.renderAssetRentalPriceListItem.bind(this);
		this.renderAssetRentalPriceListHeaders = this.renderAssetRentalPriceListHeaders.bind(this);
		this.onNewAssetRentalSpecialPrice = this.onNewAssetRentalSpecialPrice.bind(this);

		this.renderServicePriceListItem = this.renderServicePriceListItem.bind(this);
		this.renderServicePriceListHeaders = this.renderServicePriceListHeaders.bind(this);
		this.onNewServiceSpecialPrice = this.onNewServiceSpecialPrice.bind(this);

		this.renderJourneyPriceListItem = this.renderJourneyPriceListItem.bind(this);
		this.renderJourneyPriceListHeaders = this.renderJourneyPriceListHeaders.bind(this);

		this.renderLocationListItem = this.renderLocationListItem.bind(this);
		this.renderLocationListHeaders = this.renderLocationListHeaders.bind(this);

		this.renderAccountAgreementListItem = this.renderAccountAgreementListItem.bind(this);
		this.onNewAccountAgreement = this.onNewAccountAgreement.bind(this);

		this.renderAccountInvoiceListItem = this.renderAccountInvoiceListItem.bind(this);
		this.renderAccountCreditNoteListItem = this.renderAccountCreditNoteListItem.bind(this);
		this.renderAccountCreditNoteListHeaders = this.renderAccountCreditNoteListHeaders.bind(this);

		this.renderContactListItem = this.renderContactListItem.bind(this);
		this.renderContactListHeaders = this.renderContactListHeaders.bind(this);
		this.onNewContact = this.onNewContact.bind(this);
	}

	componentDidMount(){
		let manageAccount = this.props.manageAccount;

		manageAccount.fetchAccountStatuses();
		manageAccount.fetchAccountTypes();

		manageAccount.fetchCurrencies();
		manageAccount.fetchPaymentMethods();
		manageAccount.fetchCountries();

		manageAccount.fetchAssets();
		manageAccount.fetchServices();
		manageAccount.fetchVATRates();
		manageAccount.fetchProducts();
		manageAccount.fetchJourneys();

		if(this.props.customerId){
			manageAccount.onLoadCustomer(this.props.customerId);
		}
		if(this.props.viewMode){
			manageAccount.formSync(this.props.accountId);
		}
		
	}

	componentWillUnmount(){
		let manageAccount = this.props.manageAccount;
		manageAccount.initStore()
	}

	onNewSpecialPrice(){
		let manageAccount = this.props.manageAccount;
		manageAccount.addPriceListEntry();
	}

	onNewAssetPurchaseSpecialPrice(){
		let manageAccount = this.props.manageAccount;
		manageAccount.addAssetPurchasePriceListEntry();
	}

	onNewAssetRentalSpecialPrice(){
		let manageAccount = this.props.manageAccount;
		manageAccount.addAssetRentalPriceListEntry();
	}

	onNewServiceSpecialPrice(){
		let manageAccount = this.props.manageAccount;
		manageAccount.addServicePriceListEntry();
	}

	renderPriceListHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">Product</div>
				<div className="sm:col-span-1">Currency</div>
				<div className="sm:col-span-1">Price (Excl. VAT)</div>
				<div className="sm:col-span-1">VAT Rate</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAssetPurchasePriceListHeaders(){
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAssetRentalPriceListHeaders(){
		return (
			<ListItem gridCols={12}>
				<div className="sm:col-span-2">Asset</div>
				<div className="sm:col-span-2">Currency</div>
				<div className="sm:col-span-2">Price (Excl. VAT)</div>
				<div className="sm:col-span-2">VAT Rate</div>
				<div className="sm:col-span-1">Rental Days</div>
				<div className="sm:col-span-2">Rent</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderServicePriceListHeaders(){
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">Service</div>
				<div className="sm:col-span-1">Currency</div>
				<div className="sm:col-span-1">Price (Excl. VAT)</div>
				<div className="sm:col-span-1">VAT Rate</div>
				<div className="sm:col-span-1">Recurring</div>
				<div className="sm:col-span-1">Subtotal</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderJourneyPriceListHeaders(){
		return (
			<ListItem gridCols={3}>
				<div className="sm:col-span-1">Journey</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderAccountAgreementListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem onEdit={() => manageAccount.onEditAgreement(item.id)}>
				<ItemColumn text={`#${item.id}`} subtext={item.agreement_reference} />
				<Status text={item.closed ? 'Closed' : 'Open'} color={item.closed ? 'red' : 'green'} />
			</ListItem>
		)
	}

	renderAccountInvoiceListHeaders(){
		return (
			<ListItem gridCols={3}>
				<div className="sm:col-span-1">Date</div>
				<div className="sm:col-span-1">Invoice Reference</div>
				<div className="sm:col-span-1">POD</div>
			</ListItem>
		)
	}

	renderAccountCreditNoteListHeaders(){
		return (
			<ListItem gridCols={3}>
				<div className="sm:col-span-1">Date</div>
				<div className="sm:col-span-1">Credit Note Reference</div>
				<div className="sm:col-span-1">POD</div>
			</ListItem>
		)
	}

	renderAccountInvoiceListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={3} onEdit={() => manageAccount.uiStore.goToEditInvoice(item.id)}>
				<Item text={item.logged_on.format('DD/MM/YYYY')}  />
				<Item text={item.invoice_reference}  />
				<Item text={item.proof_of_delivery?.pod_reference} />
			</ListItem>
		)
	}

	renderAccountCreditNoteListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={3} onEdit={() => manageAccount.uiStore.goToEditCreditNote(item.id)}>
				<Item text={item.logged_on.format('DD/MM/YYYY')}  />
				<Item text={item.credit_note_reference}  />
				<Item text={item.proof_of_delivery?.pod_reference} />
			</ListItem>
		)
	}

	onNewAccountAgreement(){
		let manageAccount = this.props.manageAccount;
		manageAccount.onNewAccountAgreement();
	}

	renderPriceListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={6}>
				<div className="sm:col-span-1">
	              <Select 
	              	onChange={(val) => manageAccount.changePriceListItemProduct(item.uuid, val)}
           			placeholder="Choose a product"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.productOptions} 
	           		value={item.selectedProduct.value}
	           		error={item.selectedProduct.error}
	           		 />
		        </div>
				<div className="sm:col-span-1">
	              <Select 
	              	onChange={(val) => manageAccount.changePriceListItemCurrency(item.uuid, val)}
           			placeholder="Choose price currency"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-1">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter product price"
	              	onChange={(val) => manageAccount.changePriceListItemPrice(item.uuid, val)}
	              	disabled={manageAccount.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-1">
	              <Select 
	              	onChange={(val) => manageAccount.changePriceListItemVATRate(item.uuid, val)}
           			placeholder="Choose vat rate"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deletePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetPurchasePriceListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={10}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedAsset.onChange(val)}
           			placeholder="Choose asset"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter asset price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageAccount.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deleteAssetPurchasePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderAssetRentalPriceListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={12}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedAsset.onChange(val)}
           			placeholder="Choose asset"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter asset price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageAccount.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-1">
	              <TextInput 
	              	type="number"
	              	placeholder="Number of days"
	              	onChange={(val) => item.days.onChange(val)}
	              	disabled={manageAccount.viewMode}
	            	value={item.days.value} 
	            	error={item.days.error}/>
		        </div>
		       <div className="sm:col-span-2 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deleteAssetRentalPriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderServicePriceListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={12}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedService.onChange(val)}
           			placeholder="Choose service"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.serviceOptions} 
	           		value={item.selectedService.value}
	           		error={item.selectedService.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedCurrency.onChange(val)}
           			placeholder="Choose price currency"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.currencyOptions} 
	           		value={item.selectedCurrency.value}
	           		error={item.selectedCurrency.error}
	           		 />
		        </div>
				<div className="sm:col-span-2">
	              <TextInput 
	              	type="number"
	              	placeholder="Enter product price"
	              	onChange={(val) => item.price.onChange(val)}
	              	disabled={manageAccount.viewMode}
	            	value={item.price.value} 
	            	error={item.price.error}/>
		        </div>
		        <div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedVATRate.onChange(val)}
           			placeholder="Choose vat rate"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.vatRateOptions} 
	           		value={item.selectedVATRate.value}
	           		error={item.selectedVATRate.error}
	           		 />
		       </div>
		       <div className="sm:col-span-2">
	              	<div className="flex h-full flex-row items-center">
		              	<CheckBox 
				        	disabled={manageAccount.viewMode}
				        	label={item.recurring.value ? null : 'Recurring charge?'}
				        	checked={item.recurring.value} 
				        	onChange={(e) => item.recurring.onChange(e.target.checked)} />
		              	{item.recurring.value && 
			              	<div className="ml-2">
				              	<TextInput 
					              	type="number"
					              	placeholder="Charge every X days"
					              	onChange={(val) => item.days.onChange(val)}
					              	disabled={manageAccount.viewMode}
					            	value={item.days.value} 
					            	error={item.days.error}/>
					        </div>
					    }
					</div>
		       </div>
		       <div className="sm:col-span-1 flex flex-1 justify-start items-center ">
		       	<Label>{item.subtotal}</Label>
		       </div>

		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deleteServicePriceListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderJourneyPriceListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={3}>
				<div className="sm:col-span-2">
	              <Select 
	              	onChange={(val) => item.selectedJourney.onChange(val)}
           			placeholder="Choose journey"
           			disabled={manageAccount.viewMode}
	           		options={manageAccount.journeyOptions} 
	           		value={item.selectedJourney.value}
	           		error={item.selectedJourney.error}
	           		 />
		        </div>
				
		       <div className="sm:col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deleteJourneyListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		);
	}

	renderLocationListHeaders(){
		return (
			<ListItem gridCols={5}>
				<div className="sm:col-span-1">Name</div>
				<div className="sm:col-span-1">Code</div>
				<div className="sm:col-span-1">Parent Code</div>
				<div className="sm:col-span-1">Parent Name</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderLocationListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={5}>
				<div className="col-span-1">
					<TextInput value={item.name.value} error={item.name.error} onChange={(val) => item.name.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter a location name'  />
				</div>
				<div className="col-span-1">
					<TextInput value={item.code.value} error={item.code.error} onChange={(val) => item.code.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter a location code'  />
				</div>
				<div className="col-span-1">
					<TextInput value={item.parentCode.value} error={item.parentCode.error} onChange={(val) => item.parentCode.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter a parent code'  />
				</div>
				<div className="col-span-1">
					<TextInput value={item.parentName.value} error={item.parentName.error} onChange={(val) => item.parentName.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter a location parent name'  />
				</div>
				
				<div className="col-span-1">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deleteLocationListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        </div>
			</ListItem>
		)
	}

	renderContactListHeaders(){
		return (
			<ListItem gridCols={7}>
				<div className="sm:col-span-1">Name</div>
				<div className="sm:col-span-1">Job Title</div>
				<div className="sm:col-span-1">Email</div>
				<div className="sm:col-span-1">Phone Number</div>
				<div className="sm:col-span-1">Fax Number</div>
				<div className="sm:col-span-1">Primary</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}

	renderContactListItem(item){
		let manageAccount = this.props.manageAccount;
		return (
			<ListItem gridCols={6}>
				<div className="col-span-2">
					<TextInput value={item.name.value} error={item.name.error} onChange={(val) => item.name.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter a contact name'  />
				</div>
				<div className="col-span-2">
					<TextInput value={item.jobTitle.value} error={item.jobTitle.error} onChange={(val) => item.jobTitle.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter their job title'  />
				</div>
				<div className="col-span-2">
					<TextInput value={item.email.value} error={item.email.error} onChange={(val) => item.email.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter their email'  /> 
				</div>
				<div className="col-span-2">
					<TextInput value={item.phoneNumber.value} error={item.phoneNumber.error} onChange={(val) => item.phoneNumber.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter their phone number'  /> 
				</div>
				<div className="col-span-2">
					<TextInput value={item.mobilePhoneNumber.value} error={item.mobilePhoneNumber.error} onChange={(val) => item.mobilePhoneNumber.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter their mobile phone number (optional)'  /> 
				</div>
				<div className="col-span-2">
					<TextInput value={item.faxNumber.value} error={item.faxNumber.error} onChange={(val) => item.faxNumber.onChange(val)} disabled={manageAccount.viewMode} placeholder='Enter their fax number'  /> 
				</div>
				<div className="flex flex-row items-center">
					{item.primaryContact.value &&
						<div className="flex flex-row items-center">
							<CheckIcon color='text-success-600' />
							<span>Primary</span>
						</div>
					}
				</div>
				<div className="col-start-5 col-span-1 flex flex-1 justify-end items-center">
		        	<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.deleteContactListItem(item.uuid)}><DeleteIcon color='text-red-400' /></Button>
		        	{!item.primaryContact.value && 
		        		<Button fetching={manageAccount.fetching} bgColor="transparent" fgColor='white' disabled={manageAccount.viewMode} className="flex mt-1" onClick={() => manageAccount.markContactListItemAsPrimary(item.uuid)}><CheckIcon color='text-success-600' /></Button>
		        	}
		        </div>
			</ListItem>
		)
	}

	onNewContact(){
		let manageAccount = this.props.manageAccount;
		manageAccount.addContactListEntry();
	}


	render(){
		let manageAccount = this.props.manageAccount;
		return (
			<Main>
				<div>
					<div className="flex flex-row flex-1 items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {manageAccount.name.value.length > 0 ? manageAccount.name.value : 'Account Information'} {manageAccount.accountReference.value.length > 0 && ` - ${manageAccount.accountReference.value}`}
					        </Heading>
					        <Paragraph>
					          Account Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageAccount.viewMode && 
					    		<Button onClick={() => manageAccount.toggleEditMode()}>
						          Edit Account
						        </Button>
						    }
					        {!manageAccount.viewMode && 
								  <div className="flex justify-end">
								      <span className="inline-flex rounded-md shadow-sm">
								        <CancelButton onClick={() => manageAccount.onCancel()} fetching={manageAccount.fetching}>
								          Cancel changes
								        </CancelButton>
								      </span>
								      <span className="ml-3 inline-flex rounded-md shadow-sm">
								        <Button onClick={() => manageAccount.save()} fetching={manageAccount.fetching}>
								          {manageAccount.editMode ? 'Update' : 'Save' } Account
								        </Button>
								      </span>
								  </div>
							}
					   	</div>
					</div>
			        <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
			        	<div className="sm:col-span-6">
			        		<Label>
				            	Customer
				          	</Label>

				           	<SearchEntitiesList
				           			disabled={!manageAccount.addMode}
									hasSelectedValue={manageAccount.selectedCustomer != null}
									searchValue={manageAccount.searchCustomerValue}
									onChangeSearchValue={(val) => manageAccount.onChangeSearchCustomerValue(val)}
									searchValuePlaceholder="Select a Customer"
									searchOptions={manageAccount.searchCustomerOptions}
									onSelectOption={(id) => manageAccount.onSelectCustomer(id)}
									onClearSelectedValue={() => manageAccount.onClearSelectedValue()}
									selectedValue={manageAccount.selectedCustomer?.label}
								/>

				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            Account Name
				          </Label>
			              <TextInput 
			              	disabled={manageAccount.viewMode}
			            	onChange={(val) => manageAccount.name.onChange(val)}
			            	value={manageAccount.name.value} 
			            	error={manageAccount.name.error} />
				        </div>
				        <div className="sm:col-span-3">
				          <Label>
				            Account Reference
				          </Label>
			              <TextInput 
			              	disabled={manageAccount.viewMode}
			            	onChange={(val) => manageAccount.accountReference.onChange(val)}
			            	value={manageAccount.accountReference.value} 
			            	error={manageAccount.accountReference.error} />
				        </div>
				        <div className="sm:col-span-6">
			        		<Label>
				            	Account Status
				          	</Label>
			           		<Select 
			           			placeholder="Choose Account Status"
			           			disabled={manageAccount.viewMode}
				           		onChange={(val) => manageAccount.onChangeAccountStatus(val)}
				           		options={manageAccount.accountStatusOptions} 
				           		value={manageAccount.selectedStatus.value}
				           		error={manageAccount.selectedStatus.error}
				           		 />
				        </div>
				        <div className="sm:col-span-6">
			        		<Label>
				            	Account Type
				          	</Label>
			           		<Select 
			           			placeholder="Choose Account type"
			           			disabled={manageAccount.viewMode}
				           		onChange={(val) => manageAccount.selectedAccountType.onChange(val)}
				           		options={manageAccount.accountTypeOptions} 
				           		value={manageAccount.selectedAccountType.value} 
				           		error={manageAccount.selectedAccountType.error} 
				           		/>
				        </div>
				    </div>
				    <FormDivider>
					  <div>
				      	<SubHeading>
				          Account Payment Information
				        </SubHeading>
				        <Paragraph>
				          Details around payment details for this account
				        </Paragraph>
				        <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
				        	<div className="sm:col-span-3">
					          <Label>
					            Payment Method
					          </Label>
				               <Select 
				               		placeholder="Select a payment method"
				               		disabled={manageAccount.viewMode}
					           		onChange={(val) => manageAccount.onChangePaymentMethod(val)}
					           		options={manageAccount.paymentMethodOptions} 
					           		value={manageAccount.selectedPaymentMethod.value}
					           		error={manageAccount.selectedPaymentMethod.error}
					           		 />
					        </div>
				        	<div className="sm:col-span-3">
					          <Label>
					            Currency
					          </Label>
				               <Select
				               		placeholder="Select a currency"
				               		disabled={manageAccount.viewMode}
					           		onChange={(val) => manageAccount.onChangeCurrency(val)}
					           		options={manageAccount.currencyOptions} 
					           		value={manageAccount.selectedCurrency.value}
					           		error={manageAccount.selectedCurrency.error}
					           	/>
					        </div>
					        <div className="sm:col-span-3">
					          <Label>
					            Days Credit
					          </Label>
				              <TextInput 
				              	type="number"
				              	disabled={manageAccount.viewMode}
				            	onChange={(val) => manageAccount.daysCredit.onChange(val)}
				            	value={manageAccount.daysCredit.value} 
				            	error={manageAccount.daysCredit.error} />
					        </div>
					        <div className="sm:col-span-3">
					          <Label>
					            Credit Limit
					          </Label>
				              <TextInput 
				              	type="number"
				              	disabled={manageAccount.viewMode}
				            	onChange={(val) => manageAccount.creditLimit.onChange(val)}
				            	value={manageAccount.creditLimit.value} 
				            	error={manageAccount.creditLimit.error} />
					        </div>
					        <div className="sm:col-span-6 flex items-center">
						        <CheckBox 
						        	disabled={manageAccount.viewMode}
						        	label="PO Required" 
						        	checked={manageAccount.poRequired.value} 
						        	onChange={(e) => manageAccount.onChangePORequired(e.target.checked)} />
						    </div>
					        <div className="sm:col-span-3">
					          <Label>
					            VAT Rate
					          </Label>
				               <Select
				               		placeholder="Select a vat rate"
				               		disabled={manageAccount.viewMode}
					           		onChange={(val) => manageAccount.onChangeVATRate(val)}
					           		options={manageAccount.vatRateOptions} 
					           		value={manageAccount.selectedVATRate.value}
					           		error={manageAccount.selectedVATRate.error}
					           	/>
					        </div>
					    	<div className="sm:col-span-3">
					          <Label>
					            VAT Number
					          </Label>
				              <TextInput 
				              	disabled={manageAccount.viewMode}
				            	onChange={(val) => manageAccount.vatNumber.onChange(val)}
				            	value={manageAccount.vatNumber.value} 
				            	error={manageAccount.vatNumber.error} />
					        </div>
				        </div>
				      </div>
				    </FormDivider>
				    <FormDivider>
					  <div>
				      	<SubHeading>
				          Account Address
				        </SubHeading>
				        <Paragraph>
				          Delivery and Invoice Address information for this account
				        </Paragraph>


				        {manageAccount.currentCustomerAddress != null &&
				        	<div className="col-span-3 mt-6">
				        		<Label>Customer Address</Label>
				        		<Paragraph>{manageAccount.currentCustomerAddress}</Paragraph>
				        	</div>
				        }

				        <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
						        <div className="sm:col-span-3 flex items-center">
							        <CheckBox 
							        	disabled={manageAccount.viewMode}
							        	label="For this account, deliver to the address linked to the customer" 
							        	checked={manageAccount.useCustomerDeliveryAddress.value} 
							        	onChange={(e) => manageAccount.onChangeUseCustomerDeliveryAddress(e.target.checked)} />
							    </div>

						        {!manageAccount.useCustomerDeliveryAddress.value && 
						        	<>
						        		<div className="sm:col-span-6">
							        		<LocationSearchInput 
							        			disabled={manageAccount.viewMode}
												onChange={(val) => manageAccount.deliveryAddress.$.searchAddress.onChange(val)} 
												onChangeAddressComponents={(streetAddress, val) => manageAccount.onReceivedDeliveryAddressComponents(streetAddress, val)}
												onReceivedCoordinates={(coords) => manageAccount.receivedDeliveryAddressCoords(coords)}

												value={manageAccount.deliveryAddress.$.searchAddress.value} 
												label='Customer Delivery Address' 
												placeholder='Search for a delivery address' 
											/>

											{manageAccount.hasDeliveryAddressCoords && 
												<div className="w-full h-64 mt-8">
													<GoogleMarkerMap 
														zoomLevel={15}
														coords={manageAccount.deliveryAddressCoords} 
														markers={manageAccount.deliveryAddressLocationMarkers} 
														renderMarkerData={(markerData) => {
									        				return (
									        					<div>
														            <span>{manageAccount.deliveryAddress.$.streetAddress.value}</span>
														        </div>
														      	)
											        		}}
										        	/>
												</div>
											}
										</div>

							        	<div className="sm:col-span-6">
								          <Label>
								            Street Address
								          </Label>
								            <TextInput 
								            	disabled={manageAccount.viewMode}
								            	onChange={(val) => manageAccount.deliveryAddress.$.streetAddress.onChange(val)}
								            	value={manageAccount.deliveryAddress.$.streetAddress.value} 
								            	error={manageAccount.deliveryAddress.$.streetAddress.error}
								            />
								        </div>

								        <div className="sm:col-span-3">
								          <Label>
								            City
								          </Label>
								            <TextInput 
								            	disabled={manageAccount.viewMode}
								            	onChange={(val) => manageAccount.deliveryAddress.$.city.onChange(val)}
								            	value={manageAccount.deliveryAddress.$.city.value} 
								            	error={manageAccount.deliveryAddress.$.city.error}
								            />
								        </div>

								        <div className="sm:col-span-3">
								          <Label>
								            Country
								          </Label>
								           	<Select
								           		placeholder="Select a country"
								           		disabled={manageAccount.viewMode} 
								           		onChange={(val) => manageAccount.deliveryAddress.$.selectedCountry.onChange(val)}
								           		options={manageAccount.countryOptions} 
								           		value={manageAccount.deliveryAddress.$.selectedCountry.value}
								           		error={manageAccount.deliveryAddress.$.selectedCountry.error}
								           		 />
								        </div>

								        <div className="sm:col-span-3">
								          <Label>
								            County
								          </Label>
								            <TextInput
								            	disabled={manageAccount.viewMode}
								            	onChange={(val) => manageAccount.deliveryAddress.$.county.onChange(val)}
								            	value={manageAccount.deliveryAddress.$.county.value} 
								            	error={manageAccount.deliveryAddress.$.county.error}
								             />
								        </div>

								        <div className="sm:col-span-3">
								          <Label>
								          	Postcode
								          </Label>
								            <TextInput 
								            	disabled={manageAccount.viewMode}
								            	onChange={(val) => manageAccount.deliveryAddress.$.postcode.onChange(val)}
								            	value={manageAccount.deliveryAddress.$.postcode.value} 
								            	error={manageAccount.deliveryAddress.$.postcode.error}
								            />
								        </div>

								         <div className="sm:col-span-3 flex items-center">
									        <CheckBox 
									        	disabled={manageAccount.viewMode}
									        	label="Do you want to use this delivery address as the invoice address for this account?" 
									        	checked={manageAccount.useSameAccountDeliveryAndBillingAddress.value} 
									        	onChange={(e) => manageAccount.onChangeUseSameAccountDeliveryAndBillingAddress(e.target.checked)} />
									    </div>
								    </>
							    }
				        </div>

				        {!manageAccount.useSameAccountDeliveryAndBillingAddress.value &&
					        <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
							         <div className="sm:col-span-3 flex items-center">
								        <CheckBox 
								        	disabled={manageAccount.viewMode}
								        	label="For this account, send the invoice to the address linked to the customer" 
								        	checked={manageAccount.useCustomerInvoiceAddress.value} 
								        	onChange={(e) => manageAccount.onChangeUseCustomerInvoiceAddress(e.target.checked)} />
								    </div>

							        {!manageAccount.useCustomerInvoiceAddress.value &&
							        	<>
							        		<div className="sm:col-span-6">
								        		<LocationSearchInput 
								        			disabled={manageAccount.viewMode}
													onChange={(val) => manageAccount.invoiceAddress.$.searchAddress.onChange(val)} 
													onChangeAddressComponents={(streetAddress, val) => manageAccount.onReceivedInvoiceAddressComponents(streetAddress, val)}
													onReceivedCoordinates={(coords) => manageAccount.receivedInvoiceAddressCoords(coords)}
													value={manageAccount.invoiceAddress.$.searchAddress.value} 
													label='Customer Invoice Address' 
													placeholder='Search for a invoice address' 
												/>

												{manageAccount.hasInvoiceAddressCoords && 
													<div className="w-full h-64 mt-8">
														<GoogleMarkerMap 
															zoomLevel={15}
															coords={manageAccount.invoiceAddressCoords} 
															markers={manageAccount.invoiceAddressLocationMarkers} 
															renderMarkerData={(markerData) => {
										        				return (
										        					<div>
															            <span>{manageAccount.invoiceAddress.$.streetAddress.value}</span>
															        </div>
															      	)
												        		}}
											        	/>
													</div>
												}
											</div>

								        	<div className="sm:col-span-6">
									          <Label>
									            Street Address
									          </Label>
									            <TextInput 
									            	disabled={manageAccount.viewMode}
									            	onChange={(val) => manageAccount.invoiceAddress.$.streetAddress.onChange(val)}
									            	value={manageAccount.invoiceAddress.$.streetAddress.value} 
									            	error={manageAccount.invoiceAddress.$.streetAddress.error}
									            />
									        </div>

									        <div className="sm:col-span-3">
									          <Label>
									            City
									          </Label>
									            <TextInput 
									            	disabled={manageAccount.viewMode}
									            	onChange={(val) => manageAccount.invoiceAddress.$.city.onChange(val)}
									            	value={manageAccount.invoiceAddress.$.city.value} 
									            	error={manageAccount.invoiceAddress.$.city.error}
									            />
									        </div>

									        <div className="sm:col-span-3">
									          <Label>
									            Country
									          </Label>
									           	<Select
									           		placeholder="Select a country"
									           		disabled={manageAccount.viewMode} 
									           		onChange={(val) => manageAccount.invoiceAddress.$.selectedCountry.onChange(val)}
									           		options={manageAccount.countryOptions} 
									           		value={manageAccount.invoiceAddress.$.selectedCountry.value}
									           		error={manageAccount.invoiceAddress.$.selectedCountry.error}
									           		 />
									        </div>

									        <div className="sm:col-span-3">
									          <Label>
									            County
									          </Label>
									            <TextInput
									            	disabled={manageAccount.viewMode}
									            	onChange={(val) => manageAccount.invoiceAddress.$.county.onChange(val)}
									            	value={manageAccount.invoiceAddress.$.county.value} 
									            	error={manageAccount.invoiceAddress.$.county.error}
									             />
									        </div>

									        <div className="sm:col-span-3">
									          <Label>
									          	Postcode
									          </Label>
									            <TextInput 
									            	disabled={manageAccount.viewMode}
									            	onChange={(val) => manageAccount.invoiceAddress.$.postcode.onChange(val)}
									            	value={manageAccount.invoiceAddress.$.postcode.value} 
									            	error={manageAccount.invoiceAddress.$.postcode.error}
									            />
									        </div>
									    </>
								    }
					        </div>
					    }

				      </div>
				    </FormDivider>
				    <FormDivider>
					  <div>
				      	<SubHeading>
				          Account Contact Information
				        </SubHeading>
				        <Paragraph>
				          Primary point of contact for this account
				        </Paragraph>
				      </div>
				      <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
			        	 <div className="sm:col-span-6 flex items-center">
					        <CheckBox 
					        	disabled={manageAccount.viewMode}
					        	label="Use customer primary contact as primary contact for this account" 
					        	checked={manageAccount.useCustomerContact.value} 
					        	onChange={(e) => manageAccount.onChangeUseCustomerContact(e.target.checked)} />
					    </div>
					    {!manageAccount.useCustomerContact.value && manageAccount.addMode && 
				        	<>
					        	<div className="sm:col-span-3">
						          <Label>
						            Contact Name
						          </Label>
						            <TextInput 
						            	disabled={manageAccount.viewMode}
						            	onChange={(val) => manageAccount.contactName.onChange(val)}
						            	value={manageAccount.contactName.value} 
						            	error={manageAccount.contactName.error} />
						        </div>

						        <div className="sm:col-span-3">
						          <Label>
						            Job Title
						          </Label>
						            <TextInput 
						            	disabled={manageAccount.viewMode}
						            	onChange={(val) => manageAccount.contactJobTitle.onChange(val)}
						            	value={manageAccount.contactJobTitle.value} 
						            	error={manageAccount.contactJobTitle.error} />
						        </div>

						        <div className="sm:col-span-6">
						          <Label>
						            Contact Email
						          </Label>
						            <TextInput 
						            	disabled={manageAccount.viewMode}
						            	onChange={(val) => manageAccount.contactEmail.onChange(val)}
						            	value={manageAccount.contactEmail.value} 
						            	error={manageAccount.contactEmail.error} />
						        </div>


						        <div className="sm:col-span-2">
						          <Label>
						            Phone Number
						          </Label>
						            <TextInput 
						            	disabled={manageAccount.viewMode}
						            	onChange={(val) => manageAccount.contactPhoneNumber.onChange(val)}
						            	value={manageAccount.contactPhoneNumber.value} 
						            	error={manageAccount.contactPhoneNumber.error} />
						        </div>

						        <div className="sm:col-span-2">
						          <Label>
						            Mobile Phone Number
						          </Label>
						            <TextInput 
						            	disabled={manageAccount.viewMode}
						            	onChange={(val) => manageAccount.contactMobilePhoneNumber.onChange(val)}
						            	value={manageAccount.contactMobilePhoneNumber.value} 
						            	error={manageAccount.contactMobilePhoneNumber.error} />
						        </div>

						        <div className="sm:col-span-2">
						          <Label>
						            Fax Number
						          </Label>
						            <TextInput 
						            	disabled={manageAccount.viewMode}
						            	onChange={(val) => manageAccount.contactFaxNumber.onChange(val)}
						            	value={manageAccount.contactFaxNumber.value} 
						            	error={manageAccount.contactFaxNumber.error} />
						        </div>
						    </>
						}

						{!manageAccount.addMode &&
						      <div className="sm:col-span-6">
						      	<Card title="Manage Account Contacts" actionBtn={
									<Button disabled={!manageAccount.editMode} onClick={this.onNewContact}>New Contact</Button>
								}>
							      	<List 
							      		type="contacts"
							      		renderListItem={this.renderContactListItem}
							      		items={manageAccount.contacts}
									/>
							    </Card>
						      </div>
						}

				      </div>
			      </FormDivider>
			      <FormDivider>
			      	<div>
				      	<SubHeading>
				          Account Opening Hours
				        </SubHeading>
				        <Paragraph>
				          What is the opening hours for this account? 
				        </Paragraph>
				    </div>
				    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
				    	<OpeningHours 
				    		viewMode={manageAccount.viewMode}
				    		timeInputOptions={manageAccount.timeInputOptions}
				    		openingHours={manageAccount.openingHours}
				    		onClone={() => manageAccount.cloneOpeningHours()}
				    	/>
				    </div>
			      </FormDivider>
			      <FormDivider>
						<Card title="Product Prices" subtitle="Set special prices for products for this account" actionBtn={
							<Button disabled={manageAccount.viewMode} onClick={this.onNewSpecialPrice}>Add Product Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderPriceListHeaders}
					      		renderListItem={this.renderPriceListItem}
					      		items={manageAccount.priceList}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Purchase Asset Prices" subtitle="Set special prices for purchasing assets for this account" actionBtn={
							<Button disabled={manageAccount.viewMode} onClick={this.onNewAssetPurchaseSpecialPrice}>Add Asset Purchase Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderAssetPurchasePriceListHeaders}
					      		renderListItem={this.renderAssetPurchasePriceListItem}
					      		items={manageAccount.assetPurchasePriceList}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Rental Asset Prices" subtitle="Set special prices for renting assets for this account" actionBtn={
							<Button disabled={manageAccount.viewMode} onClick={this.onNewAssetRentalSpecialPrice}>Add Asset Rental Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderAssetRentalPriceListHeaders}
					      		renderListItem={this.renderAssetRentalPriceListItem}
					      		items={manageAccount.assetRentalPriceList}
							/>
				    	</Card>
				    </FormDivider>
				    <FormDivider>
						<Card title="Service Prices" subtitle="Set special prices for services for this account" actionBtn={
							<Button disabled={manageAccount.viewMode} onClick={this.onNewServiceSpecialPrice}>Add Service Price</Button>
						}>
					      	<List 
					      		type="prices"
					      		renderListHeaders={this.renderServicePriceListHeaders}
					      		renderListItem={this.renderServicePriceListItem}
					      		items={manageAccount.servicePriceList}
							/>
				    	</Card>
				    </FormDivider>

					<FormDivider>
						<Card title="Journeys" subtitle="Set journeys for this account" actionBtn={
							<Button disabled={manageAccount.viewMode} onClick={() => manageAccount.addJourneyEntry()}>Add Journey</Button>
						}>
					      	<List 
					      		type="journeys"
					      		renderListHeaders={this.renderJourneyPriceListHeaders}
					      		renderListItem={this.renderJourneyPriceListItem}
					      		items={manageAccount.accountJourneys}
							/>
				    	</Card>
				    </FormDivider>


				    <FormDivider>
						<Card title="Locations" subtitle="Add locations for this account" actionBtn={
							<Button disabled={manageAccount.viewMode} onClick={() => manageAccount.addLocationEntry()}>Add Location</Button>
						}>
					      	<List 
					      		type="locations"
					      		renderListHeaders={this.renderLocationListHeaders}
					      		renderListItem={this.renderLocationListItem}
					      		items={manageAccount.locations}
							/>
				    	</Card>
				    </FormDivider>

				    {!manageAccount.addMode && 
				    	
				    	<FormDivider>
					      <div className="mt-6">
					      	<Card title="Manage Account Agreements" actionBtn={
								<Button disabled={manageAccount.viewMode} onClick={this.onNewAccountAgreement}>New Agreement</Button>
							}>
						      	<List 
						      		type="agreements"
						      		renderListItem={this.renderAccountAgreementListItem}
						      		items={manageAccount.accountAgreements.agreements}
						      		page={manageAccount.accountAgreements.page}
									total={manageAccount.accountAgreements.total}
									hasNext={manageAccount.accountAgreements.hasNext}
									hasPrev={manageAccount.accountAgreements.hasPrev}
									offsetStart={manageAccount.accountAgreements.offsetStart}
									offsetEnd={manageAccount.accountAgreements.offsetEnd}
									onPreviousPage={() => manageAccount.onPreviousAgreementsPage()}
									onNextPage={() => manageAccount.onNextAgreementsPage()}
								/>
						    </Card>
					      </div>
					      <div className="mt-6">
					      	<Card title="Account Invoices">
						      	<List 
						      		type="invoices"
						      		renderListHeaders={this.renderAccountInvoiceListHeaders}
						      		renderListItem={this.renderAccountInvoiceListItem}
						      		items={manageAccount.accountInvoices.invoices}
						      		page={manageAccount.accountInvoices.page}
									total={manageAccount.accountInvoices.total}
									hasNext={manageAccount.accountInvoices.hasNext}
									hasPrev={manageAccount.accountInvoices.hasPrev}
									offsetStart={manageAccount.accountInvoices.offsetStart}
									offsetEnd={manageAccount.accountInvoices.offsetEnd}
									onPreviousPage={() => manageAccount.onPreviousAccountsPage()}
									onNextPage={() => manageAccount.onNextAccountsPage()}
								/>
						    </Card>
					      </div>
					      <div className="mt-6">
					      	<Card title="Account Credit Notes">
						      	<List 
						      		type="credit notes"
						      		renderListHeaders={this.renderAccountCreditNoteListHeaders}
						      		renderListItem={this.renderAccountCreditNoteListItem}
						      		items={manageAccount.accountCreditNotes.creditNotes}
						      		page={manageAccount.accountCreditNotes.page}
									total={manageAccount.accountCreditNotes.total}
									hasNext={manageAccount.accountCreditNotes.hasNext}
									hasPrev={manageAccount.accountCreditNotes.hasPrev}
									offsetStart={manageAccount.accountCreditNotes.offsetStart}
									offsetEnd={manageAccount.accountCreditNotes.offsetEnd}
									onPreviousPage={() => manageAccount.onPreviousCreditNotesPage()}
									onNextPage={() => manageAccount.onNextCreditNotesPage()}
								/>
						    </Card>
					      </div>
					   	</FormDivider>
					}
			    </div>
			</Main>
		)
	}
}

export default Authorisation(ManageAccount, ['administrator', 'employee', 'accountant']);