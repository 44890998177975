import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('journeyList')
@observer
class Journeys extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let journeyList = this.props.journeyList;
		journeyList.fetchJourneys();
	}

	componentWillUnmount(){
		let journeyList = this.props.journeyList;
		journeyList.initStore();
	}

	onPreviousPage(){
		let journeyList = this.props.journeyList;
		journeyList.onPreviousPage();
	}

	onNextPage(){
		let journeyList = this.props.journeyList;
		journeyList.onNextPage();
	}

	renderListItem(item){
		let journeyList = this.props.journeyList;
		return (
			<ListItem onEdit={() => journeyList.onEdit(item.id)}>
				<ItemColumn text={item.name} subtext={item.journey_reference} />
				<Status color={item.archived ? 'red' : 'green'} text={item.archived ? "Archived" : "Active"} />
			</ListItem>
		)
	}

	render(){
		let journeyList = this.props.journeyList;
		return (
			<Main>
				<Card title="Journeys" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Journey Name"
					  		onChange={(e) => journeyList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => journeyList.onNewJourney()}>New Journey</Button>
					}>
					<List 
						type="journeys"
						items={journeyList.journeys}
						renderListItem={this.renderListItem}
						page={journeyList.page}
						total={journeyList.total}
						hasNext={journeyList.hasNext}
						hasPrev={journeyList.hasPrev}
						offsetStart={journeyList.offsetStart}
						offsetEnd={journeyList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Journeys,  ['administrator', 'employee', 'accountant']);