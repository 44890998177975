import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPostcodeValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import CustomerApi from '../../services/customer';
import CommonApi from '../../services/common';
import AccountApi from '../../services/account';
import AgreementApi from '../../services/agreement';
import ProductApi from '../../services/product';
import AssetApi from '../../services/asset';
import ServiceApi from '../../services/service';
import JourneyApi from '../../services/journey';
import InvoiceApi from '../../services/invoice';
import CreditNoteApi from '../../services/credit-note';

import {buildAddressFromGoogleAddress} from '../../services/util';

import { uuid } from 'uuidv4';

import moment from 'moment';

const HOUR_FORMAT = 'HH:mm';

class OpeningHour{
	@observable dayId;
	@observable dayName;
	@observable active;
	@observable startTime;
	@observable endTime;

	constructor(dayId, dayName, active, startTime, endTime){
		this.dayId = dayId;
		this.dayName = dayName;
		this.active = new FieldState(active).validators((val) => isNotNullValidator(val, 'active'));
		this.startTime = new FieldState(startTime).validators((val) => isNotNullValidator(val, 'start time'));
		this.endTime = new FieldState(endTime).validators((val) => isNotNullValidator(val, 'end time'));
	}
}

class ManageAccount{
	id;

	@observable editMode;
	@observable viewMode;
	@observable addMode;

	@observable name;
	@observable accountReference;
	@observable selectedCurrency;
	@observable selectedCountry;
	@observable selectedStatus;
	@observable selectedAccountType;
	@observable selectedPaymentMethod;
	@observable selectedVATRate;
	@observable daysCredit;
	@observable creditLimit;
	@observable useCustomerDeliveryAddress;
	@observable useCustomerInvoiceAddress;
	@observable useSameAccountDeliveryAndBillingAddress;
	@observable vatNumber;
	@observable useCustomerContact;

	@observable contactName;
	@observable contactEmail;
	@observable contactJobTitle;
	@observable contactPhoneNumber;
	@observable contactMobilePhoneNumber;
	@observable contactFaxNumber;


	@observable contacts;

	@observable poRequired;

	@observable deliveryAddress;
	@observable invoiceAddress;

	@observable customers;
	@observable currencies;
	@observable countries;
	@observable paymentMethods;
	@observable accountStatuses;
	@observable accountTypes;

	@observable products;
	@observable vatRates;
	@observable assets;
	@observable services;

	@observable priceList;
	@observable assetPurchasePriceList;
	@observable assetRentalPriceList;
	@observable servicePriceList;

	@observable accountAgreements;
	@observable openingHours;
	@observable journeys;
	@observable accountJourneys;
	@observable locations;

	@observable accountInvoices;
	@observable accountCreditNotes;

	@observable fetching;

	@observable searchCustomerValue;
	@observable searchCustomerOptions;
	@observable selectedCustomer;

	customerApi;
	commonApi;
	accountApi;
	productApi;
	serviceApi;
	assetApi;
	agreementApi;
	journeyApi;
	invoiceApi;
	creditNoteApi;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.customerApi = new CustomerApi(this.uiStore);
		this.commonApi = new CommonApi(this.uiStore);
		this.accountApi = new AccountApi(this.uiStore);
		this.productApi = new ProductApi(this.uiStore);
		this.assetApi = new AssetApi(this.uiStore);
		this.serviceApi = new ServiceApi(this.uiStore);
		this.agreementApi = new AgreementApi(this.uiStore);
		this.journeyApi = new JourneyApi(this.uiStore);
		this.invoiceApi = new InvoiceApi(this.uiStore);
		this.creditNoteApi = new CreditNoteApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.accountReference = new FieldState('').validators((val) => isRequiredValidator(val, 'account reference'));
		this.selectedCurrency = new FieldState(null).validators((val) => isNotNullValidator(val, 'currency'));;
		this.selectedVATRate = new FieldState(null).validators((val) => isNotNullValidator(val, 'vat rate'));;
		this.selectedPaymentMethod = new FieldState(null).validators((val) => isNotNullValidator(val, 'payment method'));;
		this.selectedStatus = new FieldState(null).validators((val) => isNotNullValidator(val, 'account status'));;
		this.selectedAccountType = new FieldState(null).validators((val) => isNotNullValidator(val, 'account type'));;
		this.daysCredit = new FieldState(null).validators((val) => val != null && isPositiveNumberValidator(val));
		this.creditLimit = new FieldState(null).validators((val) => val != null && isPositiveNumberValidator(val));
		this.useCustomerDeliveryAddress = new FieldState(false);
		this.useCustomerInvoiceAddress = new FieldState(false);
		this.useSameAccountDeliveryAndBillingAddress = new FieldState(false);
		this.accountStatuses = [];
		this.accountTypes = [];
		this.customers = [];
		this.currencies = [];
		this.countries = [];
		this.locations = [];
		this.paymentMethods = [];
		this.deliveryAddress = new FormState({
			searchAddress: new FieldState(''),
			streetAddress : new FieldState('').validators((val) => !this.useCustomerDeliveryAddress.value && isRequiredValidator(val, 'street address')),
			city: new FieldState('').validators((val) => !this.useCustomerDeliveryAddress.value && isRequiredValidator(val, 'city')),
			selectedCountry: new FieldState(null).validators((val) => !this.useCustomerDeliveryAddress.value && isNotNullValidator(val, 'country')),
			county: new FieldState('').validators((val) => !this.useCustomerDeliveryAddress.value && isRequiredValidator(val, 'county')),
			postcode: new FieldState('').validators((val) => !this.useCustomerDeliveryAddress.value && isPostcodeValidator(val, 'postcode')),
			latitude: new FieldState(null),
			longitude: new FieldState(null)
		})

		this.invoiceAddress = new FormState({
			searchAddress: new FieldState(''),
			streetAddress : new FieldState('').validators((val) => !this.useCustomerInvoiceAddress.value && !this.useSameAccountDeliveryAndBillingAddress.value && isRequiredValidator(val, 'street address')),
			city: new FieldState('').validators((val) => !this.useCustomerInvoiceAddress.value && !this.useSameAccountDeliveryAndBillingAddress.value && isRequiredValidator(val, 'city')),
			selectedCountry: new FieldState(null).validators((val) => !this.useCustomerInvoiceAddress.value && !this.useSameAccountDeliveryAndBillingAddress.value && isNotNullValidator(val, 'country')),
			county: new FieldState('').validators((val) => !this.useCustomerInvoiceAddress.value && !this.useSameAccountDeliveryAndBillingAddress.value && isRequiredValidator(val, 'county')),
			postcode: new FieldState('').validators((val) => !this.useCustomerInvoiceAddress.value && !this.useSameAccountDeliveryAndBillingAddress.value && isPostcodeValidator(val, 'postcode')),
			latitude: new FieldState(null),
			longitude: new FieldState(null)
		});
		this.vatNumber = new FieldState('');
		this.useCustomerContact = new FieldState(false);
		this.contactName = new FieldState('').validators((val) => !this.useCustomerContact.value && isRequiredValidator(val, 'contact name'));
		this.contactEmail = new FieldState('').validators((val) => !this.useCustomerContact.value && isEmailValidator(val));
		this.contactJobTitle = new FieldState('');
		this.contactPhoneNumber = new FieldState('').validators((val) => !this.useCustomerContact.value && isRequiredValidator(val, 'contact phone number'));
		this.contactMobilePhoneNumber = new FieldState('');
		this.contactFaxNumber = new FieldState('');
		this.poRequired = new FieldState(false);
		this.fetching = false;
		this.priceList = [];
		this.assetPurchasePriceList = [];
		this.assetRentalPriceList = [];
		this.servicePriceList = [];
		this.products = [];
		this.vatRates = [];
		this.assets = [];
		this.services = [];
		this.openingHours = [
			new OpeningHour(0, "Monday", true, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT)),
			new OpeningHour(1, "Tuesday", true, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT)),
			new OpeningHour(2, "Wednesday", true, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT)),
			new OpeningHour(3, "Thursday", true, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT)),
			new OpeningHour(4, "Friday", true, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT)),
			new OpeningHour(5, "Saturday", false, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT)),
			new OpeningHour(6, "Sunday", false, moment().set("hour", 9).set("minute", 0).format(HOUR_FORMAT), moment().set("hour", 17).set("minute", 0).format(HOUR_FORMAT))
		]
		this.form = new FormState({
			name: this.name,
			accountReference: this.accountReference,
			selectedCurrency: this.selectedCurrency,
			selectedVATRate: this.selectedVATRate,
			selectedPaymentMethod: this.selectedPaymentMethod,
			selectedStatus: this.selectedStatus,
			daysCredit: this.daysCredit,
			creditLimit: this.creditLimit,
			useCustomerDeliveryAddress: this.useCustomerDeliveryAddress,
			useCustomerInvoiceAddress: this.useCustomerInvoiceAddress,
			useSameAccountDeliveryAndBillingAddress: this.useSameAccountDeliveryAndBillingAddress,
			deliveryAddress: this.deliveryAddress,
			invoiceAddress: this.invoiceAddress,
			vatNumber: this.vatNumber,
			useCustomerContact: this.useCustomerContact,
			poRequired: this.poRequired
		})

		this.contacts = [];
		this.addMode = true;
		this.viewMode = false
		this.editMode = false;

		this.accountAgreements = {
			agreements: [],
			page: 1,
			total: 0,
			hasNext: false,
			hasPrev: false,
			offsetStart: null,
			offsetEnd: null
		};

		this.accountInvoices = {
			invoices: [],
			page: 1,
			total: 0,
			hasNext: false,
			hasPrev: false,
			offsetStart: null,
			offsetEnd: null
		};

		this.accountCreditNotes = {
			creditNotes: [],
			page: 1,
			total: 0,
			hasNext: false,
			hasPrev: false,
			offsetStart: null,
			offsetEnd: null
		};
		this.journeys = [];
		this.accountJourneys = [];

		this.searchCustomerValue = null;
		this.searchCustomerOptions = [];
		this.selectedCustomer = null;
	}

	fetchCountries = async () => {
		try{
			let response  = await this.commonApi.getAllCountries();
			this.countries = response.countries;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchProducts = async () => {
		try{
			this.products  = await this.productApi.getAllProducts();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchAssets = async () => {
		try{
			this.assets  = await this.assetApi.fetchAllAssets();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchServices = async () => {
		try{
			this.services  = await this.serviceApi.fetchAllServices();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchJourneys = async () => {
		try{
			this.journeys  = await this.journeyApi.fetchAllJourneys();
		}catch(error){ 
			console.log(error);
		}
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchPaymentMethods = async () => {
		try{
			let response = await this.commonApi.getAllPaymentMethods();
			this.paymentMethods = response.payment_methods;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchAccountStatuses = async () => {
		try{
			let response = await this.accountApi.getAllStatuses();
			this.accountStatuses = response.account_statuses;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchAccountTypes = async () => {
		try{
			let response = await this.accountApi.getAllAccountTypes();
			this.accountTypes = response.account_types;
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get customerOptions(){
		return this.customers.map((customer) => {
			return {
				label: `${customer.name} (${customer.customer_reference})`,
				value: customer.id
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				label: `${currency.name} (${currency.symbol})`,
				value: currency.id
			}
		})
	}

	@computed get accountStatusOptions(){
		return this.accountStatuses.map((status) => {
			return {
				label: status.name,
				value: status.id
			}
		})
	}

	@computed get accountTypeOptions(){
		return this.accountTypes.map((accountType) => {
			return {
				label: accountType.name,
				value: accountType.id
			}
		})
	}

	@computed get countryOptions(){
		return this.countries.map((country) => {
			return {
				label: country.name,
				value: country.id
			}
		})
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get productOptions(){
		return this.products.map((product) => {
			return {
				label: product.name,
				value: product.id
			}
		})
	}

	@computed get paymentMethodOptions(){
		return this.paymentMethods.map((paymentMethod) => {
			return {
				value: paymentMethod.id,
				label: paymentMethod.name
			}
		})
	}

	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: vatRate.id,
				label: vatRate.name
			}
		})
	}

	@computed get assetOptions(){
		return this.assets.map((asset) => {
			return {
				value: asset.id,
				label: asset.name
			}
		})
	}

	@computed get serviceOptions(){
		return this.services.map((service) => {
			return {
				value: service.id,
				label: service.name
			}
		})
	}

	@computed get journeyOptions(){
		return this.journeys.map((journey) => {
			return {
				value: journey.id,
				label: `${journey.name} - Repeats every ${journey.repeats_every_days} days`
			}
		})
	}

	@computed get currentCustomer(){
		return this.selectedCustomer != null ? this.customers.find((c) => c.id == this.selectedCustomer.value): null;
	}


	onChangeCurrency(val){
		this.selectedCurrency.value = val;
	}

	onChangeVATRate(val){
		this.selectedVATRate.value = val;
	}

	onChangePaymentMethod(val){
		this.selectedPaymentMethod.value = val;
	}

	onChangeAccountStatus(val){
		this.selectedStatus.value = val;
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	@action addContactListEntry(){
		this.contacts.push(this.generateAccountContact())
	}

	@action addLocationEntry(){
		this.locations.push(this.generateAccountLocation())
	}

	formSync(accountId){
		this.viewMode = true;
		this.editMode = false;
		this.addMode = false;

		this.accountApi.getAccountById(accountId)
			.then((response) => {
				let account = response.account;
				this.id = account.id;
				this.toSelectedCustomer(account.customer);
				this.name.value = account.name;
				this.accountReference.value = account.account_reference;
				this.selectedStatus.value = account.account_status_id;
				this.selectedAccountType.value = account.account_type_id;
				this.creditLimit.value = account.credit_limit;
				this.daysCredit.value = account.days_credit;
				this.selectedCurrency.value = account.currency.id;
				this.selectedVATRate.value = account.vat_rate.id;
				this.selectedPaymentMethod.value = account.payment_method_id;
				this.poRequired.value = account.po_required;
				this.useCustomerDeliveryAddress.value = account.use_customer_address_for_delivery_address;
				this.useCustomerInvoiceAddress.value = account.use_customer_address_for_invoice_address;
				this.useSameAccountDeliveryAndBillingAddress.value = account.use_same_account_delivery_and_billing_address;
				this.useCustomerContact.value = account.use_customer_contact;
				this.vatNumber.value = account.vat_number;
				this.deliveryAddress.$.streetAddress.value = account.delivery_address.street_address;
				this.deliveryAddress.$.city.value = account.delivery_address.city;
				this.deliveryAddress.$.selectedCountry.value = account.delivery_address.country.id;
				this.deliveryAddress.$.county.value = account.delivery_address.county;
				this.deliveryAddress.$.postcode.value = account.delivery_address.postcode;
				this.deliveryAddress.$.latitude.value = account.delivery_address.latitude;
				this.deliveryAddress.$.longitude.value = account.delivery_address.longitude;
				this.invoiceAddress.$.streetAddress.value = account.invoice_address.street_address;
				this.invoiceAddress.$.city.value = account.invoice_address.city;
				this.invoiceAddress.$.selectedCountry.value = account.invoice_address.country.id;
				this.invoiceAddress.$.county.value = account.invoice_address.county;
				this.invoiceAddress.$.postcode.value = account.invoice_address.postcode;
				this.invoiceAddress.$.latitude.value = account.invoice_address.latitude;
				this.invoiceAddress.$.longitude.value = account.invoice_address.longitude;
					
				this.contacts = account.contacts.map((contact) => {
					return this.generateAccountContact(contact.id, contact.name, contact.job_title, contact.email, contact.phone_number, contact.mobile_phone_number, contact.fax_number, contact.primary_contact);
				});

				this.priceList = account.product_prices.map((priceItem) => {
					return this.generatePriceListEntry(priceItem.id, priceItem.product.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price)
				});

				this.assetRentalPriceList = account.asset_rental_prices.map((priceItem) => {
					return this.generateAssetRentalPriceListEntry(priceItem.id, priceItem.asset.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price, priceItem.days)
				})
				this.assetPurchasePriceList = account.asset_purchase_prices.map((priceItem) => {
					return this.generateAssetPurchasePriceListEntry(priceItem.id, priceItem.asset.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price)
				})
				this.servicePriceList = account.service_prices.map((priceItem) => {
					return this.generateServicePriceListEntry(priceItem.id, priceItem.service.id, priceItem.currency.id, priceItem.vat_rate.id, priceItem.price, priceItem.recurring, priceItem.recurring_days)
				})
				this.openingHours = account.opening_hours.map((openingHour) => {
					let startTime = moment(openingHour.start_time, 'HH:mm:ss').format(HOUR_FORMAT);
					let endTime = moment(openingHour.end_time, 'HH:mm:ss').format(HOUR_FORMAT);
					return new OpeningHour(openingHour.day.day_id, openingHour.day.name, openingHour.active, startTime, endTime);
				})

				this.accountJourneys = account.journeys.map((journey) => {
					return this.generateJourneyEntry(journey.id);
				})

				this.locations = account.locations.map((location) => {
					return this.generateAccountLocation(location.id, location.name, location.location_code, location.parent_name, location.parent_code);
				})

				this.fetchAccountAgreements();

				this.fetchAccountInvoices();
				this.fetchAccountCreditNotes();
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onCancel(){
		this.uiStore.goToAccounts();
	}

	onChangePORequired(val){
		this.poRequired.value = val;
	}

	onChangeUseCustomerDeliveryAddress(val){
		this.useCustomerDeliveryAddress.value = val;
		this.useSameAccountDeliveryAndBillingAddress.value = false;
	}

	onChangeUseSameAccountDeliveryAndBillingAddress(val){
		this.useSameAccountDeliveryAndBillingAddress.value = val;
		this.useCustomerInvoiceAddress.value = false;
	}

	onChangeUseCustomerInvoiceAddress(val){
		this.useCustomerInvoiceAddress.value = val;
		this.useSameAccountDeliveryAndBillingAddress.value = false;
	}

	onChangeUseCustomerContact(val){
		this.useCustomerContact.value = val;
	}

	onChangePostcode(val){
		this.postcode.value = val;
	}

	@action addPriceListEntry(){
		this.priceList.push(this.generatePriceListEntry())
	}

	@action addAssetPurchasePriceListEntry(){
		this.assetPurchasePriceList.push(this.generateAssetPurchasePriceListEntry())
	}

	@action addAssetRentalPriceListEntry(){
		this.assetRentalPriceList.push(this.generateAssetRentalPriceListEntry())
	}

	@action addServicePriceListEntry(){
		this.servicePriceList.push(this.generateServicePriceListEntry())
	}

	@action addJourneyEntry(){
		this.accountJourneys.push(this.generateJourneyEntry())
	}

	changePriceListItemProduct(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedProduct');
	}
	
	changePriceListItemCurrency(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedCurrency');
	}

	changePriceListItemPrice(uuid, val){
		this.setPriceListItem(uuid, val, 'price');
	}

	setPriceListItem(uuid, val, key){
		let entry = this.priceList.find((p) => p.uuid == uuid);
		if(entry != null){
			entry[key].onChange(val);
		}
	}

	changePriceListItemVATRate(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedVATRate');
	}

	deletePriceListItem(uuid){
		this.priceList = this.priceList.filter((p) => p.uuid != uuid);
	}

	deleteAssetPurchasePriceListItem(uuid){
		this.assetPurchasePriceList = this.assetPurchasePriceList.filter((p) => p.uuid != uuid);
	}

	deleteAssetRentalPriceListItem(uuid){
		this.assetRentalPriceList = this.assetRentalPriceList.filter((p) => p.uuid != uuid);
	}

	deleteServicePriceListItem(uuid){
		this.servicePriceList = this.servicePriceList.filter((p) => p.uuid != uuid);
	}

	deleteJourneyListItem(uuid){
		this.accountJourneys = this.accountJourneys.filter((p) => p.uuid != uuid);	
	}

	deleteLocationListItem(uuid){
		this.locations = this.locations.filter((p) => p.uuid != uuid);		
	}

	@action generatePriceListEntry(id=null, productId=null, currencyId=null, vatRateId=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedProduct: new FieldState(productId).validators((val) => isNotNullValidator(val, 'product')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				return `${currency.symbol}${totalPrice}`
			}
		})
	}

	@action generateAssetPurchasePriceListEntry(id=null, assetId=null, currencyId=null, vatRateId=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedAsset.value == null || this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				return `${currency.symbol}${totalPrice}`
			}
		})
	}

	@action generateAssetRentalPriceListEntry(id=null, assetId=null, currencyId=null, vatRateId=null, price=null, days=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedAsset.value == null || this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null || this.days.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				if(this.days.error != undefined) return null;
				let days = parseInt(this.days.value, 10);
				return `${currency.symbol}${totalPrice} every ${days} ${days > 1 ? 'days' : 'day'}`
			}
		})
	}

	@action generateServicePriceListEntry(id=null, serviceId=null, currencyId=null, vatRateId=null, price=null, recurring=false, days=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedService: new FieldState(serviceId).validators((val) => isNotNullValidator(val, 'service')),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			recurring: new FieldState(recurring).validators((val) => isNotNullValidator(val, 'recurring')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators((val) => val != null && isPositiveNumberValidator(val)),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				let isRecurring = this.recurring.value;
				if(isRecurring && this.days.error != undefined) return null;
				let priceMsg = `${currency.symbol}${totalPrice}`
				let days = this.days.value;
				if(isRecurring && days != null){
					priceMsg = `${priceMsg} every ${days} ${days > 1 ? 'days' : 'day'}`
				}
				return priceMsg
			}
		})
	}

	@action generateJourneyEntry(id=null){
		let that = this;
		return observable.object({
			uuid: uuid(),
			selectedJourney: new FieldState(id).validators((val) => isNotNullValidator(val, 'journey'))
		})
	}

	save = async () => {
		if(this.selectedCustomer?.value == null){
			this.uiStore.alertError('Please select a customer');
			return;
		}
		let res = await this.form.validate();
		if(res.hasError){
			return;
		}

		const priceListForm = new FormState(this.priceList.map((priceListItem) => {
			return new FormState({
				price: priceListItem.price,
				selectedProduct: priceListItem.selectedProduct,
				selectedVATRate: priceListItem.selectedVATRate,
				selectedCurrency: priceListItem.selectedCurrency
			});
		}));
		let priceListValidation = await priceListForm.validate();
		if(priceListValidation.hasError) return;

		let contacts = [];
		if(this.addMode){
			if(!this.useCustomerContact.value){
				let accountContactForm = new FormState({
					name: this.contactName,
					job_title: this.contactJobTitle,
					email: this.contactEmail,
					phone_number: this.contactPhoneNumber,
					mobile_phone_number: this.contactMobilePhoneNumber,
					fax_number: this.contactFaxNumber
				})
				let accountContactFormRes = await accountContactForm.validate();
				if(accountContactFormRes.hasError) return;
				contacts.push({
					name: this.contactName.value,
					job_title: this.contactJobTitle.value,
					email: this.contactEmail.value,
					phone_number: this.contactPhoneNumber.value,
					fax_number: this.contactFaxNumber.value,
					mobile_phone_number: this.contactMobilePhoneNumber.value,
					primary_contact: true
				});
			}
		}else{
			let invalidContactFormEntry = false;
			for(let contact of this.contacts){

				const contactFormRes = await new FormState({
					name: contact.name,
					jobTitle: contact.jobTitle,
					email: contact.email,
					phoneNumber: contact.phoneNumber,
					mobilePhoneNumber: contact.mobilePhoneNumber,
					faxNumber: contact.faxNumber
				}).validate();
		    	if(contactFormRes.hasError){
		    		invalidContactFormEntry = true;
		    		break;
		    	}

		    	contacts.push({
		    		name: contact.name.value,
					email: contact.email.value,
					job_title: contact.jobTitle.value,
					phone_number: contact.phoneNumber.value,
					fax_number: contact.faxNumber.value,
					mobile_phone_number: contact.mobilePhoneNumber.value,
					primary_contact: contact.primaryContact.value
		    	});
			}

			if(invalidContactFormEntry){
				return;
			}
		}

		let accountDeliveryAddress = this.useCustomerDeliveryAddress.value ? null : {
			street_address: this.deliveryAddress.$.streetAddress.value,
			country_id: parseInt(this.deliveryAddress.$.selectedCountry.value, 10),
			city: this.deliveryAddress.$.city.value,
			postcode: this.deliveryAddress.$.postcode.value,
			county: this.deliveryAddress.$.county.value,
			latitude: this.deliveryAddress.$.latitude.value,
			longitude: this.deliveryAddress.$.longitude.value
		}

		let accountInvoiceAddress = this.useCustomerInvoiceAddress.value || (this.useSameAccountDeliveryAndBillingAddress.value && accountDeliveryAddress != null) ? null : {
			street_address: this.invoiceAddress.$.streetAddress.value,
			country_id: parseInt(this.invoiceAddress.$.selectedCountry.value, 10),
			city: this.invoiceAddress.$.city.value,
			postcode: this.invoiceAddress.$.postcode.value,
			county: this.invoiceAddress.$.county.value,
			latitude: this.invoiceAddress.$.latitude.value,
			longitude: this.invoiceAddress.$.longitude.value
		}

		let accountPayload = {
			name: this.name.value,
			customer_id: this.selectedCustomer.value,
			account_reference: this.accountReference.value,
			account_status_id: parseInt(this.selectedStatus.value, 10),
			account_type_id: parseInt(this.selectedAccountType.value, 10),
			payment_method_id: parseInt(this.selectedPaymentMethod.value, 10),
			currency_id: parseInt(this.selectedCurrency.value, 10),
			days_credit: this.daysCredit.value,
			credit_limit: this.creditLimit.value,
			po_required: this.poRequired.value,
			vat_rate_id: parseInt(this.selectedVATRate.value, 10),
			vat_number: this.vatNumber.value,
			use_customer_address_for_delivery_address: this.useCustomerDeliveryAddress.value,
			use_customer_address_for_invoice_address: this.useCustomerInvoiceAddress.value,
			use_same_account_delivery_and_billing_address: this.useSameAccountDeliveryAndBillingAddress.value,
			delivery_address: accountDeliveryAddress,
			invoice_address: accountInvoiceAddress,
			use_customer_contact: this.useCustomerContact.value,
			contacts: contacts,
			product_prices: this.priceList.map((priceListEntry) => {
				return {
					id: priceListEntry.id,
					product_id: priceListEntry.selectedProduct.value,
					price: parseFloat(priceListEntry.price.value).toFixed(4),
					currency_id: parseInt(priceListEntry.selectedCurrency.value),
					vat_rate_id: parseInt(priceListEntry.selectedVATRate.value)
				}
			}),
			asset_rental_prices: this.assetRentalPriceList.map((rentalPrice) => {
				return {
					id: rentalPrice.id,
					asset_id: rentalPrice.selectedAsset.value,
					currency_id: rentalPrice.selectedCurrency.value,
					vat_rate_id: rentalPrice.selectedVATRate.value,
					price: parseFloat(rentalPrice.price.value).toFixed(4),
					days: parseInt(rentalPrice.days.value, 10)
				}
			}),
			asset_purchase_prices: this.assetPurchasePriceList.map((purchasePrice) => {
				return {
					id: purchasePrice.id,
					asset_id: purchasePrice.selectedAsset.value,
					currency_id: purchasePrice.selectedCurrency.value,
					vat_rate_id: purchasePrice.selectedVATRate.value,
					price: parseFloat(purchasePrice.price.value).toFixed(4)
				}
			}),
			service_prices: this.servicePriceList.map((servicePriceListEntry) => {
				return {
					id: servicePriceListEntry.id,
					service_id: servicePriceListEntry.selectedService.value,
					currency_id: servicePriceListEntry.selectedCurrency.value,
					vat_rate_id: servicePriceListEntry.selectedVATRate.value,
					price: parseFloat(servicePriceListEntry.price.value).toFixed(4),
					recurring: servicePriceListEntry.recurring.value,
					recurring_days: parseInt(servicePriceListEntry.days.value, 10)
				}
			}),
			opening_hours: this.openingHours.map((openingHour) => {
				return {
					day_id: openingHour.dayId,
					start_time: openingHour.startTime.value,
					end_time: openingHour.endTime.value,
					active: openingHour.active.value
				}
			}),
			journey_ids: this.accountJourneys.map((accountJourney) => {
				return accountJourney.selectedJourney.value;
			}),
			locations: this.locations.map((location) => {
				return {
					id: location.id,
					name: location.name.value,
					code: location.code.value,
					parent_code: location.parentCode.value,
					parent_name: location.parentName.value
				}
			})
		}

		this.fetching = true;
		if(this.addMode){
			this.accountApi.newAccount(accountPayload)
				.then((response) => {
					this.uiStore.alertSuccess(`Account successfully added`);
					this.uiStore.goToAccounts();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}else{
			this.accountApi.updateAccount(this.id, accountPayload)
				.then((response) => {
					this.uiStore.alertSuccess(`Account successfully updated`);
					this.uiStore.goToAccounts();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fetching = false;
				})
		}
	}

	@action fetchAccountAgreements(){
		this.agreementApi.getAll(this.accountAgreements.page, this.id)
			.then((response) => {
				this.accountAgreements.agreements = response.agreements;
				this.accountAgreements.hasNext = response.has_next;
				this.accountAgreements.hasPrev = response.has_prev;
				this.accountAgreements.page = response.page;
				this.accountAgreements.offsetStart = response.offset_start;
				this.accountAgreements.offsetEnd = response.offset_end;
				this.accountAgreements.total = response.total;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	@action onPreviousAccountsPage(){
		this.accountInvoices.page = Math.max(this.accountInvoices.page-1, 0);
		this.fetchAccountInvoices();
	}

	@action onNextAccountsPage(){
		this.accountInvoices.page += 1;
		this.fetchAccountInvoices();
	}

	@action fetchAccountInvoices(){
		this.invoiceApi.getInvoices(this.accountInvoices.page, this.id)
			.then((response) => {
				this.accountInvoices.invoices = response.invoices.map((i) => {
					i.logged_on = moment(i.logged_on);
					return i;
				});
				this.accountInvoices.hasNext = response.has_next;
				this.accountInvoices.hasPrev = response.has_prev;
				this.accountInvoices.page = response.page;
				this.accountInvoices.offsetStart = response.offset_start;
				this.accountInvoices.offsetEnd = response.offset_end;
				this.accountInvoices.total = response.total;
			})
			.catch((error) => {
				console.log(error)
			})
	}


	@action onPreviousCreditNotesPage(){
		this.accountCreditNotes.page = Math.max(this.accountCreditNotes.page-1, 0);
		this.fetchAccountCreditNotes();
	}

	@action onNextCreditNotesPage(){
		this.accountCreditNotes.page += 1;
		this.fetchAccountCreditNotes();
	}

	@action fetchAccountCreditNotes(){
		this.creditNoteApi.getCreditNotes(this.accountCreditNotes.page, this.id)
			.then((response) => {
				this.accountCreditNotes.creditNotes = response.credit_notes.map((cn) => {
					cn.logged_on = moment(cn.logged_on);
					return cn;
				});
				this.accountCreditNotes.hasNext = response.has_next;
				this.accountCreditNotes.hasPrev = response.has_prev;
				this.accountCreditNotes.page = response.page;
				this.accountCreditNotes.offsetStart = response.offset_start;
				this.accountCreditNotes.offsetEnd = response.offset_end;
				this.accountCreditNotes.total = response.total;
			})
			.catch((error) => {
				console.log(error)
			})
	}

	@action onPreviousAgreementsPage(){
		this.accountAgreements.page = Math.max(this.accountAgreements.page-1, 0);
		this.fetchAccountAgreements();
	}

	@action onNextAgreementsPage(){
		this.accountAgreements.page += 1;
		this.fetchAccountAgreements();
	}

	@action onEditAgreement(id){
		this.uiStore.goToEditAgreement(id);
	}

	@action onNewAccountAgreement(){
		this.uiStore.goToNewAgreement(this.id);
	}

	@computed get timeInputOptions(){
		let times = []
		for(let hour = 0; hour < 24; hour++){
			for(let minute = 0; minute < 60; minute += 15){
				times.push(moment({hour, minute }));
			}
		}
		return times.map((time) => {
			return {
				value: time.format(HOUR_FORMAT),
				label: time.format(HOUR_FORMAT)
			}
		});
	}

	@action cloneOpeningHours(){
		let firstEntry = this.openingHours[0];
		for(let i = 1; i < this.openingHours.length; i++){
			this.openingHours[i].startTime.value = firstEntry.startTime.value;
			this.openingHours[i].endTime.value = firstEntry.endTime.value;
		}
	}

	@action receivedDeliveryAddressCoords(coords){
		this.deliveryAddress.$.latitude.value = coords?.lat;
		this.deliveryAddress.$.longitude.value = coords?.lng;
	}

	@action receivedInvoiceAddressCoords(coords){
		this.invoiceAddress.$.latitude.value = coords?.lat;
		this.invoiceAddress.$.longitude.value = coords?.lng;
	}

	@action onReceivedDeliveryAddressComponents(streetAddress, addressComponents){
		let address = buildAddressFromGoogleAddress(addressComponents);
		this.deliveryAddress.$.streetAddress.value = streetAddress;
		this.deliveryAddress.$.city.value = address.city != null ? address.city : '';

		let countryCode = address.country;
		if(countryCode != null){
			let matchingCountry = this.countries.find((c) => c.iso_alpha_3_code == countryCode);
			if(matchingCountry != null){
				this.deliveryAddress.$.selectedCountry.value = matchingCountry.id;
			}else{
				this.deliveryAddress.$.selectedCountry.value = null;
			}
		}else{
			this.deliveryAddress.$.selectedCountry.value = null;
		}
		
		this.deliveryAddress.$.county.value = address.county != null ? address.county: '';
		this.deliveryAddress.$.postcode.value = address.postal_code != null ? address.postal_code : '';
	}

	@action onReceivedInvoiceAddressComponents(streetAddress, addressComponents){
		let address = buildAddressFromGoogleAddress(addressComponents);
		this.invoiceAddress.$.streetAddress.value = streetAddress;
		this.invoiceAddress.$.city.value = address.city != null ? address.city : '';

		let countryCode = address.country;
		if(countryCode != null){
			let matchingCountry = this.countries.find((c) => c.iso_alpha_3_code == countryCode);
			if(matchingCountry != null){
				this.invoiceAddress.$.selectedCountry.value = matchingCountry.id;
			}else{
				this.invoiceAddress.$.selectedCountry.value = null;
			}
		}else{
			this.invoiceAddress.$.selectedCountry.value = null;
		}
		
		this.invoiceAddress.$.county.value = address.county != null ? address.county: '';
		this.invoiceAddress.$.postcode.value = address.postal_code != null ? address.postal_code : '';
	}

	@computed get hasDeliveryAddressCoords(){
		return this.deliveryAddress.$.latitude.value != null && this.deliveryAddress.$.longitude.value != null;
	}

	@computed get hasInvoiceAddressCoords(){
		return this.invoiceAddress.$.latitude.value != null && this.invoiceAddress.$.longitude.value != null;
	}

	@computed get deliveryAddressCoords(){
		if(!this.hasDeliveryAddressCoords) return null;
		return {
			latitude: this.deliveryAddress.$.latitude.value,
			longitude: this.deliveryAddress.$.longitude.value,
		}
	}

	@computed get invoiceAddressCoords(){
		if(!this.hasInvoiceAddressCoords) return null;
		return {
			latitude: this.invoiceAddress.$.latitude.value,
			longitude: this.invoiceAddress.$.longitude.value,
		}
	}

	@computed get deliveryAddressLocationMarkers(){
		if(this.hasDeliveryAddressCoords){
			return [{
				id:0,
				latitude: this.deliveryAddress.$.latitude.value,
				longitude: this.deliveryAddress.$.longitude.value
			}]
		}else{
			return [];
		}
	}

	@computed get invoiceAddressLocationMarkers(){
		if(this.hasInvoiceAddressCoords){
			return [{
				id:0,
				latitude: this.invoiceAddress.$.latitude.value,
				longitude: this.invoiceAddress.$.longitude.value
			}]
		}else{
			return [];
		}
	}

	@action generateAccountContact(id=null, name=null, jobTitle=null, email=null, phoneNumber=null, mobilePhoneNumber=null, faxNumber=null, primaryContact=false){
		return observable.object({
			id: id,
			uuid: uuid(),
			name: new FieldState(name).validators((val) => isRequiredValidator(val, 'name')),
			jobTitle: new FieldState(jobTitle).validators((val) => isRequiredValidator(val, 'job title')),
			email: new FieldState(email).validators(isEmailValidator),
			phoneNumber: new FieldState(phoneNumber).validators((val) => isRequiredValidator(val, 'phone number')),
			mobilePhoneNumber: new FieldState(mobilePhoneNumber),
			faxNumber: new FieldState(faxNumber),
			primaryContact: new FieldState(primaryContact),
		})
	}

	@action generateAccountLocation(id=null, name=null, code=null, parentName=null, parentCode=null){
		return observable.object({
			id: id,
			uuid: uuid(),
			name: new FieldState(name).validators((val) => isRequiredValidator(val, 'name')),
			code: new FieldState(code),
			parentCode: new FieldState(parentCode),
			parentName: new FieldState(parentName)
		})
	}

	deleteContactListItem(uuid){
		this.contacts = this.contacts.filter((c) => c.uuid != uuid);
	}

	markContactListItemAsPrimary(uuid){
		let contactListItemIdx = this.contacts.findIndex((c) => c.uuid == uuid);
		if(contactListItemIdx != -1){
			for(let i = 0; i < this.contacts.length; i++){
				this.contacts[i].primaryContact.onChange(false);
			}

			this.contacts[contactListItemIdx].primaryContact.onChange(true);
		}
	}

	@computed get currentCustomerAddress(){
		if(this.selectedCustomer?.value == null) return null;
		let customer = this.customers.find((c) => c.id == this.selectedCustomer.value);
		if(customer == null) return null;
		let address = `${customer.street_address}, ${customer.city}, ${customer.county}, ${customer.country.name}`;
		if(customer.postcode != null){
			address = `${address}, ${customer.postcode}`;
		}
		return address;
	}

	onChangeSearchCustomerValue(val){
		this.searchCustomerValue = val;

		this.searchCustomers();
	}


	searchCustomers(){
		if(this.searchCustomerValue == ''){
			this.searchCustomerOptions = [];
		}else{
			this.customerApi.getAll(1, this.searchCustomerValue)
				.then((response) => {
					this.searchCustomerOptions = response.customers.map((customer) => {
						return {
							label: `${customer.name} (${customer.customer_reference})`,
							value: customer.id 
						}
					})
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}

	onSelectCustomer(id){
		let selectedCustomer = this.searchCustomerOptions.find((a) => a.value == id);
		if(selectedCustomer != null){
			this.selectedCustomer = selectedCustomer;
			this.searchCustomerOptions = [];
		}
	}

	onLoadCustomer(id){
		this.customerApi.getById(id)
			.then((response) => {
				let customer = response.customer;
				
				this.toSelectedCustomer(customer);
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onClearSelectedValue(){
		this.selectedCustomer = {
			label: 'None',
			value: -1
		};
		this.searchCustomerValue = '';
	}

	toSelectedCustomer(customer){
		this.selectedCustomer = {
			label: `${customer.name} (${customer.customer_reference})`,
			value: customer.id
		}
	}
}

export default ManageAccount;