import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import AssetApi from '../../services/asset';
import CommonApi from '../../services/common'

import moment from 'moment';

import { uuid } from 'uuidv4';

class ManageAsset{
	assetApi;

	@observable addMode;
	@observable viewMode;
	@observable editMode;

	id;
	@observable name;

	@observable fetching;

	@observable defaultPurchasePrices;
	@observable defaultRentalPrices;
	@observable currencies;
	@observable vatRates;

	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.assetApi = new AssetApi(this.uiStore);
		this.commonApi = new CommonApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.id = null;
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.modelNumber = new FieldState('').validators((val) => isRequiredValidator(val, 'model number'));

		this.form = new FormState({
			name: this.name,
			modelNumber: this.modelNumber
		});
		this.defaultPurchasePrices = [];
		this.defaultRentalPrices = [];
		this.vatRates = [];
		this.currencies = [];
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get validForm(){
		return this.name.value != null && this.name.value.length > 0;
	}

	@action save = async () => {
		const purchasePriceListForm = new FormState(this.defaultPurchasePrices.map((priceListItem) => {
			return new FormState({
				price: priceListItem.price,
				selectedVATRate: priceListItem.selectedVATRate,
				selectedCurrency: priceListItem.selectedCurrency,
			});
		}));

		const rentalPriceListForm = new FormState(this.defaultRentalPrices.map((priceListItem) => {
			return new FormState({
				price: priceListItem.price,
				selectedVATRate: priceListItem.selectedVATRate,
				selectedCurrency: priceListItem.selectedCurrency,
				days: priceListItem.days
			})
		}));

		
		let purchasePriceListValidation = await purchasePriceListForm.validate();
		if(purchasePriceListValidation.hasError) return;

		let rentalPriceListValidation = await rentalPriceListForm.validate();
		if(rentalPriceListValidation.hasError) return;

		const res = await this.form.validate();
	    if(res.hasError) return;

	    let payload = {
			name: this.name.value,
			model_number: this.modelNumber.value,
			rental_prices: this.defaultRentalPrices.map((rentalPrice) => {
				return {
					id: rentalPrice.id,
					currency_id: rentalPrice.selectedCurrency.value,
					vat_rate_id: rentalPrice.selectedVATRate.value,
					price: parseFloat(rentalPrice.price.value).toFixed(4),
					days: parseInt(rentalPrice.days.value, 10)
				}
			}),
			purchase_prices: this.defaultPurchasePrices.map((purchasePrice) => {
				return {
					id: purchasePrice.id,
					currency_id: purchasePrice.selectedCurrency.value,
					vat_rate_id: purchasePrice.selectedVATRate.value,
					price: parseFloat(purchasePrice.price.value).toFixed(4),
				}
			})
		};

	    this.fetching = true;
	    if(this.editMode){
	    	this.assetApi.updateAsset(this.id, payload)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Asset updated.');
	    			this.uiStore.goToAssets();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }else{
	    	this.assetApi.newAsset(payload)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Asset added.');
	    			this.uiStore.goToAssets();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	formSync(assetId){
		this.addMode = false;
		this.viewMode = true;

		this.id = assetId;
		this.fetching = true;
		this.assetApi.getById(assetId)
			.then((response) => {
				let asset = response.asset;

				this.id = asset.id;
				this.name.onChange(asset.name);
				this.modelNumber.onChange(asset.model_number);

				this.defaultPurchasePrices = asset.purchase_prices.map((purchasePrice) => {
					return this.generatePurchasePriceListEntry(purchasePrice.id, purchasePrice.currency.id, purchasePrice.vat_rate.id, purchasePrice.price);
				});
				this.defaultRentalPrices = asset.rental_prices.map((rentalPrice) => {
					return this.generateRentalPriceListEntry(rentalPrice.id, rentalPrice.currency.id, rentalPrice.vat_rate.id, rentalPrice.price, rentalPrice.rental_days);
				})
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			});
	}
	
	@action onCancel(){
		this.uiStore.goToAssets();
	}

	@action generateRentalPriceListEntry(id=null, currencyId=null, vatRateId=null, price=null, days=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null || this.days.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				if(this.days.error != undefined) return null;
				let days = parseInt(this.days.value, 10);
				return `${currency.symbol}${totalPrice} every ${days} ${days > 1 ? 'days' : 'day'}`
			}
		})
	}

	@action generatePurchasePriceListEntry(id=null, currencyId=null, vatRateId=null, price=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				return `${currency.symbol}${totalPrice}`
			}
		})
	}

	@action addPurchasePriceListEntry(){
		this.defaultPurchasePrices.push(this.generatePurchasePriceListEntry())
	}

	@action addRentalPriceListEntry(){
		this.defaultRentalPrices.push(this.generateRentalPriceListEntry())
	}

	setPurchasePriceListItem(uuid, val, key){
		let entry = this.defaultPurchasePrices.find((p) => p.uuid == uuid);
		if(entry != null){
			entry[key].onChange(val);
		}
	}

	@action changePurchasePriceListItemCurrency(uuid, val){
		this.setPurchasePriceListItem(uuid, val, 'selectedCurrency');
	}

	@action changePurchasePriceListItemPrice(uuid, val){
		this.setPurchasePriceListItem(uuid, val, 'price');
	}

	@action changePurchasePriceListItemVATRate(uuid, val){
		this.setPurchasePriceListItem(uuid, val, 'selectedVATRate');
	}

	deletePurchasePriceListItem(uuid){
		this.defaultPurchasePrices = this.defaultPurchasePrices.filter((p) => p.uuid != uuid);
	}

	setRentalPriceListItem(uuid, val, key){
		let entry = this.defaultRentalPrices.find((p) => p.uuid == uuid);
		if(entry != null){
			entry[key].onChange(val);
		}
	}

	@action changeRentalPriceListItemCurrency(uuid, val){
		this.setRentalPriceListItem(uuid, val, 'selectedCurrency');
	}

	@action changeRentalPriceListItemPrice(uuid, val){
		this.setRentalPriceListItem(uuid, val, 'price');
	}

	@action changeRentalPriceListItemVATRate(uuid, val){
		this.setRentalPriceListItem(uuid, val, 'selectedVATRate');
	}

	@action changeRentalPriceListItemDays(uuid, val){
		this.setRentalPriceListItem(uuid, val, 'days');
	}

	deleteRentalPriceListItem(uuid){
		this.defaultRentalPrices = this.defaultRentalPrices.filter((p) => p.uuid != uuid);	
	}

	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: vatRate.id,
				label: vatRate.name
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				value: currency.id,
				label: `${currency.name} (${currency.symbol})`
			}
		})
	}
}

export default ManageAsset;