import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, SearchForm, Pagination, Status} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('assetList')
@observer
class Asset extends Component{
	constructor(props){
		super(props);
		this.renderListItem = this.renderListItem.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	componentDidMount(){
		let assetList = this.props.assetList;
		assetList.fetchAssets();
	}

	componentWillUnmount(){
		let assetList = this.props.assetList;
		assetList.initStore();
	}

	onPreviousPage(){
		let assetList = this.props.assetList;
		assetList.onPreviousPage();
	}

	onNextPage(){
		let assetList = this.props.assetList;
		assetList.onNextPage();
	}

	renderListItem(item){
		let assetList = this.props.assetList;
		return (
			<ListItem onEdit={() => assetList.onEdit(item.id)}>
				<ItemColumn text={item.name} />
			</ListItem>
		)
	}

	render(){
		let assetList = this.props.assetList;
		return (
			<Main>
				<Card title="Assets" 
					search={
					  	<SearchForm 
					  		placeholder="Search by Asset Name"
					  		onChange={(e) => assetList.changeSearchText(e.target.value)}
					  		 />
					  }
					actionBtn={
						<Button onClick={() => assetList.onNewAsset()}>New Asset</Button>
					}>
					<List 
						type="asset"
						items={assetList.assets}
						renderListItem={this.renderListItem}
						page={assetList.page}
						total={assetList.total}
						hasNext={assetList.hasNext}
						hasPrev={assetList.hasPrev}
						offsetStart={assetList.offsetStart}
						offsetEnd={assetList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Asset, ['administrator', 'employee', 'accountant']);