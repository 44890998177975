import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, SearchForm} from '../elements/ui';
import {Button} from '../elements/input';

import Authorisation from '../authorisation';

@inject('userList')
@observer
class Users extends Component{
	constructor(props){
		super(props);
		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
	}

	componentDidMount(){
		let userList = this.props.userList;
		userList.fetchUsers();
	}

	componentWillUnmount(){
		let userList = this.props.userList;
		userList.initStore();
	}

	onPreviousPage(){
		let userList = this.props.userList;
		userList.onPreviousPage();
	}

	onNextPage(){
		let userList = this.props.userList;
		userList.onNextPage();
	}

	renderListItem(item){
		let userList = this.props.userList;
		let inviteAccepted = item != undefined && item.invites != null && item.invites.length > 0 ? item.invites[0].invite_accepted : false;
		return (
			<ListItem onEdit={() => userList.onEdit(item.id)} gridCols={3}>
				<ItemColumn text={item.name} subtext={item.email} />
				{item.roles.map((r) => 
					<Status text={r.role_name} />
				)}
				<Status text={inviteAccepted ? 'Invite Accepted' : 'Invite Pending'} color={inviteAccepted ? 'green' : 'red'} />
			</ListItem>
		)
	}

	render(){
		let userList = this.props.userList;
		return (
			<Main>
				<Card title="Users" 
					actionBtn={
						<Button onClick={() => userList.onNewUser()}>Invite New User</Button>
					}
					search={
				  	<SearchForm 
				  		placeholder="Search by Name"
				  		onChange={(e) => userList.changeSearchText(e.target.value)}
				  		 />
				  }
					>
					<List 
						type="users"
						items={userList.users}
						renderListItem={this.renderListItem}
						page={userList.page}
						total={userList.total}
						hasNext={userList.hasNext}
						hasPrev={userList.hasPrev}
						offsetStart={userList.offsetStart}
						offsetEnd={userList.offsetEnd}
						onPreviousPage={this.onPreviousPage}
						onNextPage={this.onNextPage}
					/>
				</Card>
			</Main>
		)
	}
}

export default Authorisation(Users, ['administrator']);