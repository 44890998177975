import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed, extendObservable } from 'mobx';

import UserApi from '../../services/user';

class UserList{
	@observable users;
	@observable page;
	@observable total;
	@observable hasNext;
	@observable hasPrev;
	@observable offsetStart;
	@observable offsetEnd;

	@observable searchText;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.userApi = new UserApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.users = [];
		this.page = 1;
		this.total = 0;
		this.hasNext = false;
		
		this.hasPrev = false;
		this.offsetStart = null;
		this.offsetEnd = null;
		this.searchText = null;
	}

	fetchUsers(){
		this.userApi.getAll(this.page, this.searchText)
			.then((response) => {
				this.users = response.users;
				
				this.page = response.page;
				this.total = response.total;
				this.hasPrev = response.has_prev;
				this.hasNext = response.has_next;
				this.offsetStart = response.offset_start;
				this.offsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchUsers();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchUsers();
	}

	onEdit(id){
		this.uiStore.goToEditUser(id);
	}

	onNewUser(){
		this.uiStore.goToNewUser();
	}

	changeSearchText(val){
		this.page = 1;
		this.searchText = val;
		this.fetchUsers();
	}
}

export default UserList;