import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPostcodeValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import { uuid } from 'uuidv4';

import {DISPLAY_DATE_FORMAT, SERVER_DATE_FORMAT} from '../../services/util';

import AccountApi from '../../services/account';
import AssetApi from '../../services/asset';
import ServiceApi from '../../services/service';
import PricingApi from '../../services/pricing';
import AgreementApi from '../../services/agreement';

import moment from 'moment';

class ManageAgreement{
	id;

	@observable editMode;
	@observable viewMode;
	@observable addMode;

	@observable fetching;

	@observable accountSearchText;
	@observable accounts;
	@observable selectedAccountId;
	@observable agreementAccount;

	@observable agreementReference;
	@observable agreementStartDate;
	@observable agreementEndDate;

	@observable trialStartDate;
	@observable trialDays;
	@observable trial;
	@observable createdOn;
	@observable closedOn;

	@observable poNumber;
	@observable agreementItems;

	@observable assets;
	@observable services;

	@observable currentAgreement;
	@observable closed;

	@observable agreementTypes;

	accountApi;
	assetApi;	
	serviceApi;
	pricingApi;
	agreementApi;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.accountApi = new AccountApi(uiStore);
		this.assetApi = new AssetApi(uiStore);
		this.serviceApi = new ServiceApi(uiStore);
		this.pricingApi = new PricingApi(uiStore);
		this.agreementApi = new AgreementApi(uiStore);
		this.initStore();
	}

	initStore(){
		this.fetching = false;
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.accounts = [];
		this.accountSearchText = new FieldState(null);
		this.agreementAccount = null;
		this.selectedAccountId = null;
		this.trial = new FieldState(false);
		this.agreementReference = new FieldState(null).validators((val) => isRequiredValidator(val, 'agreement reference'));
		this.agreementStartDate = new FieldState(null);
		this.agreementEndDate = new FieldState(null);
		this.trialStartDate = new FieldState(null);
		this.trialDays = new FieldState(null).validators((val) => val != null && isPositiveNumberValidator(val));
		this.poNumber = new FieldState(null);
		this.agreementItems = [];
		this.assets = [];
		this.services = [];
		this.currentAgreement = null;
		this.createdOn = new FieldState(null);
		this.closedOn = new FieldState(null);
		this.closed = false;
		this.agreementTypes = [];
	}

	fetchAgreementTypes(){
		this.agreementApi.getAgreementTypes()
			.then((response) => {
				this.agreementTypes = response.agreement_types;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onChangeAccountSearchText(val){
		this.accountSearchText.onChange(val);
		this.selectedAccountId = null;
		this.searchAccounts(this.accountSearchText.value);
	}

	searchAccounts = async (searchText) => {
		if(searchText == ''){
			this.accounts = [];
			return;
		}
		this.fetching = true;
		try{
			this.accounts = await this.accountApi.fetchAllAccounts(searchText);
		}catch(error){
			console.log(error);
		}finally{
			this.fetching = false
		}		
	}

	selectAccount = async (id) => {
		this.selectedAccountId = id;
		this.fetching = true;
		try{
			let response = await this.accountApi.getAccountById(id);
			this.agreementAccount = response.account;
		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}
	}

	@action toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	@action toggleViewMode(){
		this.setAgreement(this.currentAgreement);
		this.editMode = false;
		this.viewMode = true;
		this.addMode = false;
	}


	setAgreement(agreement){
		this.id = agreement.id;
		this.agreementReference.value = agreement.agreement_reference;
		this.agreementStartDate.value = agreement.agreement_start_date != null ? moment(agreement.agreement_start_date) : null;
		this.agreementEndDate.value = agreement.agreement_end_date != null ? moment(agreement.agreement_end_date) : null;
		this.trial.value = agreement.trial_agreement;
		this.poNumber.value = agreement.po_number;
		this.trialStartDate.value = agreement.trial_start_date != null ? moment(agreement.trial_start_date) : null;
		this.trialDays.value = agreement.trial_days;
		this.closed = agreement.closed;
		this.createdOn.value = moment(agreement.created_on);
		this.closedOn.value = moment(agreement.closed_on);
		this.agreementItems = agreement.items.map((rentalItem) => {
			return this.generateAgreementListItemEntry(rentalItem.id, rentalItem.asset.id, rentalItem.agreement_type.id, rentalItem.serial_number, 
														rentalItem.location, rentalItem.price, rentalItem.days, 
														rentalItem.requires_service, rentalItem.service?.id, 
														rentalItem.service_price, rentalItem.service_days);
		})
		this.viewMode = true;
		this.editMode = false;
		this.addMode = false;
	}

	formSync = async (id) => {
		try{
			let response = await this.agreementApi.getById(id);
			let agreement = response.agreement;
			this.currentAgreement = agreement;
			await this.selectAccount(agreement.account.id);
			this.setAgreement(agreement);
		}catch(e){
			console.log(e)
		}
	}

	save = async () => {
		try{
			this.fetching = true;
			let trialAgreement = this.trial.value;

			let payload = {
				account_id: this.selectedAccountId,
			    agreement_reference: this.agreementReference.value,
			    trial_agreement: trialAgreement,
			    trial_start_date: trialAgreement ? this.trialStartDate.value.format(SERVER_DATE_FORMAT) : null,
			    trial_days: trialAgreement ? parseInt(this.trialDays.value, 10): null,
			    agreement_start_date: this.agreementStartDate.value.format(SERVER_DATE_FORMAT),
			    agreement_end_date: this.agreementEndDate.value?.format(SERVER_DATE_FORMAT),
			    po_number: this.poNumber.value,
			    items: this.agreementItems.map((item) => {
			    	let requiresService = item.requiresService.value;
			    	return {
			    		id: item.id,
			    		asset_id: item.selectedAsset.value,
			    		serial_number: item.serialNumber.value,
			    		agreement_type_id: parseInt(item.selectedAgreementType.value, 10),
			    		price: parseFloat(item.price.value).toFixed(4),
			    		days: !item.isOneOffPurchase ? parseFloat(item.days.value).toFixed(4): null,
			    		location: item.location.value,
			    		requires_service: requiresService,
			    		service_id: requiresService ? item.selectedService.value : null,
			    		service_price: requiresService ? item.servicePrice.value: null,
			    		service_days: requiresService ? item.serviceDays.value : null
			    	}
			    })
			}
			if(this.addMode){
				let response = await this.agreementApi.newAgreement(payload);
			}else{
				let response = await this.agreementApi.updateAgreement(this.id, payload);
			}
			this.uiStore.alertSuccess('Agreement saved');
			this.uiStore.goToAgreements();

		}catch(e){
			console.log(e)
		}finally{
			this.fetching = false;
		}

	}

	fetchAssets = async () => {
		try{
			this.assets  = await this.assetApi.fetchAllAssets();
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get trialEndDate(){
		if(this.trialStartDate.value == null) return null;
		if(this.trialDays.value == null) return null;
		let trialDays = parseFloat(this.trialDays.value);
		if(isNaN(trialDays)) return null;
		return this.trialStartDate.value.clone().add(trialDays, 'days').format('ll');
	}

	@action generateAgreementListItemEntry(id=null, assetId=null, agreementTypeId=null, serialNumber=null, location=null, price=null, days=null, requiresService=false, serviceId=null, servicePrice=null, serviceDays=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedAsset: new FieldState(assetId).validators((val) => isNotNullValidator(val, 'asset')),
			selectedAgreementType: new FieldState(agreementTypeId).validators((val) => isNotNullValidator(val, 'agreement type')),
			serialNumber: new FieldState(serialNumber).validators((val) => isRequiredValidator(val, 'serial number')),
			location: new FieldState(location),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators((val) => !this.isOneOffPurchase && isPositiveNumberValidator(val)),
			requiresService: new FieldState(requiresService),
			selectedService: new FieldState(serviceId).validators((val) => requiresService && isNotNullValidator(val, 'service')),
			servicePrice: new FieldState(servicePrice).validators(isPositiveNumberValidator),
			serviceDays: new FieldState(serviceDays).validators((val) => requiresService && isPositiveNumberValidator(val)),
			get isOneOffPurchase(){
				let currentAgreementType = that.agreementTypes.find((at) => at.id == this.selectedAgreementType.value);
				return currentAgreementType != null ? currentAgreementType.is_one_purchase : false;
			}
		})
	}

	@action changeAgreementItemAsset(uuid, val){
		let assetId = val;
		let agreementItemIdx = this.agreementItems.findIndex((x) => x.uuid == uuid);
		if(agreementItemIdx != -1){
			this.agreementItems[agreementItemIdx].selectedAsset.value = assetId;
			this.getAccountAssetPrice(agreementItemIdx, assetId);
		}
	}

	@action changeAgreementItemAgreementType(uuid, val){
		let agreementTypeId = val;
		let agreementItemIdx = this.agreementItems.findIndex((x) => x.uuid == uuid);
		if(agreementItemIdx != -1){
			this.agreementItems[agreementItemIdx].selectedAgreementType.value = agreementTypeId;
		}
	}

	@action changeAgreementItemService(uuid, val){
		let serviceId = val;
		let agreementItemIdx = this.agreementItems.findIndex((x) => x.uuid == uuid);
		if(agreementItemIdx != -1){
			this.agreementItems[agreementItemIdx].selectedService.value = serviceId;
			this.getAccountServicePrice(agreementItemIdx, serviceId);
		}
	}

	@action addAgreementLineItem(){
		this.agreementItems.push(this.generateAgreementListItemEntry())
	}

	@computed get assetOptions(){
		return this.assets.map((asset) => {
			return {
				value: asset.id,
				label: asset.name
			}
		})
	}

	fetchServices = async () => {
		try{
			this.services  = await this.serviceApi.fetchAllServices();
		}catch(error){ 
			console.log(error);
		}
	}


	@computed get serviceOptions(){
		return this.services.map((service) => {
			return {
				value: service.id,
				label: service.name
			}
		})
	}

	@action deleteAgreementListItem(uuid){
		this.agreementItems = this.agreementItems.filter((x) => x.uuid != uuid);
	}

	@computed get agreementTypeOptions(){
		return this.agreementTypes.map((at) => {
			return {
				value: at.id,
				label: at.name
			}
		})
	}

	getAccountAssetPrice(agreementItemIdx, assetId){
		let currencyId = this.agreementAccount.currency?.id;
		this.fetching = true;
		this.pricingApi.getAccountAssetPrice(this.selectedAccountId, assetId, currencyId, true)
			.then((response) => {
				let specialPrice = response.special_price;
				this.agreementItems[agreementItemIdx].price.onChange(specialPrice.price);
				this.agreementItems[agreementItemIdx].days.onChange(specialPrice.rental_days);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}

	getAccountServicePrice(agreementItemIdx, serviceId){
		let currencyId = this.agreementAccount.currency?.id;
		this.fetching = true;
		this.pricingApi.getAccountServicePrice(this.selectedAccountId, serviceId, currencyId, true)
			.then((response) => {
				let specialPrice = response.special_price;
				this.agreementItems[agreementItemIdx].servicePrice.onChange(specialPrice.price);
				this.agreementItems[agreementItemIdx].serviceDays.onChange(specialPrice.recurring_days);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			})
	}	

	patchAgreementStatus(closed){
		this.fetching = true;
		this.agreementApi.patchAgreement(this.id, {
				closed: closed
			})
			.then((response) => {
				this.currentAgreement = response.agreement;
				this.setAgreement(this.currentAgreement);
				this.uiStore.alertSuccess(`Agreement has been ${this.currentAgreement.closed ? 'closed' : 'opened'}`);
				this.toggleViewMode();
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false
			})
	}

	closeAgreement(){
		let confirmed = window.confirm('Are you sure you want to close this agreement?')
		if(confirmed){
			this.patchAgreementStatus(true);
		}
	}

	openAgreement(){
		let confirmed = window.confirm('Are you sure you want to re-open this agreement?')
		if(confirmed){
			this.patchAgreementStatus(false);
		}
	}
}

export default ManageAgreement;