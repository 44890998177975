import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed, extendObservable } from 'mobx';

import PODApi from '../../services/pod';

import moment from 'moment';

class PODList{
	@observable pods;
	@observable page;
	@observable total;
	@observable hasNext;
	@observable hasPrev;
	@observable offsetStart;
	@observable offsetEnd;

	@observable searchText;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.podApi = new PODApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.pods = [];
		this.page = 1;
		this.total = 0;
		this.hasNext = false;
		this.hasPrev = false;
		this.offsetStart = null;
		this.offsetEnd = null;
		this.searchText = null;
	}

	fetchPODs(){
		this.podApi.getAll(this.page, this.searchText)
			.then((response) => {
				this.pods = response.pods.map((o) => {
					o.created_on = moment(o.created_on)
					return o;
				});

				this.page = response.page;
				this.total = response.total;
				this.hasPrev = response.has_prev;
				this.hasNext = response.has_next;
				this.offsetStart = response.offset_start;
				this.offsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchPODs();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchPODs();
	}

	onEdit(id){
		this.uiStore.goToEditPOD(id);
	}

	onNewPOD(){
		this.uiStore.goToNewPOD();
	}

	changeSearchText(val){
		this.searchText = val;
		this.page = 1;
		this.fetchPODs();
	}
}

export default PODList;