import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed, extendObservable } from 'mobx';

import CreditNoteApi from '../../services/credit-note';

import moment from 'moment';

class CreditNoteList{
	@observable creditNotes;
	@observable page;
	@observable total;
	@observable hasNext;
	@observable hasPrev;
	@observable offsetStart;
	@observable offsetEnd;

	@observable searchText;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.creditNoteApi = new CreditNoteApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.creditNotes = [];
		this.page = 1;
		this.total = 0;
		this.hasNext = false;
		this.hasPrev = false;
		this.offsetStart = null;
		this.offsetEnd = null;
		this.searchText = null;
	}

	fetchCreditNotes(){
		this.creditNoteApi.getCreditNotes(this.page, null, this.searchText)
			.then((response) => {
				this.creditNotes = response.credit_notes.map((o) => {
					o.logged_on = moment(o.logged_on)
					return o;
				});

				this.page = response.page;
				this.total = response.total;
				this.hasPrev = response.has_prev;
				this.hasNext = response.has_next;
				this.offsetStart = response.offset_start;
				this.offsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchCreditNotes();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchCreditNotes();
	}

	onEdit(id){
		this.uiStore.goToEditCreditNote(id);
	}

	onNewCreditNote(){
		this.uiStore.goToNewCreditNote();
	}

	changeSearchText(val){
		this.searchText = val;
		this.page = 1;
		this.fetchCreditNotes();
	}
}

export default CreditNoteList;