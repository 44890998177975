import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, DateTimePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, DownloadIcon} from '../common/icon';
import AccountSearchInput from '../common/account-search';


@observer
class InvoiceInformation extends Component{
	constructor(props){
		super(props);
	}



	render(){
		let invoice = this.props.invoice;

		let currencySymbol = invoice.currency?.symbol;
		return (
			<FormDivider>
		   		<div>
			      	<SubHeading>
			          Invoice Information
			        </SubHeading>
			        <Paragraph>
			          What has been paid and is due
			        </Paragraph>
			    </div>
			    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
			    	<div className="sm:col-span-2">
			   			<Label>Invoice Reference</Label>
			   			<Paragraph>{invoice.invoice_number}</Paragraph>
			   		</div>
			   		<div className="sm:col-span-2">
			   			<Label>Invoice Date</Label>
			   			<Paragraph>{invoice.invoice_date.format('DD/MM/YYYY')}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Invoice Status</Label>
			   			<Status text={invoice.status} />
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Amount Due</Label>
			   			<Paragraph>{currencySymbol}{invoice.amount_due}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Amount Credited</Label>
			   			<Paragraph>{currencySymbol}{invoice.amount_credited}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Amount Paid</Label>
			   			<Paragraph>{currencySymbol}{invoice.amount_paid}</Paragraph>
			   		</div>

			    </div>
		   	</FormDivider>
		)
	}
}

export default InvoiceInformation;