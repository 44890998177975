import { FormState, FieldState } from 'formstate';
import { isRequiredValidator, isNotNullValidator, isEmailValidator, isPositiveNumberValidator } from '../../services/validation';
import { observable, computed, action } from 'mobx';

import ServiceApi from '../../services/service';
import CommonApi from '../../services/common'

import moment from 'moment';

import { uuid } from 'uuidv4';

class ManageService{
	serviceApi;

	@observable addMode;
	@observable viewMode;
	@observable editMode;

	id;
	@observable name;
	@observable serviceReference;

	@observable fetching;

	@observable currencies;
	@observable vatRates;

	@observable priceList;

	form;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.serviceApi = new ServiceApi(this.uiStore);
		this.commonApi = new CommonApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.addMode = true;
		this.viewMode = false;
		this.editMode = false;
		this.id = null;
		this.name = new FieldState('').validators((val) => isRequiredValidator(val, 'name'));
		this.serviceReference = new FieldState('').validators((val) => isRequiredValidator(val, 'service reference'));
		this.form = new FormState({
			name: this.name,
			serviceReference: this.serviceReference
		});
		this.vatRates = [];
		this.currencies = [];
		this.priceList = [];
	}

	fetchCurrencies = async () => {
		try{
			let response = await this.commonApi.getAllCurrencies();
			this.currencies = response.currencies;
		}catch(error){ 
			console.log(error);
		}
	}

	fetchVATRates = async () => {
		try{
			let response = await this.commonApi.getAllVATRates();
			this.vatRates = response.vat_rates;
		}catch(error){ 
			console.log(error);
		}
	}

	@computed get vatRateOptions(){
		return this.vatRates.map((vatRate) => {
			return {
				value: parseInt(vatRate.id, 10),
				label: vatRate.name
			}
		})
	}

	@computed get currencyOptions(){
		return this.currencies.map((currency) => {
			return {
				value: parseInt(currency.id, 10),
				label: `${currency.name} (${currency.symbol})`
			}
		})
	}

	@computed get validForm(){
		return this.name.value != null && this.name.value.length > 0;
	}

	toggleEditMode(){
		this.editMode = true;
		this.viewMode = false;
		this.addMode = false;
	}

	formSync(serviceId){
		this.addMode = false;
		this.viewMode = true;

		this.id = serviceId;
		this.fetching = true;
		this.serviceApi.getById(serviceId)
			.then((response) => {
				let service = response.service;

				this.id = service.id;
				this.name.onChange(service.name);
				this.serviceReference.value = service.service_reference;

				this.priceList = service.prices.map((price) => {
					return this.generatePriceListEntry(price.id, price.currency.id, price.vat_rate.id, price.price, price.recurring, price.recurring_days);
				})
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.fetching = false;
			});
	}
	
	@action onCancel(){
		this.uiStore.goToServices();
	}

	@action generatePriceListEntry(id=null, currencyId=null, vatRateId=null, price=null, recurring=false, days=null){
		let that = this;
		return observable.object({
			id: id,
			uuid: uuid(),
			selectedCurrency: new FieldState(currencyId).validators((val) => isNotNullValidator(val, 'currency')),
			selectedVATRate: new FieldState(vatRateId).validators((val) => isNotNullValidator(val, 'VAT rate')),
			recurring: new FieldState(recurring).validators((val) => isNotNullValidator(val, 'recurring')),
			price: new FieldState(price).validators(isPositiveNumberValidator),
			days: new FieldState(days).validators((val) => val != null && isPositiveNumberValidator(val)),
			get subtotal(){
				if(this.selectedCurrency.value == null || this.selectedVATRate.value == null || this.price.value == null) return null;
				let currency = that.currencies.find((c) => c.id == this.selectedCurrency.value);
				if(currency == null) return null;
				let vatRate = that.vatRates.find((c) => c.id == this.selectedVATRate.value);
				if(vatRate == null) return null;
				if(this.price.error != undefined) return null;
				let beforeVATPrice = parseFloat(this.price.value);
				let vatAddOn = parseFloat(beforeVATPrice * (vatRate.vat_rate/100));
				let totalPrice = (beforeVATPrice + vatAddOn).toFixed(4);
				let isRecurring = this.recurring.value;
				if(isRecurring && this.days.error != undefined) return null;
				let priceMsg = `${currency.symbol}${totalPrice}`
				let days = this.days.value;
				if(isRecurring && days != null){
					priceMsg = `${priceMsg} every ${days} ${days > 1 ? 'days' : 'day'}`
				}
				return priceMsg
			}
		})
	}

	@action addPriceListItemEntry(){
		this.priceList.push(this.generatePriceListEntry())
	}

	changePriceListItemCurrency(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedCurrency');
	}

	changePriceListItemPrice(uuid, val){
		this.setPriceListItem(uuid, val, 'price');
	}

	changePriceListItemVATRate(uuid, val){
		this.setPriceListItem(uuid, val, 'selectedVATRate');
	}

	changePriceListItemRecurring(uuid, val){
		this.setPriceListItem(uuid, val, 'recurring');
	}

	changePriceListItemDays(uuid, val){
		this.setPriceListItem(uuid, val, 'days');	
	}

	deletePriceListItem(uuid){
		this.priceList = this.priceList.filter((p) => p.uuid != uuid);
	}

	setPriceListItem(uuid, val, key){
		let entry = this.priceList.find((p) => p.uuid == uuid);
		if(entry != null){
			entry[key].onChange(val);
		}
	}

	@action save = async () => {
		const priceListForm = new FormState(this.priceList.map((priceListItem) => {
			return new FormState({
				price: priceListItem.price,
				selectedVATRate: priceListItem.selectedVATRate,
				selectedCurrency: priceListItem.selectedCurrency,
				recurring: priceListItem.recurring,
				days: priceListItem.days
			}).validators(($) => {
				let validEntry = true;
				if($.recurring.value && $.days.value == null){
					return 'Please specify a recurring days value'
				}else{
					return null;
				}
			});
		}));

		
		let priceListValidation = await priceListForm.validate();
		if(priceListValidation.hasError) return;

		const res = await this.form.validate();
	    if(res.hasError) return;

	    let payload = {
			name: this.name.value,
			service_reference: this.serviceReference.value,
			default_prices: this.priceList.map((priceListEntry) => {
				return {
					id: priceListEntry.id,
					currency_id: priceListEntry.selectedCurrency.value,
					vat_rate_id: priceListEntry.selectedVATRate.value,
					price: parseFloat(priceListEntry.price.value).toFixed(4),
					recurring: priceListEntry.recurring.value ,
					recurring_days: parseInt(priceListEntry.days.value, 10)
				}
			})
		};

	    this.fetching = true;
	    if(this.editMode){
	    	this.serviceApi.updateService(this.id, payload)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Service updated.');
	    			this.uiStore.goToServices();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }else{
	    	this.serviceApi.newService(payload)
	    		.then((response) => {
	    			this.uiStore.alertSuccess('Service added.');
	    			this.uiStore.goToServices();
	    		})
	    		.catch((error) => {
	    			console.log(error);
	    		})
	    		.finally(() => {
	    			this.fetching = false;
	    		})
	    }
	}
}

export default ManageService;