import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, ArchivedStatus, FormDivider} from '../elements/ui';
import {TextInput, TextArea, DatePicker, DateTimePicker, Select, CheckBox, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon, DownloadIcon} from '../common/icon';


@observer
class CreditNoteInformation extends Component{
	constructor(props){
		super(props);
	}



	render(){
		let creditNote = this.props.creditNote;

		let currencySymbol = creditNote.currency?.symbol;
		return (
			<FormDivider>
		   		<div>
			      	<SubHeading>
			          Credit Note Information
			        </SubHeading>
			        <Paragraph>
			          What has been paid and is due
			        </Paragraph>
			    </div>
			    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
			    	<div className="sm:col-span-2">
			   			<Label>Credit Note Reference</Label>
			   			<Paragraph>{creditNote.credit_note_number}</Paragraph>
			   		</div>
			   		<div className="sm:col-span-2">
			   			<Label>Credit Note Date</Label>
			   			<Paragraph>{creditNote.credit_note_date.format('DD/MM/YYYY')}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Credit Note Status</Label>
			   			<Status text={creditNote.status} />
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Total</Label>
			   			<Paragraph>{currencySymbol}{creditNote.total}</Paragraph>
			   		</div>

			   		<div className="sm:col-span-2">
			   			<Label>Credit Remaining</Label>
			   			<Paragraph>{currencySymbol}{creditNote.remaining_credit}</Paragraph>
			   		</div>
			    </div>
		   	</FormDivider>
		)
	}
}

export default CreditNoteInformation;