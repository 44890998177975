import { FormState, FieldState } from 'formstate';
import { validator } from '../../services/validation';
import { observable, computed, extendObservable } from 'mobx';

import AccountApi from '../../services/account';

class AccountList{
	@observable accounts;
	@observable page;
	@observable total;
	@observable hasNext;
	@observable hasPrev;
	@observable offsetStart;
	@observable offsetEnd;

	@observable searchText;

	constructor(uiStore){
		this.uiStore = uiStore;
		this.accountApi = new AccountApi(this.uiStore);
		this.initStore();
	}

	initStore(){
		this.accounts = [];
		this.page = 1;
		this.total = 0;
		this.hasNext = false;
		this.hasPrev = false;
		this.offsetStart = null;
		this.offsetEnd = null;
		this.searchText = null;
	}

	fetchAccounts(){
		this.accountApi.getAll(this.page, this.searchText)
			.then((response) => {
				this.accounts = response.accounts;
				
				this.page = response.page;
				this.total = response.total;
				this.hasPrev = response.has_prev;
				this.hasNext = response.has_next;
				this.offsetStart = response.offset_start;
				this.offsetEnd = response.offset_end;
			})
			.catch((error) => {
				console.log(error);
			})
	}

	onNextPage(){
		this.page += 1;
		this.fetchAccounts();
	}

	onPreviousPage(){
		this.page = Math.max(this.page - 1, 0);

		this.fetchAccounts();
	}

	onEdit(id){
		this.uiStore.goToEditAccount(id);
	}

	onNewAccount(){
		this.uiStore.goToNewAccount();
	}

	changeSearchText(val){
		this.page = 1;
		this.searchText = val;
		this.fetchAccounts();
	}
}

export default AccountList;