import BaseApi from '../services/base';

class ServicingApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, fulfilled=null, pending=null){
		let queryUrl = `v1/servicings`;
		if(fulfilled != null){
			queryUrl = `${queryUrl}?fulfilled=${fulfilled}`
		}
		if(pending != null){
			queryUrl = `${queryUrl}?pending=${pending}`
		}
		return this.fetch(queryUrl, {
			method: 'POST',
			auth: true,
			body: JSON.stringify({
				page: page,
				fulfilled: fulfilled
			})
		})
	}

	deleteServicingById(id, payload){
		return this.fetch(`v1/servicings/${id}`, {
			method: 'DELETE',
			auth: true,
			body: JSON.stringify(payload)
		})
	}
}

export default ServicingApi;