import React, {Component} from 'react';

import { inject, observer } from 'mobx-react';

import Main from '../main';
import {Card, List, ListItem, ItemColumn, Table, Pagination, Status, FormDivider} from '../elements/ui';
import {TextInput, Select, CheckBox, DatePicker, RadioButton, Button, CancelButton} from '../elements/input';
import {Heading, SubHeading, Paragraph, Label} from '../elements/text';
import {DeleteIcon} from '../common/icon';

import AccountSearchInput from '../common/account-search';
import Authorisation from '../authorisation';

@inject('manageAgreement')
@observer
class ManageAgreement extends Component{
	constructor(props){
		super(props);
		this.renderAgreementLineItemListHeaders = this.renderAgreementLineItemListHeaders.bind(this);
		this.renderAgreementListItem = this.renderAgreementListItem.bind(this);
	}

	componentDidMount(){
		let manageAgreement = this.props.manageAgreement;
		manageAgreement.fetchAssets();
		manageAgreement.fetchServices();
		manageAgreement.fetchAgreementTypes();
		if(this.props.agreementId){
			manageAgreement.formSync(this.props.agreementId);
		}

		if(this.props.accountId){
			manageAgreement.selectAccount(this.props.accountId);
		}

	}

	componentWillUnmount(){
		let manageAgreement = this.props.manageAgreement;
		manageAgreement.initStore()
	}

	renderAgreementListItem(item){
		let manageAgreement = this.props.manageAgreement;
		return (
			<ListItem gridCols={3}>
				<div className="sm:col-span-1">
	              <Label>Asset</Label>
	              <Select 
	              	onChange={(val) => manageAgreement.changeAgreementItemAsset(item.uuid, val)}
           			placeholder="Choose asset"
           			disabled={manageAgreement.viewMode}
	           		options={manageAgreement.assetOptions} 
	           		value={item.selectedAsset.value}
	           		error={item.selectedAsset.error}
	           		/>
		        </div>
		        <div className="sm:col-span-1">
		         	<Label>Serial Number</Label>
	                <TextInput 
		              	type="text"
		              	placeholder="Serial Number"
		              	onChange={(val) => item.serialNumber.onChange(val)}
		              	disabled={manageAgreement.viewMode}
		            	value={item.serialNumber.value} 
		            	error={item.serialNumber.error}/>
		        </div>
		         <div className="sm:col-span-1">
		         	<Label>Agreement Type</Label>
	                <Select 
		              	onChange={(val) => manageAgreement.changeAgreementItemAgreementType(item.uuid, val)}
	           			placeholder="Choose agreement type"
	           			disabled={manageAgreement.viewMode}
		           		options={manageAgreement.agreementTypeOptions} 
		           		value={item.selectedAgreementType.value}
		           		error={item.selectedAgreementType.error}
		           		/>
		        </div>
		        
		        <div className="sm:col-span-1">
	                <Label>Price (Excl. VAT)</Label>
	                <TextInput 
		              	type="number"
		              	placeholder="Enter price"
		              	onChange={(val) => item.price.onChange(val)}
		              	disabled={manageAgreement.viewMode}
		            	value={item.price.value} 
		            	error={item.price.error}/>
		        </div>
		        {(!item.isOneOffPurchase) && 
		        	<div className="sm:col-span-1">
		                <Label>Charge every X days</Label>
		                <TextInput 
			              	type="number"
			              	placeholder="Every X days"
			              	onChange={(val) => item.days.onChange(val)}
			              	disabled={manageAgreement.viewMode}
			            	value={item.days.value} 
			            	error={item.days.error}/>
			        </div>
			    }
		        <div className="sm:col-span-1">
	                <Label>Location Note</Label>
	                <TextInput 
		              	type="text"
		              	placeholder="Location"
		              	onChange={(val) => item.location.onChange(val)}
		              	disabled={manageAgreement.viewMode}
		            	value={item.location.value} 
		            	error={item.location.error}/>
		        </div>
				<div className="sm:col-span-3">
					<div className="flex h-full flex-row items-center">
		        		<CheckBox 
				        	disabled={manageAgreement.viewMode}
				        	label="Does this item need serviced regularly?"
				        	checked={item.requiresService.value} 
				        	onChange={(e) => item.requiresService.onChange(e.target.checked)} />
				    </div>
				</div>
				{item.requiresService.value && 
		       		<>
			       		<div className="sm:col-span-1">
				       		<Label>Service</Label>
				       		<Select 
				              	onChange={(val) => manageAgreement.changeAgreementItemService(item.uuid, val)}
			           			placeholder="Choose service"
			           			disabled={manageAgreement.viewMode}
				           		options={manageAgreement.serviceOptions} 
				           		value={item.selectedService.value}
				           		error={item.selectedService.error}
				           		 />
				        </div>
				        <div className="sm:col-span-1">
			                <Label>Service Price (Excl. VAT)</Label>
			                <TextInput 
				              	type="number"
				              	placeholder="Enter service price"
				              	onChange={(val) => item.servicePrice.onChange(val)}
				              	disabled={manageAgreement.viewMode}
				            	value={item.servicePrice.value} 
				            	error={item.servicePrice.error}/>
				        </div>
				        <div className="sm:col-span-1">
				       		<Label>Service every X days</Label>
				       		<TextInput 
				              	type="number"
				              	placeholder="Service X days"
				              	onChange={(val) => item.serviceDays.onChange(val)}
				              	disabled={manageAgreement.viewMode}
				            	value={item.serviceDays.value} 
				            	error={item.serviceDays.error}/>
				        </div>
			        </>
		       	}
				<div className="sm:col-start-3 sm:col-span-1 flex flex-row justify-end items-end">
		        	<Button 
		        		fetching={manageAgreement.fetching} 
		        		bgColor="transparent" 
		        		fgColor='white' 
		        		disabled={manageAgreement.viewMode} 
		        		className="flex justify-end mt-1" 
		        		onClick={() => manageAgreement.deleteAgreementListItem(item.uuid)}><DeleteIcon color='text-red-400' /> </Button>
		        </div>
			</ListItem>
		)
	}

	renderAgreementLineItemListHeaders(){
		return (
			<ListItem gridCols={3}>
				<div className="sm:col-span-1">Asset</div>
				<div className="sm:col-span-1">Serial No.</div>
				<div className="sm:col-span-1">Location</div>
				<div className="sm:col-span-1">Charge</div>
				<div className="sm:col-span-1">Days</div>
				<div className="sm:col-span-2">Serviceable</div>
				<div className="sm:col-span-1"></div>
			</ListItem>
		)
	}


	render(){
		let manageAgreement = this.props.manageAgreement;

		let agreementTitle = 'New Agreement';
		if(manageAgreement.editMode){
			agreementTitle = 'Edit Agreement'
		}
		if(manageAgreement.viewMode){
			agreementTitle = 'View Agreement'
		}
		return (
			<Main>
				<>
					<div className="flex flex-row items-center justify-between">
				        <div className="flex flex-col">
					        <Heading>
					          {agreementTitle}
					        </Heading>
					        <Paragraph>
					          Agreement Details
					        </Paragraph>
					    </div>
					    <div className="flex">
					    	{manageAgreement.viewMode && 
					    		<Button fetching={manageAgreement.fetching} onClick={() => manageAgreement.toggleEditMode()}>
						          Edit Agreement
						        </Button>

						    }
					        {!manageAgreement.viewMode && manageAgreement.agreementAccount != null &&
								  <div className="flex justify-end">
									    {manageAgreement.editMode && 
									      	<span className="inline-flex rounded-md shadow-sm">
										        <CancelButton fetching={manageAgreement.fetching} onClick={() => manageAgreement.toggleViewMode()}>
										          Cancel changes
										        </CancelButton>
										      </span>
										}
									  	<span className="ml-3 inline-flex rounded-md shadow-sm">
									        {manageAgreement.closed && 
									        	<Button bgColor="green" fgColor="white" fetching={manageAgreement.fetching} onClick={() => manageAgreement.openAgreement()}>
										         	Open Agreement
										        </Button>
										    }
									    </span>
									    <span className="ml-3 inline-flex rounded-md shadow-sm">
									        <Button fetching={manageAgreement.fetching} onClick={() => manageAgreement.save()}>
									          {manageAgreement.editMode ? 'Update' : 'Save' } Agreement
									        </Button>
									    </span>
								  </div>
							}
					   	</div>
					</div>
				   	<AccountSearchInput
				   		accounts={manageAgreement.accounts}
				   		selectedAccountId={manageAgreement.selectedAccountId}
				   		disabled={manageAgreement.viewMode}
				   		onChange={(val) => manageAgreement.onChangeAccountSearchText(val)}
				   		onSelect={(id) => manageAgreement.selectAccount(id)}
				   		currentAccount={manageAgreement.agreementAccount}
				   		onGoToCustomer={(id) => manageAgreement.uiStore.goToEditCustomer(id)}
				   		onGoToAccount={(id) => manageAgreement.uiStore.goToEditAccount(id)}
				   	/>
					{manageAgreement.agreementAccount != null &&	
						<>
							<FormDivider>
				   				<div>
							      	<SubHeading>
							          Agreement Information
							        </SubHeading>
							        <Paragraph>
							          Information about this specific agreement
							        </Paragraph>
							    </div>
							    <div className="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
							    	<div className="sm:col-span-3">
							         	<Label>Agreement Reference</Label>
						                <TextInput 
							              	type="text"
							              	placeholder="Agreement Reference"
							              	onChange={(val) => manageAgreement.agreementReference.onChange(val)}
							              	disabled={manageAgreement.viewMode}
							            	value={manageAgreement.agreementReference.value} 
							            	error={manageAgreement.agreementReference.error}/>
							        </div>
							        
							        {!manageAgreement.addMode && 
							        	<>
								        	<div className="sm:col-span-1">
									         	<Label>Agreement Status</Label>
									         	<div className="mt-2">
								                	<Status text={manageAgreement.closed ? 'Closed': 'Open'} color={manageAgreement.closed ? 'red': 'green'} />
								                </div>
									        </div>
								        	<div className="sm:col-span-1">
									         	<Label>Created On</Label>
									         	<div className="mt-2">
								                	<Label>{manageAgreement.createdOn.value?.format('lll')}</Label>
								                </div>
									        </div>
									        {manageAgreement.closed && 
									        	<div className="sm:col-span-1">
										         	<Label>Closed On</Label>
										         	<div className="mt-2">
									                	<Label>{manageAgreement.closedOn.value?.format('lll')}</Label>
									                </div>
										        </div>
										    }
									    </>
								    }
							    	<div className="sm:col-span-6">
										<CheckBox 
								        	disabled={manageAgreement.viewMode}
								        	label="Trial agreement"
								        	checked={manageAgreement.trial.value} 
								        	onChange={(e) => manageAgreement.trial.onChange(e.target.checked)} />
								    </div>
								    {manageAgreement.trial.value && 
								    	<>
									    	<div className="sm:col-span-2">
										    	<Label>Trial Start Date</Label>
										    	<div className="flex">
											    	<DatePicker 
											    		disabled={manageAgreement.viewMode}
											    		placeholder="Trial start date"
											    		date={manageAgreement.trialStartDate.value}
											    		onDateChange={(date) => manageAgreement.trialStartDate.onChange(date)}
											    	/>
											    </div>
											</div>
											<div className="sm:col-span-2">
												<Label>Trial Days</Label>
												<div className="flex">
													<TextInput 
										              	type="number"
										              	placeholder="Trial days"
										              	onChange={(val) => manageAgreement.trialDays.onChange(val)}
										              	disabled={manageAgreement.viewMode}
										            	value={manageAgreement.trialDays.value} 
										            	error={manageAgreement.trialDays.error}/>
										        </div>
											</div>
											{manageAgreement.trialEndDate && 
												<div className="sm:col-span-2">
													<Label>Trial End Date</Label>
													<div className="flex">
														<TextInput value={manageAgreement.trialEndDate} disabled />
													</div>
												</div>
											}
										</>
									}
									{!manageAgreement.trial.value && 
							    		<>
								    		<div className="sm:col-span-3">
										    	<Label>Agreement Start Date</Label>
										    	<div className="flex">
											    	<DatePicker 
											    		disabled={manageAgreement.viewMode}
											    		placeholder="Choose agreement start date"
											    		date={manageAgreement.agreementStartDate.value}
											    		onDateChange={(date) => manageAgreement.agreementStartDate.onChange(date)}
											    	/>
											    </div>
											</div>
											<div className="sm:col-span-3">
										    	<Label>Agreement End Date</Label>
										    	<div className="flex">
											    	<DatePicker 
											    		disabled={manageAgreement.viewMode}
											    		placeholder="(Optional) Date this agreement will end"
											    		date={manageAgreement.agreementEndDate.value}
											    		onDateChange={(date) => manageAgreement.agreementEndDate.onChange(date)}
											    	/>
											    </div>
											</div>
										</>
									}
									<div className="sm:col-span-3">
										<Label>PO Number</Label>
										<div className="flex">
											<TextInput 
								              	type="text"
								              	placeholder="PO Number"
								              	onChange={(val) => manageAgreement.poNumber.onChange(val)}
								              	disabled={manageAgreement.viewMode}
								            	value={manageAgreement.poNumber.value} 
								            	error={manageAgreement.poNumber.error}/>
								        </div>
									</div>
							    </div>
							</FormDivider>
							<FormDivider>
								<Card title="Agreement Items" subtitle="Details of assets on this agreement" actionBtn={
									<Button disabled={manageAgreement.viewMode} onClick={() => manageAgreement.addAgreementLineItem()}>Add Agreement Item</Button>
								}>
							      	<List 
							      		type="line items"
							      		renderListItem={this.renderAgreementListItem}
							      		items={manageAgreement.agreementItems}
									/>
						    	</Card>
						    </FormDivider>
						</>
					}
				</>
			</Main>
		)
	}
}

export default Authorisation(ManageAgreement, ['administrator', 'employee', 'accountant']);