import BaseApi from '../services/base';

class ServiceApi extends BaseApi{
	constructor(props){
		super(props);
	}

	getAll(page, searchText=null){
		let url = `v1/services?page=${page}`;
		if(searchText != null){
			url = `${url}&search_text=${searchText}`
		}
		return this.fetch(url, {
			method: 'GET',
			auth: true
		})
	}

	fetchAllServices = async () => {
		let allData = [];
		let page = 1;
		let dataRemaining = true;
		while(dataRemaining){
			let response = await this.getAll(page);
			let data = response.services;
			if(data.length == 0){
				dataRemaining = false;
			}else{
				allData = allData.concat(data);
				page += 1
			}
		}
		return allData;
	}	

	getById(id){
		return this.fetch(`v1/services/${id}`, {
			method: 'GET',
			auth: true
		})
	}

	updateService(id, service){
		return this.fetch(`v1/services/${id}`, {
			method: 'PUT',
			auth: true,
			body: JSON.stringify(service)
		})
	}

	newService(service){
		return this.fetch(`v1/services`, {
			method: 'POST',
			auth: true,
			body: JSON.stringify(service)
		})
	}
}

export default ServiceApi;