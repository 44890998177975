
function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error("timeout"))
    }, ms)
    promise.then(resolve, reject)  })
}


export function apiFetch(url, options, uiStore) {
    let baseHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if(options === undefined){
        options = {};
    }
    let token = localStorage.getItem(uiStore.ACCESS_TOKEN_KEY);
    if('auth' in options && token !== null){
         baseHeaders['Authorization'] = `Bearer ${token}`;
    }

    if('contentType' in options){
        baseHeaders['Content-Type'] = options.contentType;
    }

    let apiOptions = Object.assign({}, options, {
        mode: 'cors',
        headers: baseHeaders
    });

    let silent = 'silent' in options ? options.silent : false;
    if(!silent){
        uiStore.startFetch();
    }

    let apiUri = `${process.env.REACT_APP_API_URL}/${url}`;
    return timeout(30 * 1000, fetch(apiUri, apiOptions))
            .then((response) => {
                return handleResponse(response, uiStore, baseHeaders['Content-Type']);
            })
            .catch((error) => {
                // Timeout error or something to that effect
                uiStore.completeFetch();
                throw new Error('An error has occurred.');
            });
}

function handleResponse(response, uiStore,contentType){
    uiStore.completeFetch();
    if (response.ok) {
        uiStore.resetErrors();
        if(contentType == 'application/pdf'){
            return Promise.resolve(response.blob())
        }else{
            return Promise.resolve(response.json());
        }
    } else {
        if(response.status === 401){
            uiStore.logout();
        }

        return response.json().then((json) => {
            let errorMsg = 'message' in json ? json.message : 'Looks like an error occured';
            uiStore.alertError(errorMsg);
            return Promise.reject(new Error(errorMsg));
        });
    }
}