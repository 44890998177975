import BaseApi from '../services/base';


class InviteApi extends BaseApi{
	constructor(props){
		super(props);
	}

	newInvite(invite){
		return this.fetch('v1/invites', {
			auth: true,
			method: 'POST',
			body: JSON.stringify(invite)
		})
	}

	resendInvite(userId){
		return this.fetch(`v1/invites/${userId}/resend`, {
			auth: true,
			method: 'POST'
		})
	}

	acceptInvite(token, password){
		return this.fetch('v1/invites/accept', {
			method: 'POST',
			body: JSON.stringify({
				invite_token: token,
				password: password
			})
		})
	}
}

export default InviteApi;