import BaseApi from './base';

class AuthApi extends BaseApi{
	constructor(props){
		super(props);
	}

	login(email, password){
		return this.fetch('v1/token', {
			auth: true,
			method: 'POST',
			body: JSON.stringify({
				email: email,
				password: password
			})
		})
	}
	
	refreshAccessToken(){
		return this.fetch('v1/token/refresh', {
			method: 'POST',
			auth: true,
			refresh: true
		})
	}
}

export default AuthApi;